import React, { useEffect, useState } from "react";
import Carousel from "react-grid-carousel";
import { NavLink, useNavigate } from "react-router-dom";
import { FaCaretRight } from "react-icons/fa";
import ReactStars from "react-rating-stars-component";
import ProviderImage from "../../../../images/provider-profile/Dr.BramlettHeadshot.webp";
import axios from "../../../../util/axiosInst";
import { ProvidersList } from "../../../../constants";

const Session4 = () => {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState();

  const getProviderTestimonials = async () => {
    const { data } = await axios.get(`/v1/public/testimonies/latest`);
    let tempdata = data?.filter((item) => item?.userType !== "patient");
    setTestimonials(tempdata);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProviderTestimonials();
  }, []);
  return (
    <section
      className={`px-0 mt-10rem ${window.innerWidth > 769 ? "wrapper" : ""}`}
    >
      <div className="text-center py-4" style={{ background: "#EBF5FF" }}>
        <h1 className="landing-display-1 ">
          Membership starts at only $15/month
        </h1>
        <p className="pt-4 text-black fs--22 fw-600">
          Learn more about membership benefits <FaCaretRight />
        </p>
        <button
          className="text-white btn mt-4 btn-primary rounded-pill px-5 fw-bold text-nowrap me-3 mb-lg-0 mb-3"
          type="button"
          onClick={() => navigate("/signup")}
        >
          Sign Up
        </button>
      </div>
      <h1
        className="landing-display-1 text-center"
        style={{ marginTop: "10rem" }}
      >
        Meet your team
      </h1>

      <div className=" pt-5">
        <div className="px-5">
          <Carousel cols={3} rows={1} gap={10} loop>
            {testimonials?.map((data) => (
              <Carousel.Item>
                <div className="px-4">
                  <div
                    className="w-100  px-4 pt-3 "
                    style={{
                      borderRadius: "40px",
                      height: "280px",
                      background: "#F5F5F7",
                    }}
                  >
                    <div className="d-flex">
                      <img
                        src={ProviderImage}
                        alt="Chat0"
                        className="rounded-circle"
                        style={{ width: "25%", height: "12%" }}
                        loading="lazy"
                      />
                      <div className="ms-3">
                        <h6 className="p-0 m-0 mt-1 fontweight-bold">
                          {data?.firstName}
                        </h6>
                        <p
                          className="text-muted p-0 m-0 mt-1"
                          style={{ fontSize: "11px" }}
                        >
                          {ProvidersList?.map((providerType) => (
                            <>
                              {providerType?.value === data?.userType
                                ? providerType?.label
                                : ""}
                            </>
                          ))}
                        </p>
                        <ReactStars
                          stars={5}
                          value={5}
                          isHalf={true}
                          size={22}
                          activeColor="#ffd700"
                          edit={false}
                        />
                      </div>
                    </div>
                    <p
                      className="pb-4 pt-2 fw-600 text-start testimonial-scrollbar"
                      style={{ height: "160px", overflow: "auto" }}
                    >
                      {data?.feedback}
                    </p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Session4;
