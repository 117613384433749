import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../images/final_logo 1.svg";
import Swal from "sweetalert2";
import Axios from "../../../../util/axiosInst";
import { IoLogoTwitter } from "react-icons/io";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import SOC2 from "../../../assets/images/soc2.png";
import HIPAA from "../../../assets/images/hipaa.jpg";
import Image from "../custom/Image";

const Footer = () => {
  const [email, setemail] = useState("");
  const navigate = useNavigate();

  const subscribe = async (email) => {
    let response;
    try {
      if (email) {
        response = await Axios.post("/v1/news-letter/subscribe", {
          email: email,
        });
      }
      if (response) {
        window.fbq("track", `Thank you for subscribing!`);
        setemail("");
        Swal.fire({
          icon: "success",
          title: "Thank you for subscribing!",
        });
      }
    } catch (e) {}
  };

  return (
    <footer
      className="text-white px-5 pt-4"
      style={{ backgroundColor: "#004aa0" }}
    >
      <div className="px-2 d-flex">
        <Image
          local={true}
          src={logo}
          alt="My Orthopedic Problem"
          className="width-8rem"
        />
        <img
          src={HIPAA}
          alt="Iphone banner"
          className="mx-3 mt-4"
          style={{ width: "5%", height: "1%" }}
        />
        <img
          src={SOC2}
          alt="Iphone banner"
          className="mt-4"
          style={{ width: "3%", height: "1%" }}
        />
        <p
          className="fw-600 px-2 mb-0"
          style={{ fontSize: "8px", marginTop: "33px" }}
        >
          SOC 2 TYPE 2<br />
          Certified
        </p>
      </div>
      <hr />
      <div className="ps-3">
        <div className={`${window.innerWidth < 768 ? "" : "row ms-2"}`}>
          <div className="col-lg-5 " style={{ height: "140px" }}>
            <div>
              <p className="fw-700 mb-2" style={{ fontSize: "22px" }}>
                Subscribe
              </p>
              <p>keep up to date with the most recent news and updates</p>
              <div className="row">
                <div className="col-md-6 col-lg-8 col-8">
                  <input
                    type="text"
                    id="exampleFormControlInput1"
                    className="form-control me-3 border-0 mb-md-0 mb-3 p-3"
                    placeholder="healthy@gmail.com"
                    onChange={(e) => setemail(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="col-md-6 col-lg-4 col-4">
                  <button
                    type="button"
                    className={` btn btn-primary btn-lg bg-white py-2 rounded-pill fw-semibold fs-md-6 fs-5 mb-md-0 mb-3 px-md-4 border-0 px-0  py-1 mt-1 d-flex justify-content-center`}
                    onClick={() => subscribe(email)}
                    style={{ color: "#004aa0" }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-7 mt-5 mt-md-0"
            style={{ paddingLeft: "4rem" }}
          >
            <div className="row">
              <div className="col-md-5 ps-3 col-12 text-md-start text-center">
                <p className="fw-700 mb-2" style={{ fontSize: "22px" }}>
                  For Individuals
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/signup")}
                >
                  Talk to a Provider
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/symptom-checker")}
                >
                  Symptom Checker
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/education-library")}
                >
                  Education Library
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/signup")}
                >
                  Medical Supplies
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/signup")}
                >
                  Online Prescriptions
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/signup")}
                >
                  Wellness Assessment
                </p>
              </div>
              <div className="col-md-4 col-12 text-md-start text-center">
                <p className="fw-700 mb-2" style={{ fontSize: "22px" }}>
                  For Providers
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/join-our-network")}
                >
                  Join Our Network
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/faq")}
                >
                  FAQ's
                </p>
              </div>
              <div className="col-md-3 col-12 text-md-start text-center">
                <p className="fw-700 mb-2" style={{ fontSize: "22px" }}>
                  About us
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/who-we-are")}
                >
                  Who We Are
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/why-trust-us")}
                >
                  Why Trust Us
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/who-we-work-with")}
                >
                  Who We Work With
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/newsroom")}
                >
                  Newsroom
                </p>
                <p
                  className="cursor-pointer fw-700 fs-13px"
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="text-white mt-2 text-nowrap d-md-flex d-none float-right">
        <p
          className="px-4 cursor-pointer"
          onClick={() => navigate("/termsandconditions")}
        >
          Terms
        </p>
        <p
          className="px-4 cursor-pointer"
          onClick={() => navigate("/privacypolicy")}
        >
          Privacy
        </p>
        <p
          className="px-4 cursor-pointer"
          onClick={() => navigate("/cookiepolicy")}
        >
          Cookies
        </p>
      </div>
      <div className="col-md-3 text-primary text-end text-nowrap d-md-flex d-none mt-0 justify-content-end">
        <a
          href="https://www.facebook.com/myorthoproblem"
          rel="noreferrer"
          target="_blank"
        >
          <FaFacebookSquare className="text-white m-3 width-30px height-30px" />
        </a>
        <a
          href="https://twitter.com/myorthoproblem"
          rel="noreferrer"
          target="_blank"
        >
          <IoLogoTwitter className="text-white m-3 width-30px height-30px" />
        </a>
        <a
          href="https://www.linkedin.com/company/my-orthopedic-problem"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin className="text-white m-3 width-30px height-30px" />
        </a>
        <a
          href="https://www.instagram.com/myorthopedicproblem"
          rel="noreferrer"
          target="_blank"
        >
          <FaInstagram className="text-white m-3 width-30px height-30px" />
        </a>
        <a
          href="https://www.youtube.com/@Myorthopedicproblem"
          rel="noreferrer"
          target="_blank"
        >
          <FaYoutube className="text-white m-3 width-30px height-30px" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
