import React from "react";
import LOW from "../../assets/images/Low.svg";
import MODERATE from "../../assets/images/moderate.svg";
import HIGH from "../../assets/images/high.svg";
import SEVERE from "../../assets/images/severe.svg";
import LoadingScreen from "../../../components/custom/LoadingScreen";
import { useNavigate } from "react-router-dom";

const Result = ({ result }) => {
  const navigate = useNavigate();
  return (
    <div className="container rounded shadow-lg bg-white p-5 text-center">
      <h1 className="mt-4 text-center fw-bold">Results</h1>
      {/* <h2 className="text-black fontweight-bold">Results</h2> */}
      <h5 className="fontweight-bold mt-4">
        Your Score :{" "}
        <span
          className={`fontweight-bold ${
            result.healthyKey === "low"
              ? "icon-green"
              : result.healthyKey === "moderate"
              ? "text-moderate"
              : result.healthyKey === "high"
              ? "text-warning"
              : "text-danger"
          }`}
        >
          {result.healthyCount}
        </span>
      </h5>
      {result?.healthyKey ? (
        <img
          alt="risk"
          src={
            result.healthyKey === "low"
              ? LOW
              : result.healthyKey === "moderate"
              ? MODERATE
              : result.healthyKey === "high"
              ? HIGH
              : SEVERE
          }
          width="300px"
          className={"mt-4"}
        />
      ) : (
        <LoadingScreen />
      )}
      <h4
        className={`fontweight-bold ${
          result.healthyKey === "low"
            ? "icon-green"
            : result.healthyKey === "moderate"
            ? "text-moderate"
            : result.healthyKey === "high"
            ? "text-warning"
            : "text-danger"
        } mt-4`}
      >
        {result.healthyLabel}
      </h4>
      <h5 className="mt-4">
        Based on your wellness assessment results you are not optimized to
        recover from your injury{" "}
      </h5>
      <button
        className="text-white fw-bold rounded btn btn-primary mt-4 p-2 ps-4 pe-4"
        onClick={() => navigate("/pat-talk-to-provider")}
      >
        Talk to a Provider
      </button>
    </div>
  );
};

export default Result;
