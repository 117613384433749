import React, { useEffect } from "react";
import Header from "../../../provider/components/Layout/Header";
import HelmetTag from "../../../../components/helmet/Helmet";

const Layout = ({ children }) => {
  return (
    <div id="landing" className="overflow-auto bg-white h-100">
      <HelmetTag
        title="Talk to an Orthopedic Provider 24/7 | My Orthopedic Problem"
        canonical="https://myorthopedicproblem.com/providers"
        description="My Orthopedic Problem help connect patients to leading orthopedic surgeons, physical therapists, and trainers in their area. Start your journey to recovery today!"
        JSONLD={`  {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "Find a Provider",
          "url": "http://myorthopedicproblem.com/providers",
          "image": "https://myorthopedicproblem.com/MyOrthopedicProblem.png"
        }`}
      />
      <Header />
      <>{children} </>
    </div>
  );
};

export default Layout;
