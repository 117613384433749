import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CreateAccount from "./CreateAccount";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../utils/axiosInst";
import Layout from "../layout/Layout";
import { patientSchema } from "../../utils/schema";
import {
  educationInitValue,
  hospitalAffiliatedInitialValue,
} from "../../../../pages/BasicProviderForm/init";
import FinishYourApplication from "../../../provider/components/CreateAccount/finish-your-application/FinishYourApplication";

const SignUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const useFormControl = useForm({
    resolver: yupResolver(patientSchema),
    defaultValues: {
      userType: (location?.state && location?.state?.usertype) || "patient",
      firstName: "",
      lastName: "",
      education: [educationInitValue],
      affiliations: [hospitalAffiliatedInitialValue],
      training: [
        {
          trainingType: "",
          location: "",
          completionyear: "",
        },
      ],
    },
  });

  const onSubmit = async (formData) => {
    let userBody = {};
    if (formData.userType) {
      userBody = {
        role: formData.userType === "patient" ? "patient" : "provider",
        userType: formData.userType,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        phoneNumber: phoneNumber,
      };
    } else {
      toast.error("Please select your user type", {
        position: "top-right",
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    try {
      setLoading(true);
      axios
        .post("/v1/auth/register", userBody)
        .then(({ data }) => {
          if (data) {
            setLoading(false);
            navigate("/otp/verify", {
              state: {
                ...data,
                role: data.user.role,
              },
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("err from catch", error.message);
        });
    } catch (e) {
      setLoading(false);
      toast.error(e.message, {
        position: "top-right",
        autoClose: 12000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      console.error("err from catch", e);
    }
  };
  return (
    <Layout>
      {show ? (
        <CreateAccount
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          useFormControl={useFormControl}
          setShow={setShow}
          loading={loading}
          onSubmit={onSubmit}
        />
      ) : (
        <FinishYourApplication useFormControl={useFormControl} />
      )}{" "}
    </Layout>
  );
};

export default SignUp;
