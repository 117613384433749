import React, { useEffect, useCallback, useRef } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import Image from "../Image";
import ReactPlayer from "react-player";
import MediaPreview from "../common/MediaPreview";
const Recommendations = () => {
  const navigate = useNavigate();
  const playerRef = useRef();
  const getBlogArticles = useStoreActions((actions) => actions.getBlogArticles);
  const blogArticles = useStoreState((state) => state.blogArticles);
  const activeBlogs = blogArticles.filter((filter) => {
    return filter.active === true;
  });
  const Content = useCallback(() => {
    return activeBlogs.length > 0 ? (
      activeBlogs.slice(0, 3).map((item, index) => (
        <div
          className="row cursor-pointer  p-0 px-md-2"
          key={index}
          id="video-card"
        >
          <div className="bg-white mb-3 rounded ">
            <div
              className=" evaluate_img evaluate_img_education p-2"
              onClick={() => {
                if (item.coll === "exercises") {
                  window.open(`/video/${item.slug}`);
                }
                if (item.coll === "blogs") {
                  navigate(`/provider-posts/${item.slug || item.id}`);
                }
              }}
            >
              {item.coll === "blogs" ? (
                <Image
                  src={item.banner}
                  className={`img-fluid rounded border  w-100 ${
                    item.banner
                      ? "maxheight-15rem"
                      : "maxheight-100 padding-4rem"
                  }`}
                  alt={item.title}
                />
              ) : (
                <div
                  className="rounded height-200px position-relative"
                  id="play-icon"
                >
                  <a
                    href={`/video/${item.slug}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.link ? (
                      <ReactPlayer
                        ref={playerRef}
                        url={item.link}
                        id="player-id"
                        controls={false}
                        width={"100%"}
                        height={"200px"}
                        className=" "
                        onReady={() => {
                          if (item.link.indexOf("youtube") < 0) {
                            const timeToStart = 1 * 20;
                            playerRef?.current?.seekTo(timeToStart, "seconds");
                          }
                        }}
                      />
                    ) : (
                      <MediaPreview
                        isEducation={true}
                        fileName={item.video ? item.video : item.link}
                        height={"200px"}
                        className="plays-icon"
                      />
                    )}
                    {(item.link || item.video) && (
                      <div className=" h-100 w-100 top-0 rounded position-absolute background-transparent">
                        <Image
                          local={true}
                          src={require("../../images/play.webp")}
                          alt="Play icon"
                          className="  play-icon position-absolute top-0 bottom-0 end-0 start-0 d-flex m-0 m-auto height-20px width-20px"
                        />
                      </div>
                    )}
                  </a>
                </div>
              )}
              <div className="evaluate_content text-start  mt-1 ms-2">
                <div className="fs-5 fw-700 px-md-0 overflowWrap-break-word">
                  {item.title.length > 60
                    ? item.title.slice(0, 60) + "..."
                    : item.title}
                </div>
                <h5 className=" text-body-tertiary fw-semibold mb-1">
                  {item?.blogcategories[0].name}
                </h5>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div className="text-center my-5 fs-4">No Data Found..</div>
    );
    // eslint-disable-next-line
  }, [activeBlogs]);

  useEffect(() => {
    getBlogArticles({
      page: 1,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {activeBlogs && activeBlogs.length > 0 && (
        <div className="border rounded bg-white mb-3 pb-3 p-2">
          <h3 className="p-3 mb-0 fw-semibold text-center text-black">
            Recommended
          </h3>
          <Content />
        </div>
      )}
    </>
  );
};
export default Recommendations;
