import React, { createContext, useContext, useReducer, useState } from "react";
import PropTypes from "prop-types";
import axios from "../../../../util/axiosInst";
import { settingsReducer, initialSettings } from "./settings/settingsReducer";

export const StateContext = createContext(null);

export default function AppStateProvider(props) {
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );

  const [virtualcare, setVirtualcare] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [roomType, setRoomType] = useState("CHAT");
  const [meetingId, setMeetingId] = useState(null);
  const [userID, setUserID] = useState(null);
  const [userName, setUserName] = useState(null);

  let contextValue = {
    error,
    setError,
    isFetching,
    settings,
    dispatchSetting,
    roomType,
    setRoomType,
    virtualcare,
    setVirtualcare,
    roomId,
    setRoomId,
    meetingId,
    setMeetingId,
    userID,
    setUserID,
    userName,
    setUserName,
  };

  const getVirtualCareToken = async (token) => {
    setIsFetching(true);
    return await axios
      .get("/v1/virtualcare/token/" + token)
      .then(({ data }) => {
        setVirtualcare(data);
        setRoomId(data.chatRoomId);
        setMeetingId(data.meetingId);
        setUserID(data.userID);
        setUserName(data.userName);
        setIsFetching(false);
        return data;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getVirtualCareUsers = async () => {
    return await axios
      .get("/v1/virtualcare/users/" + meetingId)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        setError(err);
        return Promise.reject(err);
      });
  };

  const markAsRead = async () => {
    return await axios
      .put(`/v1/chat/virtualcare/${roomId}/mark-read`, {
        postedUserId: userID || undefined,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(new Error(err.message));
      });
  };

  const getConversationByRoomId = async () => {
    return await axios
      .get("/v1/chat/virtualcare/" + roomId)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(new Error(err.message));
      });
  };

  const getSignedUrl = async (params) => {
    return await axios
      .get("/v1/s3/get-signed-url", { params })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(new Error(err.message));
      });
  };

  const putSignedUrl = async (file, callback) => {
    const params = {
      fileName: `/contents/${file.name}`,
    };
    await axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => callback({ signedUrl: data.url }))
      .catch((error) => console.error(error));
  };

  const deleteChatMessage = async (id) => {
    return await axios
      .delete(`/v1/chat/virtualcare/message/${id}/${roomId}`, {
        data: { postedUserId: userID || undefined },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(new Error(err.message));
      });
  };

  const sendChatMessage = async ({ message, type, title }) => {
    return await axios
      .post(`/v1/chat/virtualcare/${roomId}/message`, {
        message: message,
        type: type,
        title: title,
        postedUserId: userID || undefined,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(new Error(err.message));
      });
  };

  contextValue = {
    ...contextValue,
    getVirtualCareToken,
    getVirtualCareUsers,
    markAsRead,
    getConversationByRoomId,
    getSignedUrl,
    putSignedUrl,
    deleteChatMessage,
    sendChatMessage,
  };

  return (
    <StateContext.Provider value={contextValue}>
      {props.children}
    </StateContext.Provider>
  );
}

AppStateProvider.propTypes = {
  children: PropTypes.any,
};

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
