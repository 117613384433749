import React from "react";
import { isEmpty } from "lodash";
import { formatPhoneNumber } from "../util/utils";

const AddressInfo = ({ providerDetail }) => {
  const { phoneNumber, address, city, state, zipcode } = providerDetail;

  const mapAddress = !isEmpty(address)
    ? `https://maps.google.com/maps?q=${encodeURIComponent(
        `${address.replace("#", "")}, ${city}, ${state}, ${zipcode}`
      )}&t=&z=15&ie=UTF8&iwloc=1&output=embed`
    : "";

  return (
    <div className="row bg-white p-3 rounded-1">
      <div className="col-sm-6">
        {phoneNumber && <h4 className="text-black fw-semibold">Contact</h4>}
        {phoneNumber && (
          <p className="mt-4">
            <i className="fas fa-phone text-primary me-3 fs-3" />
            <a
              className="text-decoration-none text-black"
              href={`tel:+ ${formatPhoneNumber(phoneNumber)}`}
            >
              {formatPhoneNumber(phoneNumber)}
            </a>
          </p>
        )}
        {(!isEmpty(address) ||
          !isEmpty(city) ||
          !isEmpty(state) ||
          !isEmpty(zipcode)) && (
          <h4 className="text-black fw-semibold">Location</h4>
        )}
        {(!isEmpty(address) ||
          !isEmpty(city) ||
          !isEmpty(state) ||
          !isEmpty(zipcode)) && (
          <p className="d-flex mt-4">
            <p>
              <i className="fas fa-map-marker-alt text-primary me-3 fs-3"></i>
            </p>
            {address && (
              <>
                {address} <br />
              </>
            )}
            {city && `${city}, `}
            {state && `${state}, `}
            {zipcode && (
              <>
                {zipcode} <br />
              </>
            )}
          </p>
        )}
      </div>

      <div className="col-sm-6">
        {!isEmpty(mapAddress) && (
          <iframe
            src={mapAddress}
            width={"100%"}
            height="100%"
            className="border-0"
            title="address"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default AddressInfo;
