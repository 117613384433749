import React, { useEffect } from "react";
import Pagination from "../../common/Pagination";
import VideoPlayer from "../../VideoPlayer";

const CustomExerciseTab = ({
  providerVideos,
  setCurrentVideoPageNo,
  getVideos,
  loading,
  setCustomLoading,
}) => {
  const { innerWidth } = window;
  useEffect(() => {
    window.fbq("track", `Provider Exercises is opened.`);
  }, []);
  return (
    <>
      {providerVideos.map((exerciseType, index) => {
        let exercise = exerciseType[Object.keys(exerciseType)[0]];
        return (
          <div
            key={index}
            className={`container ${window.innerWidth > 767 ? "" : "p-0 px-3"}`}
          >
            {!loading && exercise?.length && (
              <h4 className="text-capitalize">
                {Object.keys(exerciseType)[0] === "foot_ankle" ||
                Object.keys(exerciseType)[0] === "hand_wrist"
                  ? Object.keys(exerciseType)[0].replace(`_`, " & ")
                  : Object.keys(exerciseType)[0].replace(`_`, " ")}{" "}
              </h4>
            )}

            <div className="row">
              {exercise && exercise.length ? (
                exercise.map((exercise, i) => (
                  <div
                    className="col-md-4 col-sm-12 mb-4 cursor-pointer"
                    key={i}
                  >
                    <div className="border rounded educational-animation bg-white p-2">
                      <div className=" rounded overflow-hidden">
                        <VideoPlayer
                          url={
                            exercise.video && exercise.video.length
                              ? exercise.video
                              : exercise.link
                          }
                          link={exercise.link ? true : false}
                          isS3={exercise.video && exercise.video.length}
                        />
                      </div>
                      <div className="justify-content-between mt-2">
                        <h5
                          className="text-capitalize text-truncate"
                          title={exercise.title}
                        >
                          {exercise.title}
                        </h5>
                        <p className="text-muted mb-0 text-capitalize">
                          {exercise.categories[0].name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            {exercise?.length ? (
              <Pagination
                totalPages={exerciseType.total}
                onPageChange={(event) => {
                  setCustomLoading(true);
                  document.getElementById("root").scrollTo(0, 0);
                  setCurrentVideoPageNo(event.selected + 1);
                  getVideos(
                    exercise[0]?.categories[0]?._id,
                    exercise[0]?.categories[0]?.evaluationType,
                    event.selected,
                    index
                  );
                }}
                pageRangeDisplayed={innerWidth < 769 ? 2 : 5}
              />
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </>
  );
};

export default CustomExerciseTab;
