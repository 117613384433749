import React from "react";

export default function VideoLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="194px"
      height="64px"
      viewBox="0 0 193 64"
      version="1.1"
    >
      <g id="surface1">
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 32.167969 3.039062 C 27.632812 4.332031 24.929688 7.273438 24.0625 11.925781 C 23.320312 16 24.253906 18.070312 26.828125 18.101562 C 28.660156 18.101562 29.914062 16.839844 29.914062 14.996094 C 29.914062 13.511719 30.621094 11.214844 31.296875 10.539062 C 32.90625 8.890625 36.734375 8.5 38.632812 9.792969 C 39.242188 10.183594 40.046875 11.152344 40.433594 11.960938 C 41.109375 13.347656 41.140625 13.769531 41.171875 23.175781 C 41.171875 29.640625 41.300781 33.195312 41.527344 33.746094 C 42.492188 35.847656 45.804688 36.105469 46.964844 34.164062 C 47.574219 33.195312 47.605469 32.484375 47.605469 23.757812 C 47.605469 15.742188 47.511719 14.027344 46.996094 11.863281 C 46.351562 9.082031 44.742188 6.367188 43.039062 5.074219 C 40.046875 2.8125 35.800781 2.035156 32.167969 3.039062 Z M 32.167969 3.039062 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 12.929688 6.238281 C 10.390625 7.175781 8.71875 10.3125 9.394531 12.832031 C 10.132812 15.613281 12.253906 17.261719 15.023438 17.195312 C 17.046875 17.164062 18.335938 16.515625 19.523438 14.964844 C 20.296875 13.964844 20.425781 13.480469 20.425781 11.636719 C 20.425781 9.761719 20.296875 9.339844 19.492188 8.273438 C 17.917969 6.171875 15.3125 5.367188 12.929688 6.238281 Z M 12.929688 6.238281 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 54.363281 6.367188 C 52.882812 7.015625 52.175781 7.660156 51.433594 9.113281 C 50.664062 10.667969 50.664062 12.636719 51.433594 14.15625 C 52.527344 16.257812 54.199219 17.226562 56.742188 17.226562 C 58.191406 17.226562 58.734375 17.066406 59.703125 16.324219 C 61.40625 14.996094 62.082031 13.703125 62.082031 11.667969 C 62.082031 9.246094 61.148438 7.660156 59.058594 6.625 C 57.257812 5.722656 56.003906 5.65625 54.363281 6.367188 Z M 54.363281 6.367188 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 77.523438 12.769531 L 77.523438 19.070312 L 79.773438 19.070312 L 79.773438 12.121094 L 80.511719 13.253906 C 80.898438 13.867188 81.542969 14.996094 81.863281 15.742188 C 82.410156 16.9375 82.636719 17.132812 83.472656 17.132812 C 84.375 17.132812 84.566406 16.90625 85.722656 14.707031 L 87.011719 12.28125 L 87.105469 15.675781 L 87.203125 19.070312 L 89.746094 19.070312 L 89.746094 6.691406 L 90.933594 8.273438 C 91.609375 9.148438 92.316406 10.050781 92.542969 10.277344 C 92.769531 10.503906 92.960938 10.796875 92.960938 10.925781 C 92.960938 11.054688 93.316406 11.570312 93.765625 12.089844 C 94.503906 12.960938 94.570312 13.285156 94.570312 16.066406 L 94.570312 19.070312 L 97.464844 19.070312 L 97.464844 13.382812 L 98.976562 11.28125 C 99.8125 10.148438 100.585938 9.148438 100.679688 9.050781 C 101.003906 8.757812 102.289062 6.851562 102.289062 6.660156 C 102.289062 6.5625 101.648438 6.464844 100.84375 6.464844 C 99.425781 6.464844 99.363281 6.527344 97.882812 8.566406 C 96.371094 10.667969 95.761719 11.085938 95.4375 10.183594 C 95.308594 9.921875 94.699219 8.984375 94.023438 8.113281 L 92.800781 6.496094 L 90.164062 6.496094 L 87.527344 6.464844 L 85.53125 10.214844 C 84.4375 12.25 83.535156 13.898438 83.472656 13.835938 C 83.34375 13.671875 80.640625 8.597656 80.128906 7.5 C 79.742188 6.660156 79.453125 6.464844 78.582031 6.464844 L 77.523438 6.464844 Z M 77.523438 12.769531 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 17.046875 20.6875 C 13.1875 21.269531 10.613281 22.691406 8.652344 25.339844 C 5.726562 29.253906 5.726562 35.71875 8.652344 39.628906 C 10.164062 41.664062 11.871094 42.828125 14.507812 43.636719 C 17.464844 44.542969 19.011719 44.476562 20.265625 43.441406 C 21.164062 42.667969 21.261719 42.441406 21.164062 40.953125 C 21.035156 38.886719 20.136719 38.109375 17.335938 37.785156 C 15.664062 37.59375 15.183594 37.367188 14.25 36.429688 C 12.160156 34.359375 12.191406 30.480469 14.25 28.574219 C 16.082031 26.859375 16.953125 26.730469 27.855469 26.539062 C 35.449219 26.375 37.988281 26.246094 38.375 25.921875 C 39.113281 25.308594 39.339844 23.789062 38.921875 22.496094 C 38.277344 20.492188 37.601562 20.363281 27.4375 20.394531 C 22.515625 20.429688 17.851562 20.558594 17.046875 20.6875 Z M 17.046875 20.6875 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 51.628906 20.65625 C 50.082031 21.332031 49.3125 23.175781 49.890625 24.855469 C 50.308594 26.050781 51.015625 26.503906 52.496094 26.503906 C 53.878906 26.503906 56.292969 27.183594 57.160156 27.796875 C 57.515625 28.089844 58.0625 28.863281 58.351562 29.542969 C 59.703125 32.808594 58.253906 36.039062 54.78125 37.558594 C 54.167969 37.816406 50.597656 38.011719 43.8125 38.140625 L 33.710938 38.304688 L 32.9375 39.207031 C 31.910156 40.4375 31.910156 41.988281 33.003906 43.28125 L 33.839844 44.28125 L 44.003906 44.28125 C 51.757812 44.28125 54.554688 44.152344 55.648438 43.832031 C 59.796875 42.539062 62.242188 40.597656 63.949219 37.171875 C 64.71875 35.554688 64.816406 35.070312 64.785156 32 C 64.785156 28.347656 64.300781 26.632812 62.726562 24.566406 C 61.761719 23.273438 59.1875 21.59375 57.417969 21.074219 C 55.550781 20.558594 52.367188 20.332031 51.628906 20.65625 Z M 51.628906 20.65625 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 80.160156 25.761719 C 78.292969 26.667969 76.878906 29.1875 76.878906 31.613281 C 76.878906 35.78125 79.933594 38.5625 83.921875 38.011719 C 87.203125 37.558594 88.910156 35.328125 88.910156 31.484375 C 88.875 29.480469 88.746094 28.992188 87.945312 27.796875 C 87.429688 27.023438 86.496094 26.148438 85.886719 25.828125 C 84.46875 25.050781 81.605469 25.019531 80.160156 25.761719 Z M 85.46875 28.671875 C 86.046875 29.382812 86.207031 29.996094 86.207031 31.449219 C 86.207031 34.359375 85.113281 35.847656 82.957031 35.878906 C 81.640625 35.878906 80.222656 34.714844 79.902344 33.390625 C 79.582031 32.160156 79.933594 29.21875 80.449219 28.703125 C 81.960938 27.183594 84.277344 27.152344 85.46875 28.671875 Z M 85.46875 28.671875 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 90.933594 25.4375 C 90.804688 25.535156 90.710938 28.378906 90.710938 31.742188 L 90.710938 37.816406 L 93.605469 37.816406 L 93.605469 32.96875 L 94.503906 32.96875 C 95.597656 32.96875 96.371094 33.777344 97.625 36.105469 C 98.496094 37.753906 98.558594 37.816406 99.941406 37.816406 C 100.679688 37.816406 101.324219 37.753906 101.324219 37.65625 C 101.324219 37.367188 99.332031 33.777344 98.914062 33.324219 C 98.558594 32.9375 98.65625 32.710938 99.621094 31.804688 C 100.714844 30.773438 100.746094 30.640625 100.617188 28.929688 C 100.425781 26.796875 99.78125 25.957031 97.914062 25.535156 C 96.46875 25.179688 91.226562 25.113281 90.933594 25.4375 Z M 97.980469 28.28125 C 98.589844 29.125 98.558594 29.316406 97.625 30.222656 C 97.015625 30.867188 96.53125 31.03125 95.214844 31.03125 L 93.605469 31.03125 L 93.605469 27.410156 L 95.566406 27.539062 C 97.015625 27.636719 97.65625 27.863281 97.980469 28.28125 Z M 97.980469 28.28125 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 101.648438 26.503906 L 101.648438 27.796875 L 105.507812 27.796875 L 105.507812 37.816406 L 108.402344 37.816406 L 108.402344 27.796875 L 112.261719 27.796875 L 112.261719 25.210938 L 101.648438 25.210938 Z M 101.648438 26.503906 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 113.226562 31.515625 L 113.226562 37.851562 L 114.609375 37.753906 L 115.960938 37.65625 L 116.058594 35.136719 L 116.152344 32.644531 L 121.589844 32.644531 L 121.589844 37.816406 L 124.164062 37.816406 L 124.164062 25.210938 L 121.589844 25.210938 L 121.589844 30.382812 L 116.152344 30.382812 L 116.058594 27.863281 L 115.960938 25.375 L 114.609375 25.277344 L 113.226562 25.179688 Z M 113.226562 31.515625 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 129.632812 25.566406 C 128.472656 26.050781 127.089844 27.476562 126.542969 28.734375 C 125.644531 30.867188 126.09375 34.585938 127.445312 36.203125 C 128.539062 37.496094 129.761719 37.980469 132.074219 37.980469 C 134.070312 37.949219 134.519531 37.851562 135.550781 37.074219 C 137.191406 35.78125 137.996094 34.070312 137.996094 31.742188 C 137.996094 27.636719 135.710938 25.179688 131.949219 25.246094 C 131.113281 25.246094 130.082031 25.40625 129.632812 25.566406 Z M 134.136719 28.378906 C 136.578125 30.867188 135.195312 35.878906 132.042969 35.878906 C 129.695312 35.878906 128.183594 33.066406 128.988281 30.125 C 129.664062 27.507812 132.367188 26.601562 134.136719 28.378906 Z M 134.136719 28.378906 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 139.828125 25.4375 C 139.699219 25.535156 139.601562 28.378906 139.601562 31.742188 L 139.601562 37.816406 L 142.5 37.816406 L 142.5 33.292969 L 144.203125 33.292969 C 146.550781 33.292969 148.128906 32.777344 148.964844 31.742188 C 149.511719 31.03125 149.609375 30.546875 149.511719 28.960938 C 149.382812 27.34375 149.222656 26.957031 148.449219 26.3125 C 147.96875 25.921875 147.128906 25.503906 146.582031 25.40625 C 145.296875 25.148438 140.085938 25.148438 139.828125 25.4375 Z M 146.199219 27.863281 C 146.808594 28.121094 147 28.476562 147 29.253906 C 147 30.675781 146.488281 31.03125 144.363281 31.03125 L 142.5 31.03125 L 142.5 27.476562 L 143.945312 27.476562 C 144.71875 27.476562 145.746094 27.636719 146.199219 27.863281 Z M 146.199219 27.863281 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 151.183594 31.515625 L 151.183594 37.816406 L 159.867188 37.816406 L 159.867188 35.878906 L 153.757812 35.878906 L 153.757812 32.644531 L 159.222656 32.644531 L 159.222656 30.382812 L 153.757812 30.382812 L 153.757812 27.476562 L 159.902344 27.476562 L 159.804688 26.40625 L 159.707031 25.375 L 155.460938 25.277344 L 151.183594 25.179688 Z M 151.183594 31.515625 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 161.414062 25.40625 C 161.25 25.535156 161.15625 28.378906 161.15625 31.742188 L 161.15625 37.816406 L 164.691406 37.816406 C 168.875 37.816406 170.257812 37.300781 171.480469 35.328125 C 172.125 34.296875 172.253906 33.679688 172.253906 31.484375 C 172.253906 29.125 172.15625 28.769531 171.351562 27.699219 C 169.96875 25.890625 168.875 25.46875 165.015625 25.308594 C 163.148438 25.210938 161.542969 25.246094 161.414062 25.40625 Z M 167.75 27.992188 C 169.488281 28.476562 170.289062 32.226562 169.070312 34.101562 C 168.390625 35.136719 166.75 35.878906 165.175781 35.878906 L 164.050781 35.878906 L 164.050781 27.796875 L 165.59375 27.796875 C 166.429688 27.796875 167.394531 27.894531 167.75 27.992188 Z M 167.75 27.992188 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 174.34375 31.515625 L 174.34375 37.816406 L 176.917969 37.816406 L 176.917969 25.210938 L 174.34375 25.210938 Z M 174.34375 31.515625 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 182.160156 25.695312 C 180.648438 26.34375 179.941406 27.121094 179.199219 28.863281 C 178.429688 30.675781 178.363281 31.710938 178.945312 33.84375 C 179.8125 37.171875 183.222656 38.886719 186.886719 37.816406 C 187.5 37.625 188.273438 37.269531 188.625 37.011719 C 189.171875 36.589844 189.171875 36.429688 188.820312 35.554688 C 188.59375 35.039062 188.367188 34.585938 188.273438 34.585938 C 188.207031 34.585938 187.597656 34.875 186.886719 35.230469 C 185.121094 36.136719 183.898438 36.074219 182.707031 34.972656 C 181.871094 34.261719 181.675781 33.777344 181.515625 32.289062 C 181.164062 28.734375 183.027344 26.925781 186.148438 27.765625 C 186.886719 27.960938 187.597656 28.28125 187.691406 28.445312 C 188.015625 28.992188 188.367188 28.800781 188.753906 27.960938 C 189.332031 26.699219 189.238281 26.539062 187.789062 25.859375 C 186.148438 25.113281 183.640625 25.050781 182.160156 25.695312 Z M 182.160156 25.695312 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 25.539062 28.734375 C 23.867188 29.480469 23.804688 29.800781 23.804688 40.113281 C 23.804688 45.382812 23.933594 50.425781 24.125 51.328125 C 24.863281 55.242188 27.695312 58.925781 31.167969 60.507812 C 32.90625 61.316406 33.613281 61.445312 35.898438 61.445312 C 38.148438 61.445312 38.890625 61.285156 40.59375 60.507812 C 44.324219 58.761719 46.578125 55.660156 47.347656 51.199219 C 47.800781 48.484375 47.414062 47.128906 45.964844 46.382812 C 43.488281 45.089844 41.171875 46.707031 41.171875 49.746094 C 41.171875 53.460938 37.3125 56.242188 33.9375 54.949219 C 32.617188 54.433594 31.039062 52.523438 30.460938 50.714844 C 30.011719 49.324219 29.914062 47.546875 29.914062 39.789062 C 29.914062 30.707031 29.914062 30.546875 29.175781 29.640625 C 28.402344 28.671875 26.699219 28.25 25.539062 28.734375 Z M 25.539062 28.734375 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 77.328125 44.703125 C 77.265625 44.929688 77.230469 47.742188 77.265625 50.910156 L 77.359375 56.726562 L 78.742188 56.824219 L 80.09375 56.921875 L 80.09375 52.363281 L 81.640625 52.363281 C 85.304688 52.363281 87.171875 50.941406 87.171875 48.097656 C 87.171875 46.640625 87.042969 46.351562 86.015625 45.382812 L 84.886719 44.28125 L 81.1875 44.28125 C 78.488281 44.28125 77.457031 44.378906 77.328125 44.703125 Z M 83.988281 46.867188 C 84.726562 47.289062 84.824219 48.871094 84.082031 49.488281 C 83.828125 49.714844 82.796875 49.9375 81.832031 50.035156 L 80.09375 50.164062 L 80.09375 46.546875 L 81.734375 46.546875 C 82.636719 46.546875 83.632812 46.707031 83.988281 46.867188 Z M 83.988281 46.867188 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 88.78125 50.585938 L 88.78125 56.890625 L 91.351562 56.890625 L 91.351562 52.039062 L 92.414062 52.039062 C 93.796875 52.039062 93.992188 52.203125 95.308594 54.625 C 96.4375 56.695312 96.46875 56.726562 97.914062 56.824219 C 98.71875 56.890625 99.394531 56.855469 99.394531 56.792969 C 99.394531 56.535156 98.300781 54.433594 97.433594 53.074219 L 96.5625 51.71875 L 97.65625 50.617188 C 98.816406 49.453125 99.074219 48.097656 98.429688 46.417969 C 97.820312 44.769531 96.402344 44.28125 92.414062 44.28125 L 88.78125 44.28125 Z M 95.664062 47.0625 C 95.953125 47.320312 96.179688 47.839844 96.179688 48.160156 C 96.179688 49.292969 95.277344 49.777344 93.25 49.777344 L 91.351562 49.777344 L 91.351562 46.546875 L 93.25 46.546875 C 94.570312 46.546875 95.34375 46.707031 95.664062 47.0625 Z M 95.664062 47.0625 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 103.03125 44.898438 C 101.359375 45.929688 100.359375 48.03125 100.359375 50.425781 C 100.359375 55.078125 103.449219 57.792969 107.695312 56.890625 C 110.589844 56.242188 112.261719 53.914062 112.261719 50.519531 C 112.261719 47.773438 111.199219 45.738281 109.269531 44.769531 C 107.824219 44.054688 104.316406 44.121094 103.03125 44.898438 Z M 107.953125 46.804688 C 108.753906 47.320312 109.6875 49.359375 109.6875 50.585938 C 109.6875 52.039062 108.789062 53.753906 107.726562 54.304688 C 106.921875 54.753906 104.734375 54.5625 104.058594 53.980469 C 103.351562 53.367188 102.835938 51.136719 103.09375 49.519531 C 103.382812 47.515625 104.414062 46.546875 106.214844 46.546875 C 106.984375 46.546875 107.757812 46.675781 107.953125 46.804688 Z M 107.953125 46.804688 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 114.191406 50.585938 L 114.191406 56.890625 L 117.570312 56.890625 C 121.269531 56.890625 122.78125 56.46875 123.648438 55.242188 C 124.550781 53.914062 124.195312 51.363281 122.972656 50.652344 C 122.621094 50.425781 122.652344 50.230469 123.199219 49.550781 C 123.90625 48.644531 124.035156 46.902344 123.519531 45.867188 C 122.84375 44.671875 121.492188 44.28125 117.730469 44.28125 L 114.191406 44.28125 Z M 120.785156 46.964844 C 121.203125 47.289062 121.300781 47.644531 121.171875 48.226562 C 120.945312 49.097656 119.980469 49.453125 117.859375 49.453125 C 116.765625 49.453125 116.765625 49.421875 116.765625 48 L 116.765625 46.546875 L 118.46875 46.546875 C 119.402344 46.546875 120.429688 46.738281 120.785156 46.964844 Z M 121.269531 52.332031 C 122.070312 53.851562 121.203125 54.625 118.664062 54.625 L 116.765625 54.625 L 116.765625 51.71875 L 118.855469 51.71875 C 120.558594 51.71875 120.980469 51.8125 121.269531 52.332031 Z M 121.269531 52.332031 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 125.773438 50.554688 L 125.773438 56.890625 L 134.167969 56.890625 L 134.070312 55.660156 L 133.972656 54.464844 L 128.699219 54.269531 L 128.601562 49.359375 L 128.507812 44.445312 L 127.15625 44.347656 L 125.773438 44.25 Z M 125.773438 50.554688 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 135.421875 50.585938 L 135.421875 56.890625 L 143.785156 56.890625 L 143.785156 54.625 L 137.996094 54.625 L 137.996094 51.71875 L 143.464844 51.71875 L 143.464844 49.453125 L 137.996094 49.453125 L 137.996094 46.546875 L 143.785156 46.546875 L 143.785156 44.28125 L 135.421875 44.28125 Z M 135.421875 50.585938 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 145.394531 50.554688 L 145.394531 56.890625 L 147.96875 56.890625 L 147.96875 49.9375 L 149.316406 52.429688 C 150.539062 54.722656 150.765625 54.949219 151.632812 54.949219 C 152.566406 54.917969 152.726562 54.722656 153.917969 52.332031 L 155.203125 49.746094 L 155.300781 53.300781 L 155.398438 56.890625 L 157.617188 56.890625 L 157.617188 44.28125 L 156.554688 44.28125 C 155.71875 44.28125 155.398438 44.476562 154.980469 45.316406 C 154.65625 45.898438 153.757812 47.613281 152.984375 49.132812 L 151.539062 51.878906 L 151.023438 50.746094 C 150.765625 50.132812 149.898438 48.453125 149.15625 47.03125 C 147.839844 44.574219 147.675781 44.445312 146.582031 44.347656 L 145.394531 44.25 Z M 145.394531 50.554688 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 12.03125 47.675781 C 7.011719 50.683594 9.136719 58.183594 14.988281 58.183594 C 19.171875 58.183594 21.808594 53.785156 19.945312 49.972656 C 18.558594 47.0625 14.828125 45.996094 12.03125 47.675781 Z M 12.03125 47.675781 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 53.6875 47.546875 C 53.042969 47.933594 52.140625 48.839844 51.691406 49.550781 C 49.3125 53.238281 52.046875 58.183594 56.421875 58.183594 C 59.796875 58.183594 62.082031 55.886719 62.082031 52.523438 C 62.082031 50.132812 61.34375 48.871094 59.1875 47.546875 C 57.675781 46.640625 55.261719 46.640625 53.6875 47.546875 Z M 53.6875 47.546875 "
        />
      </g>
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="96"
    //   height="96"
    //   viewBox="0 0 96 96"
    // >
    //   <g fill="none" fillRule="evenodd">
    //     <path
    //       fill="#FFF"
    //       d="M43.86 0h8.28c11.661 0 18.564 1.74 24.675 5.01 6.112 3.267 10.908 8.063 14.175 14.175C94.26 25.295 96 32.197 96 43.86v8.28c0 11.661-1.74 18.564-5.01 24.675-3.267 6.112-8.063 10.908-14.175 14.175C70.704 94.26 63.801 96 52.14 96h-8.28c-11.661 0-18.564-1.74-24.675-5.01-6.112-3.267-10.908-8.063-14.175-14.175C1.74 70.705 0 63.803 0 52.14v-8.28c0-11.661 1.74-18.564 5.01-24.675C8.277 13.073 13.073 8.277 19.185 5.01 25.295 1.74 32.197 0 43.86 0z"
    //     />
    //     <path
    //       fill="#F22F46"
    //       d="M53.25 63h-25.5C25.679 63 24 61.321 24 59.25v-22.5c0-2.071 1.679-3.75 3.75-3.75h25.5c2.071 0 3.75 1.679 3.75 3.75v5.82l9.57-4.785c1.164-.583 2.546-.521 3.653.164 1.107.685 1.78 1.894 1.777 3.196V54.87c.008 1.3-.66 2.512-1.766 3.198-1.105.686-2.487.747-3.65.162L57 53.49v5.76C57 61.321 55.32 63 53.25 63zm-25.5-27c-.414 0-.75.336-.75.75v22.5c0 .414.336.75.75.75h25.5c.414 0 .75-.336.75-.75v-8.175c-.001-.518.266-1 .705-1.275.44-.275.99-.303 1.455-.075L67.92 55.5c.224.123.496.123.72 0 .22-.141.356-.383.36-.645v-13.71c-.004-.262-.14-.504-.36-.645-.224-.123-.496-.123-.72 0l-11.745 5.865c-.47.236-1.028.21-1.473-.069-.445-.28-.711-.77-.702-1.296v-8.25c0-.414-.336-.75-.75-.75h-25.5z"
    //     />
    //   </g>
    // </svg>
  );
}
