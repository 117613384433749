export const PAGE_VARIABLES = {
  REFERRALS_HEAD: ["PATIENT", "ID", "DATE", "REFERRED TO", "NOTE", "ACTION"],
  AUTH: JSON.parse(localStorage.getItem("mop-user")),
  COMMON: {
    VIEW: "View",
    MARK_COMPLETE: "Mark Complete",
    CANCEL: "Cancel",
    SUBMIT: "Submit",
    SOCIAL_MEDIA: [
      { id: "facebook", Tooltip: "Facebook URL", label: "Facebook" },
      { id: "twitter", Tooltip: "Twitter URL", label: "Twitter" },
      { id: "instagram", Tooltip: "Instagram URL", label: "Instagram" },
      { id: "youtube", Tooltip: "Youtube URL", label: "Youtube" },
      { id: "linkedin", Tooltip: "LinkedIn URL", label: "LinkedIn" },
    ],
    TIME_VALUE: [
      "12:00 AM",
      "12:15 AM",
      "12:30 AM",
      "12:45 AM",
      "01:00 AM",
      "01:15 AM",
      "01:30 AM",
      "01:45 AM",
      "02:00 AM",
      "02:15 AM",
      "02:30 AM",
      "02:45 AM",
      "03:00 AM",
      "03:15 AM",
      "03:30 AM",
      "03:45 AM",
      "04:00 AM",
      "04:15 AM",
      "04:30 AM",
      "04:45 AM",
      "05:00 AM",
      "05:15 AM",
      "05:30 AM",
      "05:45 AM",
      "06:00 AM",
      "06:15 AM",
      "06:30 AM",
      "06:45 AM",
      "07:00 AM",
      "07:15 AM",
      "07:30 AM",
      "07:45 AM",
      "08:00 AM",
      "08:15 AM",
      "08:30 AM",
      "08:45 AM",
      "09:00 AM",
      "09:15 AM",
      "09:30 AM",
      "09:45 AM",
      "10:00 AM",
      "10:15 AM",
      "10:30 AM",
      "10:45 AM",
      "11:00 AM",
      "11:15 AM",
      "11:30 AM",
      "11:45 AM",
      "12:00 PM",
      "12:15 PM",
      "12:30 PM",
      "12:45 PM",
      "01:00 PM",
      "01:15 PM",
      "01:30 PM",
      "01:45 PM",
      "02:00 PM",
      "02:15 PM",
      "02:30 PM",
      "02:45 PM",
      "03:00 PM",
      "03:15 PM",
      "03:30 PM",
      "03:45 PM",
      "04:00 PM",
      "04:15 PM",
      "04:30 PM",
      "04:45 PM",
      "05:00 PM",
      "05:15 PM",
      "05:30 PM",
      "05:45 PM",
      "06:00 PM",
      "06:15 PM",
      "06:30 PM",
      "06:45 PM",
      "07:00 PM",
      "07:15 PM",
      "07:30 PM",
      "07:45 PM",
      "08:00 PM",
      "08:15 PM",
      "08:30 PM",
      "08:45 PM",
      "09:00 PM",
      "09:15 PM",
      "09:30 PM",
      "09:45 PM",
      "10:00 PM",
      "10:15 PM",
      "10:30 PM",
      "10:45 PM",
      "11:00 PM",
      "11:15 PM",
      "11:30 PM",
      "11:45 PM",
    ],
    DAYS: [
      {
        value: "Sunday",
      },
      {
        value: "Monday",
      },
      {
        value: "Tuesday",
      },
      {
        value: "Wednesday",
      },
      {
        value: "Thursday",
      },
      {
        value: "Friday",
      },
      {
        value: "Saturday",
      },
    ],
    EMAIL_CONTENT: [
      {
        id: "infoEmail1",
        Tooltip:
          "Email addresses below are for receiving correspondence from MyOrthopedicProblem.com. Please add team member’s email addresses who are responsible for fielding these request",
        label: "Email ID 1",
        name: "emailIds[0]",
      },
      {
        id: "infoEmail2",
        Tooltip:
          "Email addresses below are for receiving correspondence from MyOrthopedicProblem.com. Please add team member’s email addresses who are responsible for fielding these request",
        label: "Email ID 2",
        name: "emailIds[1]",
      },
      {
        id: "infoEmail3",
        Tooltip:
          "Email addresses below are for receiving correspondence from MyOrthopedicProblem.com. Please add team member’s email addresses who are responsible for fielding these request",
        label: "Email ID 3",
        name: "emailIds[2]",
      },
      {
        id: "infoEmail4",
        Tooltip:
          "Email addresses below are for receiving correspondence from MyOrthopedicProblem.com. Please add team member’s email addresses who are responsible for fielding these request",
        label: "Email ID 4",
        name: "emailIds[3]",
      },
    ],
    ALERT_MESSAGE:
      "Please do take sometime and complete your profile. This info would be much helpful for the patients/users on the portal to reach you out on the MOP portal. Thanks",
  },
  HEALTH_PROFILE: [
    {
      name: "HEALTH CONDITIONS",
      value: "conditionSymptoms",
    },
    {
      name: "HEALTH FILES",
    },
    {
      name: "ALLERGIES",
      value: "allergies",
    },
    {
      name: "CURRENT MEDICATIONS",
      value: "medications",
    },
    {
      name: "SURGERIES",
      value: "treatmentProcedure",
    },
  ],
  BUTTON: {
    TYPE: {
      SUBMIT: "submit",
      RESET: "reset",
    },
    VARIANT: {
      SUCCESS: "success",
      PRIMARY: "primary",
      OUTLINE_SECOUNDARY: "outline-secondary",
    },
  },
};
