import React from "react";
import VideoPlayer from "../VideoPlayer";
import Image from "../Image";
import LoadingScreen from "../custom/LoadingScreen";

export default function MediaPreview({
  fileName,
  type,
  className,
  style,
  height,
  isEducation,
}) {
  return (
    <>
      {fileName ? (
        type === "image" ? (
          <Image
            src={fileName}
            alt={fileName}
            height="230px"
            width="230px"
            style={style}
            className={className ? className : "rounded-circle"}
          />
        ) : (
          <VideoPlayer
            url={fileName}
            height={height}
            isEducation={isEducation}
            className={className}
          />
        )
      ) : fileName ? (
        <>
          <LoadingScreen color="primary" />
          <span className="sr-only">Loading...</span>
        </>
      ) : null}
    </>
  );
}
