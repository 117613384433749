import React, { useEffect, useState } from "react";
import axios from "../../utils/axiosInst";
import DefaultImg from "../../../assets/images/user.png";
import logo from "../../../assets/images/logo_v3.svg";
import ProviderImg from "../../../assets/images/defaultUserMOP.svg";
import FemaleDrImage from "../../../assets/images/female-dr-avatar.webp";
import MaleDrImage from "../../../assets/images/male_dr_avatar.webp";
import UserProfile from "../../../assets/images/defaultuser.webp";

const Image = ({
  className = "",
  src,
  type = "default",
  alt = "",
  gender,
  style,
  local = false,
  ...attributes
}) => {
  const getDefaultImage = () => {
    if (type === "provider") {
      return gender === "MALE"
        ? MaleDrImage
        : gender === "FEMALE"
        ? FemaleDrImage
        : ProviderImg;
    }
    if (type === "user") {
      return UserProfile;
    }
    return attributes.profileType === "profile" ? DefaultImg : logo;
  };

  const [images, setImages] = useState([]);

  const getImage = async (id) => {
    try {
      if (!local) {
        const result = await axios.get(`/v1/s3/signed/url?fileName=${id}`);
        if (result?.data?.url) {
          return result.data.url;
        }
      }
      return src;
    } catch (err) {
      return src;
    }
  };

  const getImages = async () => {
    const imageArray = [(src && (await getImage(src))) || getDefaultImage()];
    setImages(imageArray);
  };

  useEffect(() => {
    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, type]);

  return images.map((img, i) => (
    <img
      src={img}
      alt={alt || `mop-img-${i}`}
      key={i}
      className={className}
      style={style}
      loading="lazy"
      {...attributes}
      onError={() => setImages([getDefaultImage(type)])}
    />
  ));
};

export default Image;
