import React, { createContext, useContext, useReducer, useState } from "react";
import PropTypes from "prop-types";
import axios from "../../../../util/axiosInst";
import dayjs from "dayjs";

export const StateContext = createContext(null);

export default function AppStateProvider(props) {
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [virtualcare, setVirtualcare] = useState(null);
  const [meetingId, setMeetingId] = useState(null);

  const [userID, setUserID] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [participantUserID, setParticipantUserID] = useState(null);
  const [participantUserName, setParticipantUserName] = useState(null);
  const [participantUserEmail, setParticipantUserEmail] = useState(null);
  const [participantUserPhoneNumber, setParticipantUserPhoneNumber] =
    useState(null);
  const [participantUserPhoto, setParticipantUserPhoto] = useState(null);
  const [participantUserRole, setParticipantUserRole] = useState(null);

  let contextValue = {
    error,
    setError,
    isFetching,
    virtualcare,
    setVirtualcare,
    meetingId,
    setMeetingId,
    userID,
    setUserID,
    userName,
    setUserName,
    userEmail,
    setUserEmail,
    userPhoneNumber,
    setUserPhoneNumber,
    userPhoto,
    setUserPhoto,
    userRole,
    setUserRole,
    participantUserID,
    setParticipantUserID,
    participantUserName,
    setParticipantUserName,
    participantUserEmail,
    setParticipantUserEmail,
    participantUserPhoto,
    setParticipantUserPhoto,
    participantUserPhoneNumber,
    setParticipantUserPhoneNumber,
    participantUserRole,
    setParticipantUserRole,
  };

  const getVirtualCareToken = async (token) => {
    setIsFetching(true);
    return await axios
      .get("/v1/virtualcare/audio/token/" + token)
      .then(({ data }) => {
        setVirtualcare(data);
        setMeetingId(data.meetingId);

        setUserID(data.userID);
        setUserName(data.userName);
        setUserEmail(data.userEmail);
        setUserPhoneNumber(data.userPhoneNumber);
        setUserPhoto(data.userPhoto);
        setUserRole(data.userRole);

        setParticipantUserID(data.participantUserID);
        setParticipantUserName(data.participantUserName);
        setParticipantUserEmail(data.participantUserEmail);
        setParticipantUserPhoneNumber(data.participantUserPhoneNumber);
        setParticipantUserPhoto(data.participantUserPhoto);
        setParticipantUserRole(data.participantUserRole);

        setIsFetching(false);
        return data;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getVirtualCareUsers = async () => {
    return await axios
      .get("/v1/virtualcare/users/" + meetingId)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        setError(err);
        return Promise.reject(err);
      });
  };

  const getVoiceToken = async () => {
    return await axios
      .get("/v1/twilio/voice/token", {
        identity: meetingId,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(new Error(err.message));
      });
  };

  const getSignedUrl = async (params) => {
    return await axios
      .get("/v1/s3/get-signed-url", { params })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(new Error(err.message));
      });
  };

  const EndCall = async () => {
    return await axios
      .post("/v1/virtualcare/audio/end/" + virtualcare.id, {
        end: dayjs().unix(),
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(new Error(err.message));
      });
  };

  const StartCall = async () => {
    return await axios
      .post("/v1/virtualcare/audio/start/" + virtualcare.id, {
        start: dayjs().unix(),
      })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return Promise.reject(new Error(err.message));
      });
  };

  contextValue = {
    ...contextValue,
    getVirtualCareToken,
    getVirtualCareUsers,
    getVoiceToken,
    getSignedUrl,
    EndCall,
    StartCall,
  };

  return (
    <StateContext.Provider value={contextValue}>
      {props.children}
    </StateContext.Provider>
  );
}

AppStateProvider.propTypes = {
  children: PropTypes.any,
};

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
