import React from "react";
import Layout from "./layout/Layout";
import Footer from "./Home/Footer";
const WhyTrustUs = () => {
  return (
    <Layout>
      <section
        className={`container px-5 ${window.innerWidth > 769 ? "" : ""}`}
      >
        <h1 className="landing-display-1 text-center mt-3rem mb-4 mt-4">
          Why Trust Us
        </h1>
        <div className="px-5">
          <span>
            <p className="text-primary fw-600 fs-28px mb-1">
              Culture and Process
            </p>
            <p className="fs-18px mb-4">
              Earning and Maintaining Your Trust At MyOrthopedicProblem.com, we
              recognize the paramount importance of security and privacy in the
              healthcare sector. Our commitment to safeguarding your health data
              is unwavering. We employ an array of advanced monitoring and
              security measures, adhering to the highest industry standards, to
              ensure the confidentiality and integrity of your information.
            </p>
          </span>
          <span>
            <p className="text-primary fw-600 fs-28px mb-1">
              Certifications and Audits
            </p>
            <p className="fs-18px mb-4">
              Our dedication to compliance and security is evident in our
              rigorous certification processes. We proudly hold a Service
              Organization Control Type 2 (SOC 2) Type 2 certification, which is
              annually audited and certified by an American Institute of
              Certified Public Accountants (AICPA)-accredited firm. This
              certification confirms our adherence to stringent criteria in
              non-financial areas such as security, availability, processing
              integrity, confidentiality, and privacy. Our practices also align
              with the Health Insurance Portability and Accountability Act of
              1996 (HIPAA), ensuring top-tier data protection standards.
            </p>
          </span>

          <span>
            <p className="text-primary fw-600 fs-28px mb-1">Data Security</p>
            <p className="fs-18px mb-4">
              We routinely engage in penetration testing conducted by
              independent, specialized third-party firms. These tests are
              integral to our software development and engineering processes,
              helping us to continually enhance our platforms and applications.
              Additionally, we align our policies and standards with the
              requirements of Privacy Shield, General Data Protection Regulation
              2016/679 (GDPR), and the forthcoming California Consumer Privacy
              Act (CCPA). Culture and Processes Our team is the backbone of our
              security framework. Every potential employee undergoes
              comprehensive criminal, educational, and employment background
              checks. Upon joining, they receive extensive training in privacy
              and security, and sign legally binding non-disclosure and
              confidentiality agreements. Our company culture emphasizes ongoing
              security and privacy discussions, supported by updates from our
              Information Security & Compliance team through various
              communication channels.
            </p>
          </span>

          <span>
            <p className="text-primary fw-600 fs-28px mb-1">Technology</p>
            <p className="fs-18">
              Access to sensitive data is strictly regulated through role-based
              access control (RBAC), ensuring that only authorized personnel can
              access the information they need to perform their duties. Our
              stringent access protocols, including multi-factor authentication
              for our Production Network, are a testament to our commitment to
              data security. Technology and Design Incorporating compliance into
              our technological framework is a cornerstone of our approach. From
              data architecture to product and platform design, we integrate
              compliance measures at every level. Our communication channels are
              secured with industry-standard encryption protocols such as
              Transport Layer Security (TLS) and Hypertext Transfer Protocol
              Secure (HTTPS), and we extend TLS support for email encryption in
              transit.
            </p>
            <p className="fs-18px mb-4">
              At MyOrthopedicProblem.com, safeguarding your trust is not just a
              responsibility; it's our core value. We are committed to
              delivering an environment where your health information is secure,
              allowing you to focus on what matters most - your orthopedic
              health and wellness.
            </p>
          </span>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default WhyTrustUs;
