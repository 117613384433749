import React from "react";
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
} from "@material-ui/core";
import { useAppState } from "../../../state";

const useStyles = makeStyles((theme) => ({
  gutterBottom: {
    marginBottom: "1em",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5em 0 3.5em",
    "& div:not(:last-child)": {
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0 2em",
    },
  },
  textFieldContainer: {
    width: "100%",
  },
  continueButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default function RoomNameScreen({
  name,
  roomName,
  setName,
  setRoomName,
  handleSubmit,
}) {
  const classes = useStyles();
  const { user } = useAppState();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event) => {
    setRoomName(event.target.value);
  };

  const hasUsername =
    !window.location.search.includes("customIdentity=true") &&
    user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join a Room
      </Typography>
      <Typography variant="body1">
        {hasUsername
          ? "Enter the name of a room you'd like to join."
          : "Enter your name and the name of a room you'd like to join"}
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel
                shrink
                htmlFor="input-user-name"
                style={{ marginBottom: "10px" }}
              >
                Your Name
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              />
            </div>
          )}
          <div
            className={classes.textFieldContainer}
            style={{ display: "none" }}
          >
            <InputLabel shrink htmlFor="input-room-name">
              Room Name
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomName}
              onChange={handleRoomNameChange}
              disabled
            />
          </div>
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !roomName}
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
}
