import React, { useEffect, useState } from "react";
import Carousel from "react-grid-carousel";
import ReactStars from "react-rating-stars-component";
import axios from "../../../../util/axiosInst";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState();

  const getProviderTestimonials = async () => {
    const { data } = await axios.get(`/v1/public/testimonies/latest`);
    let tempdata = data?.filter((item) => item?.userType === "patient");
    setTestimonials(tempdata);
  };

  useEffect(() => {
    getProviderTestimonials();
  }, []);

  return (
    <section className="wrapper pt-5" style={{ marginTop: "13rem" }}>
      <h1 className="fontweight-bold text-center display-4  mb-5">
        Our members love us
      </h1>
      <div className="mx-5 pt-4">
        <div className="px-5" style={{ fontSize: "18px" }}>
          <Carousel cols={3} rows={1} gap={10} loop>
            {testimonials?.map((data) => (
              <Carousel.Item>
                <div className="px-4">
                  <div
                    className="w-100  px-4 pt-3"
                    style={{
                      borderRadius: "40px",
                      height: "290px",
                      background: "rgb(235, 245, 255)",
                    }}
                  >
                    <ReactStars
                      stars={5}
                      value={5}
                      isHalf={true}
                      size={30}
                      activeColor="#ffd700"
                      edit={false}
                    />
                    <p
                      className="pb-4 pt-2"
                      style={{ height: "170px", overflow: "auto" }}
                    >
                      {data?.feedback}
                    </p>
                    <p className="text-muted pb-3 ms-2"> {data?.firstName}</p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
            {testimonials?.length < 3 ? (
              <Carousel.Item>
                <div className="px-4">
                  <div
                    className="w-100  px-4 pt-3"
                    style={{
                      borderRadius: "40px",
                      height: "290px",
                      background: "rgb(235, 245, 255)",
                    }}
                  >
                    <ReactStars
                      stars={5}
                      value={5}
                      isHalf={true}
                      size={30}
                      activeColor="#ffd700"
                      edit={false}
                    />
                    <p
                      className="pb-4 pt-2"
                      style={{ height: "170px", overflow: "auto" }}
                    >
                      It helped me better understand my injury, and the
                      different treatment options available.
                    </p>
                    <p className="text-muted pb-3 ms-2">Uthayakumar K</p>
                  </div>
                </div>
              </Carousel.Item>
            ) : (
              <></>
            )}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
