import React, { useEffect, useState } from "react";
import Layout from "./layout/Layout";
import { Button } from "react-bootstrap";
import Carousel from "react-grid-carousel";
import ReactStars from "react-rating-stars-component";
import Footer from "./Home/Footer";
import Accordation from "./Home/components/Accordation";
import { individualsFAQ } from "../../../constants";
import banner_iphone_image from "../../assets/images/videocall.svg";
import SOC2 from "../../assets/images/soc2.png";
import HIPAA from "../../assets/images/hipaa.jpg";
import axios from "../../../util/axiosInst";
import Image from "./custom/Image";

const OnlinePrescriptions = () => {
  const [testimonials, setTestimonials] = useState();

  const getProviderTestimonials = async () => {
    const { data } = await axios.get(`/v1/public/testimonies/latest`);
    let tempdata = data?.filter((item) => item?.userType === "patient");
    setTestimonials(tempdata);
  };
  const immediatetVisibility = () => {
    let reveals = document.querySelectorAll(
      ".immediatereveal_LR_1,.immediatereveal_LR_2,.immediatereveal_LR_3"
    );

    for (let i = 0; i < reveals.length; i++) {
      let windowHeight = window.innerHeight;
      let elementTop = reveals[i].getBoundingClientRect().top;
      let elementVisible = 100;

      if (elementTop < windowHeight - elementVisible)
        reveals[i].classList.add("active");
      else reveals[i].classList.remove("active");
    }
  };
  useEffect(() => {
    getProviderTestimonials();
    document
      .getElementById("landing")
      .addEventListener("scroll", immediatetVisibility);
  }, []);

  return (
    <Layout>
      <section
        className={`container px-5 ${window.innerWidth > 769 ? " mt-4" : ""}`}
      >
        <div className="row p-md-3 py-md-0 p-1 pt-5 rounded-1">
          <div
            className={`col-sm-7 col-lg-7 rounded-1 ${
              window.innerWidth > 1750
                ? "py-3"
                : window.innerWidth > 1000 && window.innerWidth < 1100
                ? " py-3"
                : "my-md-3"
            }`}
          >
            <h1
              className=" fontweight-bold mt-6rem"
              style={{ lineHeight: "44px", fontSize: "55px" }}
            >
              <p className="text-black">Discounted online </p>
              <p className="text-black">prescriptions </p>
            </h1>
            <h5 className="text-black pt-2 ">
              Receive discounted prescriptions at your
              <br />
              local select pharmacy by using our discount.
            </h5>
            <Button
              type="button"
              className="jump-button bg-primary text-white rounded-pill fw-semibold mt-3"
              style={{ padding: "7px 28px", fontSize: "20px" }}
            >
              Sign Up
            </Button>
            <br />
            <div className="d-flex">
              <img
                src={HIPAA}
                alt="Iphone banner"
                className=" mt-3"
                style={{ width: "17%" }}
              />
              <img
                src={SOC2}
                alt="Iphone banner"
                className="mt-4"
                style={{ width: "7%", height: "9% " }}
              />
              <p
                className="fw-600 px-2 mb-0"
                style={{ fontSize: "10px", marginTop: "33px" }}
              >
                SOC 2 TYPE 2<br />
                Certified
              </p>
            </div>
          </div>
          {/* <div
            className="col-sm-5 col-lg-5"
            style={{ border: "1px solid lightgray", borderRadius: "15px" }}
          >
            <Register />
          </div> */}
          <div className="col-sm-5 col-lg-4">
            <Image
              local={true}
              src={banner_iphone_image}
              style={{ height: "85%" }}
              className=" rounded-3 mt-md-3  my-3 max-h-600"
              alt="Iphone banner"
            />
          </div>
          <div className="d-md-none d-flex justify-content-center col-sm-6 col-lg-5 px-md-5">
            <Image
              local={true}
              src={banner_iphone_image}
              className="d-block rounded-3 h-100 mb-5 mt-md-3 mt-lg-0 object-fit-none w-75"
              alt="Iphone banner"
            />
          </div>
        </div>
      </section>
      <section
        className="container px-5 wrapper  text-center pb-5"
        style={{ marginTop: "10rem" }}
      >
        <h1 className="fontweight-bold text-center display-4 mt-5 mb-2">
          How to get online prescriptions
        </h1>
        <p
          className="text-center mb-5"
          style={{ color: "rgb(166, 169, 174)", fontSize: "16px" }}
        >
          Sign up today to become a member. Start your online consultation with
          an orthopedic doctor in our network and receive your discounted
          prescription.
        </p>
        <div className="mx-5">
          <div className="text-start px-5 mx-5 mt-3">
            <div
              className="px-5 py-3 mt-1"
              style={{ border: "3px solid #0071FF", borderRadius: "10px" }}
            >
              <center>
                <h3 className="text-primary fw-600">3 Steps :</h3>
              </center>
              <div className="immediatereveal_LR_1">
                <h4 className="py-3" style={{ fontSize: "20px" }}>
                  <span
                    className="bg-primary rounded-circle fontweight-bold text-white fs--25 me-4"
                    style={{ padding: "3px 13px" }}
                  >
                    1
                  </span>
                  Sign up to become a member of MyOrthopedicProblem.com
                </h4>
              </div>
              <div className="immediatereveal_LR_2">
                <h4 className="py-3" style={{ fontSize: "20px" }}>
                  <span
                    className="bg-primary rounded-circle fontweight-bold text-white fs--25 me-4"
                    style={{ padding: "3px 13px" }}
                  >
                    2
                  </span>
                  Talk with a doctor licensed in your state
                </h4>
              </div>
              <div className="immediatereveal_LR_3">
                <h4 className="py-3" style={{ fontSize: "20px" }}>
                  <span
                    className="bg-primary rounded-circle fontweight-bold text-white fs--25 me-4"
                    style={{ padding: "3px 13px" }}
                  >
                    3
                  </span>
                  Pick up your prescription from your local pharmacy
                </h4>
              </div>
            </div>
          </div>
        </div>
        {/* <h1 className="text-primary text-center display-4 mt-5 pt-5 pb-2">
          Interested?
        </h1> */}
        <h2 className="text-primary fw-700 pt-5 py-4">Interested?</h2>
        <Button
          type="button"
          className="jump-button bg-primary text-white rounded-pill fw-semibold"
          style={{ padding: "6px 50px", fontSize: "20px" }}
        >
          Sign Up
        </Button>
        <h3 className="py-4">
          Already a member? <span className="text-primary">Log in</span>
        </h3>

        <section className="sec_six bg-white px-5">
          <h1 className="fontweight-bold text-center display-4 mt-5 mb-5">
            Frequently asked questions
          </h1>
          <div className="d-md-flex justify-content-between align-items-center container"></div>
          <Accordation FAQ={individualsFAQ} />
        </section>

        <h1 className="landing-display-1 text-primary mt-5rem">
          Our members love us
        </h1>
        <div className="mt-5rem">
          <Carousel cols={3} rows={1} gap={10} loop>
            {testimonials?.map((data) => (
              <Carousel.Item>
                <div className="px-4">
                  <div
                    className="w-100  px-4 pt-3"
                    style={{
                      borderRadius: "40px",
                      height: "290px",
                      background: "rgb(235, 245, 255)",
                    }}
                  >
                    <ReactStars
                      stars={5}
                      value={5}
                      isHalf={true}
                      size={30}
                      activeColor="#ffd700"
                      edit={false}
                    />
                    <p
                      className="pb-4 pt-2"
                      style={{ height: "170px", overflow: "auto" }}
                    >
                      {data?.feedback}
                    </p>
                    <p className="text-muted pb-3 ms-2"> {data?.firstName}</p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default OnlinePrescriptions;
