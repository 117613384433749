import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const timeZone = dayjs.tz.guess() || "America/Chicago";

export const getUtcOffset = () => {
  const utcOffset = dayjs.utc().tz(timeZone).utcOffset() / 60;
  return utcOffset;
};

export default dayjs;
