import React from "react";
import TextInput from "./TextInput";

const BasicInfo = ({ register, useFormControl }) => {
  const {
    formState: { errors },
  } = useFormControl;
  console.log(errors, "err");
  return (
    <section className="container">
      <h5 className="fontweight-bold mb-3">Basic Info</h5>
      <div className="row">
        <div className="col-6">
          <TextInput
            errors={errors}
            register={register}
            type="text"
            label="First name"
            name={"firstName"}
          />
        </div>
        <div className="col-6">
          <TextInput
            errors={errors}
            register={register}
            type="text"
            label="Last name"
            name={"lastName"}
          />
        </div>
        <label htmlFor={"day"} className={`form-label mt-2`}>
          Date of Birth
        </label>
        <div className="d-flex align-items-center">
          <TextInput
            register={register}
            style={{ width: "5%" }}
            type="text"
            placeholder="DD"
            name={"day"}
          />
          <p className="mb-0 mx-1">/</p>
          <TextInput
            register={register}
            style={{ width: "5%" }}
            type="text"
            placeholder="MM"
            name={"month"}
          />
          <p className="mb-0 mx-1">/</p>
          <TextInput
            register={register}
            style={{ width: "10%" }}
            type="text"
            placeholder="YYYY"
            name={"year"}
          />
        </div>
      </div>
    </section>
  );
};

export default BasicInfo;
