import React, { useEffect } from "react";
import banner1 from "../../../../images/Homepage/collage2.webp";
import banner2 from "../../../../images/Homepage/collage1.webp";
import { IoCallOutline } from "react-icons/io5";
import { BsCameraVideo } from "react-icons/bs";
import { AiOutlineMessage } from "react-icons/ai";
import ProviderImage from "../../../../images/provider-profile/Dr.BramlettHeadshot.webp";
import Image from "../custom/Image";

const Session3 = () => {
  const immediatetVisibility = () => {
    let reveals = document.querySelectorAll(
      ".immediatereveal_LR_1_home,.immediatereveal_LR_2_home,.immediatereveal_LR_3_home,.immediatereveal_LR_1,.immediatereveal_LR_1_right"
    );

    for (let i = 0; i < reveals.length; i++) {
      let windowHeight = window.innerHeight;
      let elementTop = reveals[i].getBoundingClientRect().top;
      let elementVisible = 100;

      if (elementTop < windowHeight - elementVisible)
        reveals[i].classList.add("active");
      else reveals[i].classList.remove("active");
    }
  };
  useEffect(() => {
    document
      .getElementById("landing")
      .addEventListener("scroll", immediatetVisibility);
  }, []);

  return (
    <section
      className={`container mt-8rem ${
        window.innerWidth > 769 ? "wrapper" : ""
      }`}
      style={{ marginTop: "13rem" }}
    >
      <h1 className="fontweight-bold text-center display-4 mt-5 pt-5">
        How it works
      </h1>

      <div className="row pt-5 mt-2 px-5 container ">
        <div className="col-lg-7 ps-5  ">
          <div className="immediatereveal_LR_1_home">
            <span
              className="bg-primary rounded-circle fontweight-bold text-white fs--25"
              style={{ padding: "6px 15px" }}
            >
              1
            </span>
            <span className="text-primary fs--25 ps-3 fw-700">Sign Up</span>
            <div className="pt-3 fs-14px mt-2 mb-4 ">
              <div className="d-flex  ">
                <span
                  className="rounded-circle fontweight-bold text-white fs--25"
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "rgb(241, 241, 243)",
                    margin: "0 0 0 10px",
                  }}
                ></span>
                <p className="w-75 ps-4" style={{ fontSize: "17px" }}>
                  Create your account by entering in your email and creating a
                  password
                </p>
              </div>
              <div className="d-flex  ">
                <span
                  className="rounded-circle fontweight-bold text-white fs--25"
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "rgb(241, 241, 243)",
                    margin: "0 0 0 10px",
                  }}
                ></span>
                <p className="w-75 ps-4 " style={{ fontSize: "17px" }}>
                  Select the plan that's right for you by selecting a monthly or
                  yearly subscription plan
                </p>
              </div>
            </div>
          </div>

          <div className=" immediatereveal_LR_1_home">
            <span
              className="bg-primary rounded-circle fontweight-bold text-white fs--25"
              style={{ padding: "6px 15px" }}
            >
              2
            </span>
            <span className="text-primary fs--25 ps-3 fw-700">
              Request your appointment
            </span>
            <div className="pt-3 fs-14px mt-2 mb-4">
              <div className="d-flex  ">
                <span
                  className="rounded-circle fontweight-bold text-white fs--25"
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "rgb(241, 241, 243)",
                    margin: "0px 0 0 10px",
                  }}
                ></span>
                <p className="w-75 ps-4" style={{ fontSize: "17px" }}>
                  Choose a remote visit or office visit with one of our board
                  certified providers
                </p>
              </div>
              <div className="d-flex  ">
                <span
                  className="rounded-circle fontweight-bold text-white fs--25"
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "rgb(241, 241, 243)",
                    margin: "0 0 0 10px",
                  }}
                ></span>
                <p className="w-75 ps-4" style={{ fontSize: "17px" }}>
                  Answer a few questions and within 15 minutes one of our
                  providers will contact you
                </p>
              </div>
            </div>
          </div>

          <div className=" immediatereveal_LR_1_home">
            <span
              className="bg-primary rounded-circle fontweight-bold text-white fs--25"
              style={{ padding: "6px 15px" }}
            >
              3
            </span>
            <span className="text-primary fs--25 ps-3 fw-700">
              Talk to a provider
            </span>
            <div className="pt-3 fs-14px mt-2 mb-4">
              <div className="d-flex  ">
                <span
                  className="rounded-circle fontweight-bold text-white fs--25"
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "rgb(241, 241, 243)",
                    margin: "0 0 0 10px",
                  }}
                ></span>
                <p className="w-75 ps-4" style={{ fontSize: "17px" }}>
                  Chat directly with one of our certified providers by video,
                  call, or messaging in our HIPPA secure patient portal
                </p>
              </div>
              <div className="d-flex  ">
                <span
                  className="rounded-circle fontweight-bold text-white fs--25"
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "rgb(241, 241, 243)",
                    margin: "0 0 0 10px",
                  }}
                ></span>
                <p className="w-75 ps-4" style={{ fontSize: "17px" }}>
                  24/7continued access to an orthopedic provider with an option
                  for an in-person referral to an orthopedic specialist
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-5 col-lg-5 mt-5">
          <div className="leftimage mt-5">
            <div className="immediatereveal_LR_1">
              <button
                style={{
                  position: "absolute",
                  cursor: "auto",
                  top: "0px",
                  left: "15rem",
                  fontSize: "14px",
                  padding: "2px 12px",
                  borderRadius: "25px",
                  borderColor: "#0071FF",
                }}
                className="fw-semibold bg-primary text-white btn px-3"
              >
                Hey Katie, how can
                <br /> I help you today ?
              </button>
              <Image
                local={true}
                src={banner2}
                height="250px"
                width="330px"
                className=" rounded-3 mt-md-3 mt-3 max-h-600"
                alt="Iphone banner"
              />
            </div>

            <div className="rightimage immediatereveal_LR_1_right">
              <Image
                local={true}
                src={banner1}
                className=" rounded-3 mt-md-5 h-75 mt-3 max-h-600"
                alt="Iphone banner"
              />
              <div
                className="d-flex py-1 bg-super-light"
                style={{
                  position: "absolute",
                  top: "15rem",
                  right: "3rem",
                  width: "260px",
                  height: "55px",
                  borderRadius: "30px",
                  marginLeft: "41px",
                }}
              >
                <img
                  src={ProviderImage}
                  alt="Chat0"
                  className="rounded-circle"
                  height="50px"
                  width="50px"
                  loading="lazy"
                />
                <div className="">
                  <AiOutlineMessage
                    width="30px"
                    height="30px"
                    style={{
                      width: "40px",
                      padding: "7px 3px",
                      height: "40px",
                    }}
                    className="rounded-circle bg-primary rounded-circle-mop ms-5 fontweight-bold text-white fs--25"
                  />
                </div>
                <BsCameraVideo
                  width="30px"
                  height="30px"
                  style={{ width: "40px", padding: "7px 3px", height: "40px" }}
                  className="rounded-circle bg-success rounded-circle-mop  ms-3 fontweight-bold text-white fs--25"
                />
                <IoCallOutline
                  width="30px"
                  height="30px"
                  style={{ width: "40px", padding: "7px 3px", height: "40px" }}
                  className="rounded-circle bg-danger rounded-circle-mop  ms-3 fontweight-bold text-white fs--25"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Session3;
