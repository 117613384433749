import React from "react";
import { educationInitValue } from "../athletic-trainer/init";
import TextInput from "./TextInput";

const EducationLibrary = ({
  educationRows,
  useFormControl,
  setEducationRows,
}) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormControl;

  return (
    <div className="mt-3 container">
      <h5 className="fontweight-bold mt-4">Education</h5>
      {educationRows.map((educationRow, ind) => (
        <div className="py-4" key={ind}>
          <div className="row">
            <div className="col-md-4 mb-3">
              <TextInput
                errors={errors}
                register={register}
                label="Degree"
                name={`education[${ind}].degree`}
              />
            </div>
            <div className="col-md-4 mb-3">
              <TextInput
                errors={errors}
                register={register}
                label="Degree Institute"
                name={`education[${ind}].college`}
              />
            </div>
            <div className="col-md-4 mb-1">
              <TextInput
                errors={errors}
                register={register}
                label="Year of Completion"
                name={`education[${ind}].completionyear`}
              />
            </div>
            <div className="row">
              {educationRows.length - 1 === ind && (
                <div
                  className="col-md-2 text-primary cursor-pointer"
                  onClick={() => {
                    const rows = [
                      ...getValues("education"),
                      educationInitValue,
                    ];
                    setEducationRows(rows);
                  }}
                >
                  Add new
                </div>
              )}
              <div
                className="col-md-1 text-danger cursor-pointer"
                onClick={() => {
                  let rows = [...getValues("education")];
                  if (rows.length > 1) {
                    rows.splice(ind, 1);
                    setEducationRows(rows);
                    setValue("education", rows);
                  } else {
                    setEducationRows([educationInitValue]);
                    setValue("education", [educationInitValue]);
                  }
                }}
              >
                Remove
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EducationLibrary;
