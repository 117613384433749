import React, { useState } from "react";
import {
  certificationInitValue,
  leadershipInitValue,
  serviceInitValue,
} from "./init";
import Biography from "../custom/Biography";
import Speciality from "../custom/Speciality";
import Certifications from "../custom/Certifications";
import SocialMedia from "../custom/SocialMedia";
import TextInput from "../custom/TextInput";

const ClinicProfile = ({ useFormControl, signupData }) => {
  const [serviceRows, setServiceRows] = useState([serviceInitValue]);
  const [leadershipRows, setleadershipRows] = useState([leadershipInitValue]);
  const [certificationRows, setCertificationRows] = useState([
    certificationInitValue,
  ]);

  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormControl;

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="mt-3 container">
            <h5 className="fontweight-bold mb-3">Basic Information</h5>
            <div className="row mt-3">
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Clinic Name"
                  name="firstName"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Address"
                  name="address"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="City"
                  name="city"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="State"
                  name="state"
                />
              </div>

              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Zipcode"
                  name="zipcode"
                  maxlength={5}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <Biography register={register} />
          </div>
          <div className="mt-3">
            <Speciality register={register} />
          </div>
          <div className=" mt-3 container">
            <h5 className="fontweight-bold mb-3">Leadership team</h5>
            {leadershipRows.map((leadershipRow, ind) => (
              <div className="row">
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Title"
                    name={`leadership_team[${ind}].teamTitle`}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="First Name"
                    name={`leadership_team[${ind}].firstName`}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Last Name"
                    name={`leadership_team[${ind}].lastName`}
                  />
                </div>

                <div className="row mt-3">
                  {leadershipRows.length - 1 === ind && (
                    <div
                      className="col-md-4 text-primary cursor-pointer"
                      onClick={() => {
                        const rows = [
                          ...getValues("leadership_team"),
                          leadershipInitValue,
                        ];
                        setleadershipRows(rows);
                      }}
                    >
                      Add new
                    </div>
                  )}

                  <div
                    className="col-md-1 text-danger cursor-pointer"
                    onClick={() => {
                      let rows = [...getValues("leadership_team")];
                      if (rows.length > 1) {
                        rows.splice(ind, 1);
                        setleadershipRows(rows);
                        setValue("leadership_team", rows);
                      } else {
                        setleadershipRows([leadershipInitValue]);
                        setValue("leadership_team", [leadershipInitValue]);
                      }
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Certifications
            certificationIntitialValue={certificationInitValue}
            setValue={setValue}
            setCertificationRows={setCertificationRows}
            getValues={getValues}
            signupData={signupData}
            certificationRows={certificationRows}
            register={register}
            watch={watch}
            errors={errors}
          />
          <div className="mt-3 container">
            <h5 className="fontweight-bold mb-3">Service Lines</h5>
            {serviceRows.map((serviceRow, ind) => (
              <div className="row">
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Service Lines"
                    name={`services[${ind}].serviceLines`}
                  />
                </div>

                <div className="row">
                  {serviceRows.length - 1 === ind && (
                    <div
                      className="col-md-2 text-primary cursor-pointer"
                      onClick={() => {
                        const rows = [
                          ...getValues("services"),
                          serviceInitValue,
                        ];
                        setServiceRows(rows);
                      }}
                    >
                      Add new
                    </div>
                  )}
                  <div
                    className="col-md-1 text-danger cursor-pointer"
                    onClick={() => {
                      let rows = [...getValues("services")];

                      if (rows.length > 1) {
                        rows.splice(ind, 1);
                        setServiceRows(rows);
                        setValue("services", rows);
                      } else {
                        setServiceRows([serviceInitValue]);
                        setValue("services", [serviceInitValue]);
                      }
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-4">Social Media</h5>
            <div className="row">
              <SocialMedia errors={errors} register={register} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClinicProfile;
