import React from 'react';
import { GoogleLoginButton, FacebookLoginButton } from 'react-social-login-buttons';
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login';

const SocialMediaLogin = (props) => {
  const onRejected = (err) => {
    console.log(`Social Media Error: ${err.message}`, err);
  };

  const GoogleLogin = (props) => {
    return (
      <LoginSocialGoogle
        client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        scope="profile email openid"
        discoveryDocs="claims_supported"
        onResolve={(props && props.onSuccess) || onRejected}
        onReject={onRejected}
      >
        <GoogleLoginButton
          text={(props && props.text) || 'Continue with Google'}
          preventActiveStyles={true}
          iconColor={'#000'}
          className="mt-2 mb-2 loginbtn"
        />
      </LoginSocialGoogle>
    );
  };

  const FacebookLogin = (props) => {
    return (
      <LoginSocialFacebook
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        cookie={true}
        xfbml={true}
        onResolve={(props && props.onSuccess) || onRejected}
        onReject={onRejected}
      >
        <FacebookLoginButton
          text={(props && props.text) || 'Continue with Facebook'}
          preventActiveStyles={true}
          iconColor="#004aa0"
          className="mt-2 mb-2 loginbtn"
        />
      </LoginSocialFacebook>
    );
  };

  return props.provider === 'google' ? (
    <GoogleLogin {...props}></GoogleLogin>
  ) : props.provider === 'facebook' ? (
    <FacebookLogin {...props}></FacebookLogin>
  ) : (
    <></>
  );
};

export default SocialMediaLogin;
