import React, { useState } from "react";
import { DropdownButton } from "react-bootstrap";
import ReactS3Uploader from "react-s3-uploader";
import { TiAttachment } from "react-icons/ti";
import { IoMdSend } from "react-icons/io";
import axios from "../utils/axiosInst";

const SendMessage = ({ getChat, getChatList, roomId }) => {
  const [message, setMessage] = useState("");
  const [mediaFile, setMediaFile] = useState("");
  const sendChatMessage = async () => {
    if (message?.length) {
      await axios.post(
        `/v1/chat/${roomId}/message`,
        !mediaFile?.message
          ? {
              message: message,
              type: "text",
            }
          : mediaFile
      );

      setMessage("");
      setMediaFile({});
      getChat(roomId);
      getChatList();
    }
  };

  // get Signed s3 Url
  const getSignedUrl = (file, callback) => {
    const params = {
      fileName: `/contents/${file.name}`,
    };
    axios
      .get("/v1/s3/put-presigned-url", { params })
      .then(({ data }) => callback({ signedUrl: data.url }))
      .catch((error) => console.error(error));
  };

  const FileUploadComponent = ({ accept, item }) => {
    return (
      <div className=" mb-3">
        {item}
        <div className="upload-control">
          <ReactS3Uploader
            accept={accept}
            className="d-none"
            getSignedUrl={getSignedUrl}
            signingUrlWithCredentials={true}
            uploadRequestHeaders={{}}
            onFinish={async (result, file) => {
              const { data } = await axios.get("/v1/s3/get-presigned-url", {
                params: {
                  fileName: `/contents/${file.name}`,
                },
              });
              setMediaFile({
                type:
                  file.type.split("/")[0] === "text"
                    ? file.name.split(".")[1]
                    : file.type.split("/")[0],
                message: data.url,
                title: file.name,
              });
              setMessage(file.name);
            }}
            contentDisposition="auto"
            autoUpload={true}
          />
        </div>
      </div>
    );
  };
  return (
    <div
      className="d-flex p-2 pt-3 bg-white"
      style={{ borderTop: "1px solid lightgray" }}
    >
      <div id="file-upload" className="d-flex h-50">
        <DropdownButton
          key="up"
          id="dropdown-button-drop-up"
          drop="up"
          variant="white"
          className="d-flex"
          title={<TiAttachment className="fs--25" />}
        >
          <div id="file-icon">
            <span className="text-primary ">
              <label role="button">
                <FileUploadComponent
                  fileName={"image"}
                  accept={"image/*,video/*"}
                  item={
                    <i
                      className="fa fa-image bg-primary text-white fs-3 mx-3  p-3 me-2  rounded-circle"
                      onClick={() =>
                        document
                          .querySelector("#file-upload .dropdown-menu ")
                          .classList.remove("show")
                      }
                    />
                  }
                />
              </label>
            </span>
          </div>
          <div id="file-icon">
            <span className="text-primary ">
              <label role="button">
                <FileUploadComponent
                  fileName={"doc"}
                  accept={
                    ".pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  }
                  item={
                    <i
                      className="fa fa-file bg-primary text-white fs-3  p-3  rounded-circle"
                      onClick={() =>
                        document
                          .querySelector("#file-upload .dropdown-menu ")
                          .classList.remove("show")
                      }
                    />
                  }
                />
              </label>
            </span>
          </div>
        </DropdownButton>
      </div>
      {mediaFile?.message ? (
        <div
          className="form-control border-0  d-flex p-0 rounded-pill ps-3 h-50 m-0"
          style={{ backgroundColor: "#e9ecef" }}
        >
          <p className="m-0">
            {mediaFile?.title}{" "}
            <span
              role="button"
              onClick={() => {
                setMediaFile({});
                setMessage("");
              }}
            >
              <i className="fa fa-close mt-2 ms-2 text-end" />
            </span>
          </p>
        </div>
      ) : (
        <input
          type="text"
          className="form-control pt-2 h-50 border-0 borderradius-22px"
          placeholder="Type your message here ..."
          onChange={(e) => setMessage(e.target.value)}
          onKeyUp={(e) => (e.key === "Enter" ? sendChatMessage() : "")}
          name="chatInput"
          value={message}
          style={{ backgroundColor: "#e9ecef" }}
        />
      )}
      <button
        type="button"
        id="dropdown-button-drop-up"
        aria-expanded="false"
        className="dropdown-toggle btn btn-white"
        onClick={sendChatMessage}
      >
        <IoMdSend className="width-25px height-25px" />
      </button>
    </div>
  );
};

export default SendMessage;
