import React from "react";
import Accordation from "./components/Accordation";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const Session8 = () => {
  const navigate = useNavigate();
  const individualsFAQ = [
    {
      Question:
        "How much does MyOrthopedicProblem.com cost and who does it cover?",
      Answer: `Membership costs $15 per month. There are no per-visit fees or copays regardless of your insurance coverage; you'll never be charged any additional fees.`,
    },
    {
      Question: "How do I talk to someone about my injury?",
      Answer: `Become a member by clicking Sign Up, eneter your basic info and select a monthly or yearly subscription. Select "Talk to a provider" and instantly start communicating with one of our providers.`,
    },
    {
      Question: "Can I get a referral for an in-person appointment?",
      Answer: ` Yes! As a member you have the option to choose between a "Remote Visit" or "Office Visit". If Remote Visit is selected, you will then have the option to be referred to an in-person office visit with one of our verified providers.`,
    },
    {
      Question: "Who are the providers?",
      Answer: `Our providers are comprised of U.S. licensed and certified Orthopedic Surgeons, Physical Therapists, and Nurse Practitioners. All of our providers are verified for all information and licensing required to provide telehealth services`,
    },
    {
      Question:
        "My insurance already has telehealth, should I still become a member?",
      Answer: `Yes, your telehealth provider likely will have long waits or may not be available 24/7. Our services allow you to talk with an orthopedic provider immediately for a fraction of the cost and time it takes with other telehealth services`,
    },

    {
      Question:
        "Are there any surprise fees or co-pays after I become a member?",
      Answer: `Nope! As a member there are no co-pays or surprise fees at anytime. Talk to a provider as often as you would like for no additional fee`,
    },
    {
      Question: "Can the providers write an absence or school notes?",
      Answer: `Yes! After you have an appointment with a provider they can easily write doctors notes and absence notes when appropriate`,
    },
    {
      Question: "Can I get prescriptions?",
      Answer: `Yes, when medically appropriate one of our doctors can write prescriptions and send them to your pharmacy of choice`,
    },
    {
      Question: "Can I cancel my membership?",
      Answer: `You can easily cancel your membership at any time in your account settings`,
    },
    {
      Question: "What if I already have an orthopedic provider?",
      Answer: `Our membership pairs very well with your current provider, by providing you access to talk with a Board-Certified Orthopedic Providers and get care 24/7 within 15 minutes guaranteed.`,
    },
  ];
  return (
    <section
      className="sec_six bg-white px-5 mt-4rem"
      style={{ marginTop: "10rem" }}
    >
      <h1 className="fontweight-bold text-center display-4 mt-5 mb-5">
        Frequently asked questions
      </h1>
      <div className="d-md-flex justify-content-between align-items-center container"></div>
      <Accordation FAQ={individualsFAQ} />
      <center>
        <Button
          type="button"
          className="jump-button bg-primary text-white rounded-pill fw-semibold text-center mt-5"
          style={{ padding: "7px 28px", fontSize: "20px" }}
          onClick={() => navigate("/signup")}
        >
          Talk to a Provider
        </Button>
      </center>
    </section>
  );
};

export default Session8;
