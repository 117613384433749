import React, { useState, useEffect } from "react";

import { useAppState } from "../state";
import Image from "./Image";
import DefaultUserLogo from "../../../patient/Components/DefaultUserLogo";

const MessageHeader = () => {
  const { meetingId, virtualcare, getVirtualCareUsers } = useAppState();
  const [participants, setParticipants] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const getVirtualCareParticipants = async () => {
    if (!meetingId) return false;
    setIsFetching(true);
    await getVirtualCareUsers().then((data) => {
      if (data) {
        setIsFetching(false);
        setParticipants(data.participants);
      }
    });
  };

  useEffect(() => {
    getVirtualCareParticipants();
  }, [meetingId, virtualcare]);

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex">
        <div className="d-flex">
          <div className="mt-1">
            <h5
              className="mb-0 ms-3 text-primary fw-bold align-self-center"
              title="Rakshan Krish"
            >
              Meeting #{meetingId}
            </h5>
            <p
              className="p-0 m-0 ms-3"
              style={{ fontSize: "13px", color: "#868e96" }}
            >
              {(virtualcare && virtualcare.notes) || "The virtual care program"}
            </p>
          </div>
        </div>
      </div>
      <div className="py-0">
        <div className="d-flex">
          {participants &&
            participants.length > 0 &&
            participants?.map((pat, index) => {
              return (
                <>
                  {pat?.photo ? (
                    <Image
                      key={`participant_${index}`}
                      src={pat?.photo || ""}
                      gender={pat?.gender || undefined}
                      type={pat?.role || undefined}
                      alt={`Participant ${index}`}
                      className="rounded-circle"
                      width={"40px"}
                      height={"40px"}
                      title={
                        `${pat?.name}${
                          pat?.role === "organizer" ? "(organizer)" : ""
                        }` || ""
                      }
                    />
                  ) : (
                    <DefaultUserLogo />
                  )}
                  &nbsp;
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default MessageHeader;
