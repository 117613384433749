import React from "react";
import TextInput from "./TextInput";

const Speciality = ({ register }) => {
  return (
    <section className="container">
      <h5 className="fontweight-bold mt-4">Speciality</h5>
      <div className="row">
        <div className="col-6">
          <label htmlFor={"degree"} className={`form-label`}>
            Speciality
          </label>
          <TextInput
            register={register}
            type="text"
            className="mt-2"
            name={"speciality"}
          />
        </div>
      </div>
    </section>
  );
};

export default Speciality;
