import { useStoreActions } from "easy-peasy";
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player/lazy";
import { getUserData } from "../util/cookieUtil";
import axios from "../util/axiosInst";

const VideoPlayer = ({
  url,
  height,
  className,
  isS3 = true,
  link,
  id,
  ...attr
}) => {
  const postPatientData = useStoreActions(
    (state) => state.dashboard.postPatientData
  );

  const [videos, setVideos] = useState([]);
  if (className) {
    if (document.getElementsByTagName("video").length !== 0)
      document.getElementsByTagName("video")[0].style.borderRadius = "1rem";
  }
  useEffect(() => {
    let temp = [...videos];
    if (!link) {
      async function getVideoUrl(url) {
        try {
          if (url && isS3) {
            const result = await axios.get(`/v1/s3/signed/url?fileName=${url}`);
            return (result && result.data && result.data.url) || url;
          }
          return url;
        } catch (err) {
          return url;
        }
      }
      async function getVideos() {
        const videoArray = [url && (await getVideoUrl(url))];
        setVideos(videoArray);
      }
      getVideos();
    } else {
      temp.push(url);
      setVideos(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, isS3]);
  const playerRef = useRef();

  return videos.map((video, i) => (
    <ReactPlayer
      id={id}
      key={i}
      onPlay={() => {
        window.fbq("track", "MOP Video is played.");
        postPatientData({
          type: "videoViews",
          patientId: getUserData()?.id,
        });
      }}
      ref={playerRef}
      url={video}
      className={className}
      controls={attr.isEducation ? false : true}
      width={attr.width ? attr.width : "100%"}
      height={height ? height : "220px"}
      onReady={() => {
        if (attr.isEducation) {
          const timeToStart = 1 * 20;
          playerRef.current.seekTo(timeToStart, "seconds");
        }
      }}
    />
  ));
};

export default VideoPlayer;
