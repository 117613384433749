import React from "react";
import LabelWithTooltip from "./LabelWithTooltip";
import TextInput from "../../../../landing-page/components/custom/TextInput";
import { PAGE_VARIABLES } from "../../constants";

const EmailContent = ({ errors, register }) => {
  return (
    <>
      {PAGE_VARIABLES?.COMMON?.EMAIL_CONTENT.map((email) => (
        <div className="col-md-4 mb-3" key={email.id}>
          <LabelWithTooltip
            label={email.label}
            TooltipText={email.Tooltip}
            id={email.id}
            labelClassName="form-label"
          />
          <TextInput errors={errors} register={register} name={email.name} />
        </div>
      ))}
    </>
  );
};

export default EmailContent;
