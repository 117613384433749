import React from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { isUndefined } from "lodash";
import {
  getProviderAddress,
  getProviderName,
  gymAddress,
} from "../../../../util/utils";
import Image from "../custom/Image";

const ProviderCard = ({ evaluation, condition, provider }) => {
  const business =
    provider.userType === "wellness_provider" ||
    provider.userType === "massage_therapist"
      ? !isUndefined(provider.business_info)
        ? gymAddress(provider.business_info)
        : ""
      : "";
  const address =
    provider.userType === "physical_therapist"
      ? !isUndefined(provider.rehab_info)
        ? gymAddress(provider.rehab_info)
        : ""
      : provider.clinic && provider.clinic.length
      ? getProviderAddress(provider)
      : provider.userType === "chiropractor"
      ? provider.address !== undefined
        ? provider.address
        : gymAddress(provider.rehab_info)
      : "";
  return (
    <div className="border rounded bg-white px-lg-4 py-lg-4 py-3 px-3 mb-3">
      <div className="d-flex px-0">
        <div className="parent-pic">
          <div className="provider-pics">
            <Link to={`/providers/${provider?.slug || provider?.id}`}>
              <Image
                src={provider.photo}
                type="provider"
                className="rounded-circle"
                alt={`${provider?.firstName}  ${provider?.lastName}`}
                gender={provider?.gender?.toUpperCase()}
              />
            </Link>
          </div>
        </div>
        <div className="profile-msg flex-grow-1 ps-4 ps-0">
          <div className="d-sm-flex justify-content-between name-n-date">
            <div className="d-flex">
              <div className="mt-sm-0 mt-3">
                <h5 className=" fw-bold user-name m-0 p-0">
                  <Link
                    to={`/providers/${provider?.slug || provider?.id}`}
                    className="text-decoration-none text-black"
                  >
                    {provider?.userType === "hospital" ||
                    provider?.userType === "clinic" ||
                    provider?.userType === "gym" ||
                    provider?.userType === "rehabilitation_center"
                      ? provider?.firstName
                      : getProviderName(provider)}
                    {provider.mopRecommended && (
                      <i className="fa fa-check-circle text-info small ms-lg-2 ms-1 mt-2" />
                    )}
                  </Link>
                </h5>
                <h5>
                  {(provider.profile_claimed_status === "rejected" ||
                    provider.profile_claimed_status === "created") && (
                    <Link
                      to={"/provider-claim-your-profile"}
                      className="  text-decoration-none fs--18"
                      state={{
                        id: provider?._id,
                        userId: provider.userId,
                        evaluation: evaluation,
                        condition: condition,
                        pathName: "/providers",
                      }}
                    >
                      Claim Your Profile?
                    </Link>
                  )}
                </h5>

                {provider?.userType === "wellness_provider" ? (
                  <p className="text-muted mb-2 small text-capitalize">
                    {provider?.title && provider?.title.length
                      ? `(Wellness Provider - ${provider?.title})`
                      : "(Wellness Provider)"}
                  </p>
                ) : (
                  <p className="text-muted mb-2 small text-capitalize">
                    {provider?.userType && provider?.userType.length
                      ? provider?.userType !== "neuro_surgeon"
                        ? "(" + provider?.userType?.split("_").join(" ") + ")"
                        : "(" + provider?.userType?.split("_").join("") + ")"
                      : ""}
                  </p>
                )}
                <div
                  className={`d-flex ${
                    provider?.googleRatingsCount <= 0 && "d-none"
                  }`}
                >
                  <ReactStars
                    stars={5}
                    value={Number(provider?.rating || provider?.ratings || 0)}
                    isHalf={true}
                    size={20}
                    activeColor="#ffd700"
                    edit={false}
                  />
                  <p className="align-self-center mb-0 text-muted">{`(${
                    provider?.googleRatingsCount || 0
                  })`}</p>
                </div>
                <div className="d-flex mt-2">
                  {provider?.userType === "wellness_provider" ||
                  provider?.userType === "massage_therapist" ? (
                    business ? (
                      business !== null || business !== "" ? (
                        <p className="me-2 text-muted">
                          <i className="fa fa-map-marker-alt"></i>
                        </p>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  ) : (
                    <p className="me-2 text-muted">
                      {provider?.userType === "hospital" ||
                      provider?.userType === "gym" ||
                      provider?.userType === "clinic" ||
                      provider?.userType === "rehabilitation_center" ||
                      provider?.userType === "personal_trainer" ||
                      provider?.userType === "athletic_trainers"
                        ? gymAddress(provider) !== "" && (
                            <i className="fa fa-map-marker-alt"></i>
                          )
                        : address !== "" && (
                            <i className="fa fa-map-marker-alt"></i>
                          )}
                    </p>
                  )}
                  {provider?.userType === "hospital" ||
                  provider?.userType === "gym" ||
                  provider?.userType === "rehabilitation_center" ||
                  provider?.userType === "personal_trainer" ||
                  provider?.userType === "clinic" ||
                  provider?.userType === "athletic_trainers" ? (
                    <b className="mb-0 user-address">{gymAddress(provider)}</b>
                  ) : (
                    <>
                      {provider?.userType === "wellness_provider" ||
                      provider?.userType === "massage_therapist" ? (
                        <b className="mb-0 user-address">{business}</b>
                      ) : (
                        <b className="mb-0 user-address">{address}</b>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="d-md-flex d-none mt-2 text-end align-items-end  flex-column">
              <Link
                to={`/providers/${provider?.slug || provider?._id}`}
                className="btn btn-outline-primary px-3 fw-bold text-decoration-none width-150px"
                state={{
                  userId: provider.userId,
                  evaluation: evaluation,
                  condition: condition,
                  id: provider?._id,
                }}
                onClick={() => {
                  window.fbq(
                    "track",
                    `${
                      provider?.firstName
                        ? provider?.firstName
                        : getProviderName(provider)
                    }'s Profile is viewed.`
                  );
                }}
              >
                View Profile
              </Link>
            </div>
            <div className="d-md-none d-flex mt-2 text-start align-items-start  flex-column">
              <Link
                to={`/providers/${provider?.slug || provider?._id}`}
                className="btn btn-outline-primary px-3 fw-bold text-decoration-none width-150px"
                state={{
                  userId: provider.userId,
                  evaluation: evaluation,
                  condition: condition,
                  id: provider?._id,
                }}
                onClick={() => {
                  window.fbq(
                    "track",
                    `${
                      provider?.firstName
                        ? provider?.firstName
                        : getProviderName(provider)
                    }'s Profile is viewed.`
                  );
                }}
              >
                View Profile
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProviderCard;
