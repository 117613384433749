import React, { useEffect, useState } from 'react';
import Layout from './layout/Layout';
import Footer from './Home/Footer';
import { domain, user } from '../../../constants';

const Privacypolicy = () => {
  useEffect(() => {
    window.fbq('track', `Privacy Policy Page is opened.`);
    document.getElementById('root').scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="mt-5 mb-4 flex-fill">
        <section className="mb-5">
          <div className="container">
            <div className="row mt-3">
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <h3>MOP Privacy Policy</h3>
                  <div className="d-flex"></div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="bg-white rounded p-5 shadow-lg mb-5 table-responsive">
                  <p>
                    MOP understands how important the privacy of personal information is to our users. This Privacy Policy
                    will tell you what information we collect about you and about your use of MOP and its services. It will
                    explain the choices you have about how your personal information is used and how we protect that
                    information. We urge you to read this Privacy Policy carefully.
                  </p>
                  <p>
                    This Privacy Policy applies to websites owned and operated by Clinekt Health, LLC and includes the mobile
                    optimized versions of these sites and our Mobile Device Applications or "Apps" (we refer to these sites
                    and Apps collectively as the “MOP Sites”). We refer to the MOP Sites and Apps, along with the information
                    and services made available to you through the MOP Sites and Apps, as the “Services.”
                  </p>
                  <p>
                    Except where noted, statements in this Privacy Policy with respect to the MOP Sites also apply to the
                    Apps. If you do not want us to collect, use or disclose information about you and your use of the
                    Services as described in this Privacy Policy, then you should not use the Services. By using the
                    Services, you must agree to the MOP Terms of Use, which is the contract between us and users of the
                    Services.  By accepting the MOP Terms of Use, you confirm that you have read and understand this Privacy
                    Policy and all other applicable terms, and you acknowledge that we will store, use and otherwise process
                    your information in the United States where we are located.
                  </p>
                  <p>
                    References to "MOP," “we” or “us” mean Clinekt Health, LLC, including any company that Clinekt Health,
                    LLC controls (for example, a subsidiary that Clinekt Health, LLC owns). MOP may share information among
                    its subsidiaries or websites that it owns or controls, but information collected under this Privacy
                    Policy is always protected under the terms of this Privacy Policy. Except as otherwise noted in this
                    Privacy Policy, Walk, Inc is the data controller responsible for the processing of your personal
                    information as described in this Privacy Policy.
                  </p>
                  <h5>Information Collected About You</h5>
                  <p>
                    Some of our Services do not retain your personal information, while others store your personal
                    information in accordance with this Privacy Policy. Even if you do not register with or provide any
                    personal information to MOP, we collect information about your use of the MOP Sites and Apps and the
                    Services. We may also acquire information about our users from external sources.
                  </p>

                  <p>When you use the Services, we collect information as follows:</p>
                  <h6>
                    <u>Registration</u>
                  </h6>
                  <p>
                    While you may use most of the Services without registering, certain Services do require that you register
                    with MOP for them to function properly. If you choose to register or update an existing account with MOP
                    or access certain Services, you may be required to provide certain personal information, such as your
                    name, address, telephone number, gender, email address and date of birth, and a username and password to
                    access your MOP account. You are responsible for ensuring the accuracy of the personal information that
                    you submit to MOP.
                  </p>
                  <h6>
                    <u>Interactive Services</u>
                  </h6>
                  <p>
                    Some of our Services, such are interactive and may request that you submit health-related information,
                    along with other information such as your age and gender. You are not required to register with MOP to
                    use the Services and if you are registered with MOP, we do not associate information that you submit with
                    registration information that you have previously provided.
                  </p>
                  <h6>
                    <u>Services and Device Information</u>
                  </h6>
                  <p>
                    When you access and use the Services, MOP automatically collects and stores in its server logs
                    information from your browser or mobile device such as your IP address or unique device identifier,
                    browser information (including referring URL), your preferences and settings, cookies and information
                    about the content you have viewed and actions taken (e.g., search queries, ad engagement, clicks and the
                    associated dates and times). MOP may also collect device-specific information when you install and use an
                    App including your device model, operating system information, advertising ID (which is a unique,
                    user-resettable identification number for advertising associated with a mobile device) and App version
                    and usage information. When enabled by you, we collect precise location information provided by your
                    mobile device, which you may disable through the device settings.
                  </p>
                  <h6>
                    <u>Precise Geolocation Data</u>
                  </h6>
                  <p>
                    When enabled by you, we collect precise location information provided by your mobile device. We do so to
                    provide you more relevant content including advertising based on your location. You may disable the
                    collection and use of your location data through your device settings. To change location settings on
                    your device, please refer to your device’s official knowledge base.
                  </p>
                  <h6>
                    <u>Cookies and Other Tracking Technologies</u>
                  </h6>
                  <p>
                    We and our partners use cookies to collect information about your use of the Services. “Cookies” are
                    small data files assigned to your browser when you visit a MOP Site which enable recognition of your
                    browser and collect and store information about your use of the Services, as described above. In addition
                    to cookies, we and our partners use other tracking technologies that collect information about your use
                    of the Services, including mobile identifiers and "web beacons" which are small graphic files (sometimes
                    called “clear GIFs” or “web pixels”) embedded in a web page or email typically used to monitor activity
                    and send relevant information back to a home server (which can belong to the host site, a network
                    advertiser or some other third party). The information collected by such tracking technologies may be
                    combined with other information that our partners have access to, including your name, email address and
                    physical address, so that we can send you materials, either electronically or by direct mail, that may be
                    of interest to you.
                  </p>
                  <p>
                    Our advertising service partners may use cookies and other tracking technologies to collect information
                    about your use of the MOP Sites, including content you have viewed. These third parties may use this
                    information to help MOP deliver advertising on the MOP Sites and on other third party websites based on
                    your browsing activity on the MOP Sites. MOP may further tailor the advertising on the MOP Sites and
                    these other third party websites based on additional information to the extent known by MOP or these
                    third parties. In addition to using the information it collects performing services for MOP, third
                    parties may also use such information as described in their privacy policy.
                  </p>
                  <p>
                    We may work with third party ad networks to display advertising on our MOP Sites and on third party
                    websites. Our ad network vendors use technologies to collect information about your activities on the MOP
                    Sites to provide you cookie-based targeted advertising on our MOP Sites and on third party websites based
                    upon your browsing activity and your interests.
                  </p>
                  <h5>Cross-Device Tracking</h5>
                  <p>
                    We and our service providers collect and store information about users' interactions with unaffiliated
                    websites and applications that use our technologies, including cookies and similar tracking technologies.
                    This allows us to infer the presence of a common user or household behind multiple devices. We do so in
                    order to:
                    <ul>
                      <li>
                        provide personalized advertising on each device that is inferred from the browsing patterns on all of
                        the devices;
                      </li>
                      <li>detect and prevent fraud;</li>
                      <li>
                        allow users to use our service on one device and pick up seamlessly where they left off on another
                        device;
                      </li>
                      <li>
                        limit the number of times a user is shown the same advertisement, across known or inferred devices
                      </li>
                    </ul>
                    For information on your advertising choices and opt-out, please see the "Your Choices and Rights" section
                    of this Privacy Notice.
                  </p>
                  <h6>
                    <u>How Information Collected About You Is Used</u>
                  </h6>
                  <p>
                    Information about your use of the Services may be used for the following purposes:
                    <ul>
                      <li>to provide, improve and create new Services,</li>
                      <li>
                        to respond to your inquiries and to send you administrative communications about the MOP Sites and
                        Services,
                      </li>
                      <li>to obtain your feedback about the MOP Sites and Services,</li>
                      <li>
                        to send you secure electronic messages and personalized emails pertaining to your interests as
                        inferred from your use of the Services, including news, announcements, reminders and opportunities
                        from MOP,
                      </li>
                      <li>
                        to statistically analyze trends and user behavior and activity including how frequently areas of the
                        MOP Sites are visited, how the Services are being used and how many emails are received and opened,
                      </li>
                      <li>
                        to provide you and people with similar demographic characteristics and interests with more relevant
                        content including advertising both on and off the MOP Sites and Apps,
                      </li>
                      <li>to offer lead generation services,</li>
                      <li>to detect and defend against fraud and other threats to the Services and our users,</li>
                      <li>to identify issues with the Services,</li>
                      <li>to conduct research and measurement activities, including those described below, and</li>
                      <li>to administer your account.</li>
                    </ul>
                    In addition, MOP may use personal information about you for other purposes that are disclosed to you at
                    the time we collect the information and/or with your consent.
                    <p>
                      MOP may combine your personal information and other information collected about your use of the
                      Services, and also supplement with information from external sources for the purposes described in this
                      Privacy Policy. For example, information that MOP collects about you may be combined by MOP with other
                      information available to MOP through third parties for research and measurement purposes, including
                      measuring the effectiveness of content, advertising or programs. This information from other sources
                      may include age, gender, demographic, geographic, personal interests, product purchase activity or
                      other information. We may report aggregate information, which is not able to be identified back to an
                      individual user of the MOP Sites, to our current or prospective advertisers and other business
                      partners.
                    </p>
                    <p>
                      Our legal bases for processing your information include:
                      <ul>
                        <li>your consent;</li>
                        <li>
                          exercising our rights and performing our obligations in connection with entering into or performing
                          under any contract we make with you;
                        </li>
                        <li>compliance with our legal obligations;</li>
                        <li>
                          our legitimate interests that are not overridden by your interests or your fundamental rights and
                          freedoms, like research and development, to market and promote our services, and to protect our
                          legal rights and interests.
                        </li>
                      </ul>
                    </p>
                  </p>
                  <h5>Disclosing Your Information</h5>
                  <h5>
                    We may disclose aggregated information and information that does not identify any individual, without
                    restriction.
                  </h5>
                  <h5>
                    We may disclose your information that we collect or you provide as described in this Privacy Policy:
                  </h5>
                  <h6>
                    <u>MOP Subsidiaries and Corporate Affiliates</u>
                  </h6>
                  <p>
                    We may share your information with our subsidiaries, affiliates and companies acquired by or merged with
                    us and our affiliates. In the event of a corporate change in control resulting from, for example, a sale
                    to, or merger with, another entity, or in the event of a sale of assets or a bankruptcy, MOP reserves the
                    right to transfer your personal information to the new party in control or the party acquiring assets. In
                    the event of such a change, your personal information will continue to be treated in accordance with this
                    Privacy Policy unless any changes to the Privacy Policy are made in accordance with the section below
                    that discusses “Changes to This Privacy Policy.”
                  </p>
                  <h6>
                    <u>Companies and Contractors that Work with MOP</u>
                  </h6>
                  <p>
                    MOP works with third party companies and contractors that help us provide the Services and to otherwise
                    assist in the operation of the MOP Sites and Apps, including those that provide services relating to
                    technology, data analysis, research, email management and deployment, and advertising and marketing
                    and/or content. MOP contractors sometimes have limited access to your information in the course of
                    providing products or services to MOP. We contractually require that our contractors not use or disclose
                    your information for any purpose other than providing the limited service or function for MOP.
                  </p>
                  <h6>
                    <u>Third Party Advertisers and Third Party Websites</u>
                  </h6>
                  <p>
                    We may share information that we have about you, such as a cookie ID or IP address, with third party
                    advertising service providers who may use this information, on our behalf, to help MOP deliver
                    advertising on the MOP Sites as well as on third party websites.
                  </p>
                  <p>
                    Certain content, services and advertisements offered to you through the MOP Sites are served on, or
                    contain links to, websites hosted and operated by a company other than MOP ("Third Party Websites"). MOP
                    does not share your personal information with these Third Party Websites without your consent, but you
                    should be aware that any information you disclose to these Third Party Websites once you access these
                    Third Party Websites is not subject to this Privacy Policy. MOP does not endorse and is not responsible
                    for the privacy practices of these Third Party Websites. You should review the privacy policy posted on
                    the Third Party Website to understand how that Third Party Website collects and uses your information.
                    MOP makes an effort to make it obvious to you when you leave a MOP Site and enter a Third Party Website,
                    either by requiring you to click on a link or by notifying you on the MOP Site before you visit the third
                    party site. In addition, if you see a phrase such as "Powered by" or "in association with" followed by
                    the name of a company other than MOP, then you are on a website hosted by a company other than MOP.  When
                    you use a co-branded service or register or otherwise provide information on a co-branded site, where
                    applicable, we may pass the collected information back to that partner, which may include third party
                    service providers whose services are embedded into and/or appear within the Services.
                  </p>
                  <p>
                    We may also include social widgets on the MOP Sites which enable you to interact with the associated
                    social media services, e.g., to share an article. These widgets may collect browsing data which may be
                    received by the third party that provided the widget, and are controlled by these third parties. You may
                    be able to manage your privacy preferences directly with the applicable social network platform.
                  </p>
                  <h6>
                    <u>Compliance with Law, Regulation, and Law Enforcement Requests</u>
                  </h6>
                  <p>
                    To cooperate with government and law enforcement officials and private parties to enforce and comply with
                    law, we may release personal information to third parties: (1) to comply with legal requirements such as
                    a law, regulation, search warrant, subpoena or court order; (2) when we believe in good faith that
                    disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate
                    fraud, or respond to a government request; or (3) in special cases, such as in response to a physical
                    threat to you or others, to protect property or defend or assert legal rights. In addition, we may
                    disclose personal information as described below.
                  </p>
                  <h5>How Your Information is Secured and Retained</h5>
                  <p>
                    We have implemented reasonable security measures designed to secure your personal information from
                    accidental loss and from unauthorized access, use, alteration, and disclosure. We only retain your
                    information for as long as needed: (i) to conduct business with you; (ii) for the purposes outlined in
                    this Privacy Policy or at the time of collection; or (iii) to comply with our legal obligations, resolve
                    disputes, and enforce any agreements.
                  </p>
                  <p>
                    The safety and security of your information also depends on you. Where we have given you (or where you
                    have chosen) a password for access to certain parts of the Website, you are responsible for keeping this
                    password confidential. We ask you not to share your password with anyone.
                  </p>
                  <p>
                    Unfortunately, the transmission of information via the internet is not completely secure. Although we do
                    our best to protect your Personal Information, we cannot guarantee the security of your Personal
                    Information transmitted to the Website. Any transmission of Personal Information is at your own risk. We
                    are not responsible for circumvention of any privacy settings or security measures contained on the
                    Website.
                  </p>
                  <h5>Your Choices and Rights</h5>
                  <h6>
                    <u>Updating/Removing Your Personal Information</u>
                  </h6>
                  <p>
                    If you do not want your personal information used by MOP as provided in this Privacy Policy, you should
                    not use the MOP Sites, Apps and Services.  You can correct, update or review personal information you
                    have previously submitted by going back to the specific Service, logging-in and making the desired
                    change. You can also update any personal information you have submitted by contacting us using the
                    contact information listed below or through the Contact Us link provided at the bottom of every page of
                    the MOP Sites.
                  </p>
                  <p>
                    If you have registered and desire to delete any of your registration information you have provided to us
                    from our systems please contact us using the contact information listed below in the “Contacting MOP
                    About Your Personal Information or Privacy” section or through the Contact Us link provided at the bottom
                    of every page of the MOP Sites. Upon your request, we will delete your registration information from our
                    active databases and where feasible from our back-up media. You should be aware that it is not
                    technologically possible to remove each and every record of the information you have provided to the MOP
                    Sites from our servers.
                  </p>
                  <p>
                    If you sign up to receive email communications for any of our Services, including our email newsletters,
                    or at any time, you can choose to opt out of receiving additional newsletters or other email
                    communications from MOP or our advertisers or sponsors.
                  </p>
                  <h6>
                    <u>Cookies</u>
                  </h6>
                  <p>
                    Most browser software can be set to reject Cookies. Most browsers offer instructions on how to reset the
                    browser to reject Cookies in the "Help" or “Settings” or “Preferences” section of your browser’s toolbar.
                  </p>
                  <h6>
                    <u>California Residents</u>
                  </h6>
                  <p>
                    If you are a California resident, California law may provide you with additional rights regarding our use
                    of your personal information. California Civil Code Section 1798.83 permits users of the Website that are
                    California residents to request certain information regarding our disclosure of Personal Information to
                    third parties for their direct marketing purposes. To make such a request, please send an email to {user}
                    @{domain}
                  </p>
                  <p>Nevada Residents</p>
                  <p>
                    Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter
                    603A may submit a request to this designated address:
                    {user}@{domain} However, please know we do not currently sell data triggering that statute's opt-out
                    requirements.
                  </p>
                  <h5>Children</h5>
                  <p>
                    The Website is not intended for children as defined by applicable law. No child may provide any
                    information through the Website. We do not knowingly collect personal information from or about children.
                    If you are a child, do not use or provide any information on the Website or on or through any of its
                    features, register on the Website or provide any information about yourself to us, including your name,
                    address, telephone number, email address, or any screen name or user name you may use. If you are an
                    adult, do not provide the aforementioned information about any child through the Website. If you believe
                    we might have any information from or about a child, please contact us at
                    {user}@{domain} so that we can delete that information.
                  </p>
                  <h5>Note to Users Outside of the United States</h5>
                  <p>
                    MOP and our technical infrastructure are located in the United States.  The personal information that you
                    provide to us is stored on servers located in the United States. If you are located in another
                    jurisdiction, you should be aware that in order to provide the Services to you, we must transfer your
                    personal information to the United States where it will be stored and processed in accordance with this
                    Privacy Policy. We may transfer your information outside the United States to service providers with
                    operations in other countries. By using the Services, you consent to such collection, storage and
                    processing in the United States and elsewhere, though the United States and other jurisdictions may not
                    afford the same level of data protection as considered adequate in your own country. We will take
                    reasonable steps to protect your personal information. Note that your personal information may be
                    available to the United States government or its agencies under legal process made in the United States.
                  </p>
                  <p>
                    Where we process your personal information for direct marketing purposes, you can opt-out through the
                    unsubscribe link in the email communications we send to you, by changing your subscription preferences in
                    your account settings or as otherwise specified in this Privacy Policy.
                  </p>
                  <h5>Contacting MOP About Your Personal Information or Privacy</h5>
                  <p>
                    Please send us an email by using the Contact Us link at the bottom of the home page of the MOP Sites if
                    you have any questions about this Privacy Policy or the personal information we maintain about you. We
                    will respond to your request within a reasonable timeframe.
                  </p>
                  <p>You can also contact us at:</p>
                  <p>
                    Clinekt Health, LLC<br></br>
                    Attn: Chief Legal Officer<br></br>
                    2839 Culver Road, Suite 202<br></br>
                    Birmingham, AL 35223<br></br>
                    {user}@{domain}
                  </p>
                  <h5>Changes to this Privacy Policy</h5>
                  <p>
                    We reserve the right to change or modify this Privacy Policy and any of our Services at any time and any
                    changes will be effective upon being posted unless we advise otherwise. If we make any material changes
                    to this Privacy Policy we will notify you by means of a notice on the MOP Sites and/or by email to our
                    registered users (sent to the email address specified when you register) prior to the change becoming
                    effective. By continuing to use the Services after changes are made to this Privacy Policy, you agree to
                    such changes.  We encourage you to periodically review this Privacy Policy for the latest information on
                    our privacy practices. If you do not accept the terms of this Privacy Policy, we ask that you do not
                    register with us and that you do not use the MOP Sites. Please exit the MOP Sites immediately if you do
                    not agree to the terms of this Privacy Policy.
                  </p>
                  <p>Last updated on October 6, 2022</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Layout>
  );
};

export default Privacypolicy;
