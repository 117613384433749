import React from "react";
import { useStoreState } from "easy-peasy";
import { useLocation, useNavigate } from "react-router-dom";

const getArticles = (conditions) => {
  const articles = conditions.map((condition) => condition?.contents[0]);
  return [...new Set(articles.filter((article) => article))];
};

const EducationArticleSummary = ({ data, modalData, setUserChoice }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const results = useStoreState((state) => state.results);
  const educationCondition = location.search.includes("evaluation_result=");

  return (
    <>
      <div className="mt-lg-0 mt-3">
        {educationCondition &&
          results?.conditions.map((condition, i) => (
            <div className="rounded-3 bg-muted-25 p-3 mb-3" key={i}>
              <div className="d-lg-flex d-md-block d-flex overflow-hidden">
                <div className="me-2">
                  <div className="d-block border-start ps-2 border-5 border-primary">
                    <div className="d-flex">
                      <h3 className="mb-0 me-2 fw-semibold">
                        {condition.confidence}%
                      </h3>
                      <h3 className="mb-0 align-self-end fw-semibold">
                        Confidence
                      </h3>
                    </div>
                    <h4 className="mt-3 text-primary fw-semibold">
                      {condition.name}
                    </h4>
                  </div>
                  {condition?.contents?.length > 0 && (
                    <button
                      className="btn btn-outline-primary align-self-center px-2 ms-lg-auto text-nowrap d-block d-sm-none"
                      onClick={() => {
                        setUserChoice("");
                        let similarArticles = getArticles(results?.conditions);
                        const currentArticle = condition?.contents[0];
                        const filteredArticles = similarArticles.filter(
                          (article) => article !== currentArticle
                        );
                        filteredArticles.unshift(currentArticle);
                        similarArticles = filteredArticles;
                        if (similarArticles.length > 0) {
                          navigate(
                            `/education-library/${
                              similarArticles[0]
                            }?evaluation_result=${similarArticles
                              .slice(1, similarArticles.length)
                              .join(",")}`,
                            { state: { evaluation: true } }
                          );
                        } else navigate(`/education-library`);
                      }}
                    >
                      Learn More
                    </button>
                  )}
                </div>
                {condition?.contents?.length > 0 && (
                  <button
                    className="btn btn-outline-primary align-self-center px-2 ms-lg-auto text-nowrap d-none d-sm-block"
                    onClick={() => {
                      setUserChoice("");
                      let similarArticles = getArticles(results?.conditions);
                      const currentArticle = condition?.contents[0];
                      const filteredArticles = similarArticles.filter(
                        (article) => article !== currentArticle
                      );
                      filteredArticles.unshift(currentArticle);
                      similarArticles = filteredArticles;
                      if (similarArticles.length > 0) {
                        navigate(
                          `/education-library/${
                            similarArticles[0]
                          }?evaluation_result=${similarArticles
                            .slice(1, similarArticles.length)
                            .join(",")}`,
                          { state: { evaluation: true } }
                        );
                      } else navigate(`/education-library`);
                    }}
                  >
                    Learn More
                  </button>
                )}
                {condition?.contents?.length > 0 && (
                  <button
                    onClick={() => {
                      setUserChoice("");
                      let similarArticles = getArticles(results?.conditions);
                      const currentArticle = condition?.contents[0];
                      const filteredArticles = similarArticles.filter(
                        (article) => article !== currentArticle
                      );
                      filteredArticles.unshift(currentArticle);
                      similarArticles = filteredArticles;
                      if (similarArticles.length > 0) {
                        navigate(
                          `/education-library/${
                            similarArticles[0]
                          }?evaluation_result=${similarArticles
                            .slice(1, similarArticles.length)
                            .join(",")}`,
                          { state: { evaluation: true } }
                        );
                      } else navigate(`/education-library`);
                    }}
                    className="btn btn-light align-self-center ms-auto text-nowrap bg-transparent border-0 d-block d-sm-none"
                  >
                    <i className="fa fa-chevron-right" />
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="mt-md-5 mt-3">
        <div className="container sticky-top w-75 bg-white rounded border border-primary py-3">
          <div className="row">
            <div className="col-12">
              <p className="fw-semibold text-md text-center">
                Do you believe this is your condition?
              </p>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className={educationCondition ? "col-4" : "col-12"}>
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      navigate("/signup", {
                        state: {
                          ...location?.state,
                          zipcode: modalData?.zipcode,
                        },
                      });
                    }}
                  >
                    Yes
                  </button>
                </div>
                {educationCondition && (
                  <div className="col-4">
                    <button
                      className="btn btn-outline-secondary w-100"
                      onClick={() => {
                        if (data.length > 0)
                          navigate(
                            `/education-library/${
                              data[0]
                            }?evaluation_result=${data
                              .slice(1, data.length)
                              .join(",")}`,
                            { state: location?.state }
                          );
                      }}
                    >
                      No
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EducationArticleSummary;
