import React, { useState, useEffect } from "react";

const ProgressLine = ({ progress, className = "" }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(progress);
  }, [progress]);

  return (
    <div className={`progress-line-container ${className}`}>
      <div
        className={`progress-line d-flex`}
        style={{ width: `${width}%`, height: "100%" }}
      ></div>
    </div>
  );
};

export default ProgressLine;
