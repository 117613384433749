import React from "react";
import PropTypes from "prop-types";

const PageNotFound = ({ error }) => {
  return (
    <React.Fragment>
      {error && (
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="col-sm-12 text-center">
            <p className="fs-3">
              <span className="text-danger">Opps!</span> Meeting has expired.
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

PageNotFound.propTypes = {
  error: PropTypes.any,
};

export default PageNotFound;
