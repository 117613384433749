import React from "react";
import { useLocation } from "react-router-dom";
import SideBar from "./SideBar";

const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <div
      className={`d-flex m-0 bg-F5F5F7 ${
        location?.pathname.includes("pro-provider-messages") ||
        location?.pathname.includes("view-referral")
          ? "overflow-hidden"
          : "scroll-div vh-100"
      }`}
    >
      {location.pathname !== "/login" && <SideBar />}
      <div className="flex-fill bg-F5F5F7">{children}</div>
    </div>
  );
};

export default Layout;
