import React from "react";
import { FaSearch } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";

const SearchBar = ({
  setStart,
  totalUnreadMessages,
  setFilter,
  chat,
  setSearch,
}) => {
  const ConvoFilter = (e) => {
    setSearch(e);
    setFilter(
      chat?.conversation?.filter((list) =>
        list?.roomType === "single"
          ? list.chatUserName.toLowerCase().substring(0, e.length) ===
            e.toLowerCase()
          : list?.roomType === "group"
          ? list.roomName.toLowerCase().substring(0, e.length) ===
            e.toLowerCase()
          : [{}]
      )
    );
  };
  return (
    <div className={`bg-white py-3 border-bottom border-2`}>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <FaSearch
            style={{
              transform: "translate(35px,8px)",
            }}
          />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => ConvoFilter(e.target.value)}
            className="  bg-super-light border-0 text-center borderradius-22px"
            style={{ padding: "4px" }}
          />
        </div>
        {totalUnreadMessages > 0 && (
          <span className="bg-primary count d-flex align-self-center mx-3 mb-2">
            {totalUnreadMessages}
          </span>
        )}
        <div>
          <FaRegEdit
            className="text-primary me-2 width-30px height-30px cursor-pointer"
            onClick={() => setStart(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
