import React from "react";

const Biography = ({ register }) => {
  return (
    <section className="container">
      <h5 className="fontweight-bold mt-4">Biography</h5>
      <div className="col-md-6 mb-3 d-flex flex-column">
        <label htmlFor="biography" className="form-label">
          About
        </label>
        <textarea
          name="biography"
          id="biography"
          cols="3"
          rows="3"
          className="form-control flex-fill"
          {...register("biography")}
        />
      </div>
    </section>
  );
};

export default Biography;
