import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppStateProvider, { useAppState } from "./state/state";
import Phone from "./components/Phone";

const AudioApp = ({ token, callback }) => {
  const { getVirtualCareToken } = useAppState();
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const { Token } = useParams();

  window.Intercom &&
    window.Intercom("update", {
      hide_default_launcher: false,
    });

  const VirtualCareToken = async () => {
    if (Token || token) {
      await Promise.resolve(getVirtualCareToken(Token || token))
        .then((data) => {
          setIsFetching(false);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      return false;
    }
  };

  useEffect(() => {
    document.getElementById("root").scrollTo(0, -10);
    VirtualCareToken();
  }, [Token, token]);

  return (
    <React.Fragment>
      {isFetching ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <Phone error={error} callback={callback} />
      )}
    </React.Fragment>
  );
};

const Audio = ({ token, callback }) => (
  <AppStateProvider>
    <AudioApp token={token} callback={callback} />
  </AppStateProvider>
);

export default Audio;
