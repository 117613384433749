import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  certificationInitValue,
  educationInitValue,
  schoolAffiliatedInitialValue,
  teamAffiliatedInitialValue,
} from "./init";
import axios from "../../../utils/axiosInst";
import TextInput from "../custom/TextInput";
import Certifications from "../custom/Certifications";
import SocialMedia from "../custom/SocialMedia";
import { PAGE_VARIABLES } from "../../constants";
import BasicInfo from "../custom/BasicInfo";
import Biography from "../custom/Biography";
import Speciality from "../custom/Speciality";
import EducationLibrary from "../custom/EducationLibrary";

const AthleticTrainersProfile = ({ useFormControl, signupData }) => {
  const [certificationRows, setCertificationRows] = useState([
    certificationInitValue,
  ]);
  const [educationRows, setEducationRows] = useState([educationInitValue]);
  const [teamAffiliationsRows, setTeamAffiliationsRows] = useState([
    teamAffiliatedInitialValue,
  ]);
  const [schoolAffiliationsRows, setSchoolAffiliationsRows] = useState([
    schoolAffiliatedInitialValue,
  ]);
  const {
    register,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormControl;

  const MySwal = withReactContent(Swal);

  const claimStatusCondition = () => {
    if (JSON.parse(localStorage.getItem("claim-status") === "true")) {
      MySwal.fire({
        title: PAGE_VARIABLES?.COMMON?.ALERT_MESSAGE,
        icon: "info",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      let data = {
        loggedIn: true,
      };

      if (JSON.parse(localStorage.getItem("claim-id"))) {
        axios.patch(
          `/v1/claimProfile/${JSON.parse(localStorage.getItem("claim-id"))}`,
          data
        );
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    claimStatusCondition();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="mt-3">
            <BasicInfo register={register} useFormControl={useFormControl} />
          </div>
          <div className="mt-3">
            <Biography register={register} />
          </div>
          <div className="mt-3">
            <Speciality register={register} />
          </div>
          <Certifications
            certificationIntitialValue={certificationInitValue}
            setValue={setValue}
            signupData={signupData}
            setCertificationRows={setCertificationRows}
            getValues={getValues}
            certificationRows={certificationRows}
            register={register}
            watch={watch}
            errors={errors}
          />
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-4">Years of Experience</h5>
            <div className="py-3 px-4">
              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Years of Experience"
                    name={`years_of_experience`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-4">Teams Affiliated With</h5>
            {teamAffiliationsRows.map((trainingRow, ind) => (
              <div className="py-4" key={ind}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="Team Name"
                      name={`teams_affiliated[${ind}].team_name`}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="Team City"
                      name={`teams_affiliated[${ind}].team_city`}
                    />
                  </div>
                  <div className="row">
                    {teamAffiliationsRows.length - 1 === ind && (
                      <div
                        className="col-md-2 text-primary cursor-pointer"
                        onClick={() => {
                          const rows = [
                            ...getValues("teams_affiliated"),
                            teamAffiliatedInitialValue,
                          ];
                          setTeamAffiliationsRows(rows);
                        }}
                      >
                        Add new
                      </div>
                    )}
                    <div
                      className="col-md-1 text-danger cursor-pointer"
                      onClick={() => {
                        let rows = [...teamAffiliationsRows];
                        if (rows.length > 1) {
                          rows.splice(ind, 1);
                          setTeamAffiliationsRows(rows);
                          setValue("teams_affiliated", rows);
                        } else {
                          setTeamAffiliationsRows([teamAffiliatedInitialValue]);
                          setValue("teams_affiliated", [
                            teamAffiliatedInitialValue,
                          ]);
                        }
                      }}
                    >
                      Remove
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-4">Schools Affiliated with</h5>
            {schoolAffiliationsRows.map((trainingRow, ind) => (
              <div className="py-4" key={ind}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="School Name"
                      name={`schools_affiliated[${ind}].school_name`}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="School City"
                      name={`schools_affiliated[${ind}].school_city`}
                    />
                  </div>
                  <div className="row">
                    {schoolAffiliationsRows.length - 1 === ind && (
                      <div
                        className="col-md-2 text-primary cursor-pointer"
                        onClick={() => {
                          const rows = [
                            ...getValues("schools_affiliated"),
                            schoolAffiliatedInitialValue,
                          ];
                          setSchoolAffiliationsRows(rows);
                        }}
                      >
                        Add new
                      </div>
                    )}
                    <div
                      className="col-md-1 text-danger cursor-pointer"
                      onClick={() => {
                        let rows = [...schoolAffiliationsRows];
                        if (rows.length > 1) {
                          rows.splice(ind, 1);
                          setSchoolAffiliationsRows(rows);
                          setValue("schools_affiliated", rows);
                        } else {
                          setSchoolAffiliationsRows([
                            schoolAffiliatedInitialValue,
                          ]);
                          setValue("schools_affiliated", [
                            schoolAffiliatedInitialValue,
                          ]);
                        }
                      }}
                    >
                      Remove
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <EducationLibrary
            useFormControl={useFormControl}
            setEducationRows={setEducationRows}
            educationRows={educationRows}
          />
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-4">Social Media</h5>
            <div className="row">
              <SocialMedia errors={errors} register={register} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AthleticTrainersProfile;
