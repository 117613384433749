import React from "react";
import { Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Image from "../../components/custom/Image";
import banner_iphone_image from "../../../assets/images/videocall.png";
import SOC2 from "../../../assets/images/soc2.png";
import HIPAA from "../../../assets/images/hipaa.jpg";

const Banner = () => {
  const navigate = useNavigate();
  return (
    <section
      className={`container px-5 ${window.innerWidth > 769 ? " mt-4" : ""}`}
    >
      <div className="row mx-2 p-md-3 py-md-0 p-1 pt-5 rounded-1">
        <div
          className={`col-sm-7 col-lg-7 rounded-1 ${
            window.innerWidth > 1750
              ? "py-3"
              : window.innerWidth > 1000 && window.innerWidth < 1100
              ? " py-3"
              : "my-md-3"
          }`}
        >
          <h1
            className="landing-display-1 fontweight-bold mb-4 mt-5"
            style={{ lineHeight: "49px", fontSize: "65px" }}
          >
            <p className="text-black">Immediate</p>
            <p className="text-black">access to</p>
            <p className="text-primary">orthopedic care</p>
          </h1>
          <h4
            className="text-black pt-2 fw-semibold"
            style={{ fontSize: "22px" }}
          >
            Unlimited access to 24/7 on-demand virtual care
          </h4>
          <h4
            className="text-black mt-1 mb-5 fw-semibold"
            style={{ fontSize: "22px" }}
          >
            with orthopedic specialists.
          </h4>
          <Button
            type="button"
            className="jump-button bg-primary text-white rounded-pill fw-semibold"
            style={{ padding: "5px 25px", fontSize: "20px" }}
            onClick={() => navigate("/signup")}
          >
            Talk to a Provider
          </Button>
          <br />
          <div className="d-flex">
            <img
              src={HIPAA}
              alt="Iphone banner"
              className=" mt-4"
              style={{ width: "17%" }}
            />
            <img
              src={SOC2}
              alt="Iphone banner"
              className="mt-4"
              style={{ width: "8%", height: "9%" }}
            />
            <p
              className="px-2 mb-0"
              style={{ fontSize: "11px", marginTop: "31px", fontWeight: "800" }}
            >
              SOC 2 TYPE 2<br />
              Certified
            </p>
          </div>
        </div>
        <div className="col-sm-5 col-lg-5">
          <Image
            local={true}
            src={banner_iphone_image}
            style={{ height: "923%" }}
            className=" rounded-3 mt-2 mb-3 max-h-600"
            alt="Iphone banner"
          />
        </div>
        <div className="d-md-none d-flex justify-content-center col-sm-6 col-lg-5   px-md-5">
          <Image
            local={true}
            src={banner_iphone_image}
            className="d-block rounded-3 h-100 mb-5 mt-md-3 mt-lg-0 object-fit-none w-75"
            alt="Iphone banner"
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
