import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const BreadCumbs = ({ BreadCumbs }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {BreadCumbs.map((link, index) => (
          <Fragment key={index}>
            {link.active ? (
              <li className="text-muted" aria-current="page">
                / {link.title}
              </li>
            ) : (
              <li className="breadcrumb-item">
                <Link
                  to={`/${link?.to}`}
                  className="text-decoration-none pe-1"
                  state={link?.state}
                  onClick={link?.onClick}
                >
                  {link.title}
                </Link>
              </li>
            )}
          </Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCumbs;
