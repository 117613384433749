import React from "react";
import FileMessage from "./FileMessage";

const OthersMessage = ({ conversation, formatDateTime }) => {
  return (
    <div className="d-flex mt-2">
      <div className="col-11 d-flex justify-content-start">
        <div
          className="d-flex bg-white justify-content-between rounded"
          style={{
            border: "1px solid #e9ecef",
          }}
        >
          {conversation?.message?.type === "text" ? (
            <div className="text-start" style={{ padding: "8px 12px" }}>
              <p
                className="m-0 pb-1"
                style={{ fontSize: "10px", color: "#868e96" }}
              >
                {conversation?.postedByUser?.name}{" "}
                <span className="float-right ms-3">
                  {formatDateTime(conversation?.createdAt)}
                </span>
              </p>
              <p className="mb-0 text-start fs-6 wordBreak-break-word">
                {conversation?.message?.message}
              </p>
            </div>
          ) : (
            <FileMessage
              conversation={conversation}
              formatDateTime={formatDateTime}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OthersMessage;
