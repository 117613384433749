import React, { useEffect, useState } from "react";
import Layout from "./layout/Layout";
import banner_iphone_image from "../../assets/images/videocall.png";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Image from "../components/custom/Image";
import { FaDollarSign } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { LuClock } from "react-icons/lu";
import { BiBlock } from "react-icons/bi";
import Carousel from "react-grid-carousel";
import ReactStars from "react-rating-stars-component";
import Footer from "./Home/Footer";
import axios from "../../../util/axiosInst";
import SOC2 from "../../assets/images/soc2.png";
import HIPAA from "../../assets/images/hipaa.jpg";

const JoinOurNetwork = () => {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState();

  const getProviderTestimonials = async () => {
    const { data } = await axios.get(`/v1/public/testimonies/latest`);
    let tempdata = data?.filter((item) => item?.userType !== "patient");
    setTestimonials(tempdata);
  };

  const immediatetVisibility = () => {
    let reveals = document.querySelectorAll(
      ".immediatereveal_LR_1,.immediatereveal_LR_2,.immediatereveal_LR_3"
    );

    for (let i = 0; i < reveals.length; i++) {
      let windowHeight = window.innerHeight;
      let elementTop = reveals[i].getBoundingClientRect().top;
      let elementVisible = 100;

      if (elementTop < windowHeight - elementVisible)
        reveals[i].classList.add("active");
      else reveals[i].classList.remove("active");
    }
  };
  useEffect(() => {
    getProviderTestimonials();
    document
      .getElementById("landing")
      .addEventListener("scroll", immediatetVisibility);
  }, []);

  return (
    <Layout>
      <section
        className={`container px-5 ${window.innerWidth > 769 ? " mt-4" : ""}`}
      >
        <div className="row mx-2 p-md-3 py-md-0 p-1 pt-5 rounded-1">
          <div
            className={`col-sm-7 col-lg-7 rounded-1 ${
              window.innerWidth > 1750
                ? "py-3"
                : window.innerWidth > 1000 && window.innerWidth < 1100
                ? " py-3"
                : "my-md-3"
            }`}
          >
            <h1
              className="landing-display-1 fontweight-bold mb-4 mt-5"
              style={{ lineHeight: "49px", fontSize: "65px" }}
            >
              <p className="text-black">Deliver care to</p>
              <p className="text-black">patients on your</p>
              <p className="text-black mt-4">time.</p>
            </h1>
            <h4
              className="text-black pt-2 fw-semibold"
              style={{ fontSize: "22px" }}
            >
              Join our provider network and give care to
            </h4>
            <h4
              className="text-black mt-1 mb-5 fw-semibold"
              style={{ fontSize: "22px" }}
            >
              patients anytime, anywhere.
            </h4>
            <Button
              type="button"
              className="jump-button bg-primary text-white rounded-pill fw-semibold"
              style={{ padding: "5px 25px", fontSize: "20px" }}
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </Button>
            <br />
            <div className="d-flex">
              <img
                src={HIPAA}
                alt="Iphone banner"
                className=" mt-4"
                style={{ width: "17%" }}
              />
              <img
                src={SOC2}
                alt="Iphone banner"
                className="mt-4"
                style={{ width: "8%", height: "9%" }}
              />
              <p
                className="px-2 mb-0"
                style={{
                  fontSize: "11px",
                  marginTop: "31px",
                  fontWeight: "800",
                }}
              >
                SOC 2 TYPE 2<br />
                Certified
              </p>
            </div>
          </div>
          <div className="col-sm-5 col-lg-5">
            <Image
              local={true}
              src={banner_iphone_image}
              style={{ height: "923%" }}
              className=" rounded-3 mt-2 mb-3 max-h-600"
              alt="Iphone banner"
            />
          </div>
          <div className="d-md-none d-flex justify-content-center col-sm-6 col-lg-5   px-md-5">
            <Image
              local={true}
              src={banner_iphone_image}
              className="d-block rounded-3 h-100 mb-5 mt-md-3 mt-lg-0 object-fit-none w-75"
              alt="Iphone banner"
            />
          </div>
        </div>
      </section>

      <section className="container px-5 wrapper  text-center pb-5">
        <h1 className="landing-display-1">Join our provider network today</h1>
        <p
          className="text-center pt-2"
          style={{ color: "rgb(166, 169, 174)", fontSize: "24px" }}
        >
          As a member of our provider network, you can directly connect with
          current and new patients through video, audio, and messaging services.
          As well as give and receive patient referrals with other providers
          within our network.
        </p>

        <div className="bg-white p-3 border border-solid rounded shadow-lg col-lg-6 mx-auto">
          <div className=" text-center">
            <div
              className=""
              style={{ border: "3px solid #0071FF", borderRadius: "10px" }}
            >
              <center>
                <h3 className="text-primary fw-600 mt-3">Benefits :</h3>
              </center>
              <div className="immediatereveal_LR_1 text-start ms-5">
                <h4 className="py-3" style={{ fontSize: "20px" }}>
                  <span className="me-3">
                    <FaDollarSign
                      className="text-primary"
                      style={{ width: "45px", height: "45px" }}
                    />
                  </span>
                  Compensation for consultations
                </h4>
              </div>
              <div className="immediatereveal_LR_1 text-start ms-5">
                <h4 className="py-3" style={{ fontSize: "20px" }}>
                  <span className="me-3">
                    <MdCall
                      className="text-primary"
                      style={{ width: "45px", height: "45px" }}
                    />
                  </span>
                  Work from anywhere
                </h4>
              </div>
              <div className="immediatereveal_LR_1 text-start ms-5">
                <h4 className="py-3" style={{ fontSize: "20px" }}>
                  <span className="me-3">
                    <LuClock
                      className="text-primary"
                      style={{ width: "45px", height: "45px" }}
                    />
                  </span>
                  Choose your own hours
                </h4>
              </div>
              <div className="immediatereveal_LR_1 text-start ms-5">
                <h4 className="py-3" style={{ fontSize: "20px" }}>
                  <span className="me-3">
                    <BiBlock
                      className="text-primary"
                      style={{ width: "45px", height: "45px" }}
                    />
                  </span>
                  Eliminate confusion of private practice
                </h4>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-primary fw-700 pt-5">Interested?</h2>

        <h3 className="py-4">
          Sign up today to join our provider network. Once approved you will be
          able to receive telehealth consultations and start earning on your
          time.
        </h3>

        <button
          className="text-white btn mt-4 btn-primary rounded-pill px-5 fw-bold text-nowrap me-3 mb-lg-0 mb-3"
          type="button"
          onClick={() => navigate("/signup")}
        >
          Sign Up
        </button>
        <h3 className="py-4">
          Already a Member?{" "}
          <span
            className="text-primary cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Log in
          </span>
        </h3>

        <h1 className="landing-display-1 text-primary mt-5rem">
          Provider Testimonials
        </h1>
        <div className="mt-5rem">
          <Carousel cols={3} rows={1} gap={10} loop>
            {testimonials?.map((data) => (
              <Carousel.Item>
                <div className="px-4">
                  <div
                    className="w-100  px-4 pt-3"
                    style={{
                      borderRadius: "40px",
                      height: "290px",
                      background: "rgb(235, 245, 255)",
                    }}
                  >
                    <ReactStars
                      stars={5}
                      value={5}
                      isHalf={true}
                      size={30}
                      activeColor="#ffd700"
                      edit={false}
                    />
                    <p
                      className="pb-4 pt-2"
                      style={{ height: "170px", overflow: "auto" }}
                    >
                      {data?.feedback}
                    </p>
                    <p className="text-muted pb-3 ms-2"> {data?.firstName}</p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
            {testimonials?.length < 3 ? (
              <Carousel.Item>
                <div className="px-4">
                  <div
                    className="w-100  px-4 pt-3"
                    style={{
                      borderRadius: "40px",
                      height: "290px",
                      background: "rgb(235, 245, 255)",
                    }}
                  >
                    <ReactStars
                      stars={5}
                      value={5}
                      isHalf={true}
                      size={30}
                      activeColor="#ffd700"
                      edit={false}
                    />
                    <p
                      className="pb-4 pt-2"
                      style={{ height: "170px", overflow: "auto" }}
                    >
                      It helped me better understand my injury, and the
                      different treatment options available.
                    </p>
                    <p className="text-muted pb-3 ms-2">Uthayakumar K</p>
                  </div>
                </div>
              </Carousel.Item>
            ) : (
              <></>
            )}
          </Carousel>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default JoinOurNetwork;
