import React from "react";

const Remove = ({ name, initValue, setValue, setRows, ind, getValues }) => {
  return (
    <div
      className="col-md-1 text-danger cursor-pointer"
      onClick={() => {
        let rows = [...getValues(name)];
        if (rows.length > 1) {
          rows.splice(ind, 1);
          setRows(rows);
          setValue(name, rows);
        } else {
          setRows([initValue]);
          setValue(name, [initValue]);
        }
      }}
    >
      Remove
    </div>
  );
};

export default Remove;
