export const leadershipInitValue = {
  teamTitle: "",
  firstName: "",
  lastName: "",
  serviceLines: "",
};

export const serviceInitValue = {
  serviceLines: "",
};

export const certificationInitValue = {
  label: "",
  value: "",
};
