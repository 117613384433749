import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import axios from "../../../utils/axiosInst";
import {
  certificationInitValue,
  leadershipInitValue,
  serviceInitValue,
} from "./init";
import Certifications from "../custom/Certifications";
import { PAGE_VARIABLES } from "../../constants";
import SocialMedia from "../custom/SocialMedia";
import Biography from "../custom/Biography";
import Speciality from "../custom/Speciality";
import TextInput from "../custom/TextInput";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const RehabilitationCenterProfile = ({ useFormControl, signupData }) => {
  const MySwal = withReactContent(Swal);

  const [serviceRows, setServiceRows] = useState([serviceInitValue]);
  const [certificationRows, setCertificationRows] = useState([
    certificationInitValue,
  ]);
  const [leadershipRows, setleadershipRows] = useState([leadershipInitValue]);

  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormControl;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("claim-status") === "true")) {
      MySwal.fire({
        title: PAGE_VARIABLES?.COMMON?.ALERT_MESSAGE,
        icon: "info",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      let data = {
        loggedIn: true,
      };

      if (JSON.parse(localStorage.getItem("claim-id"))) {
        axios.patch(
          `/v1/claimProfile/${JSON.parse(localStorage.getItem("claim-id"))}`,
          data
        );
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="container mt-4">
            <h5 className="fontweight-bold mt-3">Basic Information</h5>
            <div className="row mt-3">
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Name"
                  name="firstName"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Address"
                  name="address"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="City"
                  name="city"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="State"
                  name="state"
                />
              </div>

              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Zipcode"
                  name="zipcode"
                  maxlength={5}
                />
              </div>

              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  className="text-capitalize"
                  label="Website"
                  name="website"
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <Biography register={register} />
          </div>
          <div className="mt-3">
            <Speciality register={register} />
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mt-3">Specializations</h5>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label for="" className="form-label ">
                  Specializations
                  <span data-tip data-for="service">
                    <i
                      className="fa fa-info-circle me-2 text-primary padding-left-5px"
                      aria-hidden="true"
                    />
                  </span>
                  <ReactTooltip id="service" place="right" effect="solid">
                    <span>
                      To add mulitple Specializations, add each Specializations
                      seperated by Comma.
                    </span>
                  </ReactTooltip>
                </label>
                <TextInput
                  register={register}
                  className={"form-control"}
                  type={"text"}
                  name="specilaizations"
                />
              </div>
            </div>
          </div>
          <div className="container mt-3">
            <h5 className="fontweight-bold mt-3">Leadership team</h5>
            <div className="row">
              {leadershipRows.map((leadershipRow, ind) => (
                <div className="col-md-12 mb-3" key={ind}>
                  <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                      <TextInput
                        errors={errors}
                        register={register}
                        label="Title"
                        name={`leadership_team[${ind}].teamTitle`}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <TextInput
                        errors={errors}
                        register={register}
                        label="First Name"
                        name={`leadership_team[${ind}].firstName`}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <TextInput
                        errors={errors}
                        register={register}
                        label="Last Name"
                        name={`leadership_team[${ind}].lastName`}
                      />
                    </div>
                  </div>

                  <div className="row">
                    {leadershipRows.length - 1 === ind && (
                      <div
                        className="col-md-4 text-primary cursor-pointer"
                        onClick={() => {
                          const rows = [
                            ...getValues("leadership_team"),
                            leadershipInitValue,
                          ];
                          setleadershipRows(rows);
                        }}
                      >
                        Add new
                      </div>
                    )}

                    <div
                      className="col-md-1 text-danger cursor-pointer"
                      onClick={() => {
                        let rows = [...getValues("leadership_team")];
                        if (rows.length > 1) {
                          rows.splice(ind, 1);
                          setleadershipRows(rows);
                          setValue("leadership_team", rows);
                        } else {
                          setleadershipRows([leadershipInitValue]);
                          setValue("leadership_team", [leadershipInitValue]);
                        }
                      }}
                    >
                      Remove
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Certifications
            certificationIntitialValue={certificationInitValue}
            setValue={setValue}
            signupData={signupData}
            setCertificationRows={setCertificationRows}
            getValues={getValues}
            certificationRows={certificationRows}
            register={register}
            watch={watch}
            errors={errors}
          />
          <div className="container mt-3">
            <h5 className="fontweight-bold mt-3">Service Lines</h5>
            {serviceRows.map((serviceRow, ind) => (
              <div className="row">
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Service Lines"
                    name={`services[${ind}].serviceLines`}
                  />
                </div>

                <div className="row">
                  {serviceRows.length - 1 === ind && (
                    <div
                      className="col-md-2 text-primary cursor-pointer"
                      onClick={() => {
                        const rows = [
                          ...getValues("services"),
                          serviceInitValue,
                        ];
                        setServiceRows(rows);
                      }}
                    >
                      Add new
                    </div>
                  )}
                  <div
                    className="col-md-1 text-danger cursor-pointer"
                    onClick={() => {
                      let rows = [...getValues("services")];

                      if (rows.length > 1) {
                        rows.splice(ind, 1);
                        setServiceRows(rows);
                        setValue("services", rows);
                      } else {
                        setServiceRows([serviceInitValue]);
                        setValue("services", [serviceInitValue]);
                      }
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mt-3">Social Media</h5>
            <div className="row">
              <SocialMedia errors={errors} register={register} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RehabilitationCenterProfile;
