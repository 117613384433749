import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import MediaPreview from "../../../components/common/MediaPreview";
import HelmetTag from "../../../components/helmet/Helmet";
import Image from "../components/custom/Image";
import axios from "../../../util/axiosInst";
import Layout from "./layout/Layout";
import Footer from "./Home/Footer";

const BlogPostContent = () => {
  const params = useParams();
  const [post, setPost] = useState("");
  const navigate = useNavigate();
  const getPost = async () => {
    const { data } = await axios.get(`/v1/public/blog-news/${params?.id}`);
    if (data) setPost(data);
    else navigate("/404");
  };

  useEffect(() => {
    window.fbq(
      "track",
      `Latest News ${params?.id.replaceAll("-", " ")} is opened.`
    );
    document.getElementById("root").scrollTo(0, 0);
    if (document.getElementById("blog")) {
      document
        .getElementById("blog")
        .querySelectorAll("p")
        .forEach((element) => {
          element.classList.add("d-flex");
        });
      document
        .getElementById("blog")
        .querySelectorAll("img")
        .forEach((element) => {
          element.classList.add("w-100");
        });
    }
    setInterval(() => {
      if (document.getElementsByClassName("img").length > 0) {
        document
          .getElementsByClassName("img")[0]
          .childNodes.forEach((element) => {
            element.childNodes.forEach((tags) => {
              if (tags.localName === "img") tags.classList.add("w-100");
            });
          });
      }
    }, 2000);
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="bg-muted-25 ">
        <HelmetTag
          title={post?.titleTag}
          content={post.metaDescription}
          canonical={`https://myorthopedicproblem.com/press/${params.id}`}
          JSONLD={`{
          "@context": "http://schema.org",
          "@type": "ExercisePlan",
          "name": "${post.title}",
          "description":"${post.metaDescription}",
          "url": "https://myorthopedicproblem.com/press/${params.id}"
        }
        `}
        />
        <div className="">
          <section className="pt-3 mb-3">
            <div className="container bg-white py-4 my-4 ">
              <nav aria-label="breadcrumb" className="ms-md-5 ps-md-5">
                <ol className="breadcrumb ms-md-3">
                  <li className="breadcrumb-item " onClick={() => navigate(-1)}>
                    <Link className="text-decoration-none">News</Link>
                  </li>
                  <li className="breadcrumb-item active">{post?.title}</li>
                </ol>
              </nav>
              <div className="row">
                <div className="col-1 pt-5 mt-5">
                  <div className="col-1 pt-5 mt-5">
                    <ul className="text-decoration-none d-md-block  d-none sticky-top">
                      <FacebookShareButton
                        url={`${
                          process.env.REACT_APP_SERVER_URL.replace("api", "") +
                          "/press-details/" +
                          params?.id
                        }`}
                        hashtag={"#News"}
                        quote={post?.title}
                        className="d-block"
                      >
                        <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                          <i className="fab fa-facebook-f padding-rem"></i>
                        </div>
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`${
                          process.env.REACT_APP_SERVER_URL.replace("api", "") +
                          "/press-details/" +
                          params?.id
                        }`}
                        title={post?.title}
                      >
                        <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                          <i className="fa fa-twitter padding-rem"></i>
                        </div>
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={`${
                          process.env.REACT_APP_SERVER_URL.replace("api", "") +
                          "/press-details/" +
                          params?.id
                        }`}
                        title={post?.title}
                        source={post?.title}
                        summary={post?.title}
                        className={"d-block"}
                      >
                        <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                          <i className="fab fa-linkedin padding-rem"></i>
                        </div>
                      </LinkedinShareButton>
                    </ul>
                  </div>
                </div>
                <div className="col-11">
                  <div className="row">
                    <div className="d-flex col-sm-12">
                      <div className="row mb-4">
                        <div className="col-sm-12">
                          <h4>
                            <strong>{post?.title}</strong>
                          </h4>
                          <div className="pt-2 text-muted">
                            <h6>
                              News -{" "}
                              {moment(post.updatedAt).format("MMM DD, YYYY")}
                            </h6>
                          </div>
                          <div className="bg-white p-3 ps-0 rounded-2">
                            <div className="news-img  mb-4">
                              {post?.video ? (
                                <MediaPreview
                                  fileName={post?.video}
                                  type="video"
                                  height="400px"
                                />
                              ) : (
                                <Image
                                  src={post?.banner}
                                  className="banner-image border  rounded-1"
                                  alt={post?.title}
                                />
                              )}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: post.content,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container mb-3">
                      <h5 className=" d-flex justify-content-center">
                        <strong>Share:</strong>
                      </h5>
                      <nav className="d-flex justify-content-center ">
                        <FacebookShareButton
                          url={`${
                            process.env.REACT_APP_SERVER_URL.replace(
                              "api",
                              ""
                            ) +
                            "/press-details/" +
                            params?.id
                          }`}
                          hashtag={"#News"}
                          quote={post?.title}
                          className={"d-block mx-3"}
                        >
                          <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                            <i className="fab fa-facebook-f padding-rem"></i>
                          </div>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={`${
                            process.env.REACT_APP_SERVER_URL.replace(
                              "api",
                              ""
                            ) +
                            "/press-details/" +
                            params?.id
                          }`}
                          title={post?.title}
                        >
                          <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                            <i className="fa fa-twitter padding-rem"></i>
                          </div>
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={`${
                            process.env.REACT_APP_SERVER_URL.replace(
                              "api",
                              ""
                            ) +
                            "/press-details/" +
                            params?.id
                          }`}
                          title={post?.title}
                          source={post?.title}
                          summary={post?.title}
                          className={"d-block mx-3"}
                        >
                          <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                            <i className="fab fa-linkedin padding-rem"></i>
                          </div>
                        </LinkedinShareButton>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default BlogPostContent;
