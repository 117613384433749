import React, { useState, useEffect } from "react";

const Uptodate = ({
  data,
  articleId,
  sectionId,
  articleContents,
  ...attributes
}) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const getContent = async (articleId, sectionId) => {
      try {
        if (articleContents && articleContents.length) {
          const article =
            articleContents.find((art) => art.articleId === articleId) ||
            undefined;
          if (article) {
            let html = data;
            if (article.data && article.data.sectionsHtml) {
              // eslint-disable-next-line no-param-reassign
              html = article.data.sectionsHtml.find(
                (elements) => elements.id === sectionId
              ).html;
            }
            return html;
          }
        }
        return data;
      } catch (err) {
        return data;
      }
    };
    const getArticles = async () => {
      const contentArray = [
        articleId && sectionId && (await getContent(articleId, sectionId)),
      ];
      setArticles(contentArray);
    };
    getArticles();
  }, [data, articleId, sectionId, articleContents]);

  return articles.map((article, i) => (
    <div
      key={i}
      dangerouslySetInnerHTML={{
        __html: article,
      }}
    />
  ));
};

export default Uptodate;
