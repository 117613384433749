import React from "react";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import AppStateProvider, { useAppState } from "./state";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import theme from "./theme";
import "./types";
import { ChatProvider } from "./components/ChatProvider";
import { ParticipantProvider } from "./components/ParticipantProvider";
import { VideoProvider } from "./components/VideoProvider";
import { useConnectionOptions } from "./utils/useConnectionOptions/useConnectionOptions";
import { UnsupportedBrowserWarning } from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";

const VideoApp = ({ token }) => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>
          <App token={token} />
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

const Video = ({ token }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <AppStateProvider>
        <VideoApp token={token} />
      </AppStateProvider>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>
);

export default Video;
