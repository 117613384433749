import React, { useState, useEffect } from "react";

import Logo from "../images/logo.webp";
import MessageHeader from "./MessageHeader";
import MessageBody from "./MessageBody";
import MessageFooter from "./MessageFooter";
import PageNotFound from "./PageNotFound";
import { useAppState } from "../state";
import { socket } from "../../../../util/socketInst";

const Messages = ({ error, token }) => {
  const { roomId, userID, getConversationByRoomId } = useAppState();
  const [isFetching, setIsFetching] = useState(false);
  const [typing, setTyping] = useState(false);
  const [conversation, setConversation] = useState([]);

  const getConversationByRoom = async () => {
    if (!roomId) return false;
    setIsFetching(true);
    await getConversationByRoomId().then((data) => {
      if (data) {
        setIsFetching(false);
        setConversation(data.conversation);
      }
    });
  };

  const handler = (data) => {
    if (!data.messageId) {
      setConversation((old) => {
        if (old && old.length) {
          return [...old, data];
        }
        return [data];
      });
    } else {
      getConversationByRoom();
    }
  };

  const typingHandler = (data) => {
    setTyping(data);
  };

  useEffect(() => {
    document.getElementById("root").scrollTo(0, -10);
    if (roomId) {
      socket.emit("subscribe", roomId);
      socket.on(roomId, handler);
      socket.on(`display`, typingHandler);
      getConversationByRoom();
      return () => {
        socket.emit("unsubscribe", roomId);
        socket.off(roomId, handler);
      };
    }
  }, [roomId]);

  return (
    <div className="container">
      <div className="d-flex-row">
        {error && <PageNotFound error={error} />}
        {!error && (
          <React.Fragment>
            {!token && (
              <div
                className={"col-sm-12"}
                style={{ padding: "10px", textAlign: "center" }}
              >
                <img src={Logo} alt="logo" width={"200px"} height={"60px"} />
              </div>
            )}
            <div
              className={"col-sm-12"}
              style={{
                padding: 0,
                marginTop: "10px",
                border: "1px solid lightgray",
              }}
            >
              <div className=" bg-white rounded  justifyContent-space-between">
                <div className={`bg-white pt-2 border-bottom border-2 px-3`}>
                  <MessageHeader />
                </div>
                <div
                  className="chat-messages"
                  style={{ paddingBottom: "10px" }}
                >
                  <MessageBody
                    conversation={conversation}
                    isFetching={isFetching}
                  />
                </div>
                {typing &&
                  typing.typing &&
                  typing.userID !== userID &&
                  typing.roomId === roomId && (
                    <div className="d-flex p-2 pt-3 bg-white">
                      <span>{typing.userName} is typing...</span>
                    </div>
                  )}
                <div
                  className="d-flex p-2 pt-3 bg-white"
                  style={{ borderTop: "1px solid lightgray" }}
                >
                  <MessageFooter socket={socket} />
                </div>
              </div>
            </div>
            <div
              className={"col-sm-12"}
              style={{ padding: "10px", textAlign: "center" }}
            >
              Powered by My Orthopedic Problem
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Messages;
