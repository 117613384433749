export const bootWithProps = () => {
  setTimeout(() => {
    const intercomSettings = window.intercomSettings;
    const user =
      (window.localStorage &&
        JSON.parse(window.localStorage.getItem("mop-user"))) ||
      null;
    if (intercomSettings && intercomSettings.hasOwnProperty("app_id")) {
      if (user) {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          name: user.name,
          email: user.email,
          role: user.role,
          user_id: user.id,
        });
      } else {
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          name: null,
          email: null,
          role: null,
          user_id: null,
        });
      }
    }
  }, 5000);
};

export const updateWithProps = (props = {}) => {
  const intercomSettings = window.intercomSettings;
  if (intercomSettings && intercomSettings.hasOwnProperty("app_id")) {
    props.api_base = "https://api-iam.intercom.io";
    window.Intercom("update", props);
  }
  return true;
};

export const hideIntercomChatWidget = (props = {}) => {
  const hide = () => {
    const intercomSettings = window.intercomSettings;
    if (intercomSettings && intercomSettings.hasOwnProperty("app_id")) {
      props.api_base = "https://api-iam.intercom.io";
      window.Intercom("update", {
        ...props,
        hide_default_launcher: true,
      });
    }
  };
  setTimeout(() => {
    const user =
      (window.localStorage &&
        JSON.parse(window.localStorage.getItem("mop-user"))) ||
      null;
    if (user || window.location.pathname.includes("virtualcare")) {
      hide();
    }
  }, 1000);
};

export const showIntercomChatWidget = (props = {}) => {
  const intercomSettings = window.intercomSettings;
  if (intercomSettings && intercomSettings.hasOwnProperty("app_id")) {
    props.api_base = "https://api-iam.intercom.io";
    window.Intercom("update", {
      ...props,
      hide_default_launcher: false,
    });
  }
  return true;
};

export const removeUserInfo = (props = {}) => {
  const intercomSettings = window.intercomSettings;
  if (intercomSettings && intercomSettings.hasOwnProperty("app_id")) {
    window.Intercom("update", {
      api_base: "https://api-iam.intercom.io",
      name: "",
      email: "",
      role: "",
      user_id: "",
    });
  }
  return true;
};

export const signupUpdate = (user) => {
  const intercomSettings = window.intercomSettings;
  if (intercomSettings && intercomSettings.hasOwnProperty("app_id") && user) {
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      name: user.name,
      email: user.email,
      role: user.role,
      user_id: user.id,
      created_at: user.createdAt,
    };
    window.Intercom("boot");
  }
};
