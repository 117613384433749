import React from "react";
import { PAGE_VARIABLES } from "../../constants";
import LabelWithTooltip from "./LabelWithTooltip";
import TextInput from "./TextInput";

const SocialMedia = ({ errors, register }) => {
  return (
    <>
      {PAGE_VARIABLES?.COMMON?.SOCIAL_MEDIA.map((Socialmedia) => (
        <div className="col-md-4 mb-3" key={Socialmedia.id}>
          <LabelWithTooltip
            label={Socialmedia.label}
            TooltipText={Socialmedia.Tooltip}
            id={Socialmedia.id}
            labelClassName="form-label"
          />
          <TextInput
            errors={errors}
            register={register}
            name={Socialmedia.id}
          />
        </div>
      ))}
    </>
  );
};

export default SocialMedia;
