import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";

const Slider = ({ question, prompt, options, data }) => {
  const answers = useStoreState((state) => state.answers);
  const setAnswer = useStoreActions((actions) => actions.setAnswer);
  const [colorChange, setColorChange] = useState(null);
  useEffect(() => {
    if (typeof options === "number") {
      setAnswer({
        prompt,
        answer: options,
        type: "slider",
        data,
      });
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="text-center my-md-0 my-5 minheight-15rem">
      <h3 className="fw-semibold">{question}</h3>
      <div className="choose-options">
        <div className="pain-level flex-wrap gap-2 justify-content-center">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option, index) => (
            <div
              key={option}
              className={
                colorChange === index
                  ? `pain level-${option} fs-5  level-selected-color mb-1`
                  : `pain level-${option} fs-5  ${
                      answers[prompt] === option
                        ? "level-selected-color mb-1"
                        : ""
                    }`
              }
              onClick={(e) => {
                setColorChange(index);
                setAnswer({
                  prompt,
                  answer: option,
                  type: "slider",
                  data,
                });
              }}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
