import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AppStateProvider, { useAppState } from "./state";
import Messages from "./components/Messages";

const ChatApp = ({ token }) => {
  const { getVirtualCareToken } = useAppState();
  const [error, setError] = useState(null);
  const { Token } = useParams();

  useEffect(() => {
    if (Token || token) {
      Promise.resolve(getVirtualCareToken(Token || token)).catch((error) => {
        setError(error);
      });
    }
  }, [Token, token]);

  return (
    <React.Fragment>
      <Messages error={error} token={token || null} />
    </React.Fragment>
  );
};

const Chat = ({ token }) => (
  <AppStateProvider>
    <ChatApp token={token} />
  </AppStateProvider>
);

export default Chat;
