import React, { useEffect, useState } from "react";
import axios from "../utils/axiosInst";
import Pageheader from "../Components/Pageheader";
import Result from "./Result";
import LoadingScreen from "../../../components/custom/LoadingScreen";
import { useNavigate } from "react-router-dom";

const Question = () => {
  const navigate = useNavigate();
  const [assesments, setAssesments] = useState([]);
  const [questionNo, setQuestionNo] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [option, setOption] = useState([]);
  const [question, setQuestion] = useState([]);
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const nextQuestionHandler = () => {
    setOption(assesments[questionNo].options);
    setQuestion(assesments[questionNo].question);
    setQuestionNo(questionNo + 1);
  };

  const submitHandler = () => {
    setLoading(true);
    axios.post("/v1/patient/assessment", { answers: answers }).then((res) => {
      setResult(res.data);
      setLoading(false);
    });
  };

  const prevQuestionHandler = () => {
    setOption(assesments[questionNo - 2].options);
    setQuestion(assesments[questionNo - 2].question);
    setQuestionNo(questionNo - 1);
  };

  useEffect(() => {
    axios.get("/v1/assessment/find/all").then((res) => {
      setOption(res.data[0].options);
      setQuestion(res.data[0].question);
      setAssesments(res.data);
    });
  }, []);

  return (
    <Pageheader>
      {result ? (
        <Result result={result && result} />
      ) : (
        <div className="container rounded shadow-lg bg-white p-5 text-center">
          {loading ? (
            <LoadingScreen color="primary" />
          ) : (
            <React.Fragment>
              <h1 className="mt-4 text-center fw-bold">Wellness Assessment</h1>
              <h3 className="fw-semibold mt-4">{question}</h3>
              <div className="choose-options">
                {option?.map((map) => (
                  <div className="options" key={map.value}>
                    <div className="form-group">
                      <button
                        className={`fw-semibold symptom-checker me-5 borderradius-15px h-50px ${
                          answers?.length &&
                          answers[questionNo - 1]?.value === map.value
                            ? "btn btn-primary text-white"
                            : "btn btn-outline-primary text-black"
                        } ${window.innerWidth > 768 ? "w-50" : "w-100"}`}
                        onClick={(e) => {
                          if (answers?.length) {
                            let answer = answers;
                            answer[questionNo - 1] = map;
                            setAnswers([...answer]);
                          } else {
                            setAnswers(
                              answers?.length ? [...answers, map] : [map]
                            );
                          }
                          questionNo !== 15
                            ? nextQuestionHandler()
                            : submitHandler();
                        }}
                      >
                        {map.label}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-between">
                {" "}
                <button
                  className="btn btn-light rounded"
                  onClick={() => {
                    questionNo === 1
                      ? navigate("/pat-assessment")
                      : prevQuestionHandler();
                  }}
                >
                  Back
                </button>
                <button
                  className="btn btn-primary rounded"
                  onClick={() =>
                    questionNo !== 15 ? nextQuestionHandler() : submitHandler()
                  }
                >
                  {questionNo === 15 ? "Submit" : "Next"}
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </Pageheader>
  );
};

export default Question;
