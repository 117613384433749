import React, { useEffect, useState } from "react";
import { IoCallOutline } from "react-icons/io5";
import { CiVideoOn } from "react-icons/ci";
import moment from "moment";
import { ReactComponent as Referrals } from "../../assets/provider-icons/8.svg";
import ConversationMessages from "./ConversationMessages";
import ConversationCard from "./ConversationCard";
import SearchBar from "./SearchBar";
import SendMessage from "./SendMessage";
import axios from "../../../util/axiosInst";
import { socket } from "../../../util/socketInst";
import CreateConversation from "./CreateConversation";
import DefaultUserLogo from "../Components/DefaultUserLogo";
import { useLocation } from "react-router-dom";
import Image from "../../../components/Image";
import OpenVirtualcare from "../../virtualcare/OpenVirtialcare";
import Logo from "../../assets/images/logo_v3.svg";

const Conversationlist = ({ chat, getChatList, type }) => {
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("mop-user"));

  const [currentChat, setCurrentChat] = useState({});
  const [roomId, setRoomId] = useState("");
  const [start, setStart] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [isFiles, setIsFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [filter, setFilter] = useState();
  const [search, setSearch] = useState("");

  const formatDateTime = (inputDate) => {
    const currentDate = moment();
    const inputMoment = moment(inputDate);

    return inputMoment.isSame(currentDate, "day")
      ? inputMoment.format("HH:mm A")
      : inputMoment.format("MM/DD/YYYY");
  };

  const getFiles = (data) => {
    setFiles(
      data?.conversation?.filter((convo) => convo?.message?.type !== "text")
    );
  };

  const getConverserationByRoomId = async (id) => {
    const { data } = await axios.get(`/v1/chat/${id}`);
    setCurrentChat(data);
    getFiles(data);
    if (data?.conversation?.length)
      setRoomId(data?.conversation[0]?.chatRoomId);
  };

  const markAsRead = async (chatRoomId) => {
    await axios.put(`/v1/chat/${chatRoomId}/mark-read`);
    getChatList();
  };

  useEffect(() => {
    if (location?.state) {
      getConverserationByRoomId(location?.state?.roomId);
      setCurrentUser({
        name: location.state.user.userName,
        userId: location.state.user.userId || location.state.user._id,
        roomType: location?.state?.roomType || "single",
      });
      setRoomId(location?.state?.roomId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Subscribe to all chat rooms
    chat?.conversation?.forEach((convo) => {
      if (convo._id) {
        socket.emit("subscribe", convo._id);
      }
    });
    // Set up event handlers for all chat rooms
    const handlers = chat?.patient?.conversation
      ?.map((convo) => {
        if (convo._id) {
          const handler = (data) => {
            if (convo._id === roomId && convo?.message !== "") {
              markAsRead(convo._id);
              getConverserationByRoomId(convo._id);
            }
          };

          socket.on(convo._id, handler);

          return { roomId: convo._id, handler };
        }

        return null;
      })
      .filter(Boolean);
    // Clean up function to unsubscribe and remove event handlers
    return () => {
      chat?.conversation?.forEach((convo) => {
        if (convo._id) {
          socket.emit("unsubscribe", convo._id);
        }
      });

      handlers?.forEach(({ roomId, handler }) => {
        socket.off(roomId, handler);
      });
    };
    // eslint-disable-next-line
  }, [chat, roomId]);

  return (
    <div className="d-flex">
      <div className="p-0 m-0">
        {!start ? (
          <SearchBar
            setStart={setStart}
            totalUnreadMessages={chat?.totalUnreadMessages}
            chat={chat?.patient}
            setFilter={setFilter}
            setSearch={setSearch}
          />
        ) : (
          <CreateConversation
            setStart={setStart}
            getConverserationByRoomId={getConverserationByRoomId}
            setCurrentUser={setCurrentUser}
            setRoomId={setRoomId}
            type={type}
          />
        )}
        <div className="conversationsList h-75 overflow-auto">
          <div className="listContainer">
            <ConversationCard
              chat={search?.length ? { conversation: filter } : chat.patient}
              roomId={roomId}
              getChatList={getChatList}
              setIsFiles={setIsFiles}
              setCurrentUser={setCurrentUser}
              getConverserationByRoomId={getConverserationByRoomId}
              formatDateTime={formatDateTime}
            />
          </div>
        </div>
      </div>
      {(roomId && currentUser && (
        <div
          className="flex-fill ps-0"
          style={{
            borderLeft: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <div className=" bg-white rounded justifyContent-space-between">
            <div className="bg-white pt-2 border-bottom border-2 px-3">
              <div className="d-flex justify-content-between align-item-center">
                {currentUser?.photo ? (
                  <Image
                    src={currentUser?.photo}
                    alt="Chat0"
                    type="provider"
                    className="rounded-circle"
                    width={"55px"}
                    height={"55x"}
                  />
                ) : (
                  <DefaultUserLogo height="50px" width="50px" />
                )}
                <div className="mt-1" style={{ width: "50px", flexGrow: "1" }}>
                  <h5
                    className="mb-0 text-truncate ms-3 my-3 text-primary fw-bold align-self-center"
                    title={currentUser?.name}
                    style={{ maxHeight: "50px", overflow: "hidden" }}
                  >
                    {currentUser?.name}
                  </h5>
                </div>
                <div className="py-3">
                  {roomId &&
                    currentUser?.userId &&
                    currentUser?.roomType === "single" && (
                      <OpenVirtualcare
                        id={roomId}
                        type={"message"}
                        mode={"VIDEO"}
                        participantId={currentUser?.userId}
                        userId={userData?.id}
                        callback={null}
                      >
                        <CiVideoOn className="text-primary width-25px height-25px me-1" />
                        <span
                          className="text-super-light pe-3 cursor-pointer"
                          style={{ color: "#868e96" }}
                        >
                          Video
                        </span>
                      </OpenVirtualcare>
                    )}

                  <span
                    className="cursor-pointer"
                    onClick={() => setIsFiles(!isFiles)}
                  >
                    <Referrals
                      style={{
                        stroke: "#0071FF",
                      }}
                      width="20px"
                      height="20px"
                    />{" "}
                    <span style={{ color: "#868e96" }}>
                      {isFiles ? "Chat" : "Files"}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <ConversationMessages
              formatDateTime={formatDateTime}
              currentChat={isFiles ? { conversation: files } : currentChat}
            />
            <SendMessage
              roomId={
                roomId ||
                (currentChat?.conversation &&
                  currentChat?.conversation[0]?.chatRoomId)
              }
              getChatList={getChatList}
              getChat={getConverserationByRoomId}
            />
          </div>
        </div>
      )) || (
        <div
          className="flex-fill bg-white"
          style={{
            borderLeft: "1px solid lightgray",
            borderRight: "1px solid lightgray",
          }}
        >
          <div className="text-center align-middle h-100 w-100">
            <table className="h-100 w-100">
              <tbody>
                <tr>
                  <td className="align-middle">
                    <img
                      alt="mop-logo"
                      src={Logo}
                      className="mt-2 ms-2"
                      width="200px"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Conversationlist;
