import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { loadProgressBar } from "axios-progress-bar";
import { toast } from "react-toastify";
import dayjs from "../../../util/dayjs";

const timeZone = dayjs.tz.guess();
console.log(timeZone);

const url = process.env.REACT_APP_SERVER_URL;
let mopTokenData;
try {
  mopTokenData = JSON.parse(localStorage.getItem("mop-token"));
} catch (error) {}

const instance = axios.create({
  baseURL: url,
  timeout: 120000,
  headers: {
    Authorization: "Bearer " + mopTokenData?.access?.token,
    TimeZone: timeZone,
  },
});

instance.defaults.headers.post["TimeZone"] = timeZone;

instance.interceptors.response.use(
  (response) => {
    response.headers["Cache-Control"] = "max-age=86400, public";
    return response;
  },
  (error) => {
    if (
      error.response?.data?.message &&
      error.response?.data?.message !== "Please authenticate"
    ) {
      if (error?.response?.status === 404) window.location.href = "/404";
      else
        toast.error(error.response?.data?.message, {
          position: "top-right",
          autoClose: 12000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          theme: "light",
        });
    }
    throw error;
  }
);

const getAccessToken = () => {
  return mopTokenData?.access?.token;
};

instance.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  request.headers["TimeZone"] = timeZone;
  return request;
});

const refreshAuthLogic = async (failedRequest) => {
  try {
    const tokenRefreshResponse = await axios.post(
      `${url}/v1/auth/refresh-tokens`,
      { refreshToken: mopTokenData?.refresh?.token },
      {
        headers: {
          Authorization: "Bearer " + mopTokenData?.access?.token,
          TimeZone: timeZone,
        },
      }
    );

    if (!tokenRefreshResponse?.data?.access?.token) {
      throw new Error("No JWT");
    }

    localStorage.setItem(
      "mop-token",
      JSON.stringify(tokenRefreshResponse.data)
    );

    mopTokenData = tokenRefreshResponse.data;

    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + tokenRefreshResponse.data.access?.token;
  } catch (error) {
    localStorage.removeItem("mop-token");
    // window.location.href = "/";
  }
};

loadProgressBar({}, instance);

createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
