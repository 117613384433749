import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

const PageHeader = ({ children, h1, search }) => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="referral-tabs">
      {search && (
        <div
          className="float-right bg-white px-2 pt-3"
          style={{ paddingTop: "11.5px", paddingBottom: "11px" }}
        >
          <div className="d-flex me-4">
            {searchTerm.length < 1 && (
              <FaSearch
                style={{
                  transform: "translate(93px,5px)",
                  color: "#AFAFAF",
                }}
              />
            )}
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="ms-2 px-3 bg-super-light border-0 text-center borderradius-22px"
            />
          </div>
        </div>
      )}
      <h3
        className="fontweight-bold px-4 py-3 bg-white"
        style={{
          marginBottom: "0",
          borderBottom: "1px solid rgb(235 235 235)",
        }}
      >
        {h1}
      </h3>
      {children}
    </div>
  );
};

export default PageHeader;
