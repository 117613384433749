import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import StateSelect from "../../custom/StateSelect";
import { liceceInitValue } from "../basic-provider-form/init";
import TextInput from "./TextInput";

const Liscence = ({ useFormControl, setLicenceRows, licenceRows }) => {
  const { register, watch, setValue } = useFormControl;
  useEffect(() => {
    setValue("license_info", licenceRows);
  }, []);
  return (
    <section className="container">
      <h5 className="fontweight-bold mt-4">License</h5>

      {licenceRows.map((licenceRow, ind) => (
        <div className="row">
          <div className="col-6">
            <label className={`form-label mt-2`}>Country</label>
            <Form.Select name="degree">
              <option value="US">US</option>
            </Form.Select>
          </div>
          <div className="col-6">
            <label className={`form-label mt-2`}>State/Province</label>
            <StateSelect
              value={watch(`license_info[${ind}].state`)}
              setValue={(value) =>
                setValue(`license_info[${ind}].state`, value)
              }
            />
          </div>
          <div className="col-6">
            <TextInput
              register={register}
              type="text"
              className="mt-2"
              label="License Number"
              name={`license_info[${ind}].licenseNumber`}
            />
          </div>
          <div className="col-6">
            <label className={`form-label mt-2`}>Expiration date</label>
            <Datetime
              {...register(`license_info[${ind}].expirationDate`)}
              inputProps={{
                placeholder: "mm/dd/yyyy",
              }}
              dateFormat="MM-DD-YYYY"
              closeOnSelect
              timeFormat={false}
              name={`license_info[${ind}].expirationDate`}
              id="dob"
            />
          </div>
          {/* <div className="col-12">
          <TextInput
            register={register}
            type="text"
            className="mt-2"
            label="Personal NPI number"
            name={"license_info[0].npi"}
          />
        </div> */}
          <div className="row">
            {licenceRows.length - 1 === ind && (
              <div
                className="col-md-2 text-primary cursor-pointer"
                onClick={() => {
                  const rows = [...watch("license_info"), liceceInitValue];
                  setLicenceRows(rows);
                  setValue("license_info", rows);
                }}
              >
                Add new
              </div>
            )}
            <div
              className="col-md-1 text-danger cursor-pointer"
              onClick={() => {
                let rows = [...watch("license_info")];
                if (rows.length > 1) {
                  rows.splice(ind, 1);
                  setLicenceRows(rows);
                  setValue("license_info", rows);
                } else {
                  setLicenceRows([liceceInitValue]);
                  setValue("license_info", [liceceInitValue]);
                }
              }}
            >
              Remove
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Liscence;
