import React, { useState } from "react";
import Layout from "./layout/Layout";
import Footer from "./Home/Footer";
import banner2 from "../../../images/Homepage/whowewith.webp";
import { FaCheckCircle } from "react-icons/fa";

const WhoWeWorkWith = () => {
  const [tab, setTab] = useState("employers");
  return (
    <Layout>
      <section
        className={`container px-5 ${window.innerWidth > 769 ? "" : ""}`}
      >
        <h1 className="landing-display-1 text-center mt-3rem mb-4 mt-4">
          Who We Work With
        </h1>
        <center>
          <ul
            className="mt-5 w-75 nav"
            style={{
              borderRadius: "30px",
              background: "#EBF5FF",
            }}
          >
            <li className="w-25">
              <button
                className={`py-3 w-100 fw-700 word-break ${
                  tab === "employers" ? "current-list-tab" : "list-tab"
                }`}
                style={{ color: "#0071FF" }}
                onClick={() => setTab("employers")}
              >
                <small> Employers</small>
              </button>
            </li>
            <li className="w-25">
              <button
                className={`py-3 w-100 fw-700 word-break ${
                  tab === "insurers" ? "current-list-tab" : "list-tab"
                }`}
                style={{ color: "#0071FF" }}
                onClick={() => setTab("insurers")}
              >
                <small> Insurers</small>
              </button>
            </li>
            <li className="w-25">
              <button
                className={`py-3 w-100 fw-700 word-break ${
                  tab === "selfinsured" ? "current-list-tab" : "list-tab"
                }`}
                style={{ color: "#0071FF" }}
                onClick={() => setTab("selfinsured")}
              >
                <small>Self-insured</small>
              </button>
            </li>
            <li className="w-25">
              <button
                className={`py-3 w-100 fw-700 word-break ${
                  tab === "healthsystems" ? "current-list-tab" : "list-tab"
                }`}
                style={{ color: "#0071FF" }}
                onClick={() => setTab("healthsystems")}
              >
                <small> Health systems</small>
              </button>
            </li>
          </ul>
        </center>
        {tab === "employers" && (
          <div className="row pt-5 mt-2 mx-3 px-5 container">
            <div className="col-lg-6">
              <span className=" fs--25 ps-3 fw-700">Employer Benefits</span>
              <p className="ps-3 pt-3">
                Our platform helps employers significantly reduce healthcare
                costs while providing specialized high quality MSK care services
                for their employees.
              </p>
              <div className="ms-3 pt-3 fs-14px">
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">Multi-Payer Support:</span>{" "}
                    Universal access for all employee insurance plans
                  </p>
                </div>
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">Cost Control: </span>
                    Predictable and reduced orthopedic care expenses
                  </p>
                </div>
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">Specialized MSK Care: </span>
                    Comprehensive, expert treatment for musculoskeletal health
                  </p>
                </div>
                <p className="mt-4">
                  Contact us at{" "}
                  <span className="text-primary cursor-pointer">
                    support@myorthopedicproblem.com
                  </span>
                </p>
              </div>
            </div>
            <div
              className="col-sm-5 col-lg-6 mt-5 ps-5"
              // style={{ alignSelf: "center" }}
            >
              <img
                src={banner2}
                className="rounded-circle"
                style={{
                  width: "21rem",
                  height: "21rem",
                  objectFit: "cover",
                  objectPosition: "left",
                }}
                alt="Iphone banner"
              />
            </div>
          </div>
        )}
        {tab === "insurers" && (
          <div className="row pt-5 mt-2 mx-3 px-5 container">
            <div className="col-lg-6">
              <span className=" fs--25 ps-3 fw-700">Insurer Benefits</span>
              <p className="ps-3 pt-3">
                Our platform connects patients with specialized orthopedic
                resources and providers, ensuring efficient and effective
                treatment that aligns with insurer objectives for quality care
                and cost management.
              </p>
              <div className="ms-3 pt-3 fs-14px">
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">Cost-Effective Care: </span>
                    Minimize unnecessary treatments and expenditures through
                    targeted, effective orthopedic solutions
                  </p>
                </div>
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">Enhanced Patient Outcomes:</span>{" "}
                    Leverage our network for higher quality care and improved
                    patient recovery rates
                  </p>
                </div>
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">Streamlined Process: </span>
                    Facilitate smoother, faster patient-provider connections,
                    reducing administrative burdens
                  </p>
                </div>
                <p className="mt-4">
                  Contact us at{" "}
                  <span className="text-primary cursor-pointer">
                    support@myorthopedicproblem.com
                  </span>
                </p>
              </div>
            </div>
            <div
              className="col-sm-5 col-lg-6 mt-5 ps-5"
              // style={{ alignSelf: "center" }}
            >
              <img
                src={banner2}
                className="rounded-circle"
                style={{
                  width: "21rem",
                  height: "21rem",
                  objectFit: "cover",
                  objectPosition: "left",
                }}
                alt="Iphone banner"
              />
            </div>
          </div>
        )}
        {tab === "selfinsured" && (
          <div className="row pt-5 mt-2 mx-3 px-5 container">
            <div className="col-lg-6">
              <span className=" fs--25 ps-3 fw-700">Self-insured Benefits</span>
              <p className="ps-3 pt-3">
                MyOrthopedicProblem.com provides streamlined orthopedic care for
                self-insured employers, ensuring healthier employees and reduced
                healthcare costs.
              </p>
              <div className="ms-3 pt-3 fs-14px">
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">Rapid Orthopedic Diagnosis:</span>{" "}
                    Quick, accurate symptom assessment for immediate care
                  </p>
                </div>
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">Educational Resources: </span>
                    Empower employees with knowledge about their orthopedic
                    health
                  </p>
                </div>
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">
                      Seamless Provider Connection:{" "}
                    </span>
                    Easy access to specialized orthopedic care, enhancing
                    employee well-being
                  </p>
                </div>
                <p className="mt-4">
                  Contact us at{" "}
                  <span className="text-primary cursor-pointer">
                    support@myorthopedicproblem.com
                  </span>
                </p>
              </div>
            </div>
            <div
              className="col-sm-5 col-lg-6 mt-5 ps-5"
              // style={{ alignSelf: "center" }}
            >
              <img
                src={banner2}
                className="rounded-circle"
                style={{
                  width: "21rem",
                  height: "21rem",
                  objectFit: "cover",
                  objectPosition: "left",
                }}
                alt="Iphone banner"
              />
            </div>
          </div>
        )}
        {tab === "healthsystems" && (
          <div className="row pt-5 mt-2 mx-3 px-5 container">
            <div className="col-lg-6">
              <span className=" fs--25 ps-3 fw-700">
                Health System Benefits
              </span>
              <p className="ps-3 pt-3">
                Our platform facilitates rapid patient assessments, connects
                with specialized providers, and integrates seamlessly into
                existing healthcare frameworks, fostering improved patient
                outcomes and operational excellence.
              </p>
              <div className="ms-3 pt-3 fs-14px">
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">
                      Streamlined Patient Referrals:
                    </span>{" "}
                    Easy integration with health systems for quick and accurate
                    patient routing to orthopedic specialists
                  </p>
                </div>
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">
                      Enhanced Treatment Outcomes:{" "}
                    </span>
                    Collaborative approach ensuring high-quality, specialized
                    care for better patient recovery.
                  </p>
                </div>
                <div className="d-flex">
                  <FaCheckCircle className="text-success mt-2" />
                  <p className="w-75 px-3">
                    <span className="fw-700">Operational Synergy: </span>
                    Our platform complements existing health system processes,
                    improving overall efficiency and patient satisfaction.
                  </p>
                </div>
                <p className="mt-4">
                  Contact us at{" "}
                  <span className="text-primary cursor-pointer">
                    support@myorthopedicproblem.com
                  </span>
                </p>
              </div>
            </div>
            <div
              className="col-sm-5 col-lg-6 mt-5 ps-5"
              // style={{ alignSelf: "center" }}
            >
              <img
                src={banner2}
                className="rounded-circle"
                style={{
                  width: "21rem",
                  height: "21rem",
                  objectFit: "cover",
                  objectPosition: "left",
                }}
                alt="Iphone banner"
              />
            </div>
          </div>
        )}
      </section>
      <br />
      <Footer />
    </Layout>
  );
};

export default WhoWeWorkWith;
