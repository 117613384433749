import { Box } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { MdOutlineStarBorder } from "react-icons/md";
import React from "react";
import { FaThumbsUp } from "react-icons/fa";
import Layout from "../landing-page/components/layout/Layout";

const StarRating = () => {
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);

  const labels = {
    0.5: "Useless",
    1: "Useless+",
    1.5: "Poor",
    2: "Poor+",
    2.5: "Ok",
    3: "Ok+",
    3.5: "Good",
    4: "Good+",
    4.5: "Excellent",
    5: "Excellent+",
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  return (
    <Layout>
      <div className="container text-center wrapper">
        <h1 className="mb-5 fontweight-bold">
          How was the quality of your appointment?
        </h1>
        <FaThumbsUp
          className="text-primary mb-3"
          style={{ fontSize: "100px" }}
        />

        <Box
          sx={{
            width: 200,
            display: "flex",
            alignItems: "center",
          }}
          className="mx-auto  mt-3"
        >
          <Rating
            name="hover-feedback"
            value={value}
            precision={0.5}
            className="mx-auto"
            getLabelText={getLabelText}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            emptyIcon={
              <MdOutlineStarBorder
                style={{ opacity: 0.55 }}
                fontSize="inherit"
              />
            }
          />
          {value !== null && (
            <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
          )}
        </Box>
      </div>
    </Layout>
  );
};

export default StarRating;
