import React, { createRef, useEffect, useState } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "../../../../util/axiosInst";
import VideoModal from "../../../../components/VideoModal";
import Recommendations from "../../../../components/education/Recommendations";
import { isEmpty } from "lodash";
import Layout from "../layout/Layout";
import Blogspage from "../../../../components/Blogspage";
import HelmetTag from "../../../../components/helmet/Helmet";

const BlogArticle = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const ref = createRef();

  const [article, setArticle] = useState({ loading: true });
  const [show, setShow] = useState(false);
  const [video] = useState({ name: "", link: "" });
  const [data, setData] = useState(null);

  const getArticle = async (id) => {
    const { data } = await axios.get("/v1/public/blog/" + id);
    if (isEmpty(data)) navigate("/404");
    else setArticle(data);
    let modifiedContent = data.content.replaceAll("<a href", "<iframe src");
    modifiedContent = modifiedContent.replaceAll("</a>", "</iframe>");
    setData(modifiedContent);
  };

  const removeTagsFromString = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent.slice(0, 150);
  };

  useEffect(() => {
    window.fbq("track", "Blog Page is Opened.", {
      title: params.id,
    });
    document.getElementById("root").scrollTo(0, 0);
    getArticle(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Layout>
      <div className="bg-muted mb-5">
        <HelmetTag
          title={article.title}
          content={removeTagsFromString(data)}
          canonical={`https://myorthopedicproblem.com/provider-posts/${params.id}`}
          JSONLD={`{
          "@context": "http://schema.org",
          "@type": "BlogPosting",
          "name": "${article.title}",
          "url": "https://myorthopedicproblem.com/provider-posts/${params.id}",
          "author": {
            "@type": "Person",
            "name": "${article.createdBy?.name}"
          }
        }
        `}
        />
        {article?.loading ? (
          <></>
        ) : (
          <div className="bg-white rounded p-3">
            <div className={`container ${window.innerWidth > 991 ? "" : ""}`}>
              <nav className="text-muted" ref={ref} aria-label="breadcrumb">
                {location?.state?.from ? (
                  <ol className="breadcrumb">
                    <li
                      className="breadcrumb-item cursor-pointer text-primary"
                      onClick={() => navigate(-1)}
                    >
                      Profile
                    </li>

                    <li className="breadcrumb-item active" aria-current="page">
                      {article.title}
                    </li>
                  </ol>
                ) : (
                  <ol className="breadcrumb ps-1">
                    <li className="breadcrumb-item ">
                      <Link to="/" className="text-decoration-none">
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link
                        to="/education-library"
                        className="text-decoration-none"
                      >
                        Education Library
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-truncate"
                      aria-current="page"
                      title={article.title}
                    >
                      {article.title}
                    </li>
                  </ol>
                )}
              </nav>
              {show === true && (
                <VideoModal show={show} setShow={setShow} video={video} />
              )}
              <h1 className="fw-semibold text-break">{article.title}</h1>
              <p className=" text-muted">
                <span className="fw-semibold"> Published on</span>:&nbsp;
                {moment(article.createdAt).format("MMM Do YYYY, h:mm a")} |
                Author:&nbsp;
                <Link
                  to={`/providers/${
                    article?.createdBy?.slug || article?.providerId
                  }`}
                  state={{
                    userId: article?.createdBy?.id,
                    id: article?.providerId,
                  }}
                  className="text-decoration-underline fw-semibold"
                >
                  {(article?.createdBy &&
                    ([
                      "rehabilitation_center",
                      "gym",
                      "hospital",
                      "clinic",
                    ].includes(article.createdBy?.userType)
                      ? article.createdBy?.firstName
                      : article.createdBy?.name)) ||
                    ""}
                </Link>
              </p>
              <div className="row">
                <div className="col-md-8">
                  <Blogspage />
                </div>
                <div className="col-md-4">
                  <Recommendations />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
export default BlogArticle;
