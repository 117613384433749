import axios from "../../util/axiosInst";
import React, { useEffect } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getDateFormat } from "../../util/utils";

export default function Comment({
  message,
  name,
  blogId,
  createdAt,
  provider,
  comment,
  getComments,
  author,
}) {
  let userData = JSON.parse(localStorage.getItem("mop-user"));

  const deleteComment = async (data) => {
    const result = await axios.put(`/v1/public/blog/${blogId}/comments`, {
      comments: data,
    });
    const MySwal = withReactContent(Swal);
    if (result.status === 200) {
      getComments();
      MySwal.fire({
        text: result.data.message,
        icon: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="row mb-3">
      <div className="col-12">
        <div className="row">
          <div className="col-sm-11 col-9">
            <div className="d-flex justify-content-between mb-2">
              <div className="me-3">
                <h6 className="text-capitalize">{name}</h6>
                <p className="text-muted mb-0 small">
                  {getDateFormat(createdAt)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-11 offset-sm-1 d-flex">
          <p className="overflow-unset">{message}</p>
          {userData?.name.includes(author) && (
            <p
              className="text-primary text-decoration-underline cursor-pointer mx-2"
              onClick={() => deleteComment(comment)}
            >
              delete
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
