import React from "react";

const AddNew = ({ initValue, value, setValue }) => {
  const OnClickHandler = () => {
    const rows = [...value, initValue];
    setValue(rows);
  };
  return (
    <div
      className="col-md-4 text-primary cursor-pointer"
      onClick={OnClickHandler}
    >
      Add new
    </div>
  );
};

export default AddNew;
