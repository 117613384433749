import React from "react";
import Accordation from "./Home/components/Accordation";
import { Button } from "react-bootstrap";
import Layout from "./layout/Layout";
import { useNavigate } from "react-router-dom";

const FAQ = () => {
  const navigate = useNavigate();
  const individualsFAQ = [
    {
      Question:
        " What are the requirements to join the telehealth provider network?",
      Answer: ` Providers must be licensed medical professionals, possess valid
      certifications in their specialty, and adhere to all applicable
      legal and ethical standards for telehealth services. Experience in
      telehealth is preferred but not mandatory.`,
    },
    {
      Question: " How does the telehealth platform work for providers?",
      Answer: `  Our platform offers an intuitive interface for video
      consultations, scheduling, patient management, and electronic
      health records.`,
    },
    {
      Question:
        " What types of services can I offer through the telehealth network?",
      Answer: ` Answer: Providers can offer a wide range of services, including
      general consultations, follow-up appointments, and referrals to
      another provider in our network.`,
    },
    {
      Question: "How is patient privacy and data security handled?",
      Answer: ` We adhere to HIPAA guidelines and use encrypted communication
      channels to ensure patient data confidentiality and security.
      Regular audits and updates keep our systems secure.`,
    },
    {
      Question:
        "Are there any costs or fees associated with joining the network?",
      Answer: `To become a member of our provider network there is a $25 monthly
      subscription to cover operational costs, but there are no surprise
      fees.`,
    },

    {
      Question:
        "How is billing and reimbursement managed for telehealth services?",
      Answer: ` Patients are billed directly through our platform, so you don’t
      have to deal with the hassle of billing for your consultations.
      Providers are reimbursed through a calculated revenue share model
      for each consultation given on biweekly basis.`,
    },
    {
      Question:
        " Can I set my own schedule and availability for consultations?",
      Answer: ` Yes, our platform allows providers to set their availability and
      manage their schedules flexibly to balance work and personal
      commitments. All patient consultations are scheduled to providers
      on a first come first serve basis.`,
    },
    {
      Question:
        " Can I integrate my existing patient records into the telehealth system?",
      Answer: `Answer: Yes, our platform supports integration with various
      electronic health record systems, allowing for seamless transfer
      and access to patient records.`,
    },
    {
      Question:
        "What kind of support and resources are available for providers?",
      Answer: `We offer 24/7 technical support, ongoing training, and access to a
      library of professional resources and best practices in
      telehealth.`,
    },
    {
      Question:
        "How is the quality of care monitored and maintained in the network?",
      Answer: `We conduct regular quality assessments, peer reviews, and patient
      feedback surveys to ensure high standards of care. Providers are
      also expected to adhere to our quality guidelines.`,
    },
  ];
  return (
    <Layout>
      <section className="sec_six bg-white py-0 mx-5">
        <h1 className="fontweight-bold text-center display-4 mt-5 mb-5">
          Frequently asked questions
        </h1>
        <div className="d-md-flex justify-content-between align-items-center container"></div>
        <Accordation FAQ={individualsFAQ} />
        <center>
          <Button
            type="button"
            className="jump-button bg-primary text-white rounded-pill fw-semibold text-center my-5"
            onClick={() => navigate("/signup")}
          >
            Sign Up
          </Button>
        </center>
      </section>
    </Layout>
  );
};

export default FAQ;
