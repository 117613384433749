import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";

const LabelWithTooltip = ({ label, id, TooltipText, labelClassName }) => {
  return (
    <label htmlFor={id} className={labelClassName}>
      {label}
      <span data-tip data-for={id}>
        <FaInfoCircle className="padding-left-5px text-primary fontsize-20px" />
      </span>
      <ReactTooltip id={id} place="right" effect="solid">
        {TooltipText}
      </ReactTooltip>
    </label>
  );
};

export default LabelWithTooltip;
