import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import axios from "../../utils/axiosInst";
import VideoPlayer from "../../../../components/VideoPlayer";
import mixpanel from "mixpanel-browser";
import { useStoreActions } from "easy-peasy";
import { getUserData } from "../../../../util/cookieUtil";
import Uptodate from "./Uptodate";
import { FaAngleLeft, FaAngleRight, FaRegCalendarAlt } from "react-icons/fa";
import BreadCumbs from "../../../../components/common/BreadCumbs";
import Layout from "../layout/Layout";
import EducationArticleSummary from "./EducationSummary";
import Image from "../custom/Image";

const EducationArticle = () => {
  const tabsBoxRef = useRef(null);
  let params = useParams();
  const [isDragging, setIsDragging] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [searchParams] = useSearchParams();
  const showBar = window.innerWidth > 749 ? true : false;

  const similarArticlesQuery = searchParams.get("evaluation_result");

  const postPatientData = useStoreActions(
    (state) => state.dashboard.postPatientData
  );

  const [modalData] = useState(
    JSON.parse(
      localStorage.getItem("mop-user")
        ? localStorage.getItem("mop-user")
        : localStorage.getItem("education-page")
    )
  );
  const [similarArticles, setSimilarArticles] = useState([]);
  const [article, setArticle] = useState({});
  const [loading, setLoading] = useState(true);
  const [uptodate, setUptodate] = useState([]);

  const [userChoice, setUserChoice] = useState("");
  const getUptodateArticles = async (article) => {
    let articleID =
      article?.length > 15
        ? article?.splice(0, 15)
        : article?.splice(0, article.length);
    if (articleID) {
      const { data } = await axios.get(
        `/v1/uptodate/article/contents?articleIds= ${articleID.toString()} `
      );
      setUptodate([...uptodate, data]);
    }
    setTimeout(() => {
      if (article?.length) getUptodateArticles(article);
    }, 1000);
  };
  const [articleOption, setArticleOption] = useState([]);
  const handleIconClick = (direction) => {
    const tabsBox = tabsBoxRef.current;
    const scrollWidth = direction === "left" ? -150 : 150;
    tabsBox.scrollLeft += scrollWidth;
  };
  const handleTabClick = (tab) => setUserChoice(tab.title);
  const getArticle = async (id) => {
    const { data } = await axios.get("/v1/content/" + id);
    const tempArray = [];
    data?.sections?.forEach((section) => {
      if (section.active !== false) {
        section.content.filter((item) => item.data !== "").length !== 0 &&
          tempArray.push({
            title: section.title,
            content: section.content,
          });
      }
    });
    setLoading(false);
    setUserChoice(tempArray[0].title);
    setArticleOption(tempArray);
    setArticle(data);
  };

  const handleDragStart = () => {
    setIsDragging(true);
    tabsBoxRef.current.classList.add("dragging");
  };

  const handleDragging = (e) => {
    if (!isDragging) return;
    const tabsBox = tabsBoxRef.current;
    tabsBox.scrollLeft -= e.movementX;
  };

  const handleDragStop = () => {
    setIsDragging(false);
    tabsBoxRef.current.classList.remove("dragging");
  };
  const handleIcons = (scrollVal) => {
    const tabsBox = tabsBoxRef.current;
    const maxScrollableWidth = tabsBox.scrollWidth - tabsBox.clientWidth;
    setShowLeftArrow(scrollVal > 0);
    setShowRightArrow(maxScrollableWidth - scrollVal > 1);
  };

  useEffect(() => {
    if (window.innerWidth < 749) {
      let tabsBox = tabsBoxRef.current;
      const handleScroll = () => {
        handleIcons(tabsBox.scrollLeft);
      };

      handleIcons(tabsBox.scrollLeft);
      tabsBox.addEventListener("scroll", handleScroll);

      return () => tabsBox.removeEventListener("scroll", handleScroll);
    }
  }, [articleOption]);

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    document.getElementById("content-card").scrollTo(0, 0);
    getArticle(params.id);
  }, [params]);

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    document.getElementById("content-card").scrollTo(0, 0);
    if (article?.title) {
      try {
        getUptodateArticles(article?.articleIds);
        window.fbq("track", "MOP Education Page is Opened", {
          title: article.title,
        });
        mixpanel.track("User", {
          type: "Page View",
          name: `${article?.title} Page`,
        });

        postPatientData({
          type: "contentViews",
          patientId: getUserData()?.id,
          email: modalData?.email,
        });
      } catch (error) {}
    }
    // eslint-disable-next-line
  }, [article, modalData, postPatientData]);

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    document.getElementById("content-card").scrollTo(0, 0);
    if (similarArticlesQuery) {
      if (similarArticlesQuery.includes(",")) {
        const similarArticlesIds = similarArticlesQuery.split(",");
        setSimilarArticles(similarArticlesIds);
      } else {
        setSimilarArticles([similarArticlesQuery]);
      }
    } else {
      setSimilarArticles([]);
    }
  }, [similarArticlesQuery]);

  const renderContent = (section) => {
    if (section.active !== false) {
      return (
        section.content.filter((item) => item.data !== "").length !== 0 && (
          <div id={section.title} className="bg-light-50 rounded mb-4">
            <div className="bg-teal py-1 px-3 rounded-bottom-0 rounded-top education-shadow">
              <h1 className="mb-0 text-primary">{section.title}</h1>
            </div>
            <div className="p-3">
              {section.content.map((cont, index) => (
                <div
                  key={index}
                  className={`${
                    window.location.search.includes("evaluation_result=")
                      ? "iframeVideoType"
                      : "iframeVideo"
                  } ${
                    cont.type !== "ypo" && cont.type !== "viewmedica"
                      ? "mb-4"
                      : ""
                  } `}
                >
                  {cont.type === "image" && (
                    <Image
                      className="img-fluid"
                      src={cont.data}
                      alt={"Blog" + index}
                    />
                  )}
                  {(cont.type === "text" ||
                    cont.type === "ypo" ||
                    cont.type === "viewmedica") && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: cont.data,
                      }}
                      onClick={() => {
                        postPatientData({
                          type: "videoViews",
                          patientId: getUserData()?.id,
                        });
                      }}
                    />
                  )}
                  {cont.type === "uptodate" && uptodate && uptodate.length && (
                    <Uptodate
                      articleId={cont.articleId}
                      sectionId={cont.sectionId}
                      articleContents={uptodate}
                      data={cont.data}
                    />
                  )}
                  {cont.type === "video" && (
                    <div
                      onClick={() =>
                        postPatientData({
                          type: "videoViews",
                          patientId: getUserData()?.id,
                        })
                      }
                    >
                      <VideoPlayer url={cont.data} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )
      );
    }
  };
  return (
    <Layout>
      <div className="bg-muted">
        <div className="bg-white rounded p-3">
          <div className="container ps-2">
            <div
              className={`bg-white ps-0 eduarticle-header ${
                window.innerWidth > 991 ? "" : ""
              }`}
              id="fixed-card"
            >
              {articleOption.length > 0 && (
                <BreadCumbs
                  BreadCumbs={[
                    {
                      title: "Education Library",
                      to: "education-library",
                      active: false,
                    },
                    { title: article?.title, active: true },
                  ]}
                />
              )}
              <>
                <h1 className="text-capitalize">{article.title}</h1>
                {article.title?.length ? (
                  <Link to="/signup" className="text-decoration-none h5">
                    <h5>
                      <FaRegCalendarAlt className="mb-2 me-2" />
                      Find a Provider
                    </h5>
                  </Link>
                ) : (
                  <></>
                )}
                <div className="mb-md-3 mb-1 row gap-10">
                  {showBar ? (
                    <div className="d-flex flex-wrap">
                      {articleOption.length > 0 &&
                        articleOption?.map((section, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              window.location.state = {
                                evaluation: window.location.search.includes(
                                  "evaluation_result="
                                )
                                  ? true
                                  : false,
                              };
                              document
                                .getElementById("content-card")
                                .scrollTo(0, 100);
                              setTimeout(() => {
                                document.getElementById(
                                  "content-card"
                                ).style.marginTop = "1rem ";
                                document
                                  .getElementById("main")
                                  .scrollTo(0, 100);
                              }, 200);
                              setUserChoice(section.title);
                            }}
                            className={`text-decoration-none cursor-pointer p-0 mx-2 col-3 mb-2  ms-0 w-fit ${
                              userChoice === section.title
                                ? "text-primary-50"
                                : "text-black"
                            } `}
                          >
                            <div
                              className={`rounded  p-2 text-center  ${
                                userChoice === section.title
                                  ? "bg-primary-50"
                                  : "border border-light"
                              }`}
                            >
                              {section.title}
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="wrappers py-2">
                      <div
                        className={`icon ${
                          showLeftArrow ? "d-flex" : "d-none"
                        }`}
                        onClick={() => handleIconClick("left")}
                      >
                        <FaAngleLeft />
                      </div>
                      <ul
                        className="tabs-box p-0 m-0"
                        ref={tabsBoxRef}
                        onMouseDown={handleDragStart}
                        onMouseMove={handleDragging}
                        onMouseUp={handleDragStop}
                      >
                        {articleOption?.map((tab, index) => (
                          <li
                            className={`eduarticle-option rounded-pill text-nowrap text-center cursor-pointer py-2 px-3 d-flex align-items-center ${
                              userChoice === tab.title
                                ? "text-primary-50 bg-primary-50"
                                : "border border-light"
                            } `}
                            key={index}
                            onClick={() => handleTabClick(tab)}
                          >
                            {tab?.title.split(" ").map((tabSplit, i) => (
                              <>
                                {tab?.title?.split(" ")?.length >= 2 &&
                                tab?.title?.split(" ")?.length === i + 1
                                  ? tabSplit
                                  : `${tabSplit} `}
                              </>
                            ))}
                          </li>
                        ))}
                      </ul>
                      <div
                        className={`icon ${
                          showRightArrow ? "d-flex" : "d-none"
                        }`}
                        onClick={() => handleIconClick("right")}
                      >
                        <FaAngleRight />
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
            <div className="container">
              <div className="row bg-white p-3 pt-0 px-0 rounded mb-4">
                <div
                  id="content-card"
                  className={
                    window.location.search.includes("evaluation_result=")
                      ? "col-sm-7  px-0"
                      : "col-lg-12 px-0"
                  }
                >
                  {loading ? (
                    <div className="text-center p-5 rounded mb-4">
                      <h5>Data is loading...</h5>
                    </div>
                  ) : article?.sections?.length > 0 ? (
                    <div
                      className={
                        window.location.search.includes("evaluation_result=")
                          ? "col-sm-12 mt-3"
                          : "col-lg-12 mt-3"
                      }
                    >
                      {article.sections?.map((section, index) =>
                        userChoice !== ""
                          ? section.title === userChoice
                            ? renderContent(section)
                            : ""
                          : renderContent(section)
                      )}
                    </div>
                  ) : (
                    <div className="text-center p-5 rounded mb-4">
                      <h5>No Data Found</h5>
                    </div>
                  )}
                </div>
                {/* Result Summary */}
                <div
                  className={` col-sm-5 ${
                    window.innerWidth > 768
                      ? window.innerWidth < 1000
                        ? "margintop-1rem"
                        : "margintop-1rem"
                      : showBar
                      ? window.location.search.includes("evaluation_result=")
                        ? "margintop-1rem"
                        : "margintop-26rem"
                      : window.location.search.includes("evaluation_result=")
                      ? "margintop-1rem"
                      : "margintop-21rem"
                  }
                    `}
                >
                  <div className="sticky-top top-26">
                    <EducationArticleSummary
                      data={similarArticles}
                      modalData={modalData}
                      setUserChoice={setUserChoice}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EducationArticle;
