import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import {
  affiliatedGymsInitValue,
  certificationInitValue,
  classesInitValue,
  hoursOfOperation,
  leadershipInitValue,
  modalitiesInitValue,
  programInitValue,
  trainersAvailableInitValue,
} from "./init";
import Time from "../custom/Time";
import Remove from "../custom/Remove";
import AddNew from "../custom/AddNew";
import axios from "../../../utils/axiosInst";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Certifications from "../custom/Certifications";
import LabelWithTooltip from "../custom/LabelWithTooltip";
import SocialMedia from "../custom/SocialMedia";
import { PAGE_VARIABLES } from "../../constants";
import Biography from "../custom/Biography";
import Speciality from "../custom/Speciality";
import TextInput from "../custom/TextInput";

const GymProfile = ({ useFormControl, signupData }) => {
  const MySwal = withReactContent(Swal);

  const [programRows, setProgramRows] = useState([programInitValue]);
  const [modalitiesRows, setModalitiesRows] = useState([modalitiesInitValue]);
  const [leadershipRows, setleadershipRows] = useState([leadershipInitValue]);
  const [affiliatedGymsRows, setAffiliatedGyms] = useState([
    affiliatedGymsInitValue,
  ]);
  const [trainersAvailableRows, setTrainersAvailableRows] = useState([
    trainersAvailableInitValue,
  ]);
  const [classesRows, setClassesRows] = useState([classesInitValue]);
  const [hoursOfOperationRows, setHoursOfOperationRows] = useState([
    hoursOfOperation,
  ]);
  const [certificationRows, setCertificationRows] = useState([
    certificationInitValue,
  ]);
  const [error, setError] = useState({});
  const [select, setSelect] = useState("Yes");

  const {
    register,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormControl;

  const handleValidation = (ind, event) => {
    let errorArray = { index: null, error: "" };
    leadershipRows.forEach((item) => {
      if (item.teamEmail === event) {
        errorArray = { index: ind, error: "email is already taken" };
      }
    });
    setError({ ...errorArray });
  };

  const getEndTime = (ind, index) => {
    let tempArray = [...PAGE_VARIABLES?.COMMON?.TIME_VALUE];
    if (watchStartTime) {
      const lastIndex = tempArray.findIndex(
        (time) => time === watchStartTime[ind]?.businessHours[index]?.startTime
      );
      let itemToBeRemoved = tempArray.splice(0, lastIndex + 1);
      if (
        itemToBeRemoved.includes(
          watchStartTime[ind]?.businessHours[index]?.endTime
        )
      ) {
        setValue(`clinic[${ind}].businessHours[${index}].endTime`, "");
      }
      return [...itemToBeRemoved];
    } else {
      return null;
    }
  };

  const getDayDetails = (id, ind, name, index) => {
    if (!classesRows[index].timings[ind].days.includes(id)) {
      let row = getValues("classes_info");
      row[index].timings[ind].days = row[index]?.timings[ind]?.days
        ? [...row[index]?.timings[ind]?.days, id]
        : [id];

      setClassesRows([...row]);
      setValue(name, row[index].timings[ind].days);
    } else {
      const filter = classesRows[index].timings[ind].days.filter(
        (data) => data !== id
      );
      let row = getValues("classes_info");
      row[index].timings[ind].days = filter;

      setClassesRows([...row]);
      setValue(name, filter);
    }
  };

  const claimYourProfile = async () => {
    if (JSON.parse(localStorage.getItem("claim-status") === "true")) {
      MySwal.fire({
        title: PAGE_VARIABLES?.COMMON?.ALERT_MESSAGE,
        icon: "info",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      let data = {
        loggedIn: true,
      };

      if (JSON.parse(localStorage.getItem("claim-id"))) {
        await axios.patch(
          `/v1/claimProfile/${JSON.parse(localStorage.getItem("claim-id"))}`,
          data
        );
      }
    }
  };

  const getDayDetailsForHours = (id, name, index) => {
    if (!hoursOfOperationRows[index].days.includes(id)) {
      let row = hoursOfOperationRows;
      row[index].days = row[index]?.days ? [...row[index]?.days, id] : [id];
      setHoursOfOperationRows([...row]);
      setValue(name, row);
    } else {
      const filter = hoursOfOperationRows[index].days.filter(
        (data) => data !== id
      );
      let row = getValues("hours_of_operation");
      row[index].days = filter;

      setHoursOfOperationRows([...row]);
      setValue(name, row);
    }
  };

  const watchStartTime = useWatch({
    control,
    name: "classes_info",
  });

  const watchStartTimeForHours = useWatch({
    control,
    name: "hours_of_operation",
  });

  const getEndTimeForHours = (ind) => {
    let tempArray = [...PAGE_VARIABLES?.COMMON?.TIME_VALUE];
    if (watchStartTimeForHours) {
      const lastIndex = tempArray.findIndex(
        (time) => time === watchStartTimeForHours[ind]?.start_time
      );
      let itemToBeRemoved = tempArray.splice(0, lastIndex + 1);
      if (itemToBeRemoved.includes(watchStartTimeForHours[ind]?.end_time)) {
        setValue(`hours_of_operation[${ind}].end_time`, "");
      }
      return [...itemToBeRemoved];
    } else {
      return null;
    }
  };

  useEffect(() => {
    claimYourProfile();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="mt-3 container">
            <h5 className="fontweight-bold mt-3">Basic Information</h5>
            <div className="row mt-3">
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Gym Name"
                  name="firstName"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Address"
                  name="address"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="State"
                  name="state"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="City"
                  name="city"
                />
              </div>

              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Zipcode"
                  maxlength={5}
                  name="zipcode"
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Website"
                  name="website"
                />
              </div>
            </div>
          </div>
          <div className="mt-3 container">
            <h5 className="fontweight-bold mt-3">Programs</h5>
            <div className="row">
              {programRows.map((programRow, ind) => (
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Program Name"
                    name={`programs[${ind}].programName`}
                  />

                  <div className="row mt-4">
                    {programRows.length - 1 === ind && (
                      <AddNew
                        value={getValues("programs")}
                        setValue={setProgramRows}
                        initValue={programInitValue}
                      />
                    )}
                    <Remove
                      setRows={setProgramRows}
                      setValue={setValue}
                      ind={ind}
                      getValues={getValues}
                      name="programs"
                      initValue={programInitValue}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Certifications
            certificationIntitialValue={certificationInitValue}
            setValue={setValue}
            setCertificationRows={setCertificationRows}
            getValues={getValues}
            signupData={signupData}
            certificationRows={certificationRows}
            register={register}
            watch={watch}
            errors={errors}
          />
          <div className="mt-3">
            <Biography register={register} />
          </div>
          <div className="mt-3">
            <Speciality register={register} />
          </div>
          <div className=" mt-3 container">
            <h5 className="fontweight-bold mt-3">Modalities Available</h5>
            <div className="row">
              {modalitiesRows.map((modalitiesRow, ind) => (
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Modalities Available"
                    name={`modalities_available[${ind}].modalities_Name`}
                  />

                  <div className="row mt-4">
                    {modalitiesRows.length - 1 === ind && (
                      <AddNew
                        setValue={setModalitiesRows}
                        value={getValues("modalities_available")}
                        initValue={modalitiesInitValue}
                      />
                    )}

                    <div
                      className="col-md-1 text-danger cursor-pointer"
                      onClick={() => {
                        let rows = [...getValues("modalities_available")];
                        if (rows.length > 1) {
                          rows.splice(ind, 1);
                          setModalitiesRows(rows);
                          setValue("modalities_available", rows);
                        } else {
                          setModalitiesRows([modalitiesInitValue]);
                          setValue("modalities_available", [
                            modalitiesInitValue,
                          ]);
                        }
                      }}
                    >
                      Remove
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="container mt-3">
            <h5 className="fontweight-bold mt-3">Leadership team</h5>
            {leadershipRows.map((leadershipRow, ind) => (
              <div className="row">
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Name"
                    name={`leadership_team[${ind}].teamName`}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Title"
                    name={`leadership_team[${ind}].teamTitle`}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={error}
                    register={register}
                    label="Email"
                    className={
                      error && error.index === ind ? "border border-danger" : ""
                    }
                    isEmail={true}
                    setValue={setValue}
                    dataChange={(name, event) => handleValidation(ind, event)}
                    name={`leadership_team[${ind}].teamEmail`}
                  />

                  {error && error.index === ind && (
                    <span className="text-danger">{error.error}</span>
                  )}
                  <div className="row mt-4">
                    {leadershipRows.length - 1 === ind && (
                      <AddNew
                        setValue={setleadershipRows}
                        value={getValues("leadership_team")}
                        initValue={leadershipInitValue}
                      />
                    )}

                    <div
                      className="col-md-1 text-danger cursor-pointer"
                      onClick={() => {
                        let rows = [...getValues("leadership_team")];
                        if (rows.length > 1) {
                          rows.splice(ind, 1);
                          setleadershipRows(rows);
                          setValue("leadership_team", rows);
                        } else {
                          setleadershipRows([leadershipInitValue]);
                          setValue("leadership_team", [leadershipInitValue]);
                        }
                      }}
                    >
                      Remove
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3 container">
            <h5 className="fontweight-bold mt-3">Trainers Available</h5>
            <div className="row">
              {trainersAvailableRows.map((trainersAvailableRow, ind) => (
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Trainers Available"
                    name={`trainers_available[${ind}].trainers_Available`}
                  />
                  <div className="row mt-4">
                    {trainersAvailableRows.length - 1 === ind && (
                      <AddNew
                        setValue={setTrainersAvailableRows}
                        initValue={trainersAvailableInitValue}
                        value={getValues("trainers_available")}
                      />
                    )}

                    <Remove
                      initValue={trainersAvailableInitValue}
                      setRows={setTrainersAvailableRows}
                      setValue={setValue}
                      ind={ind}
                      getValues={getValues}
                      name="trainers_available"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-3">Classes Info</h5>
            {classesRows.map((classRow, ind) => (
              <>
                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="Classes Taught"
                      name={`classes_info[${ind}].taught`}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="Instructor"
                      name={`classes_info[${ind}].instructor`}
                    />
                  </div>
                </div>
                <div className="py-3">
                  <h5 className="mb-0">Classes Timings</h5>
                </div>
                <div className="row">
                  {classRow?.timings?.map((day, index) => {
                    return (
                      <>
                        <div className="col-md-4 mb-3">
                          <div className="row">
                            {PAGE_VARIABLES?.COMMON?.DAYS?.map(
                              (muscleGroup) => (
                                <div
                                  className="col-auto pe-0 mb-3"
                                  onClick={() => {
                                    getDayDetails(
                                      muscleGroup.value,
                                      index,
                                      `classes_info[${ind}].timings[${index}].days`,
                                      ind
                                    );
                                  }}
                                  key={muscleGroup.id}
                                >
                                  <div
                                    className={
                                      day?.days?.includes(muscleGroup.value)
                                        ? "education-active bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                                        : "bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                                    }
                                    id={muscleGroup.evaluationType}
                                  >
                                    <p className="text-center small mb-0">
                                      {muscleGroup.value}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-1">
                          <label>Start Time</label>
                          <Time
                            register={register}
                            name={`classes_info[${ind}].timings[${index}].start_time`}
                          />
                        </div>
                        <div className="col-md-4 mb-1">
                          <label>End Time</label>
                          <Time
                            register={register}
                            disabledEndTimes={getEndTime(
                              ind,
                              index,
                              watchStartTime,
                              setValue
                            )}
                            name={`classes_info[${ind}].timings[${index}].end_time`}
                          />
                        </div>
                        <div className="row mb-3">
                          {classesRows[ind].timings.length - 1 === index && (
                            <div
                              className="col-md-3 text-primary cursor-pointer"
                              onClick={() => {
                                const rows = {
                                  timings: [
                                    ...getValues("classes_info")[ind].timings,
                                    {
                                      days: [],
                                      start_time: "",
                                      end_time: "",
                                    },
                                  ],
                                };
                                let businessHoursRow = classesRows;
                                businessHoursRow[ind] = rows;
                                setClassesRows([...businessHoursRow]);
                                setValue(
                                  `classes_info[${ind}.timings]`,
                                  rows.timings
                                );
                              }}
                            >
                              Add Class Timings
                            </div>
                          )}
                          <div
                            className="col-md-3 text-danger cursor-pointer"
                            onClick={() => {
                              let rows = [
                                ...getValues("classes_info")[ind].timings,
                              ];
                              if (rows.length > 1) {
                                rows.splice(index, 1);
                                let businessHoursRow = classesRows;
                                businessHoursRow[ind].timings = rows;
                                setClassesRows([...businessHoursRow]);
                                setValue(
                                  `classes_info[${ind}].timings`,
                                  businessHoursRow[0].timings
                                );
                              } else {
                                let businessHoursRow = classesRows;

                                businessHoursRow[ind].timings = [
                                  {
                                    days: [],
                                    start_time: "",
                                    end_time: "",
                                  },
                                ];
                                setClassesRows([...businessHoursRow]);

                                setValue(`classes_info[${ind}].timings`, [
                                  {
                                    days: [],
                                    start_time: "",
                                    end_time: "",
                                  },
                                ]);
                              }
                            }}
                          >
                            Remove Class Timings
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="row mb-1">
                    {classesRows.length - 1 === ind && (
                      <div
                        className="col-md-2 p-2 text-primary cursor-pointer"
                        onClick={() => {
                          const rows = [
                            ...getValues("classes_info"),
                            classesInitValue,
                          ];
                          setClassesRows(rows);
                        }}
                      >
                        Add Classes Info
                      </div>
                    )}
                    <div
                      className="col-md-3 p-2 text-danger cursor-pointer"
                      onClick={() => {
                        let rows = [...getValues("classes_info")];
                        if (rows.length > 1) {
                          rows.splice(ind, 1);
                          setClassesRows(rows);
                          setValue("classes_info", rows);
                        } else {
                          setClassesRows([classesInitValue]);
                          setValue("classes_info", [classesInitValue]);
                        }
                      }}
                    >
                      Remove Classes Info
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="container mt-3">
            <h5 className="fontweight-bold mt-3">Hours of operation</h5>
            <div className="row">
              {hoursOfOperationRows?.map((day, index) => (
                <>
                  <div className="col-md-4 mb-3">
                    <div className="row">
                      {PAGE_VARIABLES?.COMMON?.DAYS.map((muscleGroup) => {
                        return (
                          <div
                            className="col-auto pe-0 mb-3"
                            onClick={() => {
                              getDayDetailsForHours(
                                muscleGroup.value,
                                `hours_of_operation`,
                                index
                              );
                            }}
                            key={muscleGroup.id}
                          >
                            <div
                              className={
                                day?.days?.includes(muscleGroup.value)
                                  ? "education-active bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                                  : "bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                              }
                              id={muscleGroup.evaluationType}
                            >
                              <p className="text-center small mb-0">
                                {muscleGroup.value}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="col-md-4 mb-1">
                    <label>Start Time</label>
                    <Time
                      register={register}
                      name={`hours_of_operation[${index}].start_time`}
                    />
                  </div>
                  <div className="col-md-4 mb-1">
                    <label>End Time</label>
                    <Time
                      register={register}
                      disabledEndTimes={getEndTimeForHours(index)}
                      name={`hours_of_operation[${index}].end_time`}
                    />
                  </div>
                  <div className="row mb-3">
                    {hoursOfOperationRows.length - 1 === index && (
                      <div
                        className="col-md-3 text-primary cursor-pointer"
                        onClick={() => {
                          setHoursOfOperationRows([
                            ...getValues("hours_of_operation"),
                            {
                              days: [],
                              start_time: "",
                              end_time: "",
                            },
                          ]);
                          setValue(`hours_of_operation[${index + 1}]`, {
                            days: [],
                            start_time: "",
                            end_time: "",
                          });
                        }}
                      >
                        Add New Hours Of Operation
                      </div>
                    )}
                    <div
                      className="col-md-3 text-danger cursor-pointer"
                      onClick={() => {
                        let rows = [...getValues("hours_of_operation")];
                        if (rows.length > 1) {
                          rows.splice(index, 1);
                          setHoursOfOperationRows(rows);
                          setValue(`hours_of_operation`, rows);
                        } else {
                          setHoursOfOperationRows([
                            {
                              days: [],
                              start_time: "",
                              end_time: "",
                            },
                          ]);

                          setValue(`hours_of_operation`, [
                            {
                              days: [],
                              start_time: "",
                              end_time: "",
                            },
                          ]);
                        }
                      }}
                    >
                      Remove Hours Of Operation
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="mt-3 container">
            <div className="row">
              <div className="col-md-6 mb-3 d-flex flex-column">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Years in Business"
                  name="years_in_business"
                />
              </div>
              <div className="col-md-6 mb-3 d-flex flex-column">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Forms of Payment accepted"
                  name="payment_accepted_methods"
                />
              </div>
            </div>
          </div>
          <div className="mt-3 container">
            <h5 className="fontweight-bold mt-3">Affiliations</h5>
            <div className="row">
              {affiliatedGymsRows.map((affiliatedGymsRow, ind) => (
                <div className="col-md-6">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Affiliated Gym Name"
                    name={`affiliations[${ind}.affiliatedGymsName]`}
                  />
                  <div className="row">
                    {affiliatedGymsRows.length - 1 === ind && (
                      <AddNew
                        setValue={setAffiliatedGyms}
                        value={getValues("affiliations")}
                        initValue={affiliatedGymsInitValue}
                      />
                    )}
                    <Remove
                      setRows={setAffiliatedGyms}
                      setValue={setValue}
                      ind={ind}
                      getValues={getValues}
                      name="affiliations"
                      initValue={affiliatedGymsInitValue}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-3">Social Media</h5>
            <div className="row">
              <SocialMedia errors={errors} register={register} />
            </div>
          </div>
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-3">Ask a Question</h5>
            <div className="row py-4 ">
              <div className="col-md-4">
                <>
                  <LabelWithTooltip
                    label="Ask a Question"
                    TooltipText="Ask a question is a unique feature
                                          for providers to receive questions and communicate directly with
                                          patients regarding their conditions. Please select
                                          “No” if you do not wish to communicate with patients
                                          in this way"
                    id="inlineRadio1"
                    labelClassName="form-label"
                  />
                  <div className="radio-buttons d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        name="askAnExpert"
                        className="align-middle me-1"
                        value="Yes"
                        checked={select === "Yes"}
                        onChange={(e) => setSelect("Yes")}
                      />
                      <label
                        className="form-check-label me-4"
                        for="inlineRadio1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        name="askAnExpert"
                        className="align-middle me-1"
                        value="No"
                        checked={select === "No"}
                        onChange={(e) => setSelect("No")}
                      />
                      <label className="form-check-label" for="inlineRadio2">
                        No
                      </label>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GymProfile;
