import React, { useState, useEffect } from "react";
import { Device } from "twilio-client";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { useAppState } from "../state/state";
import useLoudness from "../hooks/useLoudness";
import useMuteWarning from "../hooks/useMuteWarning";
import states from "../hooks/states";
import DefaultUserLogo from "./DefaultUserLogo";

const Call = ({ token, callback }) => {
  const {
    participantUserPhoneNumber,
    participantUserName,
    StartCall,
    EndCall,
  } = useAppState();
  const [state, setState] = useState(states.CONNECTING);
  const [device, setDevice] = useState(null);
  const [muted, setMuted] = useState(false);
  const [running, setRunning, loudness] = useLoudness();
  const [showMuteWarning] = useMuteWarning(loudness, running);
  const muteWarning = (
    <p className="warning">Are you speaking? You are on mute!</p>
  );

  const handleMute = (e) => {
    e.preventDefault();
    setMuted(!muted);
    setRunning(!muted);
  };

  const handleCall = (e) => {
    e.preventDefault();
    device.connect({ To: participantUserPhoneNumber });
  };

  const handleHangup = async (e) => {
    e.preventDefault();
    device.disconnectAll();
  };

  const EndStateCall = async () => {
    await EndCall();
    await callback(false);
  };

  const ConnectDevice = (token) => {
    const device = new Device();
    device.setup(token, { debug: true });
    device.on("ready", () => {
      setDevice(device);
      setState(states.READY);
    });
    device.on("connect", async () => {
      setState(states.ON_CALL);
      await StartCall();
    });
    device.on("disconnect", async () => {
      setState(states.READY);
      EndStateCall();
    });
    device.on("incoming", (connection) => {
      setState(states.INCOMING);
      connection.on("reject", () => {
        setState(states.READY);
      });
    });
    device.on("cancel", async () => {
      setState(states.READY);
      EndStateCall();
    });
    device.on("reject", async () => {
      setState(states.READY);
      EndStateCall();
    });
    return () => {
      device.destroy();
      setDevice(null);
      setState(states.OFFLINE);
    };
  };

  useEffect(() => {
    document.getElementById("root").scrollTo(0, -10);
    ConnectDevice(token);
  }, [token]);

  return (
    <React.Fragment>
      <div className={"col-sm-12 text-center"}>
        <h2>{state}</h2>
      </div>
      <div className={"col-sm-12 text-center pt-2"}>
        <DefaultUserLogo
          style={{
            width: "20%",
            height: "20%",
            padding: "5%",
            borderRadius: "50%",
          }}
        />
      </div>
      <div className={"col-sm-12 text-center pt-2"}>
        <h2>{participantUserName}</h2>
      </div>
      {token && state === states.READY && participantUserPhoneNumber && (
        <div className={"col-sm-12 text-center pt-2"}>
          <Button
            className="btn btn-success text-white ps-5 pe-5"
            onClick={handleCall}
          >
            <i class="fa-solid fa-phone me-2"></i>Call
          </Button>
        </div>
      )}
      {token && state === states.READY && !participantUserPhoneNumber && (
        <div className={"col-sm-12 text-center pt-2"}>
          <p className="text-danger">
            <h4>No phone number is associated with this participant</h4>
          </p>
          <Button
            className="btn btn-danger ps-5 pe-5"
            onClick={() => callback(false)}
          >
            Cancel
          </Button>
        </div>
      )}
      {token && state === states.ON_CALL && (
        <React.Fragment>
          <div className={"col-sm-12 text-center pt-2"}>
            <Button
              className="btn btn-warning text-white ps-5 pe-5 me-4 d-none"
              onClick={handleMute}
            >
              <i class="fas fa-volume-mute me-2"></i>Mute
            </Button>
            <Button
              className="btn btn-danger text-white ps-5 pe-5"
              onClick={handleHangup}
            >
              <i class="fa-solid fa-phone me-2"></i>End
            </Button>
          </div>
          <div className={"col-sm-12 text-center pt-2"}>
            {showMuteWarning && muteWarning}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

Call.propTypes = {
  token: PropTypes.string,
};

export default Call;
