import React, { useEffect, useState } from 'react';
import Layout from './layout/Layout';
import Footer from './Home/Footer';
import { domain, user } from '../../../constants';

const Terms = () => {
  useEffect(() => {
    window.fbq('track', `Terms of Use Page is opened.`);
    document.getElementById('root').scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="mt-5 mb-4 flex-fill">
        <section className="mb-5">
          <div className="container">
            <div className="row mt-3">
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <h3>Terms of Use</h3>
                  <div className="d-flex"></div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="bg-white rounded p-5 shadow-lg mb-5 table-responsive">
                  <h5 className="mb-3">PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS SITE.</h5>

                  <p>
                    By using this site, you signify your assent to these Terms and Conditions. If you do not agree to all of
                    these Terms and Conditions, do not use this site!
                  </p>
                  <p>
                    Clinekt Health, LLC ("MOP") may revise and update these Terms and Conditions at any time. Your continued
                    usage of the MOP website ("MOP Site," or the "Site,") will mean you accept those changes.
                  </p>
                  <h6>
                    <u>The Site Does Not Provide Medical Advice</u>
                  </h6>
                  <p>
                    The contents of the MOP Site, such as text, graphics, images, and other materials created by MOP or
                    obtained from MOP's licensors, and other materials contained on the MOP Site (collectively, "Content")
                    are for informational purposes only. The Content is not intended to be a substitute for professional
                    medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified
                    health provider with any questions you may have regarding a medical condition. Never disregard
                    professional medical advice or delay in seeking it because of something you have read on the MOP Site!
                  </p>
                  <p>
                    If you think you may have a medical emergency, call your doctor or 911 immediately. MOP does not
                    recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information
                    that may be mentioned on the Site. Reliance on any information provided by MOP, MOP employees, others
                    appearing on the Site at the invitation of MOP, or other visitors to the Site is solely at your own risk.
                  </p>
                  <h6>
                    <u>Children's Privacy</u>
                  </h6>
                  <p>
                    We are committed to protecting the privacy of children. You should be aware that this Site is not
                    intended or designed to attract children under the age of 13. We do not collect personally identifiable
                    information from any person we actually know is a child under the age of 13.
                  </p>
                  <h6>
                    <u>Use of the Content</u>
                  </h6>
                  <p>
                    The Content posted on this Site is protected by the copyright laws in the United States and in foreign
                    countries. MOP authorizes you to view or download a single copy of the Content solely for your personal,
                    noncommercial use. Any special rules for the use of certain software and other items accessible on the
                    MOP Site may be included elsewhere within the Site and are incorporated into these Terms and Conditions
                    by reference.
                  </p>
                  <p>
                    Title to the Content remains with MOP or its licensors. Any use of the Content not expressly permitted by
                    these Terms and Conditions is a breach of these Terms and Conditions and may violate copyright,
                    trademark, and other laws. Content and other features are subject to change or termination without notice
                    in the editorial discretion of MOP. All rights not expressly granted herein are reserved to MOP and its
                    licensors.
                  </p>
                  <p>
                    If you violate any of these Terms and Conditions, your permission to use the Content automatically
                    terminates and you must immediately destroy any copies you have made of any portion of the Content.
                  </p>
                  <h6>
                    <u>Liability of MOP and Its Licensors</u>
                  </h6>
                  <p>The use of the MOP Site and the Content is at your own risk.</p>
                  <p>
                    When using the MOP Site, information will be transmitted over a medium that may be beyond the control and
                    jurisdiction of MOP and its suppliers. Accordingly, MOP assumes no liability for or relating to the
                    delay, failure, interruption, or corruption of any data or other information transmitted in connection
                    with use of the MOP Site.
                  </p>
                  <p>
                    The MOP Site and the Content are provided on an "as is" basis. MOP, ITS LICENSORS, AND ITS SUPPLIERS, TO
                    THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR
                    OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF
                    THIRD PARTIES' RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. Without limiting the foregoing, MOP, its
                    licensors, and its suppliers make no representations or warranties about the following:
                    <ul>
                      <li>
                        The accuracy, reliability, completeness, currentness, or timeliness of the Content, software, links,
                        or communications provided on or through the use of the MOP Site or MOP.
                      </li>
                      <li>
                        <p>
                          OWNERSHIP: The Licensed Content, including without limitation all copyrights and other intellectual
                          property rights therein, are the sole and exclusive property of UpToDate (or its parents,
                          subsidiaries, affiliates, or designees) or its licensors. By agreeing to this Agreement, you do not
                          become the owner of the Licensed Content but are entitled to use the Licensed Content only in
                          accordance with this Agreement.
                        </p>
                      </li>
                    </ul>
                    In no event shall MOP, its licensors, its suppliers, or any third parties mentioned on the MOP Site be
                    liable for any damages (including, without limitation, incidental and consequential damages, personal
                    injury/wrongful death, lost profits, or damages resulting from lost data or business interruption)
                    resulting from the use of or inability to use the MOP Site or the Content, whether based on warranty,
                    contract, tort, or any other legal theory, and whether or not MOP, its licensors, its suppliers, or any
                    third parties mentioned on the MOP Site are advised of the possibility of such damages. MOP, its
                    licensors, its suppliers, or any third parties mentioned on the MOP Site shall be liable only to the
                    extent of actual damages incurred by you, not to exceed U.S. $1000. MOP, its licensors, its suppliers, or
                    any third parties mentioned on the MOP Site are not liable for any personal injury, including death,
                    caused by your use or misuse of the Site, Content, or Public Areas (as defined below). Any claims arising
                    in connection with your use of the Site, any Content, or the Public Areas must be brought within one (1)
                    year of the date of the event giving rise to such action occurred. Remedies under these Terms and
                    Conditions are exclusive and are limited to those expressly provided for in these Terms and Conditions.
                  </p>
                  <h6>
                    <u>Privacy</u>
                  </h6>
                  <p>
                    The personal information submitted and collected by MOP is governed by the
                    <a href="/privacypolicy">MOP Privacy Policy</a>. To the extent there is an inconsistency between this
                    Agreement and the <a href="/privacypolicy">MOP Privacy Policy</a>, this Agreement shall govern.
                  </p>
                  <h6>
                    <u>Passwords</u>
                  </h6>
                  <p>
                    MOP has several tools that allow you to record and store information. You are responsible for taking all
                    reasonable steps to ensure that no unauthorized person shall have access to your MOP passwords or
                    accounts. It is your sole responsibility to: (1) control the dissemination and use of sign-in name,
                    screen name and passwords; (2) authorize, monitor, and control access to and use of your MOP account and
                    password; (3) promptly inform MOP if you believe your account or password has been compromised or if
                    there is any other reason you need to deactivate a password. To send us an email, use the "Contact Us"
                    links located at the bottom of every page of our site. You grant MOP and all other persons or entities
                    involved in the operation of the Site the right to transmit, monitor, retrieve, store, and use your
                    information in connection with the operation of the Site. MOP cannot and does not assume any
                    responsibility or liability for any information you submit, or your or third parties' use or misuse of
                    information transmitted or received using MOP tools and services.
                  </p>
                  <h6>
                    <u>Advertisements, Searches, and Links to Other Sites</u>
                  </h6>
                  <p>
                    MOP may provide links to third-party web sites. MOP also may select certain sites as priority responses
                    to search terms you enter and MOP may agree to allow advertisers to respond to certain search terms with
                    advertisements or sponsored content. MOP does not recommend and does not endorse the content on any
                    third-party websites. MOP is not responsible for the content of linked third-party sites, sites framed
                    within the MOP Site, third-party sites provided as search results, or third-party advertisements, and
                    does not make any representations regarding their content or accuracy. Your use of third-party websites
                    is at your own risk and subject to the terms and conditions of use for such sites. MOP does not endorse
                    any product, service, or treatment advertised on the MOP Site.
                  </p>
                  <h6>
                    <u>Indemnity</u>
                  </h6>
                  <p>
                    You agree to defend, indemnify, and hold MOP, its officers, directors, employees, agents, licensors, and
                    suppliers, harmless from and against any claims, actions or demands, liabilities and settlements
                    including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result
                    from: (a) your use of the Site, (b) any User Content you post or upload, (c) your use of or reliance on
                    any User Content, or (d) your violation of these Terms and Conditions.
                  </p>
                  <h6>
                    <u>General</u>
                  </h6>
                  <p>
                    MOP is based in Birmingham, Alabama, in the United States of America. MOP makes no claims that the
                    content on any site affiliated with the MOP network is appropriate in certain jurisdictions. If you
                    access the MOP Network from outside the United States, you do so at your own risk and are responsible for
                    compliance with the laws of your jurisdiction.
                  </p>
                  <p>
                    By using the MOP Site you also agree to be bound by the UpToDate terms of use which are incorporated by
                    reference into these Terms and Conditions and can be found{' '}
                    <a href="https://dha.health-ce.wolterskluwer.com/resources/web/terms-of-use.html">here</a>.
                  </p>
                  <p>
                    The following provisions survive the expiration or termination of this Agreement for any reason
                    whatsoever: Liability, User Submissions, User Submissions – image, video, audio files, Indemnity,
                    Jurisdiction, and Complete Agreement.
                  </p>
                  <h6>
                    <u>Jurisdiction</u>
                  </h6>
                  <p>
                    You expressly agree that exclusive jurisdiction for any dispute with MOP, or in any way relating to your
                    use of the MOP Site, resides in the courts of the State of Alabama and you further agree and expressly
                    consent to the exercise of personal jurisdiction in the courts of the State of Alabama in connection with
                    any such dispute including any claim involving MOP or its affiliates, subsidiaries, employees,
                    contractors, officers, directors, telecommunication providers, and content providers.
                  </p>
                  <p>
                    These Terms and Conditions are governed by the internal substantive laws of the State of Georgia, without
                    respect to its conflict of laws principles. If any provision of these Terms and Conditions is found to be
                    invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the
                    validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and
                    effect. No waiver of any of these Terms and Conditions shall be deemed a further or continuing waiver of
                    such term or condition or any other term or condition.
                  </p>
                  <h6>
                    <u>Notice and Takedown Procedures; and Copyright Agent</u>
                  </h6>
                  <p>
                    If you believe any materials accessible on or from the Site infringe your copyright, you may request
                    removal of those materials (or access thereto) from this web site by contacting MOP's representative
                    (identified below) and providing the following information:
                    <ol>
                      <li>
                        Identification of the copyrighted work that you believe to be infringed. Please describe the work,
                        and where possible include a copy or the location (e.g., URL) of an authorized version of the work.
                      </li>
                      <li>
                        Identification of the material that you believe to be infringing and its location. Please describe
                        the material, and provide us with its URL or any other pertinent information that will allow us to
                        locate the material.
                      </li>
                      <li>Your name, address, telephone number and (if available) e-mail address.</li>
                      <li>
                        A statement that you have a good faith belief that the complained of use of the materials is not
                        authorized by the copyright owner, its agent, or the law.
                      </li>
                      <li>
                        A statement that the information that you have supplied is accurate, and indicating that "under
                        penalty of perjury," you are the copyright owner or are authorized to act on the copyright owner's
                        behalf.
                      </li>
                      <li>
                        A signature or the electronic equivalent from the copyright holder or authorized representative.
                      </li>
                    </ol>
                    MOP's agent for copyright issues relating to this web site is as follows:
                  </p>
                  <p>
                    MyOrthopedicProblem<br></br>
                    Attn: Chief Legal Officer<br></br>
                    2839 Culver Road, Suite 202<br></br>
                    Birmingham, AL 35223<br></br>
                    {user}@{domain}
                  </p>
                  <p>
                    In an effort to protect the rights of copyright owners, MOP maintains a policy for the termination, in
                    appropriate circumstances, of subscribers or user of the Site who are repeat infringers.
                  </p>
                  <h6>
                    <u>Complete Agreement</u>
                  </h6>
                  <p>
                    Except as expressly provided in a particular "legal notice" on the MOP Site, these Terms and Conditions
                    and the
                    <a href="/privacypolicy">MOP Privacy Policy</a> constitute the entire agreement between you and MOP with
                    respect to the use of the MOP Site, and Content.
                  </p>
                  <p>Last updated on September 2, 2022</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Layout>
  );
};

export default Terms;
