import React, { useEffect, useState, useRef } from "react";
import Axios from "../../../util/axiosInst";
import { Dropdown, Container, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CustomExerciseTab from "./CustomExerciseTab";
import axios from "axios";
import Pagination from "../../common/Pagination";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { fmsUrl } from "../../../constants";
import { SwalAlertFunction } from "../../../pages/SwalAlert";
import Image from "../../Image";
const ExerciseTab = ({ providerId, providerDetail }) => {
  const params = useParams();
  const [error, setError] = useState("");
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [customLoading, setCustomLoading] = useState(true);
  let userData = JSON.parse(localStorage.getItem("mop-user"));
  const [providerVideos, setProviderVideos] = useState([{}]);
  const [currentVideoPageNo, setCurrentVideoPageNo] = useState(0);
  const [fmsData, setFmsData] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLimit, setPageLimit] = useState(6);
  const [lastPage, setLastPage] = useState(0);
  const exerciseRef = useRef(null);
  const [select, setSelect] = useState({
    external: true,
    custom: true,
  });

  const getFmsToken = () => {
    axios.get(fmsUrl + "token").then((res) => {
      if (res.status === 200) {
        localStorage.setItem("fms-token", res.data.access_token);
        getProfile();
      } else {
        setError("Something went wrong");
      }
    });
  };

  const getVideos = async (id, evaluationType, currentPageNo, index) => {
    const { data } = await Axios.get(
      "/v1/public/provider/" +
        providerId +
        "/videos?active=true&limit=6&page=" +
        (currentPageNo + 1) +
        "&categoryId=" +
        id +
        "&type=exercise"
    );
    let temp = providerVideos;

    if (data.results && data.results.length) {
      if (temp && temp[index] && temp[index][evaluationType]) {
        temp[index][evaluationType] = data.results;
        temp[index]["total"] = data?.totalPages;
      } else {
        temp.push({
          [evaluationType]: data.results,
          total: data?.totalPages,
        });
      }
    }
    setTimeout(() => {
      setCustomLoading(false);
    }, 2000);
    setProviderVideos([...temp]);
  };
  const getProfile = () => {
    Axios.get("/v1/public/provider/" + params?.id + "/detail").then((res) => {
      if (res.status === 200) {
        if (res?.data?.likedFMSExercises?.length > 0) {
          setLoading(true);
          setSelect({ ...select, external: true });
          setData(res?.data?.likedFMSExercises);
          getFmsData(res?.data?.likedFMSExercises);
        } else {
          setSelect({ ...select, external: true });
          setError("No Data Found");
        }
      } else {
        setError("Something went wrong");
      }
    });
  };

  const LikedExercise = (item) => {
    let token = JSON.parse(localStorage.getItem("mop-token")).access?.token;
    Axios.post(`/v1/fms/exercise/${item.fms_exerciseid}/like`, "", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        window.fbq(
          "track",
          res.data.message.includes("Unlike")
            ? "Exercise unliked successfully."
            : "Exercise liked successfully."
        );
        SwalAlertFunction(
          res.data.message.includes("Unlike")
            ? "Exercise unliked successfully."
            : "Exercise liked successfully.",
          "success"
        );
        getProfile();
      } else {
        setError("Something went wrong");
      }
    });
  };

  const getFmsData = (data) => {
    const token = localStorage.getItem("fms-token");
    let tempArray = [];
    if (data.length > 0) {
      data.slice(lastPage, pageLimit).map((item, index) => {
        axios
          .get(fmsUrl + `exercise/${Number(item)}`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              tempArray.push(res.data);
              setFmsData([...new Set(tempArray)]);
              setLoading(false);
            } else {
              setLoading(false);
              setError("Something went wrong");
            }
          });
        return null;
      });
    }
  };
  const getCategories = () => {
    Axios.get("/v1/public/blog/categories/no-blogs")
      .then(({ data }) => {
        data.results.map((item) => {
          getVideos(item._id, item.evaluationType, 0);
          return null;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    window.fbq("track", `Exercise Tab Page is opened.`);
    getFmsToken();
    getCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getFmsData(data);
    // eslint-disable-next-line
  }, [pageLimit, lastPage]);

  return (
    <Container className=" bg-muted-25 p-md-5 mt-4 p-3 exercise-tab">
      <div ref={exerciseRef} className="row">
        <div className="bg-white rounded-1 p-2">
          {select?.external ? (
            <>
              <div className="d-md-flex justify-content-between text-md-start text-end  py-3 px-md-4 px-1">
                <h3 className="text-capitalize d-md-block d-none text-primary pt-3 text-start">
                  Exercises
                </h3>
                <h5 className="text-capitalize d-md-none d-block text-primary pt-3 text-start">
                  Exercises
                </h5>
                <div className="input-group rounded justify-content-end ps-md-0">
                  <div className="d-block">
                    <div className="input-group rounded">
                      <div className="my-2 d-flex pe-1 ps-1" id="search">
                        <input
                          name="searchQuery"
                          value=""
                          placeholder="Search..."
                          className="form-control fs--18 padding-064rem"
                          s
                          onChange={(e) => {}}
                          onKeyUp={(e) => {}}
                        />{" "}
                        <i
                          className={`fa fa-solid  fs-5 fa-magnifying-glass padding-092rem ${
                            window.innerWidth > 1225
                              ? "h-50px"
                              : "height-49.3px"
                          } `}
                        ></i>
                        <Dropdown
                          className="ps-1"
                          show={false}
                          id="exercise-search"
                          drop="down-centered"
                        >
                          <div className="d-flex">
                            <Dropdown.Toggle
                              variant="white"
                              id="dropdown-basic-exercise"
                              className="fw-semibold bg-primary rounded-0 px-0"
                            >
                              <button
                                type="button"
                                className="height-4903px input-group-text h-100 text-white mb-1 d-flex m-0 m-auto border-0 "
                              >
                                <i
                                  className="fa fa-filter fs-5 maxheight-4903px"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </Dropdown.Toggle>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`container my-5 ${
                  window.innerWidth > 767 ? "" : "p-0 px-3"
                }`}
              >
                <div className="row">
                  {loading ? (
                    <div className="moplikedexer-loading">
                      <Spinner animation="border width-3rem height-3rem" />
                      <h5 className="my-3">
                        Please wait while the exercises are loading...
                      </h5>
                    </div>
                  ) : error ? (
                    <h5 className="my-5 text-center"> {error} </h5>
                  ) : (
                    fmsData.map((item, index) => (
                      <div
                        className={`${
                          window.innerWidth > 1281
                            ? "col-4"
                            : window.innerWidth > 767
                            ? window.innerWidth > 767 &&
                              window.innerWidth < 1000
                              ? "col-6"
                              : "col-4"
                            : "col-12"
                        }  mb-4 ps-md-3`}
                        key={index}
                      >
                        <div className="bg-white rounded-2  educational-animation position-relative  exercise-card">
                          <div className="rounded-top overflow-hidden">
                            <div className="exercises_img">
                              <Image
                                local={true}
                                src={item.exercise_image}
                                className="w-100"
                                alt={item.exercise_name}
                              />
                            </div>
                            <div className="like-icons d-flex justify-content-center">
                              <i
                                className={`${
                                  userData ? "fa" : "far"
                                }  fa-heart cursor-pointer p-1 pt-2 ${
                                  userData ? "text-danger" : "text-black"
                                }`}
                                onClick={() => {
                                  if (userData) {
                                    LikedExercise(item);
                                  } else {
                                    MySwal.fire({
                                      title:
                                        "You need to login to like the exercises.",
                                      icon: "info",
                                      confirmButtonText: "Ok",
                                      allowOutsideClick: false,
                                      allowEscapeKey: false,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="p-3">
                            <h5 className="fw-semibold mb-2 exercise-heading">
                              {item.exercise_name.length > 60
                                ? item.exercise_name.substr(0, 60) + "..."
                                : item.exercise_name}
                            </h5>
                            <p className="exercise-summary">
                              {item.exercise_summary.length > 180
                                ? item.exercise_summary.substr(0, 180) + "..."
                                : item.exercise_summary}
                            </p>
                            <button
                              className="btn btn-primary text-white w-100 fw-normal rounded-0"
                              onClick={() => {
                                navigate(
                                  `/exercise-details/${
                                    item.fms_exerciseid
                                  }-${item.exercise_name.replaceAll(" ", "-")}`,
                                  {
                                    state: {
                                      id: item.fms_exerciseid,
                                      name: providerDetail.name,
                                    },
                                  }
                                );
                              }}
                            >
                              <span className="align-text-bottom me-1">
                                Learn more
                              </span>
                              <i className="fa fa-arrow-circle-right" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                {!loading && data.length > 0 && !error && (
                  <Pagination
                    onPageChange={(event) => {
                      setLoading(true);
                      exerciseRef.current.scrollTo(0, 0);
                      setPage(event.selected);
                      const pagecount = event.selected + 1;
                      setLastPage(event.selected * 6);
                      setPageLimit(pagecount * 6);
                    }}
                    pageRangeDisplayed={fmsData?.length}
                    totalPages={data?.length / 6}
                    currentPageNo={page}
                  />
                )}
              </div>
            </>
          ) : (
            <></>
          )}
          <div
            className={`container ${
              window.innerWidth > 767 ? "" : "p-0 px-md-3"
            }`}
          >
            <div className="d-md-flex justify-content-between text-md-start   py-3">
              <h3 className="d-md-block d-none text-capitalize text-primary pt-3 text-nowrap">
                My Exercises
              </h3>
              <h5 className="d-md-none d-block text-capitalize text-primary pt-3 text-nowrap">
                My Exercises
              </h5>
              <div className="input-group rounded justify-content-end ps-md-0 ">
                <div className="d-block">
                  <div className="input-group rounded">
                    <div className="my-2 d-flex pe-1 ps-1" id="search">
                      <input
                        name="searchQuery"
                        value=""
                        placeholder="Search..."
                        className="form-control fs--18 padding-064rem"
                        onChange={(e) => {}}
                        onKeyUp={(e) => {}}
                      />{" "}
                      <i className="fa fa-solid  fs-5 fa-magnifying-glass padding-092rem"></i>
                    </div>

                    <Dropdown
                      show={false}
                      id="exercise-search"
                      drop="down-centered"
                    >
                      <div className="d-flex">
                        <Dropdown.Toggle
                          variant="white"
                          id="dropdown-basic-exercise"
                          className="mt-2 fw-semibold bg-primary rounded-0 px-0"
                        >
                          <button
                            type="button"
                            className="input-group-text h-100 text-white mb-1 d-flex m-0 m-auto border-0 "
                          >
                            <i
                              className="fa fa-filter fs-5"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </Dropdown.Toggle>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {select?.custom ? (
            providerVideos && providerVideos?.length > 1 ? (
              <CustomExerciseTab
                providerVideos={providerVideos}
                setCurrentVideoPageNo={setCurrentVideoPageNo}
                currentVideoPageNo={currentVideoPageNo}
                getVideos={getVideos}
                loading={customLoading}
                setCustomLoading={setCustomLoading}
              />
            ) : customLoading ? (
              <div className="d-block mb-5 pb-5">
                <div className="py-5 text-center">
                  <Spinner animation="border" className="spinner" />
                  <h5 className=" mb-5 pb-3">
                    Please wait while the exercises are loading...
                  </h5>
                </div>
              </div>
            ) : (
              <div className="d-block ">
                <h5 className="text-black text-center pt-3 pb-5">
                  No Data Found.
                </h5>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ExerciseTab;
