import React, { Suspense, lazy, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../../pages/Login";
import Question from "./Assessment/Question.jsx";
import Layout from "./layout/Layout";
import PatientChat from "./messages/PatientChat.jsx";
import { hideIntercomChatWidget } from "../../util/intercom";

const Home = lazy(() => import("./home/Home"));
const Settings = lazy(() => import("./settings/Settings"));
const HealthFiles = lazy(() => import("./healthfiles/HealthFiles.jsx"));
const Pharmacy = lazy(() => import("./pharmacy/Pharmacy.jsx"));
const Insurance = lazy(() => import("./insurance/Insurance.jsx"));
const TalkToProvider = lazy(() =>
  import("./talkToProvider/TalkToProvider.jsx")
);
const PatMuscleType = lazy(() => import("./symptomChecker/PatMuscleType.jsx"));
const PatBlogArticle = lazy(() =>
  import("./educationLibrary/PatBlogArticle.jsx")
);
const PatQuestion = lazy(() => import("./symptomChecker/PatQuestion.jsx"));
const HealthProfile = lazy(() =>
  import("../patient/pages/health-profile/HealthProfile.jsx")
);
const HealthCondition = lazy(() =>
  import("../patient/pages/health-profile/Condition.jsx")
);
const Allergies = lazy(() =>
  import("../patient/pages/health-profile/Allergies.jsx")
);
const Medication = lazy(() =>
  import("../patient/pages/health-profile/Medication.jsx")
);
const PatEducationVideoPlayer = lazy(() =>
  import("./educationLibrary/PatEducationVideoPlayer.jsx")
);
const PatProviders = lazy(() => import("./talkToProvider/Providers.jsx"));
const Surgeries = lazy(() =>
  import("../patient/pages/health-profile/Surgeries.jsx")
);
const Assessment = lazy(() => import("./Assessment/Assessment.jsx"));
const ProviderDetails = lazy(() =>
  import("../patient/talkToProvider/ProviderComponents/ProviderDetails")
);
const PatEducationPage = lazy(() =>
  import("./educationLibrary/PatEducationPage.jsx")
);
const EducationLibraryArticle = lazy(() =>
  import("./educationLibrary/PatEducationArticle.jsx")
);
const PatPastAppointments = lazy(() =>
  import("./pastappointment/PastAppointments.jsx")
);
const PatAbout = lazy(() => import("./about/About.jsx"));
const PatContactInfo = lazy(() =>
  import("./contactinfo/ContactInformation.jsx")
);
const MedicalSupplies = lazy(() =>
  import("./medical-supplies/MedicalSupplies.jsx")
);
const SocketAlert = lazy(() => import("./Components/common/SocketAlert.jsx"));

const PatientApp = () => {
  const RequireAuth = ({ children }) => {
    let isAuthenticated = localStorage.getItem("mop-token");
    return isAuthenticated ? children : <Navigate to="/login" />;
  };
  const UnRequireAuth = ({ children }) => {
    let isAuthenticated = localStorage.getItem("mop-token");
    return !isAuthenticated ? children : <Navigate to="/pat-home" />;
  };

  useEffect(() => {
    hideIntercomChatWidget();
  }, []);

  return (
    <Suspense fallback={<Layout />}>
      <SocketAlert />
      <ToastContainer limit={1} />
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={
            <UnRequireAuth>
              <Login />
            </UnRequireAuth>
          }
        />
      </Routes>
      <Layout>
        <Routes>
          <Route
            path="/pat-home"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-symptom-checker"
            element={
              <RequireAuth>
                <PatQuestion />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-assessment-question"
            element={
              <RequireAuth>
                <Question />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-health-profile"
            element={
              <RequireAuth>
                <HealthProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-messages"
            element={
              <RequireAuth>
                <PatientChat />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-health-condition"
            element={
              <RequireAuth>
                <HealthCondition />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-allergies"
            element={
              <RequireAuth>
                <Allergies />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-medications"
            element={
              <RequireAuth>
                <Medication />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-surgeries"
            element={
              <RequireAuth>
                <Surgeries />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-symptom-checker"
            element={
              <RequireAuth>
                <PatQuestion />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-education-library"
            element={
              <RequireAuth>
                <PatEducationPage />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-symptom-checker/:muscleType/questions"
            element={
              <RequireAuth>
                <PatMuscleType />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-provider-posts/:id"
            element={
              <RequireAuth>
                <PatBlogArticle />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-video/:videotitle"
            element={<PatEducationVideoPlayer />}
          />
          <Route
            path="/pat-health-files"
            element={
              <RequireAuth>
                <HealthFiles />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-home"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-insurance"
            element={
              <RequireAuth>
                <Insurance />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-pharmacy"
            element={
              <RequireAuth>
                <Pharmacy />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-about"
            element={
              <RequireAuth>
                <PatAbout />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-contactinfo"
            element={
              <RequireAuth>
                <PatContactInfo />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-pastappointments"
            element={
              <RequireAuth>
                <PatPastAppointments />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-talk-to-provider"
            element={
              <RequireAuth>
                <TalkToProvider />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-talk-to-provider-remote"
            element={
              <RequireAuth>
                <TalkToProvider />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-talk-to-provider-office"
            element={
              <RequireAuth>
                <TalkToProvider />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-medical-supplies"
            element={
              <RequireAuth>
                <MedicalSupplies />
              </RequireAuth>
            }
          />
          <Route
            path="/pat-settings"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="education-library/:id"
            element={<EducationLibraryArticle />}
          />
          <Route path="/pat-assessment" element={<Assessment />} />
          <Route path="pat-providers/:id" element={<ProviderDetails />} />
          <Route path="/pat-providers" element={<PatProviders />} />
        </Routes>
      </Layout>
    </Suspense>
  );
};

export default PatientApp;
