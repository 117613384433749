import React, { useEffect, useState } from "react";
import axios from "../../../../util/axiosInst";
import LoadingScreen from "./LoadingScreen";
import Image from "./Image";

const MediaPreview = ({
  fileName,
  type,
  className,
  blog,
  alt,
  height,
  width,
}) => {
  const [contents, setContents] = useState(null);
  const fetchContents = async () => {
    if (fileName) {
      const params = {
        fileName: fileName,
      };
      axios
        .get("/v1/s3/get-signed-url", { params })
        .then(({ data }) => {
          setContents(data.url);
        })
        .catch((error) => {
          setContents(null);
          console.error(error);
        });
    }
  };
  useEffect(() => {
    if (fileName) {
      fetchContents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);
  return (
    <>
      {contents && fileName ? (
        type === "image" ? (
          <Image
            src={contents}
            alt={blog ? "Provider Post" : alt}
            height={height}
            width={width}
            className={className || "w-100"}
          />
        ) : (
          <video controls className={className || ""}>
            <source src={contents}></source>
          </video>
        )
      ) : fileName ? (
        <LoadingScreen color="black" />
      ) : null}
    </>
  );
};
export default MediaPreview;
