import React, { useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Video from "./video/Video";
import Audio from "./audio/Audio";
import Chat from "./chat/Chat";
import { hideIntercomChatWidget } from "../../util/intercom";

const VirtualCare = () => {
  useEffect(() => {
    hideIntercomChatWidget();
  }, []);

  return (
    <Suspense>
      <ToastContainer limit={1} />
      <Routes>
        <Route
          exact
          path="/virtualcare/video/room/:URLRoomName"
          element={<Video />}
        />
        <Route
          exact
          path="/virtualcare/audio/room/:Token"
          element={<Audio />}
        />
        <Route exact path="/virtualcare/chat/room/:Token" element={<Chat />} />
      </Routes>
    </Suspense>
  );
};

export default VirtualCare;
