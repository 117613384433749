import { useLocation, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import MultiSelect from "../../../../components/common/MultiSelect";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DropdownButton } from "react-bootstrap";
import { education } from "./init";
import Image from "../custom/Image";

const MOPEducation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  //getting all muscle type for dropdown
  const getEducationArticles = useStoreActions(
    (actions) => actions.getEducationArticles
  );
  const [loading, setLoading] = useState(true);
  const getMuscleTypes = useStoreActions((actions) => actions.getMuscleTypes);
  const muscleType = useStoreState((state) => state.muscleTypes);
  const [array, setArray] = useState([]);

  const setPageNo = useStoreActions((actions) => actions.setPageNo);

  const currentPageNo = useStoreState(
    (state) => state.educationPagination.currentPageNo
  );
  const totalPages = useStoreState(
    (state) => state.educationPagination.totalPages
  );

  const educationArticles = useStoreState((state) =>
    state.educationArticles?.length ? state.educationArticles : education
  );

  const getEducationDetails = (id) => {
    setSelectedItems(id);
    setArray(id.map((map) => map.value));
    setTimeout(() => {
      document
        .querySelector("#education-card-mop .dropdown-menu")
        .classList.remove("show");
    }, 200);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    getMuscleTypes();
  }, [getMuscleTypes]);

  useEffect(() => {
    setPageNo(0);
  }, [setPageNo]);

  useEffect(() => {
    if (array.length > 0) {
      getEducationArticles({
        id: array.toString(),
        pageNo: currentPageNo + 1,
        search: search,
      });
    } else if (state?.search) {
      setSearch(state?.search);
      if ((state?.search).length > 5) {
        getEducationArticles({
          pageNo: currentPageNo + 1,
          search: state?.search,
        });
      }
    } else {
      getEducationArticles({ pageNo: currentPageNo + 1, search: search });
    }
  }, [array, currentPageNo, getEducationArticles, search, state?.search]);

  return (
    <>
      <div className="container pb-4">
        <div className="row px-md-2 px-2">
          <div className="my-2 col-md-4 col-10 d-flex pe-1 ps-1" id="search">
            <input
              name="search"
              value={search}
              placeholder="Search..."
              className="form-control  fs--18 padding-052rem"
              onChange={(e) => {
                setSearch(e.target.value);
                if (array.length > 0) {
                  getEducationArticles({
                    id: array.toString(),
                    pageNo: currentPageNo + 1,
                    search: e.target.value,
                  });
                } else {
                  getEducationArticles({
                    pageNo: currentPageNo + 1,
                    search: e.target.value,
                  });
                }
              }}
            />
            <i className="fa fa-solid  fs-5 fa-magnifying-glass padding-08rem" />
          </div>
          <div className="col-md-3 col-2 d-flex p-0">
            <div id="education-card-mop">
              <DropdownButton
                id="education-basic-button"
                title={
                  <i
                    className="fa fa-filter text-white bg-primary  fs-5 width-fit-content height-fit-content padding-08rem margintop--1px"
                    aria-hidden="true"
                  />
                }
              >
                <div className="px-3 py-2 mb-2">
                  <label className=" mb-1">Search Category</label>
                  <MultiSelect
                    isMulti={true}
                    onChangeHandler={(e) => {
                      getEducationDetails(e);
                    }}
                    options={muscleType}
                    name="name"
                    placeholder="Search Category"
                    value={selectedItems}
                    id="id"
                  />
                </div>
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-md-start text-center">
        <div className="col-12 ">
          <div className="row px-md-0 px-3">
            {educationArticles.length > 0 ? (
              educationArticles.map((item, index) => (
                <div
                  className="col-md-3 mb-4 col-12 cursor-pointer p-0 px-md-2"
                  key={index}
                >
                  <div className="bg-white mb-3 educational-animation rounded h-23 pb-3">
                    <div
                      className=" evaluate_img evaluate_img_education p-2 "
                      onClick={() => {
                        navigate(`/education-library/${item.slug}`, {
                          state: { evaluation: false },
                        });
                      }}
                    >
                      {!loading && item.coverImage?.length ? (
                        <Image
                          src={item.coverImage}
                          className="card-img-top  rounded  w-100"
                          alt={item.title}
                        />
                      ) : (
                        <Skeleton height={200} containerClassName="w-100" />
                      )}
                      <div className="evaluate_content  my-3 ms-2">
                        <h5 className=" text-body-tertiary fw-semibold mb-1">
                          {!loading ? (
                            item?.evaluationTypeId?.label
                          ) : (
                            <Skeleton />
                          )}
                        </h5>
                        <div className="fs-4 fw-700 ">
                          {!loading ? item.title : <Skeleton />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center my-5 fs-4">No Data Found..</div>
            )}
          </div>
        </div>
      </div>
      {!loading && educationArticles.length > 0 && (
        <div className="container px-md-5 px-5">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={(event) => setPageNo(event.selected)}
            pageRangeDisplayed={window.innerWidth < 769 ? 2 : 5}
            pageCount={totalPages}
            forcePage={currentPageNo}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      )}
    </>
  );
};

export default MOPEducation;
