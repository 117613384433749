import React, { useState, useEffect } from "react";
import { PAGE_VARIABLES } from "../../constants";
const Time = ({ register, name, tabIndex, ClinicRow, disabledEndTimes }) => {
  const [newUpdatedTime, setNewUpdatedTime] = useState(
    PAGE_VARIABLES?.COMMON?.TIME_VALUE
  );

  useEffect(() => {
    tabIndex &&
      tabIndex.forEach((element) => {
        if (
          element.clinic === ClinicRow.clinic &&
          element.businessHour === ClinicRow.businessHour
        ) {
          let tempArr = [];
          PAGE_VARIABLES?.COMMON?.TIME_VALUE.forEach((values, index) => {
            if (index > element?.loopIndex) {
              tempArr.push(values);
            }
          });
          setNewUpdatedTime(tempArr);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  return (
    <>
      <select className="form-select" {...register(name)}>
        {ClinicRow?.isStartTime === false
          ? newUpdatedTime &&
            newUpdatedTime.map((values, i) => (
              <option
                disabled={
                  disabledEndTimes
                    ? disabledEndTimes.includes(values)
                      ? true
                      : false
                    : false
                }
                value={values}
                key={i}
              >
                {values}
              </option>
            ))
          : PAGE_VARIABLES?.COMMON?.TIME_VALUE &&
            PAGE_VARIABLES?.COMMON?.TIME_VALUE.map((values, i) => (
              <option
                disabled={
                  disabledEndTimes
                    ? disabledEndTimes.includes(values)
                      ? true
                      : false
                    : false
                }
                key={i}
                value={values}
              >
                {values}
              </option>
            ))}
      </select>
    </>
  );
};

export default Time;
