import React from "react";
import Layout from "../layout/Layout";
import Footer from "../Home/Footer";

const ContactUs = () => {
  return (
    <Layout>
      <section className="container px-5">
        <h1 className="landing-display-1 text-center mt-3rem mt-4">
          Contact Us
        </h1>
        <p
          className="text-center mb-4"
          style={{
            color: "rgb(166, 169, 174)",
            fontSize: "18px",
          }}
        >
          We're here to help.
        </p>
        <h2 className="text-primary fw-700 pt-2 text-center mb-4">
          You're got questions? We've got answers.
        </h2>
        <div
          class="rounded col-sm-8 mx-auto mb-2 bg-white py-3 px-1 border border-3"
          style={{ borderColor: "#f8f8f8" }}
        >
          <form>
            <div className="row mx-auto mb-1">
              <div className="mb-3 mt-1 col-6 col-sm-6">
                <input
                  type="text"
                  className="form-control py-1 border-0"
                  placeholder="Name"
                  style={{ background: "#f8f8f8" }}
                />
              </div>
              <div className="mb-3 mt-1 col-6 col-sm-6">
                <input
                  type="text"
                  className="form-control py-1 border-0"
                  placeholder="Email"
                  style={{ background: "#f8f8f8" }}
                />
              </div>
            </div>
            <div class="row mx-auto mb-1">
              <div class="col-12">
                <textarea
                  class="form-control border-0"
                  rows="7"
                  placeholder="Message"
                  style={{ background: "#f8f8f8", resize: "none" }}
                />
              </div>
            </div>
          </form>
        </div>
        <button
          className="text-white btn mt-4 btn-primary rounded-pill px-5 d-flex mx-auto fw-bold text-nowrap mb-5"
          type="button"
        >
          Submit
        </button>
        <h2 className="text-primary fw-700 pt-5 text-center pb-3">
          Birmingham Headquarters
        </h2>
        <div className="d-flex justify-content-center align-items-center mb-5">
          <div>
            <i className="fas fa-map-marker-alt text-primary me-4 fs-4"></i>
          </div>
          <div>
            <h4 className="text-black pt-2 fw-semibold">
              2839 Culver Road, #202
              <br />
              Birmingham, AL 35223
            </h4>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default ContactUs;
