import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import Logout from '../../../assets/images/logout.svg';
import Logo from '../../../assets/images/logo_v3.svg';
import MenuBar from '../../../assets/images/menu-bars.svg';
import { logOutUser } from '../../../../util/utils';

import { ReactComponent as Home } from '../../../assets/provider-icons/1.svg';
import { ReactComponent as Notification } from '../../../assets/provider-icons/2.svg';
import { ReactComponent as Appointment } from '../../../assets/provider-icons/3.svg';
import { ReactComponent as Patients } from '../../../assets/provider-icons/4.svg';
import { ReactComponent as Schedule } from '../../../assets/provider-icons/5.svg';
import { ReactComponent as Messages } from '../../../assets/provider-icons/6.svg';
import { ReactComponent as Calls } from '../../../assets/provider-icons/7.svg';
import { ReactComponent as Referrals } from '../../../assets/provider-icons/8.svg';
import { ReactComponent as Analytics } from '../../../assets/provider-icons/9.svg';
import { ReactComponent as Settings } from '../../../assets/provider-icons/10.svg';
import axios from '../../../../util/axiosInst';
import { socket } from '../../../../util/socketInst';

const SideBar = () => {
  const userData = JSON.parse(localStorage.getItem('mop-user'));
  const navigate = useNavigate();
  const location = useLocation();
  const [collapse, setCollapse] = useState(true);
  const newProviderHeader = [
    {
      pathname: '/pro-home',
      img: (
        <Home
          style={{
            stroke: window.location.pathname.includes('pro-home') ? '#0071FF' : '#333131',
          }}
          width="22px"
          height="22px"
          alt="home-icon"
        />
      ),
      label: 'Home',
    },
    {
      pathname: '/pro-notifications',
      img: (
        <Notification
          style={{
            stroke: window.location.pathname.includes('pro-notifications') ? '#0071FF' : '#333131',
          }}
          width="22.5px"
          height="22px"
          alt="home-icon"
        />
      ),
      label: 'Notifications',
    },
    {
      pathname: '/pro-appointment',
      img: (
        <Appointment
          style={{
            stroke: window.location.pathname.includes('pro-appointment') ? '#0071FF' : 'black',
            fill: window.location.pathname.includes('pro-appointment') ? '#0071FF' : 'black',
          }}
          width="22px"
          height="22px"
          alt="home-icon"
        />
      ),
      label: 'Appointments',
    },
    {
      pathname: '/pro-patients',
      img: (
        <Patients
          style={{
            stroke: window.location.pathname.includes('pro-patients') ? '#0071FF' : '#333131',
          }}
          width="22px"
          height="22px"
        />
      ),
      label: 'Patients',
    },
    {
      pathname: '/pro-schedule',
      img: (
        <Schedule
          style={{
            stroke: window.location.pathname.includes('pro-schedule') ? '#0071FF' : '#333131',
          }}
          width="22px"
          height="22px"
          alt="home-icon"
        />
      ),
      label: 'Schedule',
    },
    {
      pathname: '/pro-provider-messages',
      img: (
        <Messages
          style={{
            stroke: window.location.pathname.includes('pro-provider-messages') ? '#0071FF' : '#333131',
          }}
          width="22px"
          height="22px"
          alt="home-icon"
        />
      ),
      label: 'Messages',
    },
    {
      pathname: '/pro-calls',
      img: (
        <Calls
          style={{
            stroke: window.location.pathname.includes('pro-calls') ? '#0071FF' : '#333131',
          }}
          width="22px"
          height="22px"
          alt="home-icon"
        />
      ),
      label: 'Calls',
    },
    {
      pathname: '/pro-referrals',
      img: (
        <Referrals
          style={{
            stroke: window.location.pathname.includes('pro-referrals') ? '#0071FF' : '#333131',
          }}
          width="22px"
          height="22px"
        />
      ),
      label: 'Referrals',
    },
    {
      pathname: '/pro-analytics',
      img: (
        <Analytics
          style={{
            stroke: window.location.pathname.includes('pro-analytics') ? '#0071FF' : '#333131',
          }}
          width="22px"
          height="22px"
        />
      ),
      label: 'Analytics',
    },
    {
      pathname: '/pro-settings',
      img: (
        <Settings
          style={{
            stroke: window.location.pathname.includes('pro-settings') ? '#0071FF' : '#333131',
          }}
          width="22px"
          height="22px"
          alt="home-icon"
        />
      ),
      label: 'Settings',
    },
  ];

  const [notificationcount, setnotificationcount] = useState(socket.connected);

  const getnotificationcount = async () => {
    console.log('getnotificationcount');
    const { data } = await axios.get(`/v1/notification/unread`);
    setnotificationcount(data?.unReadCount);
  };

  const SocketDataHandler = (socketData) => {
    console.log('SocketDataHandler', socketData);
    if (socketData && socketData?.type) {
      if (socketData?.type === 'notification') {
        getnotificationcount();
      }
    }
  };

  useEffect(() => {
    socket.on('notification', SocketDataHandler);
    const USERID = (userData && (userData.userId || userData._id)) || null;
    if (USERID) {
      socket.on(String(USERID), SocketDataHandler);
      getnotificationcount();
    }
    return () => {
      socket.off('notification', SocketDataHandler);
      if (USERID) {
        socket.off(String(USERID), SocketDataHandler);
      }
    };
  }, []);

  return (
    <div className="d-flex flex-column sticky-top top-0 bg-white border vh-100 border-top-0 border-bottom-0 border-left-0">
      {collapse ? (
        <img alt="mop-logo" src={Logo} className="mt-2 ms-2" width="150px" onClick={() => setCollapse(false)} />
      ) : (
        <img
          alt="menu-bars"
          src={MenuBar}
          className="mt-2 cursor-pointer"
          style={{ marginLeft: '10px' }}
          local={true}
          width="30px"
          onClick={() => setCollapse(true)}
        />
      )}
      <div className="d-flex flex-column justify-content-between h-100  mt-2">
        <ul className="nav nav-pills text-nowrap d-sm-flex d-none flex-column mb-auto">
          {newProviderHeader.map((item) => (
            <li className="nav-item p-0">
              <NavLink
                className="d-flex align-items-center fw-light"
                onClick={() => navigate(item?.pathname)}
                style={{ margin: '0.13rem 0 0.13rem 0' }}
              >
                {item.label === 'Notifications' ? (
                  <span style={{ position: 'relative' }}>
                    {item?.img}
                    <span
                      className="bg-primary rounded-circle fontweight-bold text-white"
                      style={{
                        padding: '2px 4px',
                        fontSize: '9px',
                        position: 'absolute',
                        top: '-10px',
                        left: '17px',
                      }}
                    >
                      {notificationcount}
                    </span>
                  </span>
                ) : (
                  item?.img
                )}
                {collapse && (
                  <h6
                    className={`ms-3 mb-0 fs-6 fontweight-bold ${
                      location.pathname.includes(item?.pathname) ? 'text-primary' : 'text-black'
                    } `}
                  >
                    {item.label}
                  </h6>
                )}
              </NavLink>
            </li>
          ))}
          <li className="position-fixed bottom-0">
            <NavLink onClick={logOutUser} className="d-flex align-items-center fw-light mt-5">
              <img src={Logout} local={true} width="22px" height="22px" alt="logout" />
              {collapse && <h6 className="ms-3 mt-2 fs-6 fontweight-bold">Log out</h6>}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
