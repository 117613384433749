import React from 'react';
import { ReactComponent as DefaultUser } from '../../assets/images/Vector.svg';

const DefaultUserLogo = ({ height, width, className, style = {} }) => {
  return (
    <DefaultUser
      className={`me-2 ${className}`}
      style={{
        background: '#EBF5FF',
        strokeWidth: '10',
        borderRadius: '25px',
        padding: '8px 0px',
        overflow: 'visible',
        ...style,
      }}
      height={height ? height : '35px'}
      width={width ? width : '35px'}
    />
  );
};

export default DefaultUserLogo;
