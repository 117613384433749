import React from "react";
import { isEmpty } from "lodash";
import { formatPhoneNumbers } from "../util/utils";

const BusinessInfo = ({ providerDetail }) => {
  const { business_info, phoneNumber } = providerDetail;

  const mapAddress = !isEmpty(business_info?.address)
    ? `https://maps.google.com/maps?q=${encodeURIComponent(
        `${business_info.address.replace("#", "")}, ${business_info.city}, ${
          business_info.state
        }, ${business_info?.zipcode}`
      )}&t=&z=15&ie=UTF8&iwloc=1&output=embed`
    : "";

  return (
    <div className="row bg-white p-3 rounded-1">
      <div className="col-sm-6">
        {phoneNumber && <h4 className="text-black fw-semibold">Contact</h4>}
        {phoneNumber && (
          <p className="mt-4 d-flex">
            <i className="fas fa-phone text-primary me-3 fs-3"></i>
            <a
              href={`tel:+ ${formatPhoneNumbers(phoneNumber)}`}
              className="d-flex text-decoration-none text-black"
            >
              {formatPhoneNumbers(phoneNumber)}
            </a>
          </p>
        )}
        {(!isEmpty(business_info?.address) ||
          !isEmpty(business_info?.city) ||
          !isEmpty(business_info?.state) ||
          !isEmpty(business_info?.zipcode)) && (
          <h4 className="text-black fw-semibold">Location</h4>
        )}
        {(!isEmpty(business_info?.address) ||
          !isEmpty(business_info?.city) ||
          !isEmpty(business_info?.state) ||
          !isEmpty(business_info?.zipcode)) && (
          <p className="d-flex mt-4">
            <i className="fas fa-map-marker-alt text-primary me-3 fs-3"></i>
            {business_info?.address && (
              <>
                {business_info?.address} <br />
              </>
            )}
            {business_info?.city && business_info?.city + ","}{" "}
            {business_info?.state && business_info?.state + ","}{" "}
            {business_info?.zipcode && (
              <>
                {business_info?.zipcode} <br />
              </>
            )}
          </p>
        )}
      </div>

      <div className="col-sm-6">
        {!isEmpty(mapAddress) && (
          <iframe
            src={mapAddress}
            width="100%"
            height="100%"
            className="border-0"
            title="address"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default BusinessInfo;
