import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const HelmetTag = ({
  title,
  content,
  image,
  imageAlt,
  url,
  canonical,
  JSONLD,
}) => (
  <Helmet>
    {/* <!-- HTML Meta Tags --> */}
    <title>
      {title || "Talk to an Orthopedic Provider 24/7 | My Orthopedic Problem"}
    </title>
    <meta
      name="title"
      content={
        title || "Talk to an Orthopedic Provider 24/7 | My Orthopedic Problem"
      }
    />
    <meta
      name="description"
      content={
        content ||
        "My Orthopedic Problem assists patients in speeding up their orthopedic care with patient education, symptom evaluation tools, and links to top local providers."
      }
    />

    {/* <!-- Google / Search Engine Tags --> */}
    <meta
      itemprop="name"
      content={
        title || "Talk to an Orthopedic Provider 24/7 | My Orthopedic Problem"
      }
    />
    <meta
      itemprop="description"
      content={
        content ||
        "My Orthopedic Problem assists patients in speeding up their orthopedic care with patient education, symptom evaluation tools, and links to top local providers."
      }
    />
    <meta
      itemprop="image"
      content={
        image || "https://myorthopedicproblem.com/MyOrthopedicProblem.png"
      }
    />
    <script type="application/ld+json">{JSONLD}</script>
    <link rel="canonical" href={canonical}></link>
    {/* <!-- Facebook Meta Tags --> */}
    <meta
      property="og:url"
      content={url || "https://myorthopedicproblem.com"}
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:title"
      content={
        title || "Talk to an Orthopedic Provider 24/7 | My Orthopedic Problem"
      }
    />
    <meta
      property="og:description"
      content={
        content ||
        "My Orthopedic Problem assists patients in speeding up their orthopedic care with patient education, symptom evaluation tools, and links to top local providers."
      }
    />
    <meta
      property="og:image"
      content={
        image || "https://myorthopedicproblem.com/MyOrthopedicProblem.png"
      }
    />

    {/* <!-- Twitter Meta Tags --> */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:title"
      content={
        title || "Talk to an Orthopedic Provider 24/7 | My Orthopedic Problem"
      }
    />
    <meta
      name="twitter:description"
      content={
        content ||
        "My Orthopedic Problem assists patients in speeding up their orthopedic care with patient education, symptom evaluation tools, and links to top local providers."
      }
    />
    <meta
      name="twitter:image"
      content={
        image || "https://myorthopedicproblem.com/MyOrthopedicProblem.png"
      }
    />
    <meta name="twitter:image:alt" content={imageAlt} />
    <meta name="twitter:creator" content="@clinekthealth" />
    <meta name="twitter:site" content="@clinekthealth" />
  </Helmet>
);

HelmetTag.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  url: PropTypes.string,
  canonical: PropTypes.string,
  JSONLD: PropTypes.string,
};

export default HelmetTag;
