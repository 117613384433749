import React from "react";
import FileMessage from "./FileMessage";

const SendersMessage = ({ conversation, formatDateTime }) => {
  return (
    <div className="row mt-3">
      <div className="col-sm-12 col-12 d-flex justify-content-end pe-3 ">
        <div
          className="mb-1 d-flex justify-content-end rounded bg-primary text-white"
          style={{
            backgroundColor: "#e9ecef",
            padding: "8px 12px",
          }}
        >
          <div className="d-flex cards ">
            {conversation?.message?.type === "text" ? (
              <div className="text-end p-1">
                <p className="m-0 pb-1 text-start" style={{ fontSize: "10px" }}>
                  {conversation?.postedByUser?.name}{" "}
                  <span className="float-right ms-3">
                    {formatDateTime(conversation?.createdAt)}
                  </span>
                </p>
                <p
                  className="mb-0 text-start wordBreak-break-word"
                  style={{ fontSize: "14px" }}
                >
                  {conversation?.message?.message}
                </p>
              </div>
            ) : (
              <FileMessage
                conversation={conversation}
                formatDateTime={formatDateTime}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendersMessage;
