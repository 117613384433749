import React, { useState } from "react";
import { TiAttachment } from "react-icons/ti";
import { IoMdSend } from "react-icons/io";
import { DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import ReactS3Uploader from "react-s3-uploader";

import { useAppState } from "../state";

const MessageFooter = ({ socket }) => {
  const {
    roomId,
    userID,
    userName,
    sendChatMessage,
    getSignedUrl,
    putSignedUrl,
  } = useAppState();

  const [chatMessage, setChatMessage] = useState("");
  const [isMedia, setIsMedia] = useState({ isMedia: false, type: "" });
  const [mediaFile, setMediaFile] = useState({
    file: "",
    fileName: "",
    type: "",
  });
  let timeout = undefined;

  function typingTimeout() {
    socket.emit("typing", { userID, userName, roomId, typing: false });
  }

  const setTyping = () => {
    if (chatMessage.length) {
      socket.emit(`typing`, {
        typing: true,
        userID,
        userName,
        roomId,
      });
    }
    clearTimeout(timeout);
    timeout = setTimeout(typingTimeout, 1500);
  };

  const handleChange = (event) => {
    setChatMessage(event.target.value);
    event.preventDefault();
  };

  const handleKeyPress = (event) => {
    setTyping();
    event.preventDefault();
  };

  const clearChatMessage = () => {
    setChatMessage("");
    setIsMedia({
      isMedia: false,
      type: "",
    });
    setMediaFile({ file: "", fileName: "", type: "" });
    clearTimeout(timeout);
    typingTimeout();
  };

  const sendMessage = (event) => {
    if (chatMessage.length === 0) {
      toast.error("Please enter a message");
      return;
    }
    if (chatMessage.length > 500) {
      toast.error("Message is too long");
      return;
    }
    const type = isMedia.isMedia ? isMedia.type : "text";
    const payload = {
      roomId: roomId,
      message: chatMessage,
      type: type,
      title: "",
      postedUserId: userID,
    };
    sendChatMessage(payload);
    clearChatMessage();
    event.preventDefault();
  };

  const sendDocument = (event) => {
    const type = isMedia.isMedia ? isMedia.type : "text";
    let payload = {
      roomId: roomId,
      message: mediaFile.file,
      type: type,
      title:
        mediaFile.type === "image" || mediaFile.type === "video"
          ? ""
          : mediaFile.fileName,
      postedUserId: userID,
    };
    sendChatMessage(payload);
    clearChatMessage();
    event.preventDefault();
  };

  const sendButtonHandler = (event) => {
    if (mediaFile.type !== "" && mediaFile.type !== "text") {
      sendDocument(event);
    } else {
      sendMessage(event);
    }
  };

  const FileUploadComponent = ({ accept, item }) => {
    return (
      <div className=" mb-3">
        {item}
        <div className="upload-control">
          <ReactS3Uploader
            accept={accept}
            id={mediaFile.fileName}
            className="d-none"
            getSignedUrl={putSignedUrl}
            signingUrlWithCredentials={true}
            uploadRequestHeaders={{}}
            onFinish={(result, file) => {
              getSignedUrl({
                fileName: `/contents/${file.name}`,
              }).then((data) => {
                const type =
                  file.type.split("/")[0] === "text"
                    ? file.name.split(".")[1]
                    : file.type.split("/")[0];
                let payload = {
                  roomId: roomId,
                  message: data.url,
                  type: type,
                  title: type === "image" || type === "video" ? "" : file.name,
                  postedUserId: userID,
                };
                sendChatMessage(payload);
                clearChatMessage();
              });
            }}
            scrubFilename={() =>
              mediaFile.fileName.replace(/[^\w\d_\-.]+/gi, "")
            }
            autoUpload={true}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div id="file-upload" className="d-flex h-50">
        <DropdownButton
          key="up"
          id="dropdown-button-drop-up"
          drop="up"
          variant="white"
          className="d-flex"
          title={<TiAttachment className="width-25px height-25px" />}
        >
          <div id="file-icon">
            <span className="text-primary ">
              <label role="button">
                <FileUploadComponent
                  fileName={"image"}
                  accept={"image/*,video/*"}
                  item={
                    <i
                      className="fa fa-image bg-primary text-white fs-3 mx-3  p-3 me-2  rounded-circle"
                      onClick={() =>
                        document
                          .querySelector("#file-upload .dropdown-menu ")
                          .classList.remove("show")
                      }
                    />
                  }
                />
              </label>
            </span>
          </div>
          <div id="file-icon">
            <span className="text-primary ">
              <label role="button">
                <FileUploadComponent
                  fileName={"doc"}
                  accept={
                    ".pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  }
                  item={
                    <i
                      className="fa fa-file bg-primary text-white fs-3  p-3  rounded-circle"
                      onClick={() =>
                        document
                          .querySelector("#file-upload .dropdown-menu ")
                          .classList.remove("show")
                      }
                    />
                  }
                />
              </label>
            </span>
          </div>
        </DropdownButton>
      </div>
      <input
        type="text"
        className="form-control pt-2 h-50 bg-light chatInput border-0 borderradius-22px"
        placeholder="Type your message here ..."
        value={chatMessage}
        onChange={handleChange}
        onKeyUp={(e) =>
          e.key === "Enter" ? sendMessage(e) : handleKeyPress(e)
        }
      />
      <button
        type="button"
        id="dropdown-button-drop-up"
        aria-expanded="false"
        className="dropdown-toggle btn btn-white"
      >
        <IoMdSend
          className="width-25px height-25px"
          onClick={(e) => sendButtonHandler(e)}
        />
      </button>
    </React.Fragment>
  );
};

export default MessageFooter;
