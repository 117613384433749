import React from "react";
import Select from "react-select";

const MultiSelect = ({
  options,
  id,
  onChangeHandler,
  value,
  placeholder,
  onInputChange,
  onFocus,
  isMulti,
  name,
}) => {
  const providerOptions =
    options?.length &&
    options?.map((item) => {
      return {
        value: item[id],
        label: item[name],
      };
    });

  return (
    <Select
      options={providerOptions}
      placeholder={
        <>
          <i className="fa fa-solid fa-magnifying-glass pe-2"></i>
          {placeholder}
        </>
      }
      isMulti={isMulti}
      onFocus={onFocus}
      onChange={onChangeHandler}
      onInputChange={onInputChange}
      value={value}
    />
  );
};

export default MultiSelect;
