import React from "react";
import { isEmpty } from "lodash";
import { formatPhoneNumbers } from "../util/utils";
const RehabInfo = ({ providerDetail }) => {
  let mapAddress;
  if (!isEmpty(providerDetail?.rehab_info?.address)) {
    mapAddress =
      "https://maps.google.com/maps?q=" +
      providerDetail?.rehab_info?.address.replace(`#`, "") +
      providerDetail?.rehab_info?.city +
      providerDetail?.rehab_info?.state +
      providerDetail?.rehab_info?.zipcode +
      "&t=&z=15&ie=UTF8&iwloc=1&output=embed";
  }
  return (
    <>
      <div className="row bg-white p-3 rounded-1">
        <div className="col-sm-6">
          {providerDetail?.rehab_info?.phoneNumber && (
            <h4>
              <span className="text-black fw-semibold">Contact</span>
            </h4>
          )}
          {providerDetail?.rehab_info?.phoneNumber && (
            <p className="d-flex mt-4">
              <i className="fas fa-phone text-primary me-3 fs-3"></i>
              <a
                href={`tel:+ ${formatPhoneNumbers(
                  providerDetail?.rehab_info?.phoneNumber
                )}`}
                className="d-flex text-decoration-none text-black"
              >
                {providerDetail?.rehab_info?.phoneNumber
                  ? `${formatPhoneNumbers(
                      providerDetail?.rehab_info?.phoneNumber
                    )}`
                  : ""}
              </a>
            </p>
          )}
          {(!isEmpty(providerDetail?.rehab_info?.address) ||
            !isEmpty(providerDetail?.rehab_info?.city) ||
            !isEmpty(providerDetail?.rehab_info?.state) ||
            !isEmpty(providerDetail?.rehab_info?.zipcode)) && (
            <h4>
              <span className="text-black fw-semibold">Location</span>
            </h4>
          )}
          {(!isEmpty(providerDetail?.rehab_info?.address) ||
            !isEmpty(providerDetail?.rehab_info?.city) ||
            !isEmpty(providerDetail?.rehab_info?.country) ||
            !isEmpty(providerDetail?.rehab_info?.state) ||
            !isEmpty(providerDetail?.rehab_info?.zipcode)) && (
            <p className="d-flex mt-4 ">
              <p>
                <i className="fas fa-map-marker-alt text-primary me-3 fs-3"></i>
              </p>
              {providerDetail?.rehab_info?.address && (
                <>
                  {providerDetail?.rehab_info?.address} <br />
                </>
              )}
              {providerDetail?.rehab_info?.city &&
                providerDetail?.rehab_info?.city + ","}{" "}
              {providerDetail?.rehab_info?.state &&
                providerDetail?.rehab_info?.state + ","}{" "}
              {providerDetail?.rehab_info?.zipcode && (
                <>
                  {providerDetail?.rehab_info?.zipcode} <br />
                </>
              )}
            </p>
          )}
        </div>

        <div className="col-sm-6  ">
          {!isEmpty(mapAddress) && (
            <iframe
              src={mapAddress}
              width="100%"
              height="100%"
              title="address"
              className="border-0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          )}
        </div>
      </div>
    </>
  );
};

export default RehabInfo;
