import React from "react";
import AthleticTrainersProfile from "../../form/athletic-trainer/AthleticTrainersProfile";
import { Button } from "react-bootstrap";
import axios from "axios";
import ProviderProfile from "../../form/basic-provider-form/ProviderProfile";
import ClinicProfile from "../../form/clinic-form/ClinicProfile";
import GymProfile from "../../form/gym-form/GymProfile";
import HospitalProfile from "../../form/HospitalForm/HospitalProfile";
import RehabilitationCenterProfile from "../../form/RehabilitationCenterForm/RehabilitationCenterProfile";
import PhysicalTherapistProfile from "../../form/PhysicalTherapistForm/PhysicalTherapistProfile";
import PersonalProviderProfile from "../../form/PersonalTrainer/PersonalProviderProfile";
import WellnessProviderProfile from "../../form/WellnessProvider/WellnessProviderProfile";
import { useNavigate } from "react-router-dom";

const FinishYourApplication = ({ useFormControl, signupData }) => {
  const { setValue, watch, handleSubmit } = useFormControl;
  const navigate = useNavigate();
  const submitHandler = () => {
    if (watch("day") && watch("month") && watch("year"))
      setValue(
        "dob",
        new Date(
          `${watch("day")}-${watch("month")}-${watch("year")}`
        ).toISOString()
      );
    setValue("day", null);
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/v1/provider/${signupData?.provider?.id}`,
        {
          ...watch(),
          userId: signupData?.user?._id,
          active: false,
        },
        {
          headers: {
            Authorization: `Bearer ${signupData?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) =>
        navigate("/subscription", {
          state: {
            ...signupData,
            role: signupData.user.role,
          },
        })
      );
  };
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="container px-5">
        <div className="px-5">
          <div className="my-4 bg-white pb-4 shadow-lg rounded">
            {watch("userType") === "athletic_trainers" ? (
              <AthleticTrainersProfile
                useFormControl={useFormControl}
                signupData={signupData}
              />
            ) : watch("userType") === "clinic" ? (
              <ClinicProfile
                useFormControl={useFormControl}
                signupData={signupData}
              />
            ) : watch("userType") === "gym" ? (
              <GymProfile
                useFormControl={useFormControl}
                signupData={signupData}
              />
            ) : watch("userType") === "hospital" ? (
              <HospitalProfile
                useFormControl={useFormControl}
                signupData={signupData}
              />
            ) : watch("userType") === "rehabilitation_center" ? (
              <RehabilitationCenterProfile
                useFormControl={useFormControl}
                signupData={signupData}
              />
            ) : watch("userType") === "personal_trainer" ? (
              <PersonalProviderProfile
                useFormControl={useFormControl}
                signupData={signupData}
              />
            ) : watch("userType") === "chiropractor" ||
              watch("userType") === "physical_therapist" ? (
              <PhysicalTherapistProfile
                useFormControl={useFormControl}
                signupData={signupData}
              />
            ) : watch("userType") === "wellness_provider" ||
              watch("userType") === "massage_therapist" ? (
              <WellnessProviderProfile
                useFormControl={useFormControl}
                signupData={signupData}
              />
            ) : (
              <ProviderProfile
                useFormControl={useFormControl}
                signupData={signupData}
              />
            )}
            <Button
              className="rounded-pill text-white px-3 w-50 mt-3 d-block mx-auto"
              variant="primary"
              type="submit"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FinishYourApplication;
