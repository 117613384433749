import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import axios from "../utils/axiosInst";
import ReactSelect from "react-select";
import { ImSearch } from "react-icons/im";

const CreateConversation = ({
  getConverserationByRoomId,
  setCurrentUser,
  setStart,
  type,
  setRoomId,
}) => {
  const [providerList, setProviderList] = useState([]);
  const [conversationProvider, setConversationProvider] = useState([]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "0px",
      maxHeight: "50px", // Set the maximum height for the input field
      overflowY: "auto", // Enable vertical scrolling if the content exceeds the height
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: "12px", // Set the desired font size for the selected value
    }),
  };
  const searchProvider = (e) => {
    if (e?.length >= 3) {
      axios.get(`/v1/provider/all?active=1&name=/^${e}/`).then((response) => {
        const result = response?.data?.results?.map((map) => {
          return {
            ...map,
            value: map.id,
            label: map.name,
          };
        });
        setProviderList(result);
      });
    }
  };

  const chatInitiate = () => {
    axios
      .post("/v1/chat/initiate", {
        userIds: [
          conversationProvider[0]?.userId || conversationProvider?.userId,
        ],
        type: `patient-to-provider`,
        roomType: "chat",
      })
      .then((res) => {
        getConverserationByRoomId(res.data.chatRoom?.chatRoomId);
        if (conversationProvider[0]) {
          setCurrentUser({
            name: conversationProvider[0]?.name,
            userId: conversationProvider[0]?.userId,
            roomType: "single",
          });
        } else {
          setCurrentUser({
            name: conversationProvider?.name,
            userId: conversationProvider?.userId,
            roomType: "single",
          });
        }
        setStart(false);
        setRoomId(res.data.chatRoom?.chatRoomId);
      });
  };

  return (
    <div className="bg-white pt-3 border-bottom border-2">
      <div className="d-flex justify-content-between">
        <ReactSelect
          options={providerList}
          placeholder={
            <>
              <ImSearch />
              <span className="ps-2"> Search a provider</span>
            </>
          }
          isMulti={type === "provider" ? true : false}
          className="ms-3 mw-240px w-75 h5 cursor-pointer"
          onChange={(e) => {
            setConversationProvider(e);
          }}
          onFocus={() => setProviderList([])}
          isClearable={false}
          onInputChange={searchProvider}
          value={conversationProvider}
          styles={customStyles}
        />
        <FaCheck
          className="text-success m-3 h5 mt-2 cursor-pointer"
          onClick={chatInitiate}
        />
      </div>
    </div>
  );
};

export default CreateConversation;
