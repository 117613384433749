import React from "react";
import { get } from "lodash";

const TextInput = ({
  errors,
  register,
  label,
  name,
  id,
  mandatory,
  inputClassName,
  maxlength,
  placeholder,
  disabled,
  style,
  isEmail,
  type,
  dataChange,
  setVideoUrl,
  className,
}) => {
  return (
    <>
      {mandatory ? (
        <>
          {label ? (
            <label htmlFor={name} className={`form-label ${className}`}>
              {label} <span className="text-danger">*</span>
            </label>
          ) : (
            <></>
          )}
          <input
            maxLength={maxlength}
            id={id ? id : name}
            placeholder={placeholder}
            type={type ? type : "text"}
            className={`form-control ${inputClassName} ${
              get(errors, name) && "is-invalid"
            }`}
            style={style}
            disabled={disabled}
            {...register(name)}
          />
          <div className="invalid-feedback">{get(errors, name)?.message}</div>
        </>
      ) : (
        <>
          {label ? (
            <label htmlFor={name} className={`form-label ${className}`}>
              {label}
            </label>
          ) : (
            <></>
          )}
          <input
            maxLength={maxlength}
            id={id ? id : name}
            placeholder={placeholder}
            type={type}
            className={`form-control ${inputClassName} ${
              get(errors, name) && "is-invalid"
            }`}
            disabled={disabled}
            style={style}
            {...register(name, {
              onChange: (e) => {
                if (setVideoUrl) {
                  if (e.target.value !== "") {
                    setVideoUrl(true);
                  } else {
                    setVideoUrl(false);
                  }
                }
                if (isEmail) {
                  dataChange(name, e.target.value);
                }
              },
            })}
          />
          <div className="invalid-feedback">{get(errors, name)?.message}</div>
        </>
      )}
    </>
  );
};

export default TextInput;
