import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";

const Radio = ({ question, options, prompt, data }) => {
  const answers = useStoreState((state) => state.answers);
  const setAnswer = useStoreActions((actions) => actions.setAnswer);

  return (
    <div className="text-center my-md-0 my-5" key={question}>
      <h3 className="fw-semibold"> {question}</h3>
      <p>*Select all that apply</p>
      <div className="choose-options">
        {options.map((option, i) => (
          <div className="options" key={i}>
            <div className="form-group checkbox">
              <button
                className={`symptom-checker fw-semibold me-5 ${
                  answers[prompt] && answers[prompt].includes(option.value)
                    ? "btn btn-primary text-white"
                    : "btn btn-outline-primary text-black"
                } ${window.innerWidth > 768 ? "w-50" : "w-100"} rounded h-50px`}
                onClick={(e) => {
                  e.preventDefault();
                  setAnswer({
                    prompt,
                    answer: option.value,
                    type: "checkbox",
                    data,
                  });
                }}
              >
                {option.label}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Radio;
