import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  FaUser,
  FaCalendar,
  FaGraduationCap,
  FaFile,
  FaDumbbell,
  FaCertificate,
  FaHistory,
  FaUsersCog,
  FaRegCreditCard,
} from "react-icons/fa";
import { IoLinkSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import VideoPlayer from "../VideoPlayer";
import { remove } from "lodash";

import axios from "../../util/axiosInst";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { HiOutlineIdentification } from "react-icons/hi";
import { MdOutlineGrade, MdOutlineFolderSpecial } from "react-icons/md";
import { BsPersonCircle } from "react-icons/bs";
import { FcOrganization } from "react-icons/fc";
import { FaMapMarkerAlt } from "react-icons/fa";

const HomeTab = ({ providerDetail }) => {
  let error = 0;
  const [newError, setNewError] = useState(0);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [errror, setError] = useState(false);
  remove(
    providerDetail.certifications,
    (obj) => obj.label === "" || !obj.label?.length
  );

  const education = (
    <div className="d-flex">
      <div>
        {providerDetail?.education &&
          providerDetail?.education.length &&
          (providerDetail?.education[0]?.degree !== "" ||
            providerDetail?.education[0]?.college !== "" ||
            providerDetail?.education[0]?.completionyear !== "") && (
            <h6>
              <FaGraduationCap className="me-2 text-primary align-self-center -mt-3 width-30px height-30px" />
            </h6>
          )}
      </div>
      <div>
        {providerDetail?.education &&
          providerDetail?.education.length &&
          (providerDetail?.education[0]?.degree !== "" ||
            providerDetail?.education[0]?.college !== "" ||
            providerDetail?.education[0]?.completionyear !== "") && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Education Library
              </span>
            </h6>
          )}
        <p>
          {providerDetail.education?.map((row, index) => {
            if (
              providerDetail?.education[0]?.degree !== "" ||
              providerDetail?.education[0]?.college !== "" ||
              providerDetail?.education[0]?.completionyear !== ""
            ) {
              error = error + 1;
            }
            return (
              <>
                {(row.college !== "" ||
                  row.degree !== "" ||
                  row?.completionyear !== "") && (
                  <li key={index}>
                    <span className="text-capitalize">{row.college}</span>
                    {row.college &&
                      (row?.degree || row?.completionyear) &&
                      " - "}
                    {row?.degree}{" "}
                    {row?.completionyear && `(${row?.completionyear})`}
                  </li>
                )}
              </>
            );
          })}
        </p>
      </div>
    </div>
  );
  const program = (
    <div className="d-flex">
      <div>
        {providerDetail.programs &&
          providerDetail.programs.length &&
          providerDetail.programs[0]?.programName !== "" && (
            <h6>
              <FaGraduationCap className="me-2 text-primary align-self-center -mt-3 width-30px height-30px" />
            </h6>
          )}
      </div>
      <div>
        <h6>
          <span className="text-black fs-5 fw-bold ms-1">Programs</span>
        </h6>
        <p>
          {providerDetail.programs?.map((row, key) => {
            if (providerDetail.programs[0]?.programName !== "") {
              error = error + 1;
            }
            return (
              <li key={key}>
                <span className="text-capitalize">{row.programName}</span>
              </li>
            );
          })}
        </p>
      </div>
    </div>
  );

  const affiliations = (
    <div className="d-flex">
      <div>
        {providerDetail.userType === "primary_care" ||
        providerDetail.userType === "orthopedic_surgeon" ||
        providerDetail.userType === "neuro_surgeon" ||
        providerDetail?.userType === "nurse_practitioner" ||
        providerDetail?.userType === "pain_management_specialist"
          ? providerDetail.affiliations &&
            providerDetail.affiliations.length &&
            (providerDetail.affiliations[0]?.hospital_name !== "" ||
              providerDetail.affiliations[0]?.hospital_city !== "") && (
              <h6>
                <FaCertificate className="me-2 text-primary align-self-center -mt-3 width-30px height-25px" />
              </h6>
            )
          : providerDetail.affiliations &&
            providerDetail.affiliations.length &&
            providerDetail.affiliations[0]?.affiliatedGymsName !== "" && (
              <h6>
                <FaCertificate className="me-2 text-primary align-self-center -mt-3 width-30px height-25px" />
              </h6>
            )}
      </div>
      <div>
        {providerDetail.userType === "primary_care" ||
        providerDetail.userType === "orthopedic_surgeon" ||
        providerDetail.userType === "neuro_surgeon" ||
        providerDetail?.userType === "nurse_practitioner" ||
        providerDetail?.userType === "pain_management_specialist"
          ? providerDetail.affiliations &&
            providerDetail.affiliations.length &&
            (providerDetail.affiliations[0]?.hospital_name !== "" ||
              providerDetail.affiliations[0]?.hospital_city !== "") && (
              <h6>
                <span className="text-black fs-5 fw-bold ms-1">
                  Affiliations
                </span>
              </h6>
            )
          : providerDetail.affiliations &&
            providerDetail.affiliations.length &&
            providerDetail.affiliations[0]?.affiliatedGymsName !== "" && (
              <h6>
                <span className="text-black fs-5 fw-bold ms-1">
                  Affiliations
                </span>
              </h6>
            )}

        <p className="paddingStart-03rem">
          {providerDetail.userType === "primary_care" ||
          providerDetail.userType === "orthopedic_surgeon" ||
          providerDetail.userType === "neuro_surgeon" ||
          providerDetail?.userType === "nurse_practitioner" ||
          providerDetail?.userType === "pain_management_specialist"
            ? providerDetail.affiliations?.map((row, index) => {
                if (
                  providerDetail.affiliations[0]?.hospital_name !== "" ||
                  providerDetail.affiliations[0]?.hospital_city !== ""
                ) {
                  error = error + 1;
                }
                return (
                  <li className=" " key={index}>
                    <span className="text-capitalize ">
                      {row.hospital_name}
                      {row?.hospital_city &&
                        row?.hospital_city?.length &&
                        `(${row?.hospital_city})`}
                    </span>
                  </li>
                );
              })
            : providerDetail.affiliations?.map((row, index) => {
                if (providerDetail.affiliations[0]?.affiliatedGymsName !== "") {
                  error = error + 1;
                }
                return (
                  <li key={index}>
                    <span className="text-capitalize">
                      {row.affiliatedGymsName}
                    </span>
                  </li>
                );
              })}
        </p>
      </div>
    </div>
  );
  const modalities = (
    <div className="d-flex">
      <div>
        {providerDetail.modalities_available &&
          providerDetail.modalities_available.length &&
          providerDetail.modalities_available[0]?.modalities_Name !== "" && (
            <h6>
              <FaCertificate className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
            </h6>
          )}
      </div>
      <div>
        {providerDetail.modalities_available &&
          providerDetail.modalities_available.length &&
          providerDetail.modalities_available[0]?.modalities_Name !== "" && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Modalities Available
              </span>
            </h6>
          )}
        <p>
          {providerDetail.modalities_available?.map((row, index) => {
            if (
              providerDetail.modalities_available[0]?.modalities_Name !== ""
            ) {
              error = error + 1;
            }
            return (
              <li key={index}>
                <span className="text-capitalize">{row.modalities_Name}</span>
              </li>
            );
          })}
        </p>
      </div>
    </div>
  );
  const teams = (
    <div className="d-flex">
      <div>
        {providerDetail.teams_affiliated &&
          providerDetail.teams_affiliated.length &&
          (providerDetail.teams_affiliated[0]?.team_name !== "" ||
            providerDetail.teams_affiliated[0]?.team_city !== "") && (
            <h6>
              <FaCertificate className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
            </h6>
          )}
      </div>
      <div>
        {providerDetail.teams_affiliated &&
          providerDetail.teams_affiliated.length &&
          (providerDetail.teams_affiliated[0]?.team_name !== "" ||
            providerDetail.teams_affiliated[0]?.team_city !== "") && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Affiliated Teams
              </span>
            </h6>
          )}
        <p>
          {providerDetail.teams_affiliated?.map((row, index) => {
            if (
              providerDetail.teams_affiliated[0]?.team_name !== "" ||
              providerDetail.teams_affiliated[0]?.team_city !== ""
            ) {
              error = error + 1;
            }
            return (
              <li key={index}>
                <span className="text-capitalize">
                  {`${row.team_name} ${
                    row.team_city !== "" ? `(${row.team_city})` : ""
                  }`}
                </span>
              </li>
            );
          })}
        </p>
      </div>
    </div>
  );
  const schools = (
    <div className="d-flex">
      <div>
        {providerDetail.schools_affiliated &&
          providerDetail.schools_affiliated.length &&
          (providerDetail.schools_affiliated[0]?.school_name !== "" ||
            providerDetail.schools_affiliated[0]?.school_city !== "") && (
            <h6>
              <FaCertificate className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
            </h6>
          )}
      </div>
      <div>
        {providerDetail.schools_affiliated &&
          providerDetail.schools_affiliated.length &&
          (providerDetail.schools_affiliated[0]?.school_name !== "" ||
            providerDetail.schools_affiliated[0]?.school_city !== "") && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Affiliated Schools
              </span>
            </h6>
          )}
        <p>
          {providerDetail.schools_affiliated?.map((row, index) => {
            if (
              providerDetail.schools_affiliated[0]?.school_name !== "" ||
              providerDetail.schools_affiliated[0]?.school_city !== ""
            ) {
              error = error + 1;
            }
            return (
              <li key={index}>
                <span className="text-capitalize">
                  {`${row.school_name} ${
                    row.school_city !== "" ? `(${row.school_city})` : ""
                  }`}
                </span>
              </li>
            );
          })}
        </p>
      </div>
    </div>
  );

  const services = (
    <>
      {providerDetail.userType === "rehabilitation_center" ||
        providerDetail.userType === "hospital" ||
        (providerDetail.userType === "clinic" && (
          <div className="d-flex">
            <div>
              {typeof providerDetail?.services[0]?.serviceLines !==
              "undefined" ? (
                providerDetail?.services[0]?.serviceLines === "" ? (
                  ""
                ) : (
                  <h6>
                    <FaCertificate className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
                  </h6>
                )
              ) : providerDetail?.services[0] === "" ? (
                ""
              ) : (
                <h6>
                  <FaCertificate className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
                </h6>
              )}
            </div>
            <div>
              {providerDetail?.services[0]?.serviceLines !== "" ||
                (providerDetail?.services[0] !== "" && (
                  <h6>
                    <span className="text-black fs-5 fw-bold ms-1">
                      Services
                    </span>
                  </h6>
                ))}
              {typeof providerDetail?.services[0]?.serviceLines !== "undefined"
                ? providerDetail?.services[0]?.serviceLines === ""
                  ? ""
                  : providerDetail?.services?.map((row, index) => {
                      if (providerDetail?.services[0]?.serviceLines !== "") {
                        error = error + 1;
                      }
                      return providerDetail?.services[0]?.serviceLines ===
                        "" ? (
                        ""
                      ) : (
                        <li className="my-2" key={index}>
                          <span className="text-capitalize fw-bold">
                            {row?.serviceLines && `${row?.serviceLines}`}
                          </span>
                        </li>
                      );
                    })
                : providerDetail.services[0] === ""
                ? ""
                : providerDetail?.services?.map((row, index) => {
                    if (row !== "") {
                      error = error + 1;
                    }
                    return (
                      <li className="my-2 border" key={index}>
                        <span className="text-capitalize fw-bold">{`${row}`}</span>
                      </li>
                    );
                  })}

              <p></p>
            </div>
          </div>
        ))}
    </>
  );
  const leadership_team_second = (
    <div className="d-flex">
      <div>
        {providerDetail?.leadership_team &&
        providerDetail?.leadership_team.length !== 0 &&
        providerDetail?.leadership_team[0]?.firstName === "" &&
        providerDetail?.leadership_team[0]?.lastName === "" &&
        (providerDetail?.leadership_team[0]?.teamTitle === "" ||
          providerDetail?.leadership_team[0]?.teamTtile === "") &&
        providerDetail?.leadership_team[0]?.serviceLines === "" ? (
          ""
        ) : (
          <h6>
            <FaCertificate className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
          </h6>
        )}
      </div>
      <div>
        {providerDetail?.leadership_team &&
        providerDetail?.leadership_team.length !== 0 &&
        providerDetail?.leadership_team[0]?.firstName === "" &&
        providerDetail?.leadership_team[0]?.lastName === "" &&
        (providerDetail?.leadership_team[0]?.teamTitle === "" ||
          providerDetail?.leadership_team[0]?.teamTtile === "") &&
        providerDetail?.leadership_team[0]?.serviceLines === "" ? (
          ""
        ) : (
          <h6>
            <span className="text-black fs-5 fw-bold ms-1">
              Leadership Team
            </span>
          </h6>
        )}
        <p>
          {providerDetail.leadership_team?.map((row, index) => {
            if (
              providerDetail?.leadership_team[0]?.teamTitle
                ? providerDetail?.leadership_team[0]?.firstName !== "" ||
                  providerDetail?.leadership_team[0]?.lastName !== "" ||
                  providerDetail?.leadership_team[0]?.teamTitle !== "" ||
                  providerDetail?.leadership_team[0]?.teamTtile !== "" ||
                  providerDetail?.leadership_team[0]?.serviceLines !== ""
                : providerDetail?.leadership_team[0]?.firstName !== "" ||
                  providerDetail?.leadership_team[0]?.lastName !== "" ||
                  providerDetail?.leadership_team[0]?.teamTtile !== "" ||
                  providerDetail?.leadership_team[0]?.serviceLines !== ""
            ) {
              error = error + 1;
            }
            return providerDetail?.leadership_team &&
              providerDetail?.leadership_team.length !== 0 &&
              providerDetail?.leadership_team[0]?.firstName === "" &&
              providerDetail?.leadership_team[0]?.lastName === "" &&
              (providerDetail?.leadership_team[0]?.teamTitle === "" ||
                providerDetail?.leadership_team[0]?.teamTtile === "") &&
              providerDetail?.leadership_team[0]?.serviceLines === "" ? (
              ""
            ) : (
              <li className="my-2" key={index}>
                <span className="text-capitalize fw-bold">
                  <span className="text-capitalize fw-bold">
                    {row?.firstName && `${row?.firstName} `}
                    {row?.lastName && `${row?.lastName}`}
                  </span>

                  {row?.teamTitle &&
                    row?.teamTitle?.length &&
                    ` - ${row?.teamTitle}`}
                </span>
              </li>
            );
          })}
        </p>
      </div>
    </div>
  );
  const philosophy = (
    <div className="d-flex">
      <div>
        {providerDetail?.philosophy && providerDetail?.philosophy.length && (
          <h6>
            <FaDumbbell className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
          </h6>
        )}
      </div>
      <div>
        {providerDetail?.philosophy && providerDetail?.philosophy.length && (
          <h6>
            <span className="text-black fs-5 fw-bold ms-1">
              {providerDetail?.userType === "physical_therapist"
                ? "Training Philosophy"
                : providerDetail?.userType === "wellness_provider"
                ? "Wellness Philosophy"
                : "GYM Philosophy"}
            </span>
          </h6>
        )}
        <p className="px-4">{providerDetail.philosophy}</p>
      </div>
    </div>
  );
  const training_philosophy = (
    <div className="d-flex">
      <div>
        {providerDetail?.training_philosophy &&
          providerDetail?.training_philosophy.length && (
            <h6>
              <FaDumbbell className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
            </h6>
          )}
      </div>
      <div>
        {providerDetail?.training_philosophy &&
          providerDetail?.training_philosophy.length && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Training Philosophy
              </span>
            </h6>
          )}
        <p className="px-4">{providerDetail.training_philosophy}</p>
      </div>
    </div>
  );
  const trainers = (
    <div className="d-flex">
      <div>
        {providerDetail?.trainers_available &&
          providerDetail?.trainers_available?.length !== 0 &&
          providerDetail?.trainers_available[0]?.trainers_Available !== "" && (
            <h6>
              <FaUsersCog className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
            </h6>
          )}
      </div>
      <div>
        {providerDetail?.trainers_available &&
          providerDetail?.trainers_available?.length !== 0 &&
          providerDetail?.trainers_available[0]?.trainers_Available !== "" && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Trainers Available
              </span>
            </h6>
          )}
        <p>
          {providerDetail?.trainers_available?.length > 0 &&
            providerDetail?.trainers_available?.map((map, index) => {
              if (
                providerDetail?.trainers_available[0]?.trainers_Available !== ""
              ) {
                error = error + 1;
              }
              return (
                <p key={index}>
                  <span className="px-4">{map?.trainers_Available}</span>
                  <span className="px-4">{map?.[0]}</span>
                </p>
              );
            })}
        </p>
      </div>
    </div>
  );
  const operation_hours = (
    <div className="d-flex">
      <div>
        {providerDetail?.hours_of_operation &&
          providerDetail?.hours_of_operation?.length > 0 &&
          ((providerDetail?.hours_of_operation[0]?.days &&
            providerDetail?.hours_of_operation[0]?.days.length > 0) ||
            providerDetail?.hours_of_operation[0]?.start_time !== "" ||
            providerDetail?.hours_of_operation[0]?.end_time !== "") && (
            <h6>
              <FaHistory className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
            </h6>
          )}
      </div>
      <div>
        {providerDetail?.hours_of_operation &&
          providerDetail?.hours_of_operation?.length > 0 &&
          ((providerDetail?.hours_of_operation[0]?.days &&
            providerDetail?.hours_of_operation[0]?.days.length > 0) ||
            providerDetail?.hours_of_operation[0]?.start_time !== "" ||
            providerDetail?.hours_of_operation[0]?.end_time !== "") && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Hours Of Operation
              </span>
            </h6>
          )}
        {providerDetail?.hours_of_operation?.length > 0 &&
          providerDetail?.hours_of_operation?.map((map, index) => {
            if (
              providerDetail?.hours_of_operation[0]?.start_time !== "" ||
              providerDetail?.hours_of_operation[0]?.end_time !== ""
            ) {
              error = error + 1;
            }
            const days =
              map?.days?.length > 0 &&
              map?.days?.map((day, index) => (
                <span className="fw-semibold" key={index}>
                  {day}
                  {map?.days?.length - 1 !== index ? "," : ""}
                </span>
              ));
            return (
              <>
                {providerDetail?.hours_of_operation.length > 0 &&
                  (providerDetail?.hours_of_operation[0]?.start_time !== "" ||
                    providerDetail?.hours_of_operation[0]?.end_time !== "") && (
                    <>
                      {days}
                      {map?.start_time && map?.end_time ? (
                        <>
                          <p>
                            {map?.start_time &&
                            map?.start_time.length &&
                            map?.end_time &&
                            map?.end_time.length
                              ? moment(map?.start_time, ["HH:mm:A"]).format(
                                  "hh:mm A"
                                ) +
                                " - " +
                                moment(map?.end_time, ["HH:mm:A"]).format(
                                  "hh:mm A"
                                )
                              : moment(map?.start_time, ["HH:mm:A"]).format(
                                  "hh:mm A"
                                )}
                          </p>
                        </>
                      ) : (
                        <p className="px-2"></p>
                      )}
                    </>
                  )}
              </>
            );
          })}
      </div>
    </div>
  );
  const payment_method = (
    <div className="d-flex">
      <div>
        {JSON.stringify(providerDetail?.payment_accepted_methods) !==
          JSON.stringify([""]) && (
          <h6>
            <FaRegCreditCard className="me-2 text-primary align-self-center -mt-3 height-25px width-25px" />
          </h6>
        )}
      </div>
      <div>
        {providerDetail?.payment_accepted_methods?.length > 0 &&
          providerDetail?.payment_accepted_methods[0] !== "" && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Payment Methods
              </span>
            </h6>
          )}
        {providerDetail?.payment_accepted_methods?.map((row, index) => {
          if (row !== "") {
            error = error + 1;
          }
          return (
            row !== "" && (
              <li key={index}>
                <span className="text-capitalize">{row}</span>
              </li>
            )
          );
        })}
      </div>
    </div>
  );
  const certifications = (
    <div className="d-flex">
      <div>
        {providerDetail.certifications &&
          providerDetail.certifications.length &&
          (providerDetail.certifications[0]?.label !== "" ||
            providerDetail.certifications[0]?.year !== "") && (
            <h6>
              <FaFile className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
            </h6>
          )}
      </div>
      <div>
        {providerDetail.certifications &&
          providerDetail.certifications.length &&
          (providerDetail.certifications[0]?.label !== "" ||
            providerDetail.certifications[0]?.year !== "") && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Certifications
              </span>
            </h6>
          )}
        <ol>
          {providerDetail.certifications?.map((row, index) => {
            if (row.label !== "" || row?.year !== "") error = error + 1;
            return row.label?.length && row?.year?.length ? (
              index < 7 && (
                <li key={index}>
                  <span className="text-capitalize">{row.label}</span>
                  {row.label && row?.year && ` (${row?.year})`}
                </li>
              )
            ) : row.label?.length ? (
              index < 7 && (
                <li key={index}>
                  <span className="text-capitalize">{row.label}</span>
                </li>
              )
            ) : (
              <></>
            );
          })}
        </ol>
      </div>
    </div>
  );
  const business_year = (
    <div className="d-flex">
      <div>
        {providerDetail?.years_in_business &&
          providerDetail?.years_in_business.length && (
            <h6>
              <FaCalendar className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
            </h6>
          )}
      </div>
      <div>
        {providerDetail?.years_in_business &&
          providerDetail?.years_in_business.length && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">
                Years In Business
              </span>
            </h6>
          )}
        <p className="px-4">{providerDetail.years_in_business} Years</p>
      </div>
    </div>
  );
  const about = (
    <>
      {providerDetail?.about && providerDetail?.about.length && (
        <h6 className="d-flex">
          <FaUser className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
          <span className="text-black fs-5 fw-bold ms-1">About Me</span>
        </h6>
      )}
      <p className="px-4">{providerDetail.about}</p>
    </>
  );

  const training = (
    <div className="d-flex">
      <div>
        {providerDetail?.training &&
          providerDetail?.training.length &&
          (providerDetail?.training[0]?.location !== "" ||
            providerDetail?.training[0]?.trainingType !== "" ||
            providerDetail?.training[0]?.completionyear !== "") && (
            <h6>
              <FaGraduationCap className="me-2 text-primary align-self-center -mt-3 height-30px width-30px" />
            </h6>
          )}
      </div>
      <div className="mb-2">
        {providerDetail?.training &&
          providerDetail?.training.length &&
          (providerDetail?.training[0]?.location !== "" ||
            providerDetail?.training[0]?.trainingType !== "" ||
            providerDetail?.training[0]?.completionyear !== "") && (
            <h6>
              <span className="text-black fs-5 fw-bold ms-1">Training</span>
            </h6>
          )}
        {providerDetail.training?.map((row, index) => {
          if (
            row.location !== "" ||
            row.trainingType !== "" ||
            row?.completionyear !== ""
          ) {
            error = error + 1;
          }
          return (
            <div key={index}>
              {(row.location !== "" ||
                row.trainingType !== "" ||
                row?.completionyear !== "") && (
                <li>
                  <span className="text-capitalize">{row.trainingType}</span>
                  {row.trainingType &&
                    (row.location || row?.completionyear) &&
                    " - "}
                  {row.location}
                  {row?.completionyear && `(${row?.completionyear})`}
                </li>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
  const experience = (
    <div className="d-flex">
      <div>
        {providerDetail?.years_of_experience &&
          providerDetail?.years_of_experience.length && (
            <h6>
              <FaCalendar className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
            </h6>
          )}
      </div>
      <div>
        <div>
          {providerDetail?.years_of_experience &&
            providerDetail?.years_of_experience.length && (
              <h6>
                <span className="text-black fs-5 fw-bold ms-1">
                  Years Of Experience
                </span>
              </h6>
            )}
        </div>
        <div>
          {providerDetail?.years_of_experience?.includes("Years") ? (
            <p className="px-2">{providerDetail.years_of_experience}</p>
          ) : (
            <p className="px-2">{providerDetail.years_of_experience} Years</p>
          )}
        </div>
      </div>
    </div>
  );
  const biography = (
    <>
      {providerDetail?.biography && providerDetail?.biography?.length && (
        <h6 className="d-flex">
          <FaUser className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
          <span className="text-black fs-5 fw-bold ms-1">About Me</span>
        </h6>
      )}
      <p>{providerDetail.biography}</p>
    </>
  );
  const webLinks = (
    <>
      {providerDetail?.webLinks?.length > 0 &&
        (providerDetail?.webLinks[0].name !== "" ||
          providerDetail?.webLinks[0].link !== "") && (
          <div className="d-flex">
            <div>
              <h6 className="d-flex">
                <IoLinkSharp className="me-2 text-primary align-self-center -mt-3 height-30px width-30px" />
              </h6>
            </div>
            <div>
              {providerDetail?.webLinks?.length > 0 &&
                (providerDetail?.webLinks[0].name !== "" ||
                  providerDetail?.webLinks[0].link !== "") && (
                  <h6 className="d-flex">
                    <span className="text-black fs-5 fw-bold ms-1">
                      Recommendations
                    </span>
                  </h6>
                )}
              <div className="row ">
                <div className="col-12">
                  {providerDetail?.webLinks?.slice(0, 8).map((map) => {
                    if (map?.link !== "" || map?.name !== "") {
                      error = error + 1;
                    }
                    return (
                      <li
                        className="text-truncate"
                        title={
                          map?.userType
                            ? `${map?.name} - ${
                                map?.userType
                                  ? map?.userType
                                      ?.split("_")
                                      .join(" ")
                                      .toUpperCase()
                                  : ""
                              }`
                            : map?.name
                        }
                      >
                        {map?.link?.length ? (
                          <Link
                            className="text-decoration-none text-capitalize "
                            to={`/providers/${map?.link}`}
                          >
                            {map?.name} {map?.userType ? "-" : <></>}{" "}
                            {map?.userType?.split("_").join(" ")}
                          </Link>
                        ) : (
                          <>{map?.name}</>
                        )}
                        <br />
                      </li>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );

  useEffect(() => {
    if (providerDetail) {
      if (
        providerDetail?.biography &&
        providerDetail?.biography !== undefined &&
        providerDetail?.biography !== ""
      ) {
        setNewError(newError + 1);
      } else if (
        providerDetail?.years_of_experience &&
        providerDetail?.years_of_experience !== undefined &&
        providerDetail?.years_of_experience.length
      ) {
        setNewError(newError + 1);
      } else if (
        providerDetail?.about &&
        providerDetail?.about !== undefined &&
        providerDetail?.about !== ""
      ) {
        setNewError(newError + 1);
      } else if (
        providerDetail?.years_in_business &&
        providerDetail?.years_in_business !== undefined &&
        providerDetail?.years_in_business !== ""
      ) {
        setNewError(newError + 1);
      } else if (
        providerDetail?.training_philosophy &&
        providerDetail?.training_philosophy !== undefined &&
        providerDetail?.training_philosophy !== ""
      ) {
        setNewError(newError + 1);
      } else if (
        providerDetail?.philosophy &&
        providerDetail?.philosophy !== undefined &&
        providerDetail?.philosophy !== ""
      ) {
        setNewError(newError + 1);
      } else if (
        providerDetail.video &&
        providerDetail.video !== undefined &&
        providerDetail.video !== ""
      ) {
        setNewError(newError + 1);
      } else if (
        providerDetail.videoUrl &&
        providerDetail.videoUrl !== undefined &&
        providerDetail.videoUrl !== ""
      ) {
        setNewError(newError + 1);
      }
    }
    if (providerDetail.npiId) {
      axios
        .get(`v1/public/provider/npi-details?npiId=${providerDetail.npiId}`)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setData(res.data);
          } else setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerDetail]);

  const renderProviders = () => {
    if (providerDetail.userType === "nurse_practitioner") {
      return (
        <div className="col-md-12 row ">
          {providerDetail?.biography && (
            <div
              className={
                providerDetail?.biography?.toString()?.split(" ").length > 50
                  ? "col-md-12 "
                  : "col-md-12 "
              }
            >
              {biography}
            </div>
          )}

          {!!providerDetail?.years_of_experience?.length && (
            <div className="col-md-12 ">{experience}</div>
          )}

          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}

          {providerDetail.userType !== "wellness_provider" &&
            !!providerDetail?.training?.length && (
              <div className="col-md-12 ">{training}</div>
            )}

          {providerDetail?.about && <div className="col-md-12 ">{about}</div>}

          {!!providerDetail?.years_in_business?.length && (
            <div className="col-md-12 ">{business_year}</div>
          )}

          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12 ">{certifications}</div>
            )}
          {!!providerDetail?.education?.length &&
            (providerDetail?.education[0].college !== "" ||
              providerDetail?.education[0].completionyear !== "" ||
              providerDetail?.education[0].degree !== "") && (
              <div className="col-md-12 ">{education}</div>
            )}

          {!!providerDetail?.programs?.length &&
            providerDetail?.programs[0].programName !== "" && (
              <div className="col-md-12 ">{program}</div>
            )}

          {!!providerDetail?.affiliations?.length &&
            (providerDetail?.affiliations[0].hospital_name !== "" ||
              providerDetail?.affiliations[0].hospital_city !== "") && (
              <div className="col-md-12 ">{affiliations}</div>
            )}

          {!!providerDetail?.modalities_available?.length &&
            providerDetail?.modalities_available[0].modalities_Name !== "" && (
              <div className="col-md-12 ">{modalities}</div>
            )}
          {!!providerDetail?.teams_affiliated?.length &&
            (providerDetail?.teams_affiliated[0].team_name !== "" ||
              providerDetail?.teams_affiliated[0].team_city !== "") && (
              <div className="col-md-12 ">{teams}</div>
            )}
          {!!providerDetail?.schools_affiliated?.length &&
            (providerDetail?.schools_affiliated[0].school_name !== "" ||
              providerDetail?.schools_affiliated[0].school_city !== "") && (
              <div className="col-md-12 ">{schools}</div>
            )}

          {!!providerDetail?.philosophy?.length && (
            <div className="col-md-12 ">{philosophy}</div>
          )}
          {!!providerDetail?.training_philosophy?.length && (
            <div className="col-md-12 ">{training_philosophy}</div>
          )}

          {providerDetail?.trainers_available?.length > 0 &&
            providerDetail?.trainers_available[0].trainers_Available !== "" && (
              <div className="col-md-12 ">{trainers}</div>
            )}

          {providerDetail?.hours_of_operation?.length > 0 && (
            <div className="col-md-12 ">{operation_hours}</div>
          )}

          {!!providerDetail?.payment_accepted_methods?.length && (
            <div className="col-md-12 ">{payment_method}</div>
          )}
        </div>
      );
    } else if (
      providerDetail.userType === "orthopedic_surgeon" ||
      providerDetail.userType === "neuro_surgeon" ||
      providerDetail.userType === "pain_management_specialist"
    ) {
      return (
        <div className="col-md-12 row">
          {providerDetail?.biography && (
            <div
              className={
                providerDetail?.biography?.toString()?.split(" ").length > 50
                  ? "col-md-12"
                  : "col-md-12"
              }
            >
              {biography}
            </div>
          )}

          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}

          {!!providerDetail?.years_of_experience?.length && (
            <div className="col-md-12">{experience}</div>
          )}

          {providerDetail.userType !== "wellness_provider" &&
            !!providerDetail?.training?.length && (
              <div className="col-md-12">{training}</div>
            )}

          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12">{certifications}</div>
            )}

          {providerDetail?.about && <div className="col-md-12">{about}</div>}

          {!!providerDetail?.years_in_business?.length && (
            <div className="col-md-12">{business_year}</div>
          )}

          {!!providerDetail?.education?.length &&
            (providerDetail?.education[0].college !== "" ||
              providerDetail?.education[0].completionyear !== "" ||
              providerDetail?.education[0].degree !== "") && (
              <div className="col-md-12">{education}</div>
            )}

          {!!providerDetail?.programs?.length &&
            providerDetail?.programs[0].programName !== "" && (
              <div className="col-md-12">{program}</div>
            )}

          {!!providerDetail?.affiliations?.length &&
            (providerDetail?.affiliations[0].hospital_name !== "" ||
              providerDetail?.affiliations[0].hospital_city !== "") && (
              <div className="col-md-12 ">{affiliations}</div>
            )}

          {!!providerDetail?.modalities_available?.length &&
            providerDetail?.modalities_available[0].modalities_Name !== "" && (
              <div className="col-md-12">{modalities}</div>
            )}
          {!!providerDetail?.teams_affiliated?.length &&
            (providerDetail?.teams_affiliated[0].team_name !== "" ||
              providerDetail?.teams_affiliated[0].team_city !== "") && (
              <div className="col-md-12">{teams}</div>
            )}
          {!!providerDetail?.schools_affiliated?.length &&
            (providerDetail?.schools_affiliated[0].school_name !== "" ||
              providerDetail?.schools_affiliated[0].school_city !== "") && (
              <div className="col-md-12">{schools}</div>
            )}

          {!!providerDetail?.philosophy?.length && (
            <div className="col-md-12">{philosophy}</div>
          )}
          {!!providerDetail?.training_philosophy?.length && (
            <div className="col-md-12">{training_philosophy}</div>
          )}

          {providerDetail?.trainers_available?.length > 0 &&
            providerDetail?.trainers_available[0].trainers_Available !== "" && (
              <div className="col-md-12">{trainers}</div>
            )}

          {providerDetail?.hours_of_operation?.length > 0 && (
            <div className="col-md-12">{operation_hours}</div>
          )}

          {!!providerDetail?.payment_accepted_methods?.length && (
            <div className="col-md-12">{payment_method}</div>
          )}
        </div>
      );
    } else if (providerDetail.userType === "primary_care") {
      return (
        <div className="col-md-12 row">
          {providerDetail?.biography && (
            <div
              className={
                providerDetail?.biography?.toString()?.split(" ").length > 50
                  ? "col-md-12"
                  : "col-md-12"
              }
            >
              {biography}
            </div>
          )}

          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}

          {!!providerDetail?.years_of_experience?.length && (
            <div className="col-md-12">{experience}</div>
          )}

          {providerDetail.userType !== "wellness_provider" &&
            !!providerDetail?.training?.length && (
              <div className="col-md-12">{training}</div>
            )}

          {providerDetail?.about && <div className="col-md-12">{about}</div>}

          {!!providerDetail?.years_in_business?.length && (
            <div className="col-md-12">{business_year}</div>
          )}

          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12">{certifications}</div>
            )}
          {!!providerDetail?.education?.length &&
            (providerDetail?.education[0].college !== "" ||
              providerDetail?.education[0].completionyear !== "" ||
              providerDetail?.education[0].degree !== "") && (
              <div className="col-md-12">{education}</div>
            )}

          {!!providerDetail?.affiliations?.length &&
            (providerDetail?.affiliations[0].hospital_name !== "" ||
              providerDetail?.affiliations[0].hospital_city !== "") && (
              <div className="col-md-12 ">{affiliations}</div>
            )}

          {!!providerDetail?.philosophy?.length && (
            <div className="col-md-12">{philosophy}</div>
          )}
          {!!providerDetail?.training_philosophy?.length && (
            <div className="col-md-12">{training_philosophy}</div>
          )}

          {!!providerDetail?.trainers_available?.length &&
            providerDetail?.trainers_available[0].trainers_Available !== "" && (
              <div className="col-md-12">{trainers}</div>
            )}
        </div>
      );
    } else if (providerDetail.userType === "personal_trainer") {
      return (
        <div className="col-md-12 row">
          {providerDetail?.biography && (
            <div
              className={
                providerDetail?.biography?.toString()?.split(" ").length > 50
                  ? "col-md-12"
                  : "col-md-12"
              }
            >
              {biography}
            </div>
          )}

          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}

          {!!providerDetail?.years_of_experience?.length && (
            <div className="col-md-12">{experience}</div>
          )}

          {providerDetail.userType !== "wellness_provider" &&
            !!providerDetail?.training?.length && (
              <div className="col-md-12">{training}</div>
            )}
          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12">{certifications}</div>
            )}

          {!!providerDetail?.years_in_business?.length && (
            <div className="col-md-12">{business_year}</div>
          )}

          {!!providerDetail?.affiliations?.length &&
            providerDetail?.affiliations[0].affiliatedGymsName !== "" && (
              <div className="col-md-12 ">{affiliations}</div>
            )}

          {!!providerDetail?.philosophy?.length && (
            <div className="col-md-12">{philosophy}</div>
          )}
          {!!providerDetail?.training_philosophy?.length && (
            <div className="col-md-12">{training_philosophy}</div>
          )}

          {!!providerDetail?.payment_accepted_methods?.length && (
            <div className="col-md-12">{payment_method}</div>
          )}
        </div>
      );
    } else if (
      providerDetail.userType === "physical_therapist" ||
      providerDetail.userType === "chiropractor"
    ) {
      return (
        <div className="col-md-12 row">
          {providerDetail?.biography && (
            <div
              className={
                providerDetail?.biography?.toString()?.split(" ").length > 50
                  ? "col-md-12"
                  : "col-md-12"
              }
            >
              {biography}
            </div>
          )}

          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}

          {!!providerDetail?.years_of_experience?.length && (
            <div className="col-md-12">{experience}</div>
          )}

          {!!providerDetail?.years_in_business?.length && (
            <div className="col-md-12">{business_year}</div>
          )}

          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12">{certifications}</div>
            )}

          {!!providerDetail?.affiliations?.length &&
            providerDetail?.affiliations[0].affiliatedGymsName !== "" && (
              <div className="col-md-12 ">{affiliations}</div>
            )}
          {!!providerDetail?.philosophy?.length && (
            <div className="col-md-12">{philosophy}</div>
          )}
          {!!providerDetail?.training_philosophy?.length && (
            <div className="col-md-12">{training_philosophy}</div>
          )}

          {!!providerDetail?.payment_accepted_methods?.length && (
            <div className="col-md-12">{payment_method}</div>
          )}
        </div>
      );
    } else if (
      providerDetail.userType === "wellness_provider" ||
      providerDetail.userType === "massage_therapist"
    ) {
      return (
        <div className="col-md-12 row">
          {providerDetail?.biography && (
            <div
              className={
                providerDetail?.biography?.toString()?.split(" ").length > 50
                  ? "col-md-12"
                  : "col-md-12"
              }
            >
              {biography}
            </div>
          )}

          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}

          {!!providerDetail?.years_of_experience?.length && (
            <div className="col-md-12">{experience}</div>
          )}

          {providerDetail.userType !== "wellness_provider" &&
            !!providerDetail?.training?.length && (
              <div className="col-md-12">{training}</div>
            )}

          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12">{certifications}</div>
            )}

          {!!providerDetail?.years_in_business?.length && (
            <div className="col-md-12">{business_year}</div>
          )}

          {!!providerDetail?.education?.length &&
            providerDetail?.education[0].college !== "" &&
            providerDetail?.education[0].completionyear !== "" &&
            providerDetail?.education[0].degree !== "" && (
              <div className="col-md-12">{education}</div>
            )}

          {!!providerDetail?.philosophy?.length && (
            <div className="col-md-12">{philosophy}</div>
          )}

          {!!providerDetail?.payment_accepted_methods?.length && (
            <div className="col-md-12">{payment_method}</div>
          )}
        </div>
      );
    } else if (providerDetail.userType === "athletic_trainers") {
      return (
        <div className="col-md-12 row">
          {providerDetail?.biography && (
            <div className="col-md-12">{biography}</div>
          )}

          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}

          {!!providerDetail?.years_of_experience?.length && (
            <div className="col-md-12">{experience}</div>
          )}

          {!!providerDetail?.years_in_business?.length && (
            <div className="col-md-12">{business_year}</div>
          )}

          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12">{certifications}</div>
            )}
          {!!providerDetail?.education?.length &&
            (providerDetail?.education[0].college !== "" ||
              providerDetail?.education[0].completionyear !== "" ||
              providerDetail?.education[0].degree !== "") && (
              <div className="col-md-12">{education}</div>
            )}

          {!!providerDetail?.teams_affiliated?.length &&
            (providerDetail?.teams_affiliated[0].team_name !== "" ||
              providerDetail?.teams_affiliated[0].team_city !== "") && (
              <div className="col-md-12">{teams}</div>
            )}
          {!!providerDetail?.schools_affiliated?.length &&
            (providerDetail?.schools_affiliated[0].school_name !== "" ||
              providerDetail?.schools_affiliated[0].school_city !== "") && (
              <div className="col-md-12">{schools}</div>
            )}

          {!!providerDetail?.philosophy?.length && (
            <div className="col-md-12">{philosophy}</div>
          )}
          {!!providerDetail?.training_philosophy?.length && (
            <div className="col-md-12">{training_philosophy}</div>
          )}

          {providerDetail?.trainers_available?.length > 0 &&
            providerDetail?.trainers_available[0].trainers_Available !== "" && (
              <div className="col-md-12">{trainers}</div>
            )}

          {providerDetail?.hours_of_operation?.length > 0 && (
            <div className="col-md-12">{operation_hours}</div>
          )}

          {!!providerDetail?.payment_accepted_methods?.length && (
            <div className="col-md-12">{payment_method}</div>
          )}
        </div>
      );
    } else if (providerDetail.userType === "gym") {
      return (
        <div className="col-md-12 row">
          {!!providerDetail?.years_of_experience?.length && (
            <div className="col-md-12">{experience}</div>
          )}
          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}
          {providerDetail.userType !== "wellness_provider" &&
            !!providerDetail?.training?.length && (
              <div className="col-md-12">{training}</div>
            )}

          {providerDetail?.about && <div className="col-md-12">{about}</div>}

          {!!providerDetail?.years_in_business?.length && (
            <div className="col-md-12">{business_year}</div>
          )}

          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12">{certifications}</div>
            )}

          {!!providerDetail?.programs?.length &&
            providerDetail?.programs[0].programName !== "" && (
              <div className="col-md-12">{program}</div>
            )}

          {!!providerDetail?.affiliations?.length &&
            providerDetail.affiliations[0]?.affiliatedGymsName !== "" && (
              <div className="col-md-12">{affiliations}</div>
            )}

          {!!providerDetail?.modalities_available?.length &&
            providerDetail?.modalities_available[0].modalities_Name !== "" && (
              <div className="col-md-12">{modalities}</div>
            )}

          {!!providerDetail?.teams_affiliated?.length &&
            (providerDetail?.teams_affiliated[0].team_name !== "" ||
              providerDetail?.teams_affiliated[0].team_city !== "") && (
              <div className="col-md-12">{teams}</div>
            )}

          {!!providerDetail?.philosophy?.length && (
            <div className="col-md-12">{philosophy}</div>
          )}
          {!!providerDetail?.training_philosophy?.length && (
            <div className="col-md-12">{training_philosophy}</div>
          )}

          {providerDetail?.trainers_available?.length > 0 &&
            providerDetail?.trainers_available[0]?.trainers_Available !==
              "" && <div className="col-md-12">{trainers}</div>}

          {providerDetail?.hours_of_operation?.length > 0 && (
            <div className="col-md-12">{operation_hours}</div>
          )}

          {!!providerDetail?.payment_accepted_methods?.length && (
            <div className="col-md-12">{payment_method}</div>
          )}
        </div>
      );
    } else if (providerDetail.userType === "rehabilitation_center") {
      return (
        <div className="col-md-12 row">
          {providerDetail?.biography && (
            <div className="col-md-12">{biography}</div>
          )}

          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}

          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12">{certifications}</div>
            )}

          {!!providerDetail?.leadership_team?.length && (
            <div className="col-md-12">{leadership_team_second}</div>
          )}
          {!!providerDetail?.services?.length && (
            <div className="col-md-12">{services}</div>
          )}
        </div>
      );
    } else if (
      providerDetail.userType === "hospital" ||
      providerDetail.userType === "clinic"
    ) {
      return (
        <div className="col-md-12 row">
          {providerDetail?.biography && (
            <div className="col-md-12">{biography}</div>
          )}

          {!!providerDetail?.webLinks?.length && (
            <div className="col-md-12 mb-2">{webLinks}</div>
          )}

          {!!providerDetail?.certifications?.length &&
            (providerDetail?.certifications[0].label !== "" ||
              providerDetail?.certifications[0].year !== "") && (
              <div className="col-md-12">{certifications}</div>
            )}

          {!!providerDetail?.leadership_team?.length && (
            <div className="col-md-12">{leadership_team_second}</div>
          )}
          {!!providerDetail?.services?.length && (
            <div className="col-md-12">{services}</div>
          )}
        </div>
      );
    }
  };

  return (
    <div>
      <div className="p-3 container bg-muted-25 p-md-5 mt-4 card-scroll">
        {useMemo(() => {
          return (
            <p
              className={`text-center fw-bold mt-4 justify-content-center ${
                error === 0 && newError === 0 ? "d-flex" : "d-none"
              }`}
            >
              No Data Found
            </p>
          );
        }, [error, newError])}

        <div className="row">
          {(providerDetail?.education && providerDetail?.education?.length) ||
          (providerDetail.programs && providerDetail.programs?.length) ||
          (providerDetail.affiliations &&
            providerDetail.affiliations?.length) ||
          (providerDetail.modalities_available &&
            providerDetail.modalities_available?.length) ||
          (providerDetail.webLinks && providerDetail.webLinks?.length) ||
          (providerDetail.teams_affiliated &&
            providerDetail.teams_affiliated?.length) ||
          (providerDetail.schools_affiliated &&
            providerDetail.schools_affiliated?.length) ||
          (providerDetail?.services &&
            providerDetail?.services?.length !== 0) ||
          (providerDetail?.leadership_team &&
            providerDetail?.leadership_team?.length !== 0) ||
          (providerDetail?.philosophy && providerDetail?.philosophy?.length) ||
          (providerDetail?.training_philosophy &&
            providerDetail?.training_philosophy?.length) ||
          (providerDetail?.trainers_available &&
            providerDetail?.trainers_available?.length !== 0) ||
          (providerDetail?.hours_of_operation &&
            providerDetail?.hours_of_operation?.length > 0) ||
          providerDetail?.payment_accepted_methods?.length ||
          (providerDetail.certifications &&
            providerDetail.certifications?.length) ||
          (providerDetail?.years_in_business &&
            providerDetail?.years_in_business?.length) ||
          (providerDetail?.about && providerDetail?.about?.length) ||
          (providerDetail?.training && providerDetail?.training?.length) ||
          (providerDetail?.years_of_experience &&
            providerDetail?.years_of_experience?.length) ||
          providerDetail?.biography?.length ||
          (providerDetail.video && providerDetail.video?.length) ||
          (providerDetail.videoUrl && providerDetail.videoUrl?.length) ? (
            <>
              <div className="p-2 bg-white rounded-1">
                <div className={"col-12 p-3 bg-white"}>{renderProviders()}</div>

                {providerDetail.video ? (
                  <div className="col-md-12 p-3 px-md-5">
                    <div className="rounded-2 px-md-4" id="video">
                      <VideoPlayer
                        url={providerDetail.video}
                        isS3={true}
                        height={
                          providerDetail.video
                            ? window.innerWidth < 768
                              ? "100%"
                              : "450px"
                            : "0px"
                        }
                        id="home-tab"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-12  p-3 px-md-5">
                    <div className="rounded-2 px-md-4" id="video">
                      <VideoPlayer
                        url={providerDetail.videoUrl}
                        isS3={false}
                        id="home-tab"
                        height={
                          providerDetail.videoUrl
                            ? window.innerWidth < 768
                              ? "100%"
                              : "450px"
                            : "0px"
                        }
                      />
                    </div>
                  </div>
                )}
                {providerDetail.npiId && (
                  <div className="mx-3 mt-2">
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <HiOutlineIdentification className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          NPI ID
                        </span>
                      </h6>
                      <p className="pl-3rem">{data?.npi}</p>
                    </div>
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <BsPersonCircle className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          Name
                        </span>
                      </h6>
                      <p className="pl-3rem">{data?.name?.full}</p>
                    </div>
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <MdOutlineGrade className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          Credential
                        </span>
                      </h6>
                      <p className="pl-3rem">{data?.credential}</p>
                    </div>
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <BsFillPersonPlusFill className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          Age
                        </span>
                      </h6>
                      <p className="pl-3rem">{data?.estimatedAge}</p>
                    </div>
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <FaUser className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          Gender
                        </span>
                      </h6>
                      <p className="pl-3rem">{data?.gender}</p>
                    </div>
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <MdOutlineFolderSpecial className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          Primary Specialty
                        </span>
                      </h6>
                      <p className="pl-3rem">
                        {data?.primarySpecialty?.description}
                      </p>
                    </div>
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <FcOrganization className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          Affiliated Organizations
                        </span>
                      </h6>
                      <p className="pl-3rem">
                        {data?.affiliatedOrganizations?.items.map(
                          (option, i) => (
                            <li>{option.name}</li>
                          )
                        )}
                      </p>
                    </div>
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <FaCalendar className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          Affiliated Practices
                        </span>
                      </h6>
                      <p className="pl-3rem">
                        {data?.affiliatedPractices?.total}
                      </p>
                    </div>
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <FaMapMarkerAlt className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          Affiliated Practice Address
                        </span>
                      </h6>
                      <p className="pl-3rem">
                        {data?.affiliatedPractices?.items.map((option, i) => (
                          <li>
                            {option.address?.street}, {option.address?.city},{" "}
                            {option.address?.zipCode}, {option.address?.state},{" "}
                            {option.address?.county}
                          </li>
                        ))}
                      </p>
                    </div>
                    <div className="col-md-12 ">
                      <h6 className="d-flex">
                        <FaGraduationCap className="me-2 text-primary align-self-center  -mt-3 height-25px width-25px" />
                        <span className="text-black fs-5 fw-bold ms-1">
                          Medical School
                        </span>
                      </h6>
                      <p className="pl-3rem">
                        {data?.medicalSchool?.name},{" "}
                        {data?.medicalSchool?.graduationYear}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default HomeTab;
