import React, { useState } from "react";
import axios from "../../utils/axiosInst";

const StateSelect = ({ value, setValue }) => {
  const [state, setState] = useState("");
  const [list, setList] = useState([]);
  const handleChange = async (e) => {
    setList(["loading..."]);
    const response = await axios("/v1/public/states?search=" + e.target.value);
    setList(
      response?.data?.states?.length
        ? response?.data?.states
        : ["No states found"]
    );
  };

  const selectValue = (selectedValue) => {
    setState("");
    setValue(selectedValue);
  };

  return (
    <div className="mb-3 position-relative">
      <div className="mb-3 position-relative">
        <div className="mb-3">
          <input
            type="text"
            className="form-control text-black"
            value={value}
            onKeyUp={handleChange}
            placeholder="Enter state"
            onChange={(e) => setState(e.target.value)}
          />
          {state?.length > 0 ? (
            <div className="dropdown-menu d-block mt-0 provider-talk-select-div">
              <ul className="provider-select-ul">
                {list?.length &&
                  list?.map((data, i) => (
                    <li
                      key={i}
                      onClick={() => selectValue(data?.state)}
                      className="provider-talk-select-li"
                    >
                      {data?.state || data}
                    </li>
                  ))}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default StateSelect;
