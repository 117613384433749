import * as yup from "yup";

export const patientSchema = (path) => {
  let schema = yup.object({
    firstName: yup
      .string()
      .required("First name is required")
      .matches(
        /^(?=.*[a-zA-Z])[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
        "Please enter the First Name correctly"
      ),
    lastName: yup.string().when("userType", {
      is: (userType) =>
        userType === "rehabilitation_center" ||
        userType === "hospital" ||
        userType === "clinic" ||
        userType === "gym",
      then: yup.string(),
      otherwise: yup
        .string()
        .required("Last name is required")
        .matches(
          /^(?=.*[a-zA-Z])[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
          "Please enter the Last Name correctly"
        ),
    }),
    userType: yup.string(),
  });

  if (path === "/finish/your/application") {
    return schema.required();
  } else {
    return schema
      .shape({
        email: yup
          .string()
          .email("Email must be a valid email")
          .required("Email is a required field"),
        password: yup
          .string()
          .required("Password is a required field")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
          ),
      })
      .required();
  }
};
