import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import axios from "../../../../util/axiosInst";
import mixpanel from "mixpanel-browser";
import { MuscleTypes } from "../../../../constants/index";
import HelmetTag from "../../../../components/helmet/Helmet";
import Layout from "../layout/Layout";
import Image from "../custom/Image";

const Question = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { muscleType } = useParams();

  const currentQuestionNo = useStoreState((state) => state.currentQuestionNo);
  const initEvaluationStore = useStoreActions(
    (actions) => actions.initEvaluationStore
  );
  const [muscleGroups, setMuscleGroups] = useState([]);

  const fetchMuscleGroups = async () => {
    const { data } = await axios.get("/v1/public/evaluation/type");
    const muscleGroupsLocal = data.map((muscleGroup) => {
      return {
        name: muscleGroup.name,
        id: muscleGroup.evaluationType,
        serverId: muscleGroup.id,
      };
    });
    setMuscleGroups(muscleGroupsLocal);
  };

  const fetchQuestionsForMuscleGroup = async (muscleGroupId) => {
    navigate(`/symptom-checker/${muscleGroupId}/questions`, {
      state: location.state ? location.state.option : null,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      window.fbq("track", `Evaluation Page is opened.`);
      mixpanel.track("User", {
        type: "Page View",
        name: "Evaluation Page",
      });
    } catch (error) {}

    if (!muscleType) {
      // document
      //   .querySelector("#evaluationDisclaimerModal")
      //   .classList.add("show");
      // document.querySelector("#evaluationDisclaimerModal").style.display =
      //   "block";
      // document
      //   .getElementById("custom-drop")
      //   .classList.add("modal-backdrop", "fade", "show");
      initEvaluationStore();
      fetchMuscleGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muscleType]);

  return (
    <Layout>
      <div className="">
        <HelmetTag
          title="Orthopedic Condition Diagnosis Tools | My Orthopedic Problem"
          content="The source of your orthopedic pain with our interactive diagnostic tools and quickly find treatment options from trusted experts."
          image="https://myorthopedicproblem.com/MyOrthopedicProblem.png"
          url="http://dev.myorthopedicproblem.com/evaluation/"
          canonical="https://myorthopedicproblem.com/evaluation"
          JSONLD={`
        {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "Symptom Checker",
          "url": "http://myorthopedicproblem.com/evaluation",
          "description": "The source of your orthopedic pain with our interactive diagnostic tools and quickly find treatment options from trusted experts."
        }
        `}
        />
        <div className={`container rounded border shadow-lg mt-5`}>
          <section className="sec_three p-0 pb-4">
            <div className="container bg-white rounded">
              {currentQuestionNo === -1 && (
                <>
                  <div className="content_01">
                    <div className="container pt-3">
                      <h1 className="fw-bold mb-1">Symptom Checker</h1>
                      <h6 className="text-body-tertiary mb-3">
                        Enter your symptoms and get a probable diagnosis
                      </h6>
                    </div>
                    <div className="container-fluid px-0">
                      <div className="col-12 ">
                        <div className="row row-cols-md-4 px-md-3">
                          {MuscleTypes.map((muscleGroup) => (
                            <div
                              className="evaluate_img_evaluation content-animation mt-3 mb-4 p-0 col-md-3 col-12"
                              onClick={() => {
                                let id = muscleGroups.filter(
                                  (item) => item.name === muscleGroup.name
                                );
                                if (id.length > 0)
                                  fetchQuestionsForMuscleGroup(
                                    id[0].id,
                                    id[0].serverId
                                  );
                              }}
                              key={muscleGroup?.slug}
                            >
                              <Image
                                local={true}
                                src={muscleGroup.image}
                                className="w-100 h-100"
                                alt={muscleGroup.name}
                              />
                              <div className="evaluate_content">
                                <h5 className=" text-white mb-0">
                                  {muscleGroup.name}
                                </h5>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <Outlet />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};
export default Question;
