import React, { createRef } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Layout from "../layout/Layout";
import MOPEducation from "./MOPEducation";
import ProviderEducation from "./ProviderEducation";

const EducationPage = () => {
  const ref = createRef();

  return (
    <Layout>
      <div ref={ref} className="pb-5">
        <div className="container rounded p-3">
          <div
            className="container p-5 rounded border bg-white shadow-lg"
            ref={ref}
          >
            <div
              className={`container pb-1 ${window.innerWidth > 991 ? "" : ""}`}
            >
              <h1 className="text-black fw-bold mb-1 d-md-block d-none">
                Education Library
              </h1>
              <h1 className="text-black fw-bold mb-1 d-md-none d-block">
                Education Library
              </h1>
              <h6 className="text-body-tertiary mb-3">
                Learn more about different conditions and treatment options
              </h6>
            </div>
            <Tabs
              defaultActiveKey="education"
              className="px-3 py-3 mb-3 rounded"
              variant="pills"
            >
              <Tab eventKey="education" title="Condition">
                <MOPEducation />
              </Tab>
              <Tab eventKey="provider-post" title="Provider Posts">
                <ProviderEducation />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EducationPage;
