import { useEffect, useState } from "react";
import moment from "moment";
import ReactPlayer from "react-player";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "../../../../util/axiosInst";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import MediaPreview from "../../../../components/common/MediaPreview";
import HelmetTag from "../../../../components/helmet/Helmet";
import Layout from "../layout/Layout";

const EducationVideoPlayer = () => {
  const param = useParams();
  const [data, setData] = useState();
  const getExercise = async () => {
    window.scrollTo(0, 0);
    const { data } = await axios.get(
      "/v1/public/exercise/" + param?.videotitle
    );
    setData(data);
  };
  useEffect(() => {
    getExercise();
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      <div className=" bg-muted">
        <HelmetTag
          title={data?.title}
          content={data?.video || data?.link}
          canonical={`https://myorthopedicproblem.com/video/${param.videotitle}`}
          JSONLD={`{
          "@context": "http://schema.org",
          "@type": "BlogPosting",
          "name": "${data?.title}",
          "url": "https://myorthopedicproblem.com/video/${param.videotitle}"
        }
        `}
        />
        <div className="bg-white rounded p-3">
          <div className="row">
            <div className="col-lg-1 mt-3">
              <ul className="text-decoration-none d-md-block  d-none sticky-top">
                <FacebookShareButton
                  url={`${
                    process.env.REACT_APP_SERVER_URL.replace("api", "") +
                    "/video/" +
                    param?.videotitle
                  }`}
                  hashtag={"#News"}
                  quote={data?.title}
                  className={"d-block"}
                >
                  <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                    <i className="fab fa-facebook-f padding-rem"></i>
                  </div>
                </FacebookShareButton>
                <TwitterShareButton
                  url={`${
                    process.env.REACT_APP_SERVER_URL.replace("api", "") +
                    "/video/" +
                    param?.videotitle
                  }`}
                  title={data?.title}
                >
                  <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                    <i className="fa fa-twitter padding-rem"></i>
                  </div>
                </TwitterShareButton>
                <LinkedinShareButton
                  url={`${
                    process.env.REACT_APP_SERVER_URL.replace("api", "") +
                    "/video/" +
                    param?.videotitle
                  }`}
                  title={data?.title}
                  source={data?.title}
                  summary={data?.title}
                  className={"d-block"}
                >
                  <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                    <i className="fab fa-linkedin padding-rem"></i>
                  </div>
                </LinkedinShareButton>
              </ul>
            </div>
            <div className="col-lg-11">
              {data ? (
                <>
                  {(data?.video &&
                    data?.video?.indexOf("amazonaws.com") === 0) ||
                  (data?.link && data?.link) ? (
                    <ReactPlayer
                      url={data?.link}
                      controls={true}
                      width={"100%"}
                      height={"400px"}
                    />
                  ) : (
                    <MediaPreview
                      fileName={data?.video}
                      type="video"
                      height={window.innerWidth > 768 ? "400px" : "250px"}
                    />
                  )}
                  <div className="mt-2 mb-4">
                    <div className="d-flex justify-content-between mt-2">
                      <p className="text-muted mb-0 text-capitalize">
                        {data?.blogcategories}
                      </p>
                    </div>
                    <h5
                      className="text-truncate margintop-10px"
                      title={data?.title}
                    >
                      {data?.title}
                    </h5>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted mb-2">{data?.providername}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted mb-2">
                        {moment(data?.createdAt).format("MMM Do YYYY, h:mm a")}
                      </p>
                    </div>
                  </div>
                  <div className="container mb-3">
                    <h5 className=" d-flex justify-content-center">
                      <strong>Share:</strong>
                    </h5>
                    <nav className="d-flex justify-content-center ">
                      <FacebookShareButton
                        url={`${
                          process.env.REACT_APP_SERVER_URL.replace("api", "") +
                          "/video/" +
                          param?.videotitle
                        }`}
                        hashtag={"#News"}
                        quote={data?.title}
                        className={"d-block mx-3"}
                      >
                        <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                          <i className="fab fa-facebook-f padding-rem"></i>
                        </div>
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`${
                          process.env.REACT_APP_SERVER_URL.replace("api", "") +
                          "/video/" +
                          param?.videotitle
                        }`}
                        title={data?.title}
                      >
                        <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                          <i className="fa fa-twitter padding-rem"></i>
                        </div>
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={`${
                          process.env.REACT_APP_SERVER_URL.replace("api", "") +
                          "/video/" +
                          param?.videotitle
                        }`}
                        title={data?.title}
                        source={data?.title}
                        summary={data?.title}
                        className={"d-block mx-3"}
                      >
                        <div className="text-decoration-none bg-primary text-white rounded-circle socialmedia-btn mb-4">
                          <i className="fab fa-linkedin padding-rem"></i>
                        </div>
                      </LinkedinShareButton>
                    </nav>
                  </div>
                </>
              ) : (
                <Spinner
                  animation="border"
                  className="d-flex m-0 m-auto mt-5 height-3rem width-3rem"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EducationVideoPlayer;
