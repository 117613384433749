import { useStoreState, useStoreActions } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../util/axiosInst";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FcLike } from "react-icons/fc";
import { FaCommentDots } from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import { BsFillBookmarkFill } from "react-icons/bs";
import UserProfile from "../images/user.png";
import EducationPageModal from "./education/EducationPageModal";
import Comment from "./education/Comment";
import LoadingScreen from "./custom/LoadingScreen";
import { SwalAlertFunction } from "../pages/SwalAlert";
import Image from "./Image";

const Blogspage = () => {
  const params = useParams();
  const id = params.id;
  const nameCondition = ["rehabilitation_center", "gym", "hospital", "clinic"];
  const userData = localStorage.getItem("mop-user");
  const [article, setArticle] = useState({});
  const [provider_detail, setprovider_detail] = useState();
  const [addcomment, setaddcomment] = useState(false);
  const [showcomments, setshowcomments] = useState(false);
  const [savepost, setsavepost] = useState(false);

  const educationPageModalData = useStoreState((state) =>
    localStorage.getItem("mop-user")
      ? JSON.parse(localStorage.getItem("mop-user"))
      : JSON.parse(localStorage.getItem("education-page"))
  );

  const { email, firstName, lastName, zipcode } = educationPageModalData
    ? educationPageModalData
    : { email: "", firstName: "", lastName: "", zipcode: "", userType: "" };
  const getArticle = async (id) => {
    const { data } = await axios.get("/v1/public/blog/" + id);
    setArticle(data);
    const detail = await axios.get(
      "/v1/public/provider/" + data?.createdBy.slug + "/detail"
    );
    setprovider_detail(detail.data);
    setData(data?.content.replaceAll("<a href", "<iframe src"));
  };

  const setComments = useStoreActions((actions) => actions.setComments);

  const comments = useStoreState((state) => state.comments);
  const getComments = async () => {
    setaddcomment(false);
    const { data } = await axios.get(`/v1/public/blog/${id}/comments`);
    setComments(data);
  };
  const [data, setData] = useState(null);
  const schema = yup
    .object({
      message: yup.string().required("Message is a required field"),
    })
    .required();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    if (email !== "") {
      formData.firstName = firstName;
      formData.lastName = lastName;
      formData.email = email;
      if (zipcode !== "") formData.zipcode = zipcode;
      try {
        const response = await axios.post(
          `/v1/public/blog/${id}/comments`,
          formData
        );
        if (response.status === 200) {
          reset();
          window.fbq("track", "MOP Blog Comment is added.", {
            name: formData.firstName + formData.lastName,
            email: formData.email,
          });
          SwalAlertFunction("Your comment is added.", "success");
          getComments();
        }
      } catch (e) {}
    } else {
      SwalAlertFunction(
        "We need your details to post a comment.",
        "warning",
        () => {
          document.querySelector("#educationPageModal").classList.add("show");
          document.querySelector("#educationPageModal").style.display = "block";
          document
            .getElementById("custom-drop")
            .classList.add("modal-backdrop", "fade", "show");
        }
      );
    }
  };
  useEffect(() => {
    getArticle(params.id);
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    setInterval(() => {
      if (document.getElementsByClassName("blog").length > 0) {
        document
          .getElementsByClassName("blog")[0]
          .childNodes.forEach((element) => {
            element.childNodes.forEach((tags) => {
              if (tags.localName === "img") tags.classList.add("w-100");
              if (tags.localName === "iframe" && tags.localName !== undefined) {
                tags.style.width = "100%";
                tags.style.height = "630px";
              }
            });
          });
      }
    }, 2000);
  }, []);

  return (
    <>
      <div className="float-end">
        <h5 className="fw-semibold ">
          Save
          {savepost ? (
            <BsFillBookmarkFill
              onClick={() => {
                setsavepost(false);
                SwalAlertFunction("Coming Soon...", "info");
              }}
              className="fillBookmarkFill"
            />
          ) : (
            <BsFillBookmarkFill
              onClick={() => {
                setsavepost(true);
                SwalAlertFunction("Coming Soon...", "info");
              }}
              className="fillBookmarkFill"
            />
          )}
        </h5>
      </div>
      <div className="border rounded bg-white mb-5 p-3 px-3">
        <div className="border-0 bg-white ">
          <div className="d-flex px-0">
            <div className=" d-flex">
              <Image
                src={
                  provider_detail?.photo ? provider_detail.photo : UserProfile
                }
                alt={`provider ${
                  nameCondition.includes(article.createdBy?.userType)
                    ? article.createdBy?.firstName
                    : article.createdBy?.name
                }`}
                type="provider"
                className="rounded-circle  profile-icon"
              />
              <div className="d-flex">
                <div className="sm-0 p-4 px-1">
                  <h5 className="text-muted m-0 p-0">
                    <Link
                      to={`/providers/${
                        article?.createdBy?.slug || article?.providerId
                      }`}
                      state={{
                        userId: article?.createdBy?.id,
                        id: article?.providerId,
                      }}
                      className="text-decoration-none text-black"
                    >
                      {(article?.createdBy &&
                        (nameCondition.includes(article.createdBy?.userType)
                          ? article.createdBy?.firstName
                          : article.createdBy?.name)) ||
                        ""}
                    </Link>
                  </h5>
                  <div className="d-flex  text-capitalize text-muted">
                    {article.createdBy?.userType.replace("_", " ")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded overflow-hidden my-3 d-flex  justify-content-center">
          <Image
            className="img-fluid max-h-250"
            src={article.banner}
            alt={article.title}
          />
        </div>
        <div
          className="blog"
          id="blog-content"
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        ></div>
        <div className=" text-muted d-flex justify-content-between">
          <>
            <FcLike
              className="height-30px width-30px"
              onClick={() =>
                userData
                  ? SwalAlertFunction("Coming Soon...", "info")
                  : SwalAlertFunction("Login or Sign Up to Comment.", "info")
              }
            />
            0
          </>
          <div
            className="cursor-pointer"
            onClick={(e) => {
              setshowcomments(!showcomments);
            }}
          >
            {comments?.length} Comments 0 shares
          </div>
        </div>
        <hr className="p-0 border-4 h-3 m-2" />
        <p className=" text-muted d-flex justify-content-center">
          <span
            className="cursor-pointer px-4"
            onClick={(e) => {
              if (userData) SwalAlertFunction("Coming Soon...", "info");
              else SwalAlertFunction("Login or Sign Up to Comment.", "info");
            }}
          >
            <FcLike className="social-icon" />
            &nbsp;Like
          </span>
          <span
            className="cursor-pointer px-4"
            onClick={(e) => {
              setaddcomment(!addcomment);
              if (!userData)
                SwalAlertFunction("Login or Sign Up to Comment.", "info");
            }}
          >
            <FaCommentDots className="social-icon icon-blue" />
            &nbsp;Comment
          </span>
          <span
            className="cursor-pointer px-4"
            onClick={(e) => {
              if (userData) SwalAlertFunction("Coming Soon...", "info");
              else SwalAlertFunction("Login or Sign Up to Comment.", "info");
            }}
          >
            <IoIosShareAlt className="social-icon icon-green" />
            Share
          </span>
        </p>
        {userData && addcomment && (
          <>
            <div className="d-flex mb-3">
              <EducationPageModal getComments={getComments} />
            </div>
            <form className="pb-5">
              <div className="d-flex rounded-pill overflow-hidden border justify-content-between">
                <input
                  type="text"
                  id="add-comment"
                  className={`form-control ${
                    errors.message ? "is-invalid" : ""
                  }`}
                  placeholder="Add Comment"
                  {...register("message")}
                />
                <div className="invalid-feedback">
                  {errors.message?.message}
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  {isSubmitting ? <LoadingScreen color="primary" /> : "Submit"}
                </button>
              </div>
            </form>
          </>
        )}
        {showcomments &&
          comments &&
          comments.length > 0 &&
          comments.map((comment, i) => (
            <Comment
              key={i}
              name={
                comment?.name
                  ? comment?.name
                  : comment?.provider?.userType === "hospital" ||
                    comment?.provider?.userType === "clinic" ||
                    comment?.provider?.userType === "gym" ||
                    comment?.provider?.userType === "rehabilitation_center"
                  ? comment?.firstName
                  : comment?.firstName + " " + comment?.lastName
              }
              createdAt={comment?.createdAt}
              message={comment?.message}
              provider={comment?.provider?.photo}
              comment={comment}
              blogId={id}
              getComments={getComments}
              author={
                ["rehabilitation_center", "gym", "hospital"].includes(
                  article.createdBy?.userType
                )
                  ? article.createdBy?.firstName
                  : article.createdBy?.name
              }
            />
          ))}
      </div>
    </>
  );
};
export default Blogspage;
