import React from "react";
import Layout from "./layout/Layout";
import Footer from "./Home/Footer";
const WhoWeAre = () => {
  return (
    <Layout>
      <section
        className={`container px-5 ${window.innerWidth > 769 ? "" : ""}`}
      >
        <h1 className="landing-display-1 text-center mt-3rem mb-4 mt-4">
          Who we are
        </h1>
        <div className="px-5">
          <span>
            <p className="text-primary fw-600 fs-28px mb-1">
              Dr. Kenneth Bramlett, Founder
            </p>
            <p className="fs-18px mb-4">
              Dr. Bramlett is a Board-Certified Orthopedic Surgeon. He has been
              a practicing orthopedic surgeon for over 30 years and has
              performed over 30,000 orthopedic cases. What sets Dr. Bramlett
              apart is his intense focus on the problems presented by his
              patients, and the effort he delivers to solve his patients’
              problems in a simple and safe manner. It is his passion to solve
              patient problems big or small and to get them back to their
              preferred form of normal. It’s a huge honor in his eyes to take
              cate of patients and he senses the responsibility to help them
              create a better more positive personal health profile, learn how
              to avoid injury and problems, and assist them in a personal custom
              tailored surgical recovery as needed. As a designer and developer,
              he originated the Surgical Pre-Habilitation philosophy and
              Managment Technique Ortho P.A.C.E.in 1990. This patient education
              tool has helped thousand s to lose weight, increase their
              intrinsic strength and in hundreds of scenarios avoid surgical
              intervention and progressive functional decline. He has developed
              and partnered on multiple knee and hip implant deign development
              projects, patient software education systems, and has been a key
              innovator in multiple surgical techniques regarding hip knee
              shoulder and foot and surgery.
            </p>
          </span>
          <span>
            <p className="text-primary fw-600 fs-28px mb-1">
              Kevin Wilk, PT, DPT, FAPTA
            </p>
            <p className="fs-18px mb-4">
              Dr. Kevin Wilk has been a physical therapist, researcher and
              educator for over 39 years. Kevin is currently Associate Clinical
              Director and Co-Founder for Champion Sports Medicine (a Select
              Medical Facility) in Birmingham, AL. Dr. Wilk is also the
              Vice-President of clinical research and education for Select
              Medical. In addition, he is the Director of Rehabilitative
              Research at the American Sports Medicine Institute in Birmingham
              and is Adjunct Assistant Professor in the Physical Therapy Program
              at Marquette University in Milwaukee, WI. Dr Wilk received his
              physical therapy from Northwestern University Medical School in
              Chicago, IL, and his DPT from Massachusetts General Hospital
              Institute of HealthCare Professions in Boston, MA. Dr. Wilk has
              performed rehabilitation on some great athletes through his
              career, here are a few of the names: Michael Jordan, Bo Jackson,
              Charles Barkley, Derek Jeter, Drew Brees, HHH, John Cena, Scottie
              Pippen, Tom Watson, Roger Clemens, Mariano Rivera, John Smoltz,
              Eli Manning, to name just a few.
            </p>
          </span>

          <span>
            <p className="text-primary fw-600 fs-28px mb-1">
              Jarred Commander, PT, DPT
            </p>
            <p className="fs-18px mb-4">
              We routinely engage in penetration testing conducted by
              independent, specialized third-party firms. These tests are
              integral to our software development and engineering processes,
              helping us to continually enhance our platforms and applications.
              Additionally, we align our policies and standards with the
              requirements of Privacy Shield, General Data Protection Regulation
              2016/679 (GDPR), and the forthcoming California Consumer Privacy
              Act (CCPA). Culture and Processes Our team is the backbone of our
              security framework. Every potential employee undergoes
              comprehensive criminal, educational, and employment background
              checks. Upon joining, they receive extensive training in privacy
              and security, and sign legally binding non-disclosure and
              confidentiality agreements. Our company culture emphasizes ongoing
              security and privacy discussions, supported by updates from our
              Information Security & Compliance team through various
              communication channels.
            </p>
          </span>

          <span>
            <p className="text-primary fw-600 fs-28px mb-1">
              James Stanton, NP
            </p>
            <p className="fs-18">
              Access to sensitive data is strictly regulated through role-based
              access control (RBAC), ensuring that only authorized personnel can
              access the information they need to perform their duties. Our
              stringent access protocols, including multi-factor authentication
              for our Production Network, are a testament to our commitment to
              data security. Technology and Design Incorporating compliance into
              our technological framework is a cornerstone of our approach. From
              data architecture to product and platform design, we integrate
              compliance measures at every level. Our communication channels are
              secured with industry-standard encryption protocols such as
              Transport Layer Security (TLS) and Hypertext Transfer Protocol
              Secure (HTTPS), and we extend TLS support for email encryption in
              transit.
            </p>
            <p className="fs-18px mb-4">
              At MyOrthopedicProblem.com, safeguarding your trust is not just a
              responsibility; it's our core value. We are committed to
              delivering an environment where your health information is secure,
              allowing you to focus on what matters most - your orthopedic
              health and wellness.
            </p>
          </span>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default WhoWeAre;
