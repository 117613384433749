import React, { useState } from 'react';
import { Rating } from '@material-ui/lab';
import { FaThumbsUp } from 'react-icons/fa';
import { MdOutlineStarBorder } from 'react-icons/md';
import { Box, makeStyles } from '@material-ui/core';
import { useAppState } from '../../state';

import IntroContainer from '../IntroContainer/IntroContainer';

const useStyles = makeStyles((theme) => ({
  feedbackContainer: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    marginTop: '100px',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
      textAlign: 'center',
      width: '100%',
    },
  },
}));

const FeedbackStar = () => {
  const classes = useStyles();
  const [value, setValue] = useState(3);
  const [hover, setHover] = useState(-1);
  const { updateFeedbackStar, virtualcare } = useAppState();

  const handleSubmit = async (event, newValue) => {
    event.preventDefault();
    await updateFeedbackStar(virtualcare, newValue);
    window.close();
  };

  const labels = {
    0.5: 'Terrible',
    1: 'Poor',
    1.5: 'Not great',
    2: 'Fair',
    2.5: 'Okay',
    3: 'Good',
    3.5: 'Very good',
    4: 'Excellent',
    4.5: 'Outstanding',
    5: 'Superb',
  };

  const getLabelText = (value) => {
    return labels[value];
  };

  return (
    <IntroContainer>
      <div className={classes.feedbackContainer}>
        <div className="mb-4" style={{ width: '100%' }}>
          {(virtualcare && virtualcare.userRole === 'patient' && <h4>How was the quality of your appointment?</h4>) ||
            'Thanks for Joining with us!'}
        </div>
        {virtualcare && virtualcare.userRole === 'patient' && (
          <div className="mb-4" style={{ width: '100%' }}>
            <FaThumbsUp className="text-primary" style={{ fontSize: '50px' }} />
          </div>
        )}
        {virtualcare && virtualcare.userRole === 'patient' && (
          <div className="mb-2" style={{ width: '100%' }}>
            <Rating
              size="large"
              name="size-large"
              value={value}
              precision={0.5}
              getLabelText={getLabelText}
              onChange={(event, newValue) => {
                setValue(newValue);
                handleSubmit(event, newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={<MdOutlineStarBorder style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
          </div>
        )}
        {virtualcare && virtualcare.userRole === 'patient' && value !== null && (
          <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
        )}
      </div>
    </IntroContainer>
  );
};

export default FeedbackStar;
