import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import axios from "../../../utils/axiosInst";
import {
  certificationInitValue,
  classesInitValue,
  educationInitValue,
} from "./init";
import Time from "../custom/Time";
import SocialMedia from "../custom/SocialMedia";
import Certifications from "../custom/Certifications";
import { PAGE_VARIABLES } from "../../constants";
import BasicInfo from "../custom/BasicInfo";
import Biography from "../custom/Biography";
import Speciality from "../custom/Speciality";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TextInput from "../custom/TextInput";

const WellnessProviderProfile = ({ useFormControl, signupData }) => {
  const MySwal = withReactContent(Swal);

  const [certificationRows, setCertificationRows] = useState([
    certificationInitValue,
  ]);
  const [educationRows, setEducationRows] = useState([educationInitValue]);
  const [classesRows, setClassesRows] = useState([classesInitValue]);
  const [select, setSelect] = useState("Yes");

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormControl;

  const getDayDetails = (id, ind, name, index) => {
    if (!classesRows[index].timings[ind].days.includes(id)) {
      let row = getValues("classes_info");
      row[index].timings[ind].days = row[index]?.timings[ind]?.days
        ? [...row[index].timings[ind].days, id]
        : [id];
      setClassesRows([...row]);
      setValue(name, row[index].timings[ind].days);
    } else {
      const filter = classesRows[index].timings[ind].days.filter(
        (data) => data !== id
      );
      let row = getValues("classes_info");
      row[index].timings[ind].days = filter;
      setClassesRows([...row]);
      setValue(name, filter);
    }
  };

  useEffect(() => {
    async function getData() {
      if (JSON.parse(localStorage.getItem("claim-status") === "true")) {
        MySwal.fire({
          title: PAGE_VARIABLES?.COMMON?.ALERT_MESSAGE,
          icon: "info",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        let data = {
          loggedIn: true,
        };

        if (JSON.parse(localStorage.getItem("claim-id"))) {
          await axios.patch(
            `/v1/claimProfile/${JSON.parse(localStorage.getItem("claim-id"))}`,
            data
          );
        }
      }
    }
    getData();
    // eslint-disable-next-line
  }, []);

  const watchStartTime = useWatch({
    control,
    name: "classes_info",
  });
  const getEndTime = (ind, index) => {
    let tempArray = [...PAGE_VARIABLES?.COMMON?.TIME_VALUE];
    if (watchStartTime) {
      const lastIndex = tempArray.findIndex(
        (time) => time === watchStartTime[ind]?.timings[index]?.start_time
      );
      let itemToBeRemoved = tempArray.splice(0, lastIndex + 1);
      if (
        itemToBeRemoved.includes(watchStartTime[ind]?.timings[index]?.end_time)
      ) {
        setValue(`classes_info[${ind}].timings[${index}].end_time`, "");
      }
      return [...itemToBeRemoved];
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="mt-3">
            <BasicInfo register={register} useFormControl={useFormControl} />
          </div>
          <div className="mt-3">
            <Biography register={register} />
          </div>
          <div className="mt-3">
            <Speciality register={register} />
          </div>
          <Certifications
            certificationIntitialValue={certificationInitValue}
            setValue={setValue}
            signupData={signupData}
            setCertificationRows={setCertificationRows}
            getValues={getValues}
            certificationRows={certificationRows}
            register={register}
            watch={watch}
            errors={errors}
          />
          <div className="container mt-4">
            <h5 className="fontweight-bold mb-3">Education</h5>
            {educationRows.map((educationRow, ind) => (
              <div className="row">
                <div className="col-md-4">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Degree"
                    name={`education[${ind}].degree`}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Degree Institute"
                    name={`education[${ind}].college`}
                  />
                </div>
                <div className="col-md-4 mb-1">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Year of Completion"
                    name={`education[${ind}].completionyear`}
                  />
                </div>
                <div className="row">
                  {educationRows.length - 1 === ind && (
                    <div
                      className="col-md-2 text-primary cursor-pointer"
                      onClick={() => {
                        const rows = [
                          ...getValues("education"),
                          educationInitValue,
                        ];
                        setEducationRows(rows);
                      }}
                    >
                      Add new
                    </div>
                  )}
                  <div
                    className="col-md-1 text-danger cursor-pointer"
                    onClick={() => {
                      let rows = [...getValues("education")];
                      if (rows.length > 1) {
                        rows.splice(ind, 1);
                        setEducationRows(rows);
                        setValue("education", rows);
                      } else {
                        setEducationRows([educationInitValue]);
                        setValue("education", [educationInitValue]);
                      }
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mb-3">Business Info</h5>
            <div className="row mb-3">
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Business Name"
                  name={"business_info.name"}
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Business Address"
                  name={"business_info.address"}
                />
              </div>
              <div className="col-md-4 mb-1">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Business City"
                  name={"business_info.city"}
                />
              </div>
              <div className="col-md-4 mb-1">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Business State"
                  name={"business_info.state"}
                />
              </div>
              <div className="col-md-4 mb-1">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Business Zip"
                  name={"business_info.zipcode"}
                  maxlength={5}
                />
              </div>
              <div className="col-md-4 mb-1">
                <TextInput
                  maxlength={10}
                  errors={errors}
                  register={register}
                  label="Business Phone Number"
                  name={"business_info.phoneNumber"}
                />
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mb-3">Classes Info</h5>
            {classesRows.map((classRow, ind) => (
              <>
                <div className="row mb-3" key={ind}>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="Classes Taught"
                      name={`classes_info[${ind}].taught`}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <TextInput
                      errors={errors}
                      register={register}
                      label="Classes Location"
                      name={`classes_info[${ind}].location`}
                    />
                  </div>
                </div>
                <div className="py-3">
                  <h5 className="mb-0">Classes Timings</h5>
                </div>
                <div className="row">
                  {classRow?.timings?.map((day, index) => (
                    <>
                      <div className="col-md-4 mb-3" key={index}>
                        <div className="row">
                          {PAGE_VARIABLES?.COMMON?.DAYS.map((muscleGroup) => (
                            <div
                              className="col-auto pe-0 mb-3"
                              onClick={() => {
                                getDayDetails(
                                  muscleGroup.value,
                                  index,
                                  `classes_info[${ind}].timings[${index}].days`,
                                  ind
                                );
                              }}
                              key={muscleGroup.id}
                            >
                              <div
                                className={
                                  day?.days?.includes(muscleGroup.value)
                                    ? "education-active bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                                    : "bg-white box-shadow align-items-center text-center border border-blue cursor-pointer px-2 py-2 rounded-1 d-flex"
                                }
                                id={muscleGroup.evaluationType}
                              >
                                <p className="text-center small mb-0">
                                  {muscleGroup.value}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="col-md-4 mb-1">
                        <label>Start Time</label>
                        <Time
                          register={register}
                          name={`classes_info[${ind}].timings[${index}].start_time`}
                        />
                      </div>
                      <div className="col-md-4 mb-1">
                        <label>End Time</label>
                        <Time
                          register={register}
                          disabledEndTimes={getEndTime(ind, index)}
                          name={`classes_info[${ind}].timings[${index}].end_time`}
                        />
                      </div>
                      <div className="row mb-3">
                        {classesRows[ind].timings.length - 1 === index && (
                          <div
                            className="col-md-3 text-primary cursor-pointer"
                            onClick={() => {
                              const rows = {
                                timings: [
                                  ...getValues("classes_info")[ind].timings,
                                  {
                                    days: [],
                                    start_time: "",
                                    end_time: "",
                                  },
                                ],
                              };
                              let businessHoursRow = classesRows;
                              businessHoursRow[ind] = rows;
                              setClassesRows([...businessHoursRow]);
                              setValue(
                                `classes_info[${ind}.timings]`,
                                rows.timings
                              );
                            }}
                          >
                            Add Class Timings
                          </div>
                        )}

                        <div
                          className="col-md-3 text-danger cursor-pointer"
                          onClick={() => {
                            let rows = [
                              ...getValues("classes_info")[ind].timings,
                            ];
                            if (rows.length > 1) {
                              rows.splice(index, 1);
                              let businessHoursRow = classesRows;
                              businessHoursRow[ind].timings = rows;
                              setClassesRows([...businessHoursRow]);
                              setValue(
                                `classes_info[${ind}].timings`,
                                businessHoursRow[0].timings
                              );
                            } else {
                              let businessHoursRow = classesRows;

                              businessHoursRow[ind].timings = [
                                {
                                  days: [],
                                  start_time: "",
                                  end_time: "",
                                },
                              ];
                              setClassesRows([...businessHoursRow]);

                              setValue(`classes_info[${ind}].timings`, [
                                {
                                  days: [],
                                  start_time: "",
                                  end_time: "",
                                },
                              ]);
                            }
                          }}
                        >
                          Remove Class Timings
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="row mb-1">
                    {classesRows.length - 1 === ind && (
                      <div
                        className="col-md-2 p-2 text-primary cursor-pointer"
                        onClick={() => {
                          const rows = [
                            ...getValues("classes_info"),
                            classesInitValue,
                          ];
                          setClassesRows(rows);
                        }}
                      >
                        Add Classes Info
                      </div>
                    )}
                    <div
                      className="col-md-3 p-2 text-danger cursor-pointer"
                      onClick={() => {
                        let rows = [...classesRows];
                        if (rows.length > 1) {
                          rows.splice(ind, 1);
                          setClassesRows(rows);
                          setValue("classes_info", rows);
                        } else {
                          setClassesRows([classesInitValue]);
                          setValue("classes_info", [classesInitValue]);
                        }
                      }}
                    >
                      Remove Classes Info
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mb-3">Ask a question</h5>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div className=" my-4">
                  <label className="form-radio-label" for="inlineRadio1">
                    Ask a Question
                    <span data-tip data-for="info">
                      <i className="fa fa-info-circle me-2 text-primary padding-left-5px" />
                    </span>
                    <ReactTooltip id="info" place="right" effect="solid">
                      <span>
                        Ask a question is a unique feature for providers to
                        receive questions and communicate directly with patients
                        regarding their conditions. Please select “No” if you do
                        not wish to communicate with patients in this way
                      </span>
                    </ReactTooltip>
                  </label>
                  <div className="radio-buttons d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        name="askAnExpert"
                        className="align-middle me-1"
                        value="Yes"
                        checked={select === "Yes"}
                        onChange={(e) => setSelect("Yes")}
                      />
                      <label
                        className="form-check-label me-4"
                        for="inlineRadio1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        name="askAnExpert"
                        className="align-middle me-1"
                        value="No"
                        checked={select === "No"}
                        onChange={(e) => setSelect("No")}
                      />
                      <label className="form-check-label" for="inlineRadio2">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mb-3">Social Media</h5>
            <div className="row">
              <SocialMedia errors={errors} register={register} />
            </div>
          </div>
          <div className="container mt-4">
            <div className="row py-4 px-4">
              <div className="col-md-6 mb-3 d-flex flex-column">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Years of Experience"
                  name="years_of_experience"
                />
              </div>
              <div className="col-md-6 mb-3 d-flex flex-column">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Forms of Payment accepted"
                  name="payment_accepted_methods"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WellnessProviderProfile;
