import React, { useEffect, Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import Layout from "../provider/components/Layout/Layout";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import JoinOurNetwork from "./components/JoinOurNetwork.jsx";
import OnlinePrescriptions from "./components/OnlinePrescriptions.jsx";
import WellnessAssesment from "./components/WellnessAssesment.jsx";
import WhoWeAre from "./components/WhoWeAre.jsx";
import WhyTrustUs from "./components/WhyTrustUs.jsx";
import WhoWeWorkWith from "./components/WhoWeWorkWith.jsx";
import Newsroom from "./components/Newsroom.jsx";
import Cookiepolicy from "./components/Cookiepolicy.jsx";
import StarRating from "../star-rating/StarRating.jsx";
import Terms from "./components/Terms.jsx";
import Privacypolicy from "./components/Privacypolicy.jsx";
import BlogPostContent from "./components/BlogPostContent.jsx";
import FAQ from "./components/FAQ.jsx";
import ContactUs from "./components/contact-us/ContactUs";
import Question from "./components/symptom-checker/Question";
import MuscleType from "./components/symptom-checker/MuscleType";
import BlogArticle from "./components/education-library/BlogArticle";
import EducationVideoPlayer from "./components/education-library/EducationVideoPlayer";
import EducationPage from "./components/education-library/EducationPage";
import EducationArticle from "./components/education-library/EducationArticle";
import Register from "../../pages/Register.jsx";
import Providers from "./components/providers/Providers.jsx";
import ProviderDetails from "./components/providers/ProviderDetails.jsx";
import SignUp from "./components/singup/SignUp.jsx";
import { showIntercomChatWidget } from "../../util/intercom";

const Login = lazy(() => import("./components/Login.jsx"));
const ForgotPassword = lazy(() => import("./components/ForgotPassword.jsx"));
const ResetPassword = lazy(() => import("./components/ResetPassword.jsx"));
const UserTypes = lazy(() => import("./components/singup/UserTypes.jsx"));

// const CreateAccount = lazy(() =>
//   import("./components/singup/CreateAccount.jsx")
// );
const SubscriptionPlan = lazy(() =>
  import("./components/pricing/SubscriptionPlan.jsx")
);
const SuccessPage = lazy(() => import("./components/pricing/SuccessPage.jsx"));

const PatientSubscriptionPlan = lazy(() =>
  import("./components/pricing/PatientSubscriptionPlan.jsx")
);
const ProviderSubscriptionPlan = lazy(() =>
  import("./components/pricing/ProviderSubscriptionPlan.jsx")
);
const ProviderRegister = lazy(() =>
  import("./components/singup/ProviderRegister.jsx")
);
const MasterServiceAgreement = lazy(() =>
  import("./components/pricing/MasterServiceAgreement.jsx")
);
const OtpVerify = lazy(() => import("./components/singup/OtpVerify.jsx"));
const ProviderFinishYourApplication = lazy(() =>
  import("./components/singup/ProviderFinishYourApplication.jsx")
);
const Unsubscribe = lazy(() => import("./components/Unsubscribe.jsx"));
const UnsubscribeMail = lazy(() => import("./components/UnsubscribeMail.jsx"));

const LandingPageApp = () => {
  useEffect(() => {
    showIntercomChatWidget();
  }, []);

  return (
    <Suspense fallback={<Layout></Layout>}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/star" element={<StarRating />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/usertype" element={<UserTypes />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/otp/verify" element={<OtpVerify />} />
        <Route
          path="/finish/your/application"
          element={<ProviderFinishYourApplication />}
        />
        <Route path="/subscription" element={<SubscriptionPlan />} />
        <Route path="/signup/success" element={<SuccessPage />} />
        <Route path="/patient/signup" element={<SignUp />} />
        <Route path="/unsubscribe-mail" element={<UnsubscribeMail />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route
          path="/patient/subscription"
          element={<PatientSubscriptionPlan />}
        />
        <Route path="/patient/signup/success" element={<SuccessPage />} />
        <Route
          path="/provider/subscription"
          element={<ProviderSubscriptionPlan />}
        />
        <Route path="/provider/signup" element={<ProviderRegister />} />
        <Route
          path="/provider/signup/agreement"
          element={<MasterServiceAgreement />}
        />
        <Route path="/provider/signup/success" element={<SuccessPage />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/join-our-network" element={<JoinOurNetwork />} />
        <Route path="/wellness-assessment" element={<WellnessAssesment />} />
        <Route path="/online-prescriptions" element={<OnlinePrescriptions />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/why-trust-us" element={<WhyTrustUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/symptom-checker" element={<Question />} />
        <Route
          path="/symptom-checker/:muscleType/questions"
          element={<MuscleType />}
        />
        <Route path="/providers/:id" element={<ProviderDetails />} />
        <Route path="/provider-posts/:id" element={<BlogArticle />} />
        <Route path="/video/:videotitle" element={<EducationVideoPlayer />} />
        <Route path="/education-library" element={<EducationPage />} />
        <Route path="/education-library/:id" element={<EducationArticle />} />
        <Route path="/who-we-work-with" element={<WhoWeWorkWith />} />
        <Route path="/newsroom" element={<Newsroom />} />
        <Route path="/cookiepolicy" element={<Cookiepolicy />} />
        <Route path="/termsandconditions" element={<Terms />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/providers" element={<Providers />} />
        <Route path="press/:id" element={<BlogPostContent />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Suspense>
  );
};

export default LandingPageApp;
