import React from "react";
import { ReactComponent as TalkToProvider } from "../../../assets/patient-icons/sidebar/2.svg";
import { ReactComponent as Pharmacy } from "../../../assets/patient-icons/sidebar/9.svg";
import { ReactComponent as SymtomChecker } from "../../../assets/patient-icons/sidebar/3.svg";
import { ReactComponent as MedicalSupplies } from "../../../assets/patient-icons/sidebar/5.svg";
import { ReactComponent as EducationLibrary } from "../../../assets/patient-icons/sidebar/4.svg";
import { ReactComponent as Assessment } from "../../../assets/patient-icons/sidebar/8.svg";
import { useNavigate } from "react-router-dom";

const Session6 = () => {
  const navigate = useNavigate();
  return (
    <section
      className={`px-5 ${window.innerWidth > 769 ? " wrapper mt-8rem" : ""}`}
      style={{ marginTop: "13rem" }}
    >
      <h1 className="fontweight-bold text-center display-4 mt-5">
        What all we offer
      </h1>
      <div className="d-md-flex justify-content-between align-items-center container">
        <div className="row pt-2 mx-5 mt-5 px-5 container">
          <div
            className="col-lg-6 py-3 cursor-pointer"
            onClick={() => navigate("/signup")}
          >
            <div className="row">
              <div className="col-lg-2">
                <span
                  className="rounded-circle rounded-circle-mop fontweight-bold text-white fs--25"
                  style={{
                    padding: "5px 14px",
                    background: "#EBF5FF",
                  }}
                >
                  <TalkToProvider
                    style={{ stroke: "#0071FF" }}
                    width="30px"
                    height="30px"
                    local={true}
                    alt="home-icon"
                  />
                </span>
              </div>
              <div className="col-lg-10 px-2 pe-5">
                <h5
                  className=" fw-700"
                  style={{
                    textDecoration: "underline",
                    color: "#0071FF",
                  }}
                >
                  Talk to a Provider
                </h5>
                <h5 style={{ fontSize: "15px" }}>
                  Engage with a provider at every step of your injury via Video,
                  Call or Message
                </h5>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 py-3 cursor-pointer"
            onClick={() => navigate("/signup")}
          >
            <div className="row">
              <div className="col-lg-2  ">
                <span
                  className="rounded-circle rounded-circle-mop fontweight-bold text-white fs--25"
                  style={{ background: "#EBF5FF" }}
                >
                  <Pharmacy
                    style={{ fill: "#0071FF", stroke: "#0071FF" }}
                    width="30px"
                    height="30px"
                    local={true}
                    alt="home-icon"
                  />
                </span>
              </div>
              <div className="col-lg-10 px-2 pe-5">
                <h5
                  className="cursor-pointer fw-700"
                  style={{
                    textDecoration: "underline",
                    color: "#0071FF",
                  }}
                >
                  Online Prescriptions
                </h5>
                <h5 style={{ fontSize: "15px" }}>
                  Receive discounted online prescriptions simply by becoming a
                  member
                </h5>
              </div>
            </div>
          </div>{" "}
          <div
            className="col-lg-6 py-3 cursor-pointer"
            onClick={() => navigate("/symptom-checker")}
          >
            <div className="row">
              <div className="col-lg-2  ">
                <span
                  className="rounded-circle rounded-circle-mop bg-primary-45 fontweight-bold text-white fs--25"
                  style={{
                    padding: "5px 14px",
                    background: "#EBF5FF",
                  }}
                >
                  <SymtomChecker
                    style={{ stroke: "#0071FF" }}
                    width="30px"
                    height="30px"
                    local={true}
                    alt="home-icon"
                  />
                </span>
              </div>
              <div className="col-lg-10 px-2 pe-5">
                <h5
                  className="cursor-pointer fw-700"
                  style={{
                    textDecoration: "underline",
                    color: "#0071FF",
                  }}
                >
                  Symptom Checker
                </h5>
                <h5 style={{ fontSize: "15px" }}>
                  Quickly identify the cause of your pain with a preliminary
                  diagnosis with AI
                </h5>
              </div>
            </div>
          </div>{" "}
          <div
            className="col-lg-6 py-3 cursor-pointer"
            onClick={() => navigate("/signup")}
          >
            <div className="row">
              <div className="col-lg-2  ">
                <span
                  className="rounded-circle rounded-circle-mop bg-primary-45 fontweight-bold text-white fs--25"
                  style={{
                    padding: "5px 14px",
                    background: "#EBF5FF",
                  }}
                >
                  <MedicalSupplies
                    style={{ stroke: "#0071FF" }}
                    width="30px"
                    height="30px"
                    local={true}
                    alt="home-icon"
                  />
                </span>
              </div>
              <div className="col-lg-10 px-2 pe-5">
                <h5
                  className=" fw-700"
                  style={{
                    textDecoration: "underline",
                    color: "#0071FF",
                  }}
                >
                  Medical Supplies
                </h5>
                <h5 style={{ fontSize: "15px" }}>
                  Order all of your bracing and post-operative medical supplies
                  directly through us
                </h5>
              </div>
            </div>
          </div>{" "}
          <div
            className="col-lg-6 py-3 cursor-pointer"
            onClick={() => navigate("/education-library")}
          >
            <div className="row">
              <div className="col-lg-2  ">
                <span
                  className="rounded-circle rounded-circle-mop bg-primary-45 fontweight-bold text-white fs--25"
                  style={{
                    padding: "5px 14px",
                    background: "#EBF5FF",
                  }}
                >
                  <EducationLibrary
                    style={{ fill: "#0071FF", stroke: "#0071FF" }}
                    width="30px"
                    height="30px"
                    local={true}
                    alt="home-icon"
                  />
                </span>
              </div>
              <div className="col-lg-10 px-2 pe-5">
                <h5
                  className="cursor-pointer fw-700"
                  style={{
                    textDecoration: "underline",
                    color: "#0071FF",
                  }}
                >
                  Education Library
                </h5>
                <h5 style={{ fontSize: "15px" }}>
                  Learn more about your injury and all available treatment
                  options
                </h5>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 py-3 cursor-pointer"
            onClick={() => navigate("/signup")}
          >
            <div className="row">
              <div className="col-lg-2  ">
                <span
                  className="rounded-circle rounded-circle-mop bg-primary-45 fontweight-bold text-white fs--25"
                  style={{
                    padding: "5px 14px",
                    background: "#EBF5FF",
                  }}
                >
                  <Assessment
                    style={{
                      stroke: "#0071FF",
                    }}
                    width="30px"
                    height="30px"
                    local={true}
                    alt="home-icon"
                  />
                </span>
              </div>
              <div className="col-lg-10 px-2 pe-5">
                <h5
                  className="cursor-pointer fw-700"
                  style={{
                    textDecoration: "underline",
                    color: "#0071FF",
                  }}
                >
                  Wellness Assessment
                </h5>
                <h5 style={{ fontSize: "15px" }}>
                  Take a short quiz and receive ongoing recommendations from our
                  providers
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Session6;
