import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { formatPhoneNumber } from "../util/utils";
const MySwal = withReactContent(Swal);
const SwalAlert = (message, icon, providerDetail) => {
  MySwal.fire({
    padding: "2em",
    icon: icon,
    text: `${message} ${
      providerDetail !== ""
        ? providerDetail?.phoneNumber
          ? formatPhoneNumber(providerDetail?.phoneNumber)
          : "xxx-xxx-xxxx"
        : ""
    }`,
    confirmButtonText: "Ok",
    allowOutsideClick: false,
    allowEscapeKey: false,
  }).then(() => {});
};
export default SwalAlert;

const SwalAlertFunction = (message, icon, then, text) => {
  MySwal.fire({
    title: message,
    icon: icon,
    text: text,
    confirmButtonText: "Ok",
    allowOutsideClick: false,
    allowEscapeKey: false,
  }).then(() => {
    if (then) then();
  });
};

export { SwalAlertFunction, SwalAlert };
