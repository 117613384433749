import * as yup from "yup";
import moment from "moment";
import {
  FBurl,
  insUrl,
  linkedinUrl,
  TWurl,
  websiteRegex,
  youtubeUrl,
} from "../constants";

// gym
export const GymProfileSchema = yup.object({
  firstName: yup.string().required("GYM name is required"),
  email: yup.string().email().required("Email is required"),
  prefix: yup.string(),
  suffix: yup.string(),
  gender: yup.string(),
  phoneNumber: yup
    .string()
    .nullable()
    .min(0)
    .max(10, "Phone number is too long"),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: "Website must be a proper URL",
  }),
  photo: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  philosophy: yup.string(),
  askAnExpert: yup.boolean(),
  emailIds: yup.array().of(yup.string().email("this must be a valid email")),
  services: yup.string(),
  about: yup.string(),
  specilaizations: yup.string(),
  affiliations: yup.array().of(
    yup.object().shape({
      affiliatedGymsName: yup.string(),
    })
  ),
  certifications: yup.array().of(
    yup.object().shape({
      completionyear: yup.string(),
      certificationName: yup.string(),
    })
  ),
  modalities_available: yup.array().of(
    yup.object().shape({
      modalitiesName: yup.string(),
    })
  ),
  trainers_available: yup.array().of(
    yup.object().shape({
      trainers_Available: yup.string(),
    })
  ),
  programs: yup.array().of(
    yup.object().shape({
      programName: yup.string(),
    })
  ),
  leadership_team: yup.array().of(
    yup.object().shape({
      teamName: yup.string(),
      teamTitle: yup.string(),
      teamEmail: yup.string(),
    })
  ),
  classes_info: yup.array().of(
    yup.object({
      taught: yup.string(),
      instructor: yup.string(),
      timings: yup.array().of(
        yup.object().shape({
          days: yup.array().of(yup.string()),
          start_time: yup.string(),
          end_time: yup.string(),
        })
      ),
    })
  ),
  hours_of_operation: yup.array().of(
    yup.object({
      days: yup.array().of(yup.string()),
      start_time: yup.string(),
      end_time: yup.string(),
    })
  ),
  webLinks: yup.array().of(
    yup.object({
      value: yup.string(),
      label: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
});

// Atheletic Trainer
export const AtheleticTrainerschema = yup.object({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  email: yup.string().email().required("Email is required"),
  prefix: yup.string(),
  suffix: yup.string(),
  specilaizations: yup.string(),
  phoneNumber: yup
    .string()
    .nullable()
    .min(0)
    .max(10, "Phone number is too long"),
  training_philosophy: yup.string(),
  biography: yup.string(),
  photo: yup.string(),
  video: yup.string(),
  years_of_experience: yup.string(),
  videoUrl: yup.string(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: "Website must be a proper URL",
  }),
  certifications: yup.array().of(
    yup.object().shape({
      year: yup.string(),
      certification: yup.string(),
    })
  ),
  affiliations: yup.array().of(
    yup.object().shape({
      teamAffilated: yup.string(),
      schoolAffilated: yup.string(),
    })
  ),
  education: yup.array().of(
    yup.object().shape({
      degree: yup.string(),
      college: yup.string(),
      completionyear: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
});

// Clinic
export const Clinicschema = yup.object({
  firstName: yup.string().required("Clinic Name is required"),
  email: yup.string().email().required("Email is required"),
  phoneNumber: yup
    .string()
    .nullable()
    .min(0)
    .max(10, "Phone number is too long"),
  userType: yup.string(),
  photo: yup.string(),
  biography: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: "Website must be a proper URL",
  }),
  leadership_team: yup.array().of(
    yup.object().shape({
      teamTitle: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
    })
  ),
  services: yup.array().of(
    yup.object().shape({
      serviceLines: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
});

// Primary care, Orthopedic surgeon , Nurse Practioner
export const BasicProviderschema = yup.object({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  email: yup.string().email().required("Email is required"),
  prefix: yup.string(),
  suffix: yup.string(),
  gender: yup.string(),
  phoneNumber: yup
    .string()
    .nullable()
    .min(0)
    .max(10, "Phone number is too long"),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: "Website must be a proper URL",
  }),
  affiliations: yup.array().of(
    yup.object().shape({
      hospital_name: yup.string(),
      hospital_city: yup.string(),
    })
  ),
  askAnExpert: yup.boolean(),
  biography: yup.string(),
  photo: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  clinic: yup.array().of(
    yup.object({
      name: yup.string(),
      addressLine: yup.string(),
      city: yup.string(),
      state: yup.string(),
      country: yup.string(),
      zipcode: yup.string(),
      businessHours: yup.array().of(
        yup.object().shape({
          startDay: yup.string(),
          endDay: yup.string(),
          startTime: yup.string(),
          endTime: yup.string(),
        })
      ),
    })
  ),
  emailIds: yup.array().of(yup.string().email("this must be a valid email")),
  years_of_experience: yup.string(),
  services: yup.string(),
  specilaizations: yup.string(),
  active: yup.boolean(),
  publish: yup.boolean(),
  mopRecommended: yup.boolean(),
  education: yup.array().of(
    yup.object().shape({
      degree: yup.string(),
      college: yup.string(),
      completionyear: yup.string(),
    })
  ),
  training: yup.array().of(
    yup.object().shape({
      trainingType: yup.string(),
      location: yup.string(),
      completionyear: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
});

// Hospital
export const Hospitalschema = yup.object({
  firstName: yup.string().required("Hospital Name is required"),
  email: yup.string().email().required("Email is required"),
  phoneNumber: yup
    .string()
    .nullable()
    .min(0)
    .max(10, "Phone number is too long"),
  userType: yup.string(),
  photo: yup.string(),
  biography: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: "Website must be a proper URL",
  }),
  leadership_team: yup.array().of(
    yup.object().shape({
      teamTitle: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
    })
  ),
  services: yup.array().of(
    yup.object().shape({
      serviceLines: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
});

// Personal Trainer
export const PersonalTrainerschema = yup.object({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  email: yup.string().email().required("Email is required"),
  prefix: yup.string(),
  suffix: yup.string(),
  phoneNumber: yup
    .string()
    .nullable()
    .min(0)
    .max(10, "Phone number is too long"),
  training_philosophy: yup.string(),
  biography: yup.string(),
  photo: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: "Website must be a proper URL",
  }),
  clinic: yup.array().of(
    yup.object({
      name: yup.string(),
      addressLine: yup.string(),
      city: yup.string(),
      state: yup.string(),
      country: yup.string(),
      zipcode: yup.string(),
      businessHours: yup.array().of(
        yup.object().shape({
          startDay: yup.string(),
          endDay: yup.string(),
          startTime: yup.string(),
          endTime: yup.string(),
        })
      ),
    })
  ),
  classes_info: yup.array().of(
    yup.object({
      taught: yup.string(),
      location: yup.string(),
      timings: yup.array().of(
        yup.object().shape({
          days: yup.array().of(yup.string()),
          start_time: yup.string(),
          end_time: yup.string(),
        })
      ),
    })
  ),

  services: yup.string(),
  specilaizations: yup.string(),
  affiliations: yup.array().of(
    yup.object().shape({
      affiliatedGymsName: yup.string(),
    })
  ),
  certifications: yup.array().of(
    yup.object().shape({
      completionyear: yup.string(),
      certificationName: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
});

//  Wellness Provider
export const WellnessProviderSchema = yup.object({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  email: yup.string().email().required("Email is required"),
  prefix: yup.string(),
  suffix: yup.string(),
  title: yup.string(),
  specilaizations: yup.string(),
  phoneNumber: yup
    .string()
    .nullable()
    .min(0)
    .max(10, "Phone number is too long"),
  training_philosophy: yup.string(),
  philosophy: yup.string(),
  photo: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: "Website must be a proper URL",
  }),
  business_info: yup.object({
    name: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    phoneNumber: yup.string().nullable(),
  }),
  classes_info: yup.array().of(
    yup.object({
      taught: yup.string(),
      location: yup.string(),
      timings: yup.array().of(
        yup.object().shape({
          days: yup.array().of(yup.string()),
          start_time: yup.string(),
          end_time: yup.string(),
        })
      ),
    })
  ),
  education: yup.array().of(
    yup.object().shape({
      degree: yup.string(),
      college: yup.string(),
      completionyear: yup.string(),
    })
  ),
  services: yup.string(),
  affiliations: yup.array().of(
    yup.object().shape({
      affiliatedGymsName: yup.string(),
    })
  ),
  certifications: yup.array().of(
    yup.object().shape({
      completionyear: yup.string(),
      certificationName: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
});

// Physical Therapist
export const PhysicalTherapistschema = yup.object({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  email: yup.string().email().required("Email is required"),
  suffix: yup.string(),
  specilaizations: yup.string(),
  training_philosophy: yup.string(),
  photo: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: "Website must be a proper URL",
  }),
  rehab_info: yup.object({
    name: yup.string(),
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zipcode: yup.string(),
    phoneNumber: yup.string().nullable(),
  }),
  services: yup.string(),
  affiliations: yup.array().of(
    yup.object().shape({
      affiliatedGymsName: yup.string(),
    })
  ),
  certifications: yup.array().of(
    yup.object().shape({
      completionyear: yup.string(),
      certificationName: yup.string(),
    })
  ),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
});

//Rehabilitation Center
export const RehabilitationCenterSchema = yup.object({
  firstName: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  phoneNumber: yup
    .string()
    .nullable()
    .min(0)
    .max(10, "Phone number is too long"),
  userType: yup.string(),
  photo: yup.string(),
  biography: yup.string(),
  video: yup.string(),
  videoUrl: yup.string(),
  website: yup.string().matches(websiteRegex, {
    excludeEmptyString: true,
    message: "Website must be a proper URL",
  }),
  leadership_team: yup.array().of(
    yup.object().shape({
      teamTitle: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
    })
  ),
  services: yup.array().of(
    yup.object().shape({
      serviceLines: yup.string(),
    })
  ),
  specilaizations: yup.string(),
  facebook: yup.string().matches(FBurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  twitter: yup.string().matches(TWurl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  instagram: yup.string().matches(insUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  youtube: yup.string().matches(youtubeUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
  linkedin: yup.string().matches(linkedinUrl, {
    excludeEmptyString: true,
    message: "Enter a valid url",
  }),
});

//Referral
export const ReferralSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    address: yup.string().nullable(),
    zipcode: yup
      .string()
      .max(5)
      .required("Zipcode is required")
      .matches(/^[0-9]+$/, "Enter valid zipcode"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    phoneNumber: yup
      .string()
      .min(10, "Phone number must be atleast 10 digits")
      .max(12)
      .required("Phone number is required"),
    requstedOn: yup.date().required("Requested Appointment Date is required"),
    dob: yup
      .date()
      .min(new Date("01-01-1950".replace(/-/g, "/")))
      .max(
        new Date(),
        `dob field must be at earlier than ${moment(new Date()).format(
          "MM-DD-YYYY"
        )}`
      )
      .required("DOB is required"),
  })
  .required();
//Education and Evaluation Modal
export const EducationModalSchema = yup
  .object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .email("Enter a valid Email Address")
      .required("Email Address is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    zipcode: yup
      .string()
      .required("Zip Code is required")
      .matches(/^[0-9]+$/, "Enter a valid Zip Code")
      .length(5, "Zip Code must be 5 digits"),
  })
  .required();

export const ProviderRegisterSchema = yup
  .object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string(),
    email: yup
      .string()
      .email("Email must be a email")
      .required("Email is required"),
    userType: yup.string(),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
  })
  .required();
export const Registerschema = yup
  .object({
    firstName: yup.string().required("It is required"),
    lastName: yup.string().required("It is required"),
    email: yup
      .string()
      .email("Email must be a email")
      .required("Email is required"),
    userType: yup.string(),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
  })
  .required();
