export const certificationInitValue = {
  label: "",
  value: "",
};
export const schoolAffiliatedInitialValue = {
  school_name: "",
  school_city: "",
};
export const teamAffiliatedInitialValue = {
  team_name: "",
  team_city: "",
};

export const educationInitValue = {
  degree: "",
  college: "",
  completionyear: "",
};
