import React from "react";
import Layout from "./layout/Layout";
import Banner from "./Home/Banner";
import Session2 from "./Home/Session2";
import Session3 from "./Home/Session3";
import Session4 from "./Home/Session4";
import Session6 from "./Home/Session6";
import Session8 from "./Home/Session8";
import LatestNews from "./Home/LatestNews";
import Footer from "./Home/Footer";
import Testimonials from "./Home/Testimonial";

const Home = () => {
  return (
    <Layout>
      <div className="x-5">
        <Banner />
        <Session2 />
        <Session3 />
        <Session4 />
        <Session6 />
        <Testimonials />
        <Session8 />
        <LatestNews />
        <Footer />
      </div>
    </Layout>
  );
};

export default Home;
