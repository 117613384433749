import React from "react";
import MediaPreview from "../../../../components/custom/MediaPreview";
import { BiSolidFilePdf } from "react-icons/bi";
import { HiDocumentText } from "react-icons/hi2";

const FileMessage = ({ conversation, formatDateTime }) => {
  return (
    <>
      {conversation.message.type === "image" ||
      conversation.message.type === "video" ? (
        <div className="text-end p-1">
          <p className="m-0 pb-1 text-start" style={{ fontSize: "10px" }}>
            {conversation?.postedByUser?.name}
            <span className="float-right">
              {formatDateTime(conversation?.createdAt)}
            </span>
          </p>
          <MediaPreview
            fileName={conversation.message.message}
            type={conversation.message.type}
            width="250px"
            height="250px"
            className="my-2 rounded w-100"
          />
        </div>
      ) : (
        <div
          className={`border-primary border px-2 py-1 rounded height-70px d-flex ${
            window.innerWidth > 900 ? "width-280px" : "width-195px"
          }`}
        >
          {conversation?.message?.title?.includes(".pdf") ? (
            <BiSolidFilePdf className={"w-25 h-100"} />
          ) : (
            <HiDocumentText className={"w-25 h-100"} />
          )}

          <div
            className={`fontSize-smaller mt-md-3 ms-1 text-start word-break ${
              window.innerWidth > 900 ? "" : "fs-6"
            }`}
          >
            {conversation.message.title}
          </div>
        </div>
      )}
    </>
  );
};

export default FileMessage;
