import React, { useEffect } from "react";
import moment from "moment";
import { FaUsers, FaUserClock } from "react-icons/fa";
const ClassesTab = ({ providerDetail }) => {
  const sortDaysTiming = (timings) => {
    const sortTimings = [];
    const days = {
      Sunday: 1,
      Monday: 2,
      Tuesday: 3,
      Wednesday: 4,
      Thursday: 5,
      Friday: 6,
      Saturday: 7,
    };
    timings.map((current, index) => {
      const timing = [];
      current.days.map((day) => {
        timing.push({
          sort: Number(days[day]),
          day,
          start_time: moment(
            new Date(`2000-01-01 ${current.start_time}`)
          ).format("hh:mm A"),
          end_time: moment(new Date(`2000-01-01 ${current.end_time}`)).format(
            "hh:mm A"
          ),
        });
        return day;
      });
      sortTimings.push(timing);
      return current;
    });
    return sortTimings;
  };
  useEffect(() => {
    window.fbq("track", `Classes Tab Page is opened.`);
  }, []);
  const classes = (
    <>
      <p>
        {providerDetail?.classes_info &&
        providerDetail?.classes_info.length !== 0 &&
        providerDetail?.classes_info[0]?.timings.length !== 0 &&
        ((providerDetail?.classes_info[0]?.instructor !== "" &&
          providerDetail?.classes_info[0]?.location !== "") ||
          providerDetail?.classes_info[0]?.taught !== "" ||
          providerDetail?.classes_info[0]?.timings[0]?.days.length !== 0 ||
          providerDetail?.classes_info[0]?.timings[0]?.start_time !== "" ||
          providerDetail?.classes_info[0]?.timings[0]?.end_time !== "") ? (
          <h6>
            <FaUsers className="me-2 text-primary align-self-center  -mt-3 width-25px height-25px" />
            <span className="fw-bold text-black text-uppercase">
              Classes Info
            </span>
          </h6>
        ) : (
          <></>
        )}

        {providerDetail?.classes_info &&
          (providerDetail?.classes_info[0]?.instructor !== "" ||
            providerDetail?.classes_info[0]?.taught !== "") &&
          providerDetail?.classes_info?.map((row) => {
            return (
              <>
                {(row?.location !== "" ||
                  row?.taught !== "" ||
                  row?.instructor !== "") && (
                  <h6>
                    <div className="px-1 fw-bold mt-3 mb-2">
                      <span className="text-capitalize fs-5">
                        {row?.taught}
                      </span>
                      {/* {row?.location !== "" && row?.taught !== "" ? " - " : ""}
                      {row?.location && `${row?.location}`}
                      {row?.instructor && `${row?.instructor}`}*/}
                    </div>
                  </h6>
                )}
                <div className="mb-2">
                  {row.timings &&
                    sortDaysTiming(row.timings).map((timing, index) => {
                      return (
                        <>
                          {(timing && timing.length && (
                            <h9 className="mb-2">
                              <FaUserClock className="me-2 text-primary align-self-center  -mt-3 height-20px width-20px" />
                              <span className=" fs-6 fw-bold text-black text-uppercase">
                                class timings {index + 1}
                              </span>
                            </h9>
                          )) ||
                            ""}
                          {timing &&
                            timing
                              .sort(
                                (a, b) =>
                                  moment(
                                    new Date(`2000-01-01 ${a.start_time}`)
                                  ) -
                                  moment(new Date(`2000-01-01 ${b.start_time}`))
                              )
                              .sort((a, b) => a.sort - b.sort)
                              .map((data, index) => {
                                return (
                                  <div className="px-2">
                                    <div className="text-capitalize row">
                                      <span className="text-primary  col-4 col-lg-3 col-xl-2 ">
                                        {data.day}
                                      </span>
                                      <span className="col-8 col-lg-7  col-xl-7 ps-lg-0 ps-xl-3 pe-0">
                                        {data?.start_time &&
                                          ": " +
                                            `${moment(data?.start_time, [
                                              "HH:mm:A",
                                            ]).format("hh:mm A")} `}
                                        {data?.start_time !== "" &&
                                        data?.end_time !== ""
                                          ? " - "
                                          : ""}
                                        {data?.end_time &&
                                          ` ${moment(data?.end_time, [
                                            "HH:mm:A",
                                          ]).format("hh:mm A")}`}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                        </>
                      );
                    })}
                </div>
              </>
            );
          })}
      </p>
    </>
  );
  const renderProviders = () => {
    if (
      providerDetail?.userType === "nurse_practitioner" ||
      providerDetail?.userType === "orthopedic_surgeon" ||
      providerDetail?.userType === "primary_care" ||
      providerDetail?.userType === "personal_trainer" ||
      providerDetail?.userType === "wellness_provider" ||
      providerDetail?.userType === "gym"
    ) {
      return (
        <div className="col-md-12 row">
          {!!providerDetail?.classes_info?.length && (
            <div className="col-md-12">{classes}</div>
          )}
        </div>
      );
    }
  };
  return (
    <div className="p-4 container bg-muted-25 p-md-4 mt-4 p-2 rounded card-scroll">
      <div className="row bg-white p-2 pt-4">
        {providerDetail?.classes_info &&
        providerDetail?.classes_info.length !== 0 &&
        providerDetail?.classes_info[0]?.timings.length !== 0 &&
        ((providerDetail?.classes_info[0]?.instructor !== "" &&
          providerDetail?.classes_info[0]?.location !== "") ||
          providerDetail?.classes_info[0]?.taught !== "" ||
          providerDetail?.classes_info[0]?.timings[0]?.days.length !== 0 ||
          providerDetail?.classes_info[0]?.timings[0]?.start_time !== "" ||
          providerDetail?.classes_info[0]?.timings[0]?.end_time !== "") ? (
          <div className="col-md-6">{renderProviders()}</div>
        ) : (
          <p className="text-center">No Data Found!</p>
        )}
      </div>
    </div>
  );
};
export default ClassesTab;
