export const certificationInitValue = {
  label: "",
  value: "",
};
export const affiliatedGymsInitValue = {
  affiliatedGymsName: "",
};

export const modalitiesInitValue = {
  modalities_Name: "",
};
export const trainersAvailableInitValue = {
  trainers_Available: "",
};
export const programInitValue = {
  programName: "",
};
export const leadershipInitValue = {
  teamName: "",
  teamTitle: "",
  teamEmail: "",
};
export const classesInitValue = {
  taught: "",
  instructor: "",
  timings: [{ days: [], start_time: "12:00 AM", end_time: "12:00 AM" }],
};
export const hoursOfOperation = {
  days: [],
  start_time: "12:00 AM",
  end_time: "12:00 AM",
};
