import React, { useState } from "react";
import axios from "../../../utils/axiosInst";
import ReactTooltip from "react-tooltip";
import {
  BusinessHoursInitValue,
  ClinicInitValue,
  certificationInitValue,
  educationInitValue,
  hospitalAffiliatedInitialValue,
  liceceInitValue,
  trainingInitValue,
} from "./init";
import Certifications from "../custom/Certifications";
import BasicInfo from "../custom/BasicInfo";
import Biography from "../custom/Biography";
import Liscence from "../custom/Liscence";
import Speciality from "../custom/Speciality";
import SocialMedia from "../custom/SocialMedia";
import EmailContent from "../custom/EmailContent";
import LabelWithTooltip from "../custom/LabelWithTooltip";
import { PAGE_VARIABLES } from "../../constants";
import Time from "../custom/Time";
import { useWatch } from "react-hook-form";
import EducationLibrary from "../custom/EducationLibrary";
import TextInput from "../custom/TextInput";

const ProviderProfile = ({ useFormControl, signupData }) => {
  const [hospitalAffiliationsRows, setHospitalAffiliationsRows] = useState([
    hospitalAffiliatedInitialValue,
  ]);
  const [licenceRows, setLicenceRows] = useState([liceceInitValue]);
  const [educationRows, setEducationRows] = useState([educationInitValue]);
  const [clinicRows, setClinicRows] = useState([ClinicInitValue]);
  const [trainingRows, setTrainingRows] = useState([trainingInitValue]);
  const [certificationRows, setCertificationRows] = useState([
    certificationInitValue,
  ]);
  const [city, setcity] = useState([]);
  const [selectedcity, setselectedcity] = useState([]);

  const {
    register,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useFormControl;

  const selectcity_handler = (i, ind) => {
    setValue(`clinic[${ind}].addressLine`, city[ind][i].description);
    let selectedcity_temp = selectedcity;
    selectedcity_temp[ind] = city[i];
    setselectedcity(selectedcity_temp);
    setcity([]);
  };

  const onchangehandler_city = (e, ind) => {
    setcity([]);
    setValue(`clinic[${ind}].addressLine`, e.target.value);
    if (e.target.value.length > 3) {
      axios
        .get(`/v1/public/search/address?place=${e.target.value} `)
        .then(({ data }) => {
          let city_temp = city;
          city_temp[ind] = data.predictions;
          setcity(city_temp);
        });
    }
  };

  const watchStartTime = useWatch({
    control,
    name: "clinic",
  });

  const getEndTime = (ind, index) => {
    let tempArray = [...PAGE_VARIABLES?.COMMON?.TIME_VALUE];
    if (watchStartTime) {
      const lastIndex = tempArray.findIndex(
        (time) => time === watchStartTime[ind]?.businessHours[index]?.startTime
      );
      let itemToBeRemoved = tempArray.splice(0, lastIndex + 1);
      if (
        itemToBeRemoved.includes(
          watchStartTime[ind]?.businessHours[index]?.endTime
        )
      ) {
        setValue(`clinic[${ind}].businessHours[${index}].endTime`, "");
      }
      return [...itemToBeRemoved];
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="mt-3">
            <BasicInfo register={register} useFormControl={useFormControl} />
          </div>
          <div className="mt-3">
            <Biography register={register} />
          </div>
          {watch("userType") === "neuro_surgeon" ||
          watch("userType") === "primary_care" ||
          watch("userType") === "orthopedic_surgeon" ? (
            <div className="mt-3">
              <Liscence
                useFormControl={useFormControl}
                setLicenceRows={setLicenceRows}
                licenceRows={licenceRows}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="mt-3">
            <Speciality register={register} />
          </div>
          <div className="mt-4">
            <div className="py-3 px-4">
              <LabelWithTooltip
                label="Add Top Procedures and Conditions"
                id="service"
                TooltipText="To add mulitple Top Procedures and Conditions, add
                              each Procedures and Conditions seperated by Comma."
                labelClassName="fontweight-bold mt-4 h5"
              />{" "}
            </div>
            <div className="pb-4 ps-4">
              <div className="row">
                <div className="col-sm-6">
                  <TextInput
                    register={register}
                    type={"text"}
                    name="services"
                  />
                </div>
              </div>
            </div>
          </div>
          <EducationLibrary
            useFormControl={useFormControl}
            setEducationRows={setEducationRows}
            educationRows={educationRows}
          />
          <div className="mt-3 container">
            <h5 className="fontweight-bold mt-4">Training</h5>
            {trainingRows.map((trainingRow, ind) => (
              <div className="row" key={ind}>
                <div className="col-md-4">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Training type"
                    name={`training[${ind}].trainingType`}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Location"
                    name={`training[${ind}].location`}
                  />
                </div>
                <div className="col-md-4 mb-1">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Year of Completion"
                    name={`training[${ind}].completionyear`}
                  />
                </div>
                <div className="row">
                  {trainingRows.length - 1 === ind && (
                    <div
                      className="col-md-2 text-primary cursor-pointer"
                      onClick={() => {
                        const rows = [
                          ...getValues("training"),
                          trainingInitValue,
                        ];
                        setTrainingRows(rows);
                      }}
                    >
                      Add new
                    </div>
                  )}
                  <div
                    className="col-md-1 text-danger cursor-pointer"
                    onClick={() => {
                      let rows = [...trainingRows];
                      if (rows.length > 1) {
                        rows.splice(ind, 1);
                        setTrainingRows(rows);
                        setValue("training", rows);
                      } else {
                        setTrainingRows([trainingInitValue]);
                        setValue("training", [trainingInitValue]);
                      }
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-4">Clinic Info</h5>
            {clinicRows.map((clinicRow, ind) => (
              <div className="row mb-3" key={ind}>
                <div className="col-md-4 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Clinic Name"
                    name={`clinic[${ind}].name`}
                  />
                </div>
                <div className="col-md-4 mb-3 position-relative">
                  <label className="form-label">Clinic Address Line</label>
                  <input
                    type="text"
                    className={`form-control text-black`}
                    value={selectedcity[ind]?.description}
                    name={selectedcity[ind]?.description}
                    {...register(`clinic[${ind}].addressLine`)}
                    onChange={(e) => onchangehandler_city(e, ind)}
                  />
                  {city[ind]?.length > 0 && (
                    <div className="dropdown-menu d-block provider-select-div w-100 ">
                      <ul className="provider-select-ul">
                        <ReactTooltip id="service" place="top" effect="solid">
                          <span>Close the Suggestion</span>
                        </ReactTooltip>
                        <p
                          className="float-right cursor-pointer p-0 fw-bold"
                          onClick={() => setcity([])}
                        >
                          <span data-tip data-for="service">
                            X
                          </span>
                        </p>
                        {city[ind].map((data, i) => {
                          return (
                            <li
                              key={i}
                              onClick={() => selectcity_handler(i, ind)}
                              className="provider-select-li"
                            >
                              {" "}
                              {data.description}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="col-md-4 mb-1">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Clinic City"
                    name={`clinic[${ind}].city`}
                  />
                </div>
                <div className="col-md-4 mb-1">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Clinic State"
                    name={`clinic[${ind}].state`}
                  />
                </div>
                <div className="col-md-4 mb-1">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Clinic Country"
                    name={`clinic[${ind}].country`}
                  />
                </div>
                <div className="col-md-4 mb-1">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Clinic Zipcode"
                    name={`clinic[${ind}].zipcode`}
                    maxlength={5}
                  />
                </div>
                <div className="py-3 ">
                  <h6 className="fontweight-bold mt-4">Business Hours</h6>
                </div>
                {clinicRow.businessHours.map((businessHour, index) => (
                  <>
                    <div className="col-md-3 mb-1" key={ind}>
                      <label>Start Day</label>
                      <select
                        className="form-select"
                        {...register(
                          `clinic[${ind}].businessHours[${index}].startDay`
                        )}
                      >
                        <option value="">Select Start Day</option>
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                      </select>
                    </div>
                    <div className="col-md-3 mb-1">
                      <label>End Day</label>
                      <select
                        className="form-select"
                        {...register(
                          `clinic[${ind}].businessHours[${index}].endDay`
                        )}
                      >
                        <option value="">Select End Day</option>
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                      </select>
                    </div>
                    <div className="col-md-3 mb-1">
                      <label>Start Time</label>
                      <Time
                        register={register}
                        name={`clinic[${ind}].businessHours[${index}].startTime`}
                      />
                    </div>
                    <div className="col-md-3 mb-1">
                      <label>End Time</label>
                      <Time
                        register={register}
                        disabledEndTimes={getEndTime(ind, index)}
                        name={`clinic[${ind}].businessHours[${index}].endTime`}
                      />
                    </div>
                    <div className="row">
                      {clinicRows[ind].businessHours.length - 1 === index && (
                        <div
                          className="col-md-3 text-primary cursor-pointer"
                          onClick={() => {
                            const rows = {
                              ...clinicRow,
                              businessHours: [
                                ...getValues("clinic")[ind].businessHours,
                                BusinessHoursInitValue,
                              ],
                            };
                            let businessHoursRow = clinicRows;
                            businessHoursRow[ind] = rows;
                            setClinicRows([...businessHoursRow]);
                          }}
                        >
                          Add Business Hours
                        </div>
                      )}
                      <div
                        className="col-md-3 text-danger cursor-pointer"
                        onClick={() => {
                          let rows = [...clinicRows[ind].businessHours];
                          if (rows.length > 1) {
                            rows.splice(index, 1);
                            let businessHoursRow = clinicRows;
                            businessHoursRow[ind].businessHours = rows;
                            setClinicRows([...businessHoursRow]);
                            setValue(`clinic`, businessHoursRow);
                          } else {
                            let businessHoursRow = clinicRows;
                            businessHoursRow[ind].businessHours = [
                              BusinessHoursInitValue,
                            ];
                            setClinicRows([...businessHoursRow]);
                            setValue(`clinic[${ind}].businessHours`, [
                              BusinessHoursInitValue,
                            ]);
                          }
                        }}
                      >
                        Remove Business Hours
                      </div>
                    </div>
                  </>
                ))}
                <div className="row">
                  {clinicRows.length - 1 === ind && (
                    <div
                      className="col-md-2 p-2 text-primary cursor-pointer"
                      onClick={() => {
                        const rows = [...getValues("clinic"), ClinicInitValue];
                        setClinicRows(rows);
                      }}
                    >
                      Add Clinic Info
                    </div>
                  )}
                  <div
                    className="col-md-3 p-2 text-danger cursor-pointer"
                    onClick={() => {
                      let rows = [...clinicRows];
                      if (rows.length > 1) {
                        rows.splice(ind, 1);
                        setClinicRows(rows);
                        setValue("clinic", rows);
                      } else {
                        setClinicRows([ClinicInitValue]);
                        setValue("clinic", [ClinicInitValue]);
                      }
                    }}
                  >
                    Remove Clinic Info
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Certifications
            certificationIntitialValue={certificationInitValue}
            setValue={setValue}
            signupData={signupData}
            setCertificationRows={setCertificationRows}
            getValues={getValues}
            certificationRows={certificationRows}
            register={register}
            watch={watch}
            errors={errors}
          />
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-4">Hospital Affiliation</h5>
            {hospitalAffiliationsRows.map((trainingRow, ind) => (
              <div className="row mb-3" key={ind}>
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Hospital Name"
                    name={`affiliations[${ind}].hospital_name`}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Hospital City"
                    name={`affiliations[${ind}].hospital_city`}
                  />
                </div>
                <div className="row">
                  {hospitalAffiliationsRows.length - 1 === ind && (
                    <div
                      className="col-md-2 text-primary cursor-pointer"
                      onClick={() => {
                        const rows = [
                          ...getValues("affiliations"),
                          hospitalAffiliatedInitialValue,
                        ];
                        setHospitalAffiliationsRows(rows);
                      }}
                    >
                      Add new
                    </div>
                  )}
                  <div
                    className="col-md-1 text-danger cursor-pointer"
                    onClick={() => {
                      let rows = [...hospitalAffiliationsRows];
                      if (rows.length > 1) {
                        rows.splice(ind, 1);
                        setHospitalAffiliationsRows(rows);
                        setValue("affiliations", rows);
                      } else {
                        setHospitalAffiliationsRows([
                          hospitalAffiliatedInitialValue,
                        ]);
                        setValue("affiliations", [
                          hospitalAffiliatedInitialValue,
                        ]);
                      }
                    }}
                  >
                    Remove
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mt-4">Email ID</h5>
            <div className="row">
              <EmailContent errors={errors} register={register} />
            </div>
          </div>
          <div className="mt-3 container">
            <h5 className="fontweight-bold mt-4">Location URL</h5>
            <div className="row">
              <div className="col-md-6 mb-3 d-flex flex-column">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Location URL"
                  name="googleProfile"
                />
              </div>
            </div>
          </div>
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-4">Years of Experience</h5>
            <div className="row">
              <div className="col-md-6">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Years of Experience"
                  name={`years_of_experience`}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 container">
            <h5 className="fontweight-bold mt-4">Social Media</h5>
            <div className="row">
              <SocialMedia errors={errors} register={register} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProviderProfile;
