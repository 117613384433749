import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";

const Radio = ({ question, options, prompt, nextQuestionHandler, data }) => {
  const answers = useStoreState((state) => state.answers);
  const setAnswer = useStoreActions((actions) => actions.setAnswer);
  return (
    <div className="text-center my-md-0 my-5" key={question}>
      <h3 className="fw-semibold "> {question}</h3>
      <div className="choose-options">
        {options.map((option) => (
          <div className="options" key={option.value}>
            <div className="form-group">
              <button
                className={`fw-semibold symptom-checker me-5 borderradius-15px h-50px ${
                  answers[prompt] === option.value
                    ? "btn btn-primary text-white"
                    : "btn btn-outline-primary text-black"
                } ${window.innerWidth > 768 ? "w-50" : "w-100"}`}
                onClick={(e) => {
                  e.preventDefault();
                  setAnswer({
                    prompt,
                    answer: option.value,
                    type: "radio",
                    data,
                  });
                  nextQuestionHandler({ ...answers, [prompt]: option.value });
                }}
              >
                {option.label}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Radio;
