import React, { useEffect } from "react";
import Layout from "./layout/Layout";
import { Button } from "react-bootstrap";
import Footer from "./Home/Footer";
import Accordation from "./Home/components/Accordation";
import { individualsFAQ } from "../../../constants";
import Register from "../../../pages/RegisterBanner";
import banner1 from "../../../images/Homepage/collage2.webp";
import banner2 from "../../../images/Homepage/collage1.webp";
import SOC2 from "../../assets/images/soc2.png";
import HIPAA from "../../assets/images/hipaa.jpg";
import Image from "./custom/Image";

const WellnessAssesment = () => {
  const immediatetVisibility = () => {
    let reveals = document.querySelectorAll(
      ".immediatereveal_LR_1,.immediatereveal_LR_2,.immediatereveal_LR_3"
    );

    for (let i = 0; i < reveals.length; i++) {
      let windowHeight = window.innerHeight;
      let elementTop = reveals[i].getBoundingClientRect().top;
      let elementVisible = 100;

      if (elementTop < windowHeight - elementVisible)
        reveals[i].classList.add("active");
      else reveals[i].classList.remove("active");
    }
  };
  useEffect(() => {
    document
      .getElementById("landing")
      .addEventListener("scroll", immediatetVisibility);
  }, []);

  return (
    <Layout>
      <section
        className={`container px-5 ${window.innerWidth > 769 ? " mt-4" : ""}`}
      >
        <div className="row p-md-3 py-md-0 p-1 pt-5 rounded-1">
          <div
            className={`col-sm-7 col-lg-7 rounded-1 ${
              window.innerWidth > 1750
                ? "py-3"
                : window.innerWidth > 1000 && window.innerWidth < 1100
                ? " py-3"
                : "my-md-3"
            }`}
          >
            <h1
              className="landing-display-1 fontweight-bold mt-6rem "
              style={{ lineHeight: "44px" }}
            >
              <p className="text-black">Begin your </p>
              <p className="text-black">wellness</p>
              <p className="text-black">assessment today</p>
            </h1>
            <h5 className="text-black pt-2 ">
              Answer a few questions and follow our video exercises <br />
              to get your wellness assessment score and learn ways to <br />
              improve your health immediately
            </h5>

            <Button
              type="button"
              className="jump-button bg-primary text-white rounded-pill fw-semibold mt-3"
              style={{ padding: "7px 28px", fontSize: "20px" }}
            >
              Sign Up
            </Button>
            <br />
            <div className="d-flex">
              <img
                src={HIPAA}
                alt="Iphone banner"
                className=" mt-3"
                style={{ width: "17%" }}
              />
              <img
                src={SOC2}
                alt="Iphone banner"
                className="mt-4"
                style={{ width: "7%", height: "9% " }}
              />
              <p
                className="fw-600 px-2 mb-0"
                style={{ fontSize: "10px", marginTop: "33px" }}
              >
                SOC 2 TYPE 2<br />
                Certified
              </p>
            </div>
          </div>
          <div
            className="col-sm-5 col-lg-5"
            style={{ border: "1px solid lightgray", borderRadius: "15px" }}
          >
            <Register />
          </div>
        </div>
      </section>
      <h1
        className="fontweight-bold text-center display-4 mt-5 pt-5"
        style={{ marginTop: "10rem" }}
      >
        How it works
      </h1>
      <div className="row pt-5 mt-2 px-5 container ">
        <div className="col-lg-7 ps-5 ">
          <div className="immediatereveal_LR_1">
            <span
              className="bg-primary rounded-circle fontweight-bold text-white fs--25"
              style={{ padding: "6px 15px" }}
            >
              1
            </span>
            <span className="text-primary fs--25 ps-3 fw-700">
              Take movement assessment
            </span>
          </div>
          <div className="pt-3 fs-14px mt-2 mb-4 ">
            <div className="d-flex immediatereveal_LR_2">
              <span
                className="rounded-circle fontweight-bold text-white fs--25"
                style={{
                  width: "25px",
                  height: "25px",
                  background: "rgb(241, 241, 243)",
                  margin: "0 0 0 10px",
                }}
              ></span>
              <p className="w-75 ps-4 fs-16px">
                Start by answering a few questions and doing a couple of
                stretches to get your baseline movement score
              </p>
            </div>
          </div>
          <div className="immediatereveal_LR_1">
            <span
              className="bg-primary rounded-circle fontweight-bold text-white fs--25"
              style={{ padding: "6px 15px" }}
            >
              2
            </span>
            <span className="text-primary fs--25 ps-3 fw-700">
              Get your results
            </span>
          </div>
          <div className="pt-3 fs-14px mt-2 mb-4">
            <div className="d-flex immediatereveal_LR_2">
              <span
                className="rounded-circle fontweight-bold text-white fs--25"
                style={{
                  width: "25px",
                  height: "25px",
                  background: "rgb(241, 241, 243)",
                  margin: "0px 0 0 10px",
                }}
              ></span>
              <p className="w-75 ps-4  fs-16px">
                View your report to see how your overall health and wellness
                scores, and which areas need attention.
              </p>
            </div>
          </div>
          <div className="immediatereveal_LR_1">
            <span
              className="bg-primary rounded-circle fontweight-bold text-white fs--25"
              style={{ padding: "6px 15px" }}
            >
              3
            </span>
            <span className="text-primary fs--25 ps-3 fw-700">
              Start your program
            </span>
          </div>
          <div className="pt-3 fs-14px mt-2 mb-4">
            <div className="d-flex immediatereveal_LR_2">
              <span
                className="rounded-circle fontweight-bold text-white fs--25"
                style={{
                  width: "25px",
                  height: "25px",
                  background: "rgb(241, 241, 243)",
                  margin: "0 0 0 10px",
                }}
              ></span>
              <p className="w-75 ps-4  fs-16px">
                Get personalized program and guided corrective exercises, made
                just for you based on your health and wellness assessment
                results.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-5 col-lg-5 mt-5">
          <div className="leftimage">
            <button
              style={{
                position: "absolute",
                cursor: "auto",
                top: "0px",
                left: "15rem",
                fontSize: "14px",
                padding: "2px 12px",
                borderRadius: "25px",
                borderColor: "#0071FF",
              }}
              className="fw-semibold bg-primary text-white btn px-3"
            >
              Hey Katie, how can
              <br /> I help you today ?
            </button>
            <Image
              local={true}
              src={banner2}
              height="220px"
              width="275px"
              className=" rounded-3 mt-md-3 mt-3 max-h-600"
              alt="Iphone banner"
            />

            <div className="rightimage">
              <Image
                local={true}
                src={banner1}
                className=" rounded-3 mt-md-5 h-75 mt-3 max-h-600"
                alt="Iphone banner"
              />
            </div>
          </div>
        </div>
      </div>
      <section className="container px-5 wrapper  text-center pb-5  ">
        <h2 className="fontweight-bold text-center display-4 mt-5 mb-5 text-primary">
          Benefits:
        </h2>
        <div className=" d-flex justify-content-center">
          <div className="text-start px-5  ">
            <div className="immediatereveal_LR_1">
              <h3 className="py-3 ">
                <span
                  className="me-3"
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: "#0071FF",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
                Physical Therapy tracking (MSK)
              </h3>
            </div>
            <div className="immediatereveal_LR_2">
              <h3 className="py-3">
                <span
                  className="me-3"
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: "#0071FF",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
                Pre-and-post rehab assessment
              </h3>
            </div>
            <div className="immediatereveal_LR_3">
              <h3 className="py-3">
                <span
                  className="me-3"
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: "#0071FF",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
                Cognitive abilities baseline & assessment
              </h3>
            </div>
            <div className="immediatereveal_LR_1">
              <h3 className="py-3 ">
                <span
                  className="me-3"
                  style={{
                    height: "25px",
                    width: "25px",
                    backgroundColor: "#0071FF",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                />
                Range of motion assessment (MSD)
              </h3>
            </div>
          </div>
        </div>
        <section className="sec_six bg-white px-5 mt-4rem">
          <h1 className="fontweight-bold text-center display-4 mt-5 mb-5">
            Frequently asked questions
          </h1>
          <div className="d-md-flex justify-content-between align-items-center container"></div>
          <Accordation FAQ={individualsFAQ} />
        </section>
      </section>
      <Footer />
    </Layout>
  );
};

export default WellnessAssesment;
