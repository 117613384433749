import React, { useEffect, useState } from "react";
import axios from "../utils/axiosInst";
import Conversationlist from "./Conversationlist";
import PageHeader from "../../provider/components/Layout/PageHeader";
import { Tab, Tabs } from "react-bootstrap";

const PatientChat = () => {
  const [chat, setChat] = useState([]);

  const getChatList = async () => {
    const data = await axios("/v1/chat?roomType=chat");

    const patient = data.data.conversation.filter(
      (chat) =>
        chat?.roomDetails?.type === "patient-to-provider" ||
        chat?.roomDetails?.type === "provider-to-patient"
    );
    setChat({
      patient: {
        conversation: patient,
        totalUnreadMessages: data.data.totalUnreadMessages,
      },
    });
  };

  useEffect(() => {
    getChatList();
  }, []);

  return (
    <PageHeader h1="Messages">
      <Tabs
        defaultActiveKey="Provider"
        className="pt-1 bg-white fontweight-bold text-black border-bottom"
        variant="pills"
      >
        <Tab eventKey="Provider" title="Provider" className="bg-super-light ">
          <Conversationlist
            chat={chat}
            getChatList={getChatList}
            type={"patient"}
          />
        </Tab>
      </Tabs>
    </PageHeader>
  );
};

export default PatientChat;
