import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useRef } from "react";
import { createRef } from "react";
import MultiSelect from "../../../../components/common/MultiSelect";
import { useState } from "react";
import Pagination from "../../../../components/common/Pagination";
import ReactPlayer from "react-player";
import MediaPreview from "../../../../components/common/MediaPreview";
import moment from "moment";
import { DropdownButton } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Image from "../custom/Image";

const ProviderEducation = () => {
  const navigate = useNavigate();
  const ref = createRef();
  const [Loading, setLoading] = useState(true);
  const playerRef = useRef();
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");

  //getting all muscle type for dropdown
  const getBlogArticles = useStoreActions((actions) => actions.getBlogArticles);
  const setBlogPageNo = useStoreActions((actions) => actions.setBlogPageNo);
  const blogArticles = useStoreState((state) =>
    state.blogArticles?.length
      ? state.blogArticles
      : [{}, {}, {}, {}, {}, {}, {}, {}]
  );
  const { innerWidth } = window;
  const getBlogCounts = useStoreActions((actions) => actions.getBlogCounts);

  const totalBlogPages = useStoreState(
    (state) => state.blogPagination.totalPages
  );
  const blogCounts = useStoreState((state) => state.blogCounts);
  const [array, setArray] = useState([]);
  const currentPageNo = useStoreState(
    (state) => state.blogPagination.currentPageNo
  );

  const getBlogDetails = (id) => {
    setSelectedItems(id);
    setArray(id.map((map) => map.value));
    setTimeout(() => {
      document
        .querySelector("#education-card .dropdown-menu")
        .classList.remove("show");
    }, 200);
  };

  useEffect(() => {
    setBlogPageNo(0);
  }, [setBlogPageNo]);

  useEffect(() => {
    getBlogCounts();
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [getBlogCounts]);

  useEffect(() => {
    if (selectedItems?.length > 0) {
      getBlogArticles({
        page: currentPageNo + 1,
        id: array.toString(),
        search: search,
        type: type,
      });
    } else {
      getBlogArticles({ page: currentPageNo + 1, search: search, type: type });
    }
  }, [
    array,
    currentPageNo,
    getBlogArticles,
    search,
    selectedItems?.length,
    type,
  ]);

  return (
    <>
      <div className="container pb-4">
        <div className="row px-md-2 px-2 py-2">
          <div className="my-2 col-md-4 col-10 d-flex pe-1 ps-1" id="search">
            <input
              name="search"
              value={search}
              placeholder="Search..."
              className="form-control  fs--18 padding-052rem"
              onChange={(e) => setSearch(e.target.value)}
            />
            <i className="fa fa-solid fs-5 fa-magnifying-glass padding-08rem" />
          </div>
          <div className="col-md-3 col-2 d-flex p-0">
            <div id="education-card">
              <DropdownButton
                id="education-basic-button"
                title={
                  <i
                    className="fa fa-filter text-white bg-primary mt-2 fs-5 width-fit-content height-fit-content padding-08rem"
                    aria-hidden="true"
                  />
                }
              >
                <div className="px-3 py-2 mb-2">
                  <label className=" mb-1">Search Category</label>
                  <MultiSelect
                    isMulti={true}
                    onChangeHandler={(e) => {
                      getBlogDetails(e);
                    }}
                    options={blogCounts}
                    name="evaluationName"
                    placeholder="Search Category"
                    value={selectedItems}
                    id="_id"
                  />
                  <label className=" mb-1 mt-3">Search Media Type</label>
                  <select
                    className={`form-select bg-light-50`}
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                      setTimeout(() => {
                        document
                          .querySelector("#education-card .dropdown-menu")
                          .classList.remove("show");
                      }, 200);
                    }}
                  >
                    <option value=""></option>
                    <option value="provider_post">Writing/Photos</option>
                    <option value="post">Video</option>
                  </select>
                </div>
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-md-start text-center">
        <div className="col-12 px-md-0 px-3">
          <div className="row">
            {blogArticles?.length > 0 &&
              blogArticles.map((item, index) => (
                <div
                  className="col-md-3 mb-4 col-12 cursor-pointer p-0 px-md-2"
                  key={index}
                  id="video-card"
                >
                  <div className="bg-white mb-3 educational-animation rounded h-26">
                    <div
                      className=" evaluate_img evaluate_img_education p-2"
                      onClick={() => {
                        if (item.coll === "exercises")
                          navigate(`/video/${item.slug}`);
                        if (item.coll === "blogs")
                          navigate(`/provider-posts/${item.slug || item.id}`);
                      }}
                    >
                      {item.coll === "blogs" ? (
                        !Loading ? (
                          <Image
                            src={item.banner}
                            className={`img-fluid rounded w-100 ${
                              item.banner
                                ? "maxheight-15rem"
                                : "maxheight-100 padding-4rem"
                            } `}
                            alt={item.title}
                          />
                        ) : (
                          <Skeleton height={200} containerClassName="w-100" />
                        )
                      ) : !Loading ? (
                        <div
                          className="rounded position-relative height-200px"
                          id="play-icon"
                          onClick={() => navigate(`/video/${item.slug}`)}
                        >
                          {/* <a
                            href={`./video/${item.slug}`}
                            target="_blank"
                            rel="noreferrer"
                          > */}
                          {item.link ? (
                            <ReactPlayer
                              ref={playerRef}
                              url={item.link}
                              id="player-id"
                              controls={false}
                              width={"100%"}
                              height={"200px"}
                              onReady={() => {
                                if (item.link.indexOf("youtube") < 0) {
                                  const timeToStart = 1 * 20;
                                  playerRef?.current?.seekTo(
                                    timeToStart,
                                    "seconds"
                                  );
                                }
                              }}
                            />
                          ) : (
                            <MediaPreview
                              isEducation={true}
                              fileName={item.video ? item.video : item.link}
                              height={"200px"}
                              className="rounded"
                            />
                          )}
                          {(item.link || item.video) && (
                            <div className=" h-100 w-100 top-0 rounded position-absolute background-transparent">
                              <Image
                                local={true}
                                alt="play"
                                src={require("../../../../images/play.webp")}
                                className=" position-absolute height-20px width-20px play-icon top-0 bottom-0 start-0 end-0 d-flex m-0 m-auto"
                              />
                            </div>
                          )}
                          {/* </a> */}
                        </div>
                      ) : (
                        <Skeleton height={200} containerClassName="w-100" />
                      )}
                      <div className="evaluate_content text-start  my-3 ms-2">
                        <h5 className=" text-body-tertiary fw-semibold mb-1">
                          {!Loading ? (
                            item?.blogcategories?.length &&
                            item?.blogcategories[0]?.name
                          ) : (
                            <Skeleton />
                          )}
                        </h5>
                        <div className="fs-5 fw-700 px-md-0 overflowWrap-break-word">
                          {!Loading ? (
                            item?.title?.length > 60 ? (
                              item.title.slice(0, 60) + "..."
                            ) : (
                              item.title
                            )
                          ) : (
                            <Skeleton />
                          )}
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="text-muted mb-2">
                            {!Loading ? (
                              item?.userDetails?.userType === "hospital" ||
                              item?.userDetails?.userType === "clinic" ||
                              item?.userDetails?.userType === "gym" ||
                              item?.userDetails?.userType ===
                                "rehabilitation_center" ? (
                                item?.userDetails?.firstName
                              ) : (
                                item.providerName
                              )
                            ) : (
                              <Skeleton />
                            )}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="text-muted mb-2">
                            {!Loading &&
                              moment(item.createdAt).format(
                                "MMM Do YYYY, h:mm a"
                              )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {!Loading && blogArticles.length > 0 && (
        <div className="container px-4 px-md-5">
          <Pagination
            onPageChange={(event) => {
              setBlogPageNo(event.selected);
              ref.current.scrollIntoView({
                behavior: "smooth",
                alignToTop: true,
              });
            }}
            pageRangeDisplayed={innerWidth < 769 ? 1 : 5}
            totalPages={totalBlogPages}
            currentPageNo={currentPageNo}
          />
        </div>
      )}
    </>
  );
};

export default ProviderEducation;
