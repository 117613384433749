import React from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { formatPhoneNumbers } from "../util/utils";

const ClinicInfo = ({ providerDetail }) => {
  return (
    <>
      {providerDetail?.clinic?.map((map, index) => {
        const mapAddress = !isEmpty(map.addressLine)
          ? `https://maps.google.com/maps?q=${encodeURIComponent(
              `${map.addressLine.replace("#", "")}, ${map.city}, ${
                map.state
              }, ${map.zipcode} `
            )}&t=&z=15&ie=UTF8&iwloc=1&output=embed`
          : "";

        return (
          <div key={index} className="row bg-white p-3 rounded-1">
            <div className="col-sm-6">
              {providerDetail.phoneNumber && (
                <>
                  <h4 className="text-black fw-semibold">Contact</h4>
                  <p className="d-flex mt-4">
                    <i className="fas fa-phone text-primary me-3 fs-3"></i>
                    <a
                      href={`tel:${formatPhoneNumbers(
                        providerDetail.phoneNumber
                      )}`}
                      className="d-flex text-decoration-none text-black"
                    >
                      {formatPhoneNumbers(providerDetail.phoneNumber)}
                    </a>
                  </p>
                </>
              )}

              {(!isEmpty(map.addressLine) ||
                !isEmpty(map.city) ||
                !isEmpty(map.state) ||
                !isEmpty(map.zipcode)) && (
                <>
                  <h4 className="text-black fw-semibold">Location</h4>
                  <p className="d-flex fw-light mt-4">
                    <p>
                      <i className="fas fa-map-marker-alt text-primary me-3 fs-3"></i>
                    </p>
                    {map?.addressLine && (
                      <>
                        {map?.addressLine} <br />
                      </>
                    )}
                    {map?.city && `${map?.city}, `}
                    {map?.state && `${map?.state}, `}
                    {map?.zipcode && (
                      <>
                        {map?.zipcode} <br />
                      </>
                    )}
                  </p>
                </>
              )}

              {!!providerDetail?.clinic[0]?.businessHours?.length > 0 ||
                ((!isEmpty(
                  providerDetail?.clinic[0]?.businessHours[0]?.startDay
                ) ||
                  !isEmpty(
                    providerDetail?.clinic[0]?.businessHours[0]?.endDay
                  ) ||
                  !isEmpty(
                    providerDetail?.clinic[0]?.businessHours[0]?.startTime
                  ) ||
                  !isEmpty(
                    providerDetail?.clinic[0]?.businessHours[0]?.endTime
                  )) && <h6>Business Hours</h6>)}

              {map.businessHours.map((businessHours, index) => (
                <div key={index} className="d-flex">
                  <i className="fas fa-map-marker-alt text-primary me-3 fs-3 visibility-hidden gap-20px" />
                  <div className="d-flex">
                    <p>
                      Hours:{" "}
                      {businessHours.startTime &&
                      businessHours.startTime.length &&
                      businessHours.endTime &&
                      businessHours.endTime.length
                        ? `${moment(businessHours.startTime, [
                            "HH:mm:A",
                          ]).format("hh:mm a")} - ${moment(
                            businessHours.endTime,
                            ["HH:mm:A"]
                          ).format("hh:mm a")}`
                        : moment(businessHours.startTime, ["HH:mm:A"]).format(
                            "hh:mm a"
                          )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-sm-6">
              {!isEmpty(mapAddress) && (
                <iframe
                  src={mapAddress}
                  width={"100%"}
                  height="100%"
                  title="address"
                  className="border-0"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ClinicInfo;
