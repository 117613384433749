export const Settings = {
  contentPreferencesMode: "auto" || "manual",
};

export const SettingsKeys = Object.keys(Settings);

export const initialSettings = {
  contentPreferencesMode: "auto",
};

export const inputLabels = (() => {
  const target = {};
  for (const setting in initialSettings) {
    target[setting] = setting;
  }
  return target;
})();

export function settingsReducer(state, action) {
  return {
    ...state,
    [action.name]: action.value === "default" ? undefined : action.value,
  };
}
