export let PAGE_VARIABLES = {
  HEADING: {
    HEALTH_PROFILE: "Health Profile",
    TALK_TO_A_PROVIDER: "Talk to a Provider",
    SETTINGS: "Settings",
  },
  AUTH: JSON.parse(localStorage.getItem("mop-user")),
  //INSURANCE
  INSURANCE: {
    INSURANCE: "Insurance",
    ADD_INSURANCE: "Add Insurance",
    INSURANCE_PROVIDER: "Insurance provider",
    MEMBER_ID: "Member ID",
    POLICY_HOLDER_QUESTION: "Are you policyholder ?",
  },
  TALK_TO_A_PROVIDER: {
    STEP_8: [
      {
        value: "check1",
        label: `I agree to the informed consent on my own behalf, or on behalf of
      my minor dependent, to recieve care with a Clinekt Health Health
      provider.(This is need only once per year and apply to all visits
      during the year.)*`,
      },
      {
        value: "check2",
        label: `I understand that I may access Clinekt Health's Notice of Privacy
        Practices and acknowledge that I have been provided access to such
        Notice of Privacy Practices.*`,
      },
      {
        value: "check3",
        label: ` (Optional) I agree to the release of my medication history, if
      available, for review by clinician or provider for this visit.`,
      },
    ],
    ORTHO_CARE_OPTIONS: [
      { value: "urgentCare", label: "Urgent Care" },
      { value: "emergencyRoom", label: "Emergency Room" },
      { value: "primaryCareDoctor", label: "Primary Care Doctor" },
      { value: "physicalTherapist", label: "Physical Therapist" },
      { value: "orthopedicSpecalist", label: "Orthopedic Specalist" },
    ],
  },
  SETTINGS: {
    CONTACT_INFO: "Contact info",
    ADDRESS: "Address",
    CITY: "City",
    STATE: "State",
    ZIPCODE: "Zipcode",
    GENDER: ["male", "female"],
    ETHNICITY: [
      "Hispanic or Latin American",
      "Middle Eastern",
      "African",
      "East African",
      "African American",
      "White or Caucasian",
      "Indian",
      "Native Hawaiian",
      "Maori",
      "Fijian",
      "Cook Island Maori",
      "Native American or Inuit",
      "Chinese",
      "East Asian",
      "South Asian",
      "South East Asian",
    ],
    LANGUAGES: [
      "Afrikaans",
      "Albanian",
      "Amharic",
      "Arabic",
      "Armenian",
      "Bengali",
      "Bosnian",
      "Bulgarian",
      "Burmese",
      "Cantonese",
      "Croatian",
      "Czech",
      "Dutch",
      "English",
      "Filipino",
      "French",
      "German",
      "Greek",
      "Gujarati",
      "Haitian Creole",
      "Hebrew",
      "Hindi",
      "Hungarian",
      "Indonesian",
      "Italian",
      "Japanese",
      "Kannada",
      "Khmer",
      "Korean",
      "Malay",
      "Malayalam",
      "Mandarin",
      "Marathi",
      "Northern Sotho",
      "Norwegian",
      "Persian",
      "Polish",
      "Portuguese",
      "Punjabi",
      "Romanian",
      "Russian",
      "Serbian ",
      "Slovak",
      "Spanish",
      "Swahili",
      "Swedish",
      "Tamil",
      "Telugu",
      "Thai",
      "Turkish",
      "Ukrainian",
      "Urdu",
      "Vietnamese",
      "Yoruba",
      "Azerbaijani",
      "Belarusian",
      "Bihari",
      "Catalan",
      "Danish",
      "Estonian",
      "Finnish",
      "Guarani",
      "Hausa",
      "Hmong",
      "Igbo",
      "Kashmiri",
      "Kyrgiz",
      "Luxembourgish",
      "Lao",
      "Lithuanian",
      "Latvian",
      "Malagasy",
      "Macedonian",
      "Nepali",
      "Navajo",
      "Oriya",
      "Papiamento",
      "Dari",
      "Pashto",
      "Somali",
      "Sotho",
      "Southern Ndebele",
      "Swazi",
      "Tigrinya",
      "Tsonga",
      "Tswana",
      "Venda",
      "Xhosa",
      "Zulu",
      "American Sign Language",
    ],
  },
  // HEALTH PROFILE MODULE
  HEALTH_PROFILE: [
    {
      name: "HEALTH CONDITIONS",
      value: "conditionSymptoms",
      pathName: "/pat-health-condition",
    },
    {
      name: "HEALTH FILES",
      pathName: "/pat-health-files",
    },
    {
      name: "ALLERGIES",
      value: "allergies",
      pathName: "/pat-allergies",
    },
    {
      name: "CURRENT MEDICATIONS",
      value: "medications",
      pathName: "/pat-medications",
    },
    {
      name: "SURGERIES",
      value: "treatmentProcedure",
      pathName: "/pat-surgeries",
    },
  ],
  //types
  MUSCLE_GROUPS: [
    "Knee",
    "Shoulder",
    "Hand & Wrist",
    "Foot & Ankle",
    "Back",
    "Neck",
    "Hip",
    "Elbow",
  ],
  TIME: [
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
  ],
  PROVIDERS: [
    {
      value: "orthopedic_surgeon",
      label: "Orthopedic Surgeon",
      message: "Click here to see available Orthopedic Surgeons in your area",
      image: "mop orthopedic surgeon.svg",
    },
    {
      value: "nurse_practitioner",
      label: "Nurse Practitioner",
      message: "Click here to see available Nurse Practitioners in your area",
      image: "mop nurse.svg",
    },
    {
      value: "primary_care",
      label: "Primary Care",
      message:
        "Click here to see available Primary Care Physicians in your area",
      image: "mop primary.svg",
    },
    {
      value: "neuro_surgeon",
      label: "Neurosurgeon",
      message: "Click here to see available Neurosurgeons in your area",
      image: "mop neurosurgeon.svg",
    },
    {
      value: "physical_therapist",
      label: "Physical Therapist",
      message: "Click here to see available Physical Therapist in your area",
      image: "mop physical therapist.svg",
    },
    {
      value: "wellness_provider",
      label: "Wellness Provider",
      message:
        "Click here to see the large variety of Wellness Providers in your area",
      image: "Wellness Provider.svg",
    },
    {
      value: "personal_trainer",
      label: "Personal Trainer",
      message: "Click here to see available Personal Trainers in your area",
      image: "mop personal trainer.svg",
    },
    {
      value: "athletic_trainers",
      label: "Athletic Trainers",
      message: "Click here to see available Athletic Trainers in your area",
      image: "Athletic Trainer.svg",
    },
    {
      value: "chiropractor",
      label: "Chiropractor",
      message:
        "Create a network of trusted providers and a library of exercises for you and your clients",
      image: "mop chiro.svg",
    },
    {
      value: "pain_management_specialist",
      label: "Pain Management Specialist",
      message:
        "Easily educate your patients on musculoskeletal issues leading to better comprehension and outcomes",
      image: "PainM.svg",
    },
    {
      value: "massage_therapist",
      label: "Massage Therapist",
      message:
        "Create a network of trusted providers to generate more referrals",
      image: "Massage Therapist.svg",
    },
    {
      value: "rehabilitation_center",
      label: "Rehabilitation Center",
      message:
        "Click here to see available Rehabilitation Centers in your area",
      image: "RehabCenter.svg",
    },
    {
      value: "gym",
      label: "Gym",
      message: "Click here to see available Gyms in your area",
      image: "mop gym.svg",
    },
    {
      value: "hospital",
      label: "Hospital",
      message: "Click here to see available Hospitals in your area",
      image: "Hospital.svg",
    },
    {
      value: "clinic",
      label: "Clinic",
      message: "Click here to see available Clinic in your area",
      image: "Clinic.svg",
    },
  ],
  // BUTTON CONSTANTS
  BUTTON: {
    TYPE: {
      SUBMIT: "submit",
      RESET: "reset",
      BUTTON: "button",
    },
    VARIANT: {
      SUCCESS: "success",
      PRIMARY: "primary",
      OUTLINE_SECOUNDARY: "outline-secondary",
      OUTLINE_DARK: "outline-dark",
      OUTLINE_PRIMARY: "outline-primary",
    },
  },

  // COMMONLY USED VARIABLES
  COMMON: {
    VIEW: "View",
    MARK_COMPLETE: "Mark Complete",
    CANCEL: "Cancel",
    SUBMIT: "Submit",
    OK: "Ok",
    FIRST_NAME: "First name",
    LAST_NAME: "Last name",
    YES: "Yes",
    NO: "No",
    SAVE: "Save",
    ADD: "Add",
  },
};
