import { useCallback, useEffect, useState } from "react";
import axios from "../../../../../util/axiosInst";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

export function useFirebaseAuth() {
  const [user, setUser] = useState(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const getToken = useCallback(
    async (user_identity, room_name) => {
      const headers = new window.Headers();

      const idToken = await user.getIdToken();
      headers.set("Authorization", idToken);
      headers.set("content-type", "application/json");
      headers.set("Access-Control-Allow-Origin", "*");

      const { data } = await axios.post(
        "/v1/twilio/video/token",
        {
          user_identity,
          room_name,
          create_conversation:
            process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true",
        },
        {
          headers,
        }
      );
      return data;
    },
    [user]
  );

  const updateRecordingRules = useCallback(
    async (room_sid, rules) => {
      const headers = new window.Headers();

      const idToken = await user.getIdToken();
      headers.set("Authorization", idToken);
      headers.set("content-type", "application/json");
      headers.set("Access-Control-Allow-Origin", "*");

      return await axios
        .post(
          "/v1/twilio/video/recordingrules",
          {
            room_sid,
            rules,
          },
          {
            headers,
          }
        )
        .then(async (res) => {
          if (!res.ok) {
            const recordingError = new Error(
              res.error?.message ||
                "There was an error updating recording rules"
            );
            recordingError.code = res.error?.code;
            return Promise.reject(recordingError);
          }
          return res;
        });
    },
    [user]
  );

  useEffect(() => {
    initializeApp(firebaseConfig);
    getAuth().onAuthStateChanged((newUser) => {
      setUser(newUser);
      setIsAuthReady(true);
    });
  }, []);

  const signIn = useCallback(() => {
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/plus.login");

    return signInWithPopup(getAuth(), provider).then((newUser) => {
      setUser(newUser.user);
    });
  }, []);

  const signOut = useCallback(() => {
    return getAuth()
      .signOut()
      .then(() => {
        setUser(null);
      });
  }, []);

  return { user, signIn, signOut, isAuthReady, getToken, updateRecordingRules };
}
