import React from "react";
import { TbToggleLeft, TbToggleRight } from "react-icons/tb";

const PasswordCheck = ({ password, typePassword }) => {
  const passwordMatch = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  let hasNumber = /\d/;

  const checkUppercase = (str) => {
    for (let i = 0; i < str.length; i++) {
      if (
        str.charAt(i) === str.charAt(i).toUpperCase() &&
        str.charAt(i).match(/[a-z]/i)
      ) {
        return true;
      }
    }
    return false;
  };

  const checkLowercase = (str) => {
    for (let i = 0; i < str.length; i++) {
      if (
        str.charAt(i) === str.charAt(i).toLowerCase() &&
        str.charAt(i).match(/[a-z]/i)
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {typePassword &&
        (password.length < 8 ||
          !hasNumber.test(password) ||
          !checkUppercase(password) ||
          !checkLowercase(password) ||
          !password.match(passwordMatch)) && (
          <div className="text-start liststyletype-none">
            <li>
              {password.length < 8 ? (
                <TbToggleLeft className="password-toggle-red" />
              ) : (
                <TbToggleRight className="password-toggle-green" />
              )}
              Must Contain at least 8 Characters
            </li>
            <li>
              {!hasNumber.test(password) ? (
                <TbToggleLeft className="password-toggle-red" />
              ) : (
                <TbToggleRight className="password-toggle-green" />
              )}
              Must Contain at least 1 digit (0-9)
            </li>
            <li>
              {!checkUppercase(password) ? (
                <TbToggleLeft className="password-toggle-red" />
              ) : (
                <TbToggleRight className="password-toggle-green" />
              )}
              Must Contain at least 1 uppercase letter (A-Z)
            </li>
            <li>
              {!checkLowercase(password) ? (
                <TbToggleLeft className="password-toggle-red" />
              ) : (
                <TbToggleRight className="password-toggle-green" />
              )}
              Must Contain at least 1 lowercase letter (a-z)
            </li>
            <li>
              {!password.match(passwordMatch) ? (
                <TbToggleLeft className="password-toggle-red" />
              ) : (
                <TbToggleRight className="password-toggle-green" />
              )}
              Must Contain at least 1 special case character (! - *)
            </li>
          </div>
        )}
    </>
  );
};

export default PasswordCheck;
