import React from "react";
import SendersMessage from "./MessageTypes/SendersMessage";
import OthersMessage from "./MessageTypes/OthersMessage";
import { PAGE_VARIABLES } from "../constants/constant";

const ConversationMessages = ({ currentChat, formatDateTime }) => {
  return (
    <div className="chat-messages">
      <div className="pr-4  overflowX-hidden px-3 pt-2">
        {currentChat?.conversation?.map((conversation) => (
          <>
            {conversation?.postedByUser?.userId ===
            PAGE_VARIABLES?.AUTH?.userId ? (
              <>
                <SendersMessage
                  conversation={conversation}
                  formatDateTime={formatDateTime}
                />
              </>
            ) : (
              <OthersMessage
                conversation={conversation}
                formatDateTime={formatDateTime}
              />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default ConversationMessages;
