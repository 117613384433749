import React from "react";
import BusinessInfo from "../BusinessInfo";
import ClinicInfo from "../Clinicinfo";
import RehabInfo from "../RehabInfo";
import AddressInfo from "../AddressInfo";

const ContactTab = ({ providerDetail }) => {
  const BusinessHoursInitValue = {
    startDay: "",
    endDay: "",
    startTime: "",
    endTime: "",
  };
  const ClinicInitValue = {
    name: "",
    addressLine: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    businessHours: [BusinessHoursInitValue],
  };

  return (
    <div className="p-3 container bg-muted-25 p-md-5 mt-4 card-scroll">
      {(providerDetail?.clinic &&
        (providerDetail?.clinic?.addressLine !== "" ||
          providerDetail?.clinic?.city !== "" ||
          providerDetail?.clinic?.country !== "" ||
          providerDetail?.clinic?.state !== "" ||
          providerDetail?.clinic?.zipcode !== "")) ||
      (providerDetail?.business_info &&
        (providerDetail?.business_info?.address !== "" ||
          providerDetail?.business_info?.city !== "" ||
          providerDetail?.business_info?.state !== "" ||
          providerDetail?.business_info?.zipcode !== "")) ||
      (providerDetail?.rehab_info &&
        (providerDetail?.rehab_info?.address !== "" ||
          providerDetail?.rehab_info?.city !== "" ||
          providerDetail?.rehab_info?.state !== "" ||
          providerDetail?.rehab_info?.zipcode !== "")) ||
      (providerDetail?.address &&
        (providerDetail?.address !== "" ||
          providerDetail?.city !== "" ||
          providerDetail?.state !== "" ||
          providerDetail?.country !== "" ||
          providerDetail?.zipcode !== "" ||
          providerDetail?.phoneNumber?.toString().length)) ? (
        <>
          {providerDetail.userType === "orthopedic_surgeon" ||
          providerDetail.userType === "neuro_surgeon" ||
          providerDetail.userType === "primary_care" ||
          providerDetail.userType === "nurse_practitioner" ||
          providerDetail?.userType === "pain_management_specialist" ? (
            <ClinicInfo
              providerDetail={providerDetail}
              ClinicInitValue={ClinicInitValue}
            />
          ) : (
            <>
              {providerDetail.userType === "wellness_provider" ||
              providerDetail.userType === "massage_therapist" ? (
                <BusinessInfo providerDetail={providerDetail} />
              ) : (
                <>
                  {providerDetail.userType === "physical_therapist" ||
                  providerDetail.userType === "chiropractor" ? (
                    <RehabInfo providerDetail={providerDetail} />
                  ) : (
                    <>{<AddressInfo providerDetail={providerDetail} />}</>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <p className="text-center">No Data Found!</p>
      )}
    </div>
  );
};

export default ContactTab;
