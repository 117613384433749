export const leadershipInitValue = {
  teamTitle: "",
  firstName: "",
  lastName: "",
};
export const serviceInitValue = {
  serviceLines: "",
};
export const certificationInitValue = {
  label: "",
  value: "",
};
