import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PageNotFound from "./PageNotFound";
import { useAppState } from "../state/state";
import Call from "./Call";

const Phone = ({ error, callback }) => {
  const { meetingId, getVoiceToken } = useAppState();
  const [token, setToken] = useState(null);

  const VoiceToken = async () => {
    if (!meetingId) return false;
    await getVoiceToken().then((data) => {
      if (data) {
        setToken(data.token);
      }
    });
  };

  useEffect(() => {
    document.getElementById("root").scrollTo(0, -10);
    VoiceToken();
  }, [meetingId]);

  return (
    <div className="container bg-white">
      {error && <PageNotFound error={error} />}
      {!error && token && (
        <div key={token} className={"col-sm-12 p-4"}>
          {token && <Call token={token} callback={callback} />}
        </div>
      )}
      <div className={"col-sm-12 text-center pb-4"}>
        Powered by My Orthopedic Problem
      </div>
    </div>
  );
};

Phone.propTypes = {
  error: PropTypes.any,
};

export default Phone;
