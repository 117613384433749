import React from "react";
import CertificationSelect from "./CertificationSelect";
import TextInput from "./TextInput";

const Certifications = ({
  setCertificationRows,
  certificationIntitialValue,
  setValue,
  certificationRows,
  register,
  signupData,
  watch,
}) => {
  return (
    <div className="container">
      <h5 className="fontweight-bold mt-4">Certifications</h5>
      {certificationRows.map((trainingRow, ind) => (
        <div className="row" key={ind}>
          <div className="col-md-6">
            <CertificationSelect
              setValue={setValue}
              certifications={trainingRow}
              certificationRows={certificationRows}
              index={ind}
              signupData={signupData}
              watch={watch}
              name={`certifications[${ind}]`}
              setCertificationRows={setCertificationRows}
            />
          </div>
          <div className="col-md-6 mb-3">
            <TextInput
              register={register}
              maxlength="4"
              type="text"
              label="Year of Completion"
              name={`certifications[${ind}].year`}
            />
          </div>
          <div className="row">
            {certificationRows.length - 1 === ind && (
              <div
                className="col-md-2 text-primary cursor-pointer"
                onClick={() => {
                  const rows = [
                    ...watch("certifications"),
                    certificationIntitialValue,
                  ];
                  setCertificationRows(rows);
                }}
              >
                Add new
              </div>
            )}
            <div
              className="col-md-1 text-danger cursor-pointer"
              onClick={() => {
                let rows = [...certificationRows];
                if (rows.length > 1) {
                  rows.splice(ind, 1);
                  setCertificationRows(rows);
                  setValue("certifications", rows);
                } else {
                  setCertificationRows([
                    { ...certificationIntitialValue, year: "" },
                  ]);
                  setValue("certifications", [
                    { ...certificationIntitialValue, year: "" },
                  ]);
                }
              }}
            >
              Remove
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Certifications;
