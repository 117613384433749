import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import axios from "../../../utils/axiosInst";
import { affiliatedGymsInitValue, certificationInitValue } from "./init";
import Certifications from "../custom/Certifications";
import SocialMedia from "../custom/SocialMedia";
import { PAGE_VARIABLES } from "../../constants";
import BasicInfo from "../custom/BasicInfo";
import Biography from "../custom/Biography";
import Speciality from "../custom/Speciality";
import TextInput from "../custom/TextInput";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const PhysicalTherapistProfile = ({ useFormControl, signupData }) => {
  const MySwal = withReactContent(Swal);

  const [certificationRows, setCertificationRows] = useState([
    certificationInitValue,
  ]);
  const [affiliatedGymsRows, setAffiliatedGyms] = useState([
    affiliatedGymsInitValue,
  ]);
  const [select, setSelect] = useState("Yes");
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormControl;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("claim-status") === "true")) {
      MySwal.fire({
        title: PAGE_VARIABLES?.COMMON?.ALERT_MESSAGE,
        icon: "info",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      let data = {
        loggedIn: true,
      };

      if (JSON.parse(localStorage.getItem("claim-id"))) {
        axios.patch(
          `/v1/claimProfile/${JSON.parse(localStorage.getItem("claim-id"))}`,
          data
        );
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="mt-3">
            <BasicInfo register={register} useFormControl={useFormControl} />
          </div>
          <div className="mt-3">
            <Biography register={register} />
          </div>
          <div className="mt-3">
            <Speciality register={register} />
          </div>
          <Certifications
            certificationIntitialValue={certificationInitValue}
            setValue={setValue}
            signupData={signupData}
            setCertificationRows={setCertificationRows}
            getValues={getValues}
            certificationRows={certificationRows}
            register={register}
            watch={watch}
            errors={errors}
          />
          <div className="container mt-4">
            <h5 className=" fontweight-bold mb-3">Affiliated Gyms</h5>
            <div className="row">
              {affiliatedGymsRows.map((affiliatedGymsRow, ind) => (
                <div className="col-md-6" key={ind}>
                  <TextInput
                    errors={errors}
                    register={register}
                    label="Affiliated Gym Name"
                    name={`affiliations[${ind}.affiliatedGymsName]`}
                  />
                  <div className="row my-3 mb-4">
                    {affiliatedGymsRows.length - 1 === ind && (
                      <div
                        className="col-md-4 text-primary cursor-pointer"
                        onClick={() => {
                          const rows = [
                            ...getValues("affiliations"),
                            affiliatedGymsInitValue,
                          ];
                          setAffiliatedGyms(rows);
                        }}
                      >
                        Add new
                      </div>
                    )}
                    <div
                      className="col-md-1 text-danger cursor-pointer"
                      onClick={() => {
                        let rows = [...getValues("affiliations")];

                        if (rows.length > 1) {
                          rows.splice(ind, 1);
                          setAffiliatedGyms(rows);
                          setValue("affiliations", rows);
                        } else {
                          setAffiliatedGyms([affiliatedGymsInitValue]);
                          setValue("affiliations", [affiliatedGymsInitValue]);
                        }
                      }}
                    >
                      Remove
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mb-3">Rehabilitation Info</h5>
            <div className="row mb-3">
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Rehabilitation Name"
                  name={"rehab_info.name"}
                />
              </div>
              <div className="col-md-4 mb-3">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Rehabilitation Address"
                  name={"rehab_info.address"}
                />
              </div>
              <div className="col-md-4 mb-1">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Rehabilitation City"
                  name={"rehab_info.city"}
                />
              </div>
              <div className="col-md-4 mb-1">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Rehabilitation State"
                  name={"rehab_info.state"}
                />
              </div>
              <div className="col-md-4 mb-1">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Rehabilitation Zip"
                  name={"rehab_info.zipcode"}
                  maxlength={5}
                />
              </div>
              <div className="col-md-4 mb-1">
                <TextInput
                  maxlength={10}
                  errors={errors}
                  register={register}
                  label="Rehabilitation Phone Number"
                  name={"rehab_info.phoneNumber"}
                />
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mb-3"> Ask a Question</h5>
            <div className="row">
              <div className="col-md-4">
                <label className="form-radio-label" for="inlineRadio1">
                  <h5 className="mb-3">
                    Ask a Question
                    <span data-tip data-for="info">
                      <i
                        className="fa fa-info-circle me-2 text-primary padding-left-5px fontsize-14px"
                        aria-hidden="true"
                      />
                    </span>
                  </h5>
                  <ReactTooltip id="info" place="right" effect="solid">
                    <span>
                      Ask an expert is a unique feature for providers to receive
                      questions and communicate directly with patients regarding
                      their conditions. Please select “No” if you do not wish to
                      communicate with patients in this way
                    </span>
                  </ReactTooltip>
                </label>
                <div className="radio-buttons d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name="askAnExpert"
                      className="align-middle me-1"
                      value="Yes"
                      checked={select === "Yes"}
                      onChange={(e) => setSelect("Yes")}
                    />
                    <label className="form-check-label me-4" for="inlineRadio1">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      name="askAnExpert"
                      className="align-middle me-1"
                      value="No"
                      checked={select === "No"}
                      onChange={(e) => setSelect("No")}
                    />
                    <label className="form-check-label" for="inlineRadio2">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <h5 className="fontweight-bold mb-3">Social Media</h5>
            <div className="row">
              <SocialMedia errors={errors} register={register} />
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-6 mb-3 d-flex flex-column">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Forms of Payment accepted"
                  name="payment_accepted_methods"
                />
              </div>
              <div className="col-md-6 mb-3 d-flex flex-column">
                <TextInput
                  errors={errors}
                  register={register}
                  label="Years of Experience"
                  name="years_of_experience"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PhysicalTherapistProfile;
