import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Spinner } from "react-bootstrap";

const VideoModal = ({ video, show, setShow }) => {
  const [showData, setShowData] = useState(true);

  useEffect(() => {
    window.fbq("track", `Education Blog Content Opened in modal.`);
  }, []);
  return (
    <div
      className={`modal fade show bd-example-modal-lg background-00000061 ${
        show ? "d-block" : "d-none"
      }`}
      id="videoModal"
      data-backdrop="static"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="videoModalLabel">
              {video.name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShow(false);
              }}
            ></button>
          </div>
          {showData && (
            <div className="videomodal-showdata">
              <Spinner className="width-3rem height-3rem" animation="grow" />
            </div>
          )}

          {video.link.includes("youtube") ||
          video.link.includes("youtu.be") ||
          video.link.includes(".mp4") ||
          video.link.includes(".mp3") ? (
            <div className={showData === false ? "height-40rem" : "height-0"}>
              <ReactPlayer
                onReady={() => {
                  setShowData(false);
                }}
                url={video.link}
                id="frame"
                height="100%"
                controls={true}
                width="100%"
              />
            </div>
          ) : (
            <div className={showData === false ? "height-40rem" : "height-0"}>
              <iframe
                src={video.link}
                width="795"
                height="630"
                title="i_frame"
                id="i_frame"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default VideoModal;
