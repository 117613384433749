import React from "react";

const Pageheader = ({ children }) => {
  return (
    <div
      className="flex-fill mt-3 px-4 bg-F5F5F7"
      style={{
        paddingTop: "3rem",
      }}
    >
      {children}
    </div>
  );
};

export default Pageheader;
