import React from "react";
import { Table } from "react-bootstrap";
import { MdOutlineMapsHomeWork } from "react-icons/md";
import { BsHospitalFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { FaDollarSign } from "react-icons/fa";
import Image from "../custom/Image";

const Session2 = () => {
  return (
    <section className="container px-5 wrapper mb-5">
      <h1 className="landing-display-1 text-center">
        Better orthopedic care when you need it
      </h1>
      <p className="text-center fs--25" style={{ color: "rgb(166, 169, 174)" }}>
        Trust in our board-certified providers for your virtual orthopedic
        clinic needs.
      </p>
      <Table className="bg-white mt-5 mx-2">
        <thead>
          <tr>
            <td
              colSpan={2}
              className="text-end"
              style={{ paddingBottom: "20px" }}
            >
              <Image
                local={true}
                alt="My Orthopedic Problem"
                className="width-8rem"
              />
            </td>
            <td className="text-center">
              <MdOutlineMapsHomeWork
                className="fs--42"
                style={{ color: "red", width: "20px", height: "22px" }}
              />
              <br />
              <span className="fs--22 fontweight-bold">Urgent Care</span>
            </td>
            <td className="text-center">
              <BsHospitalFill
                className="fs--42 "
                style={{ color: "#b650d6", width: "20px", height: "22px" }}
              />
              <br />
              <span className="fs--22 fontweight-bold">Emergency Room</span>
            </td>
          </tr>
        </thead>
        <tbody
          style={{
            border: "2px solid lightgray",
            borderLeft: "none",
            borderRight: "none",
          }}
        >
          <tr className="" style={{ borderTop: "hidden" }}>
            <td className="h5 pe-0">Same day/next day appointments</td>
            <td
              className="text-center"
              style={{
                background: "#EBF5FF",
                borderRadius: "20px 20px 0 0",
              }}
            >
              <FaCheck className="text-success h3" />
            </td>
            <td className="text-center">
              <FaCheck className="text-success h3" />
            </td>
            <td className="text-center">
              <FaXmark className="text-danger h3 fontweight-bold" />
            </td>
          </tr>
          <tr>
            <td className="h5 pe-0" style={{ paddingTop: "17px" }}>
              No surprise fees
            </td>
            <td
              className="text-center m-0 py-2"
              style={{ background: "#EBF5FF" }}
            >
              <FaDollarSign className="text-success" />
              <p className="m-0 p-0"> $0/per visit</p>
            </td>
            <td className="text-center m-0 p-0 py-2">
              <FaDollarSign className="text-danger" />
              <FaDollarSign className="text-danger" />
              <p className="m-0 p-0 text-muted"> $175</p>
            </td>
            <td className="text-center m-0 p-0 py-2">
              <FaDollarSign className="text-danger" />
              <FaDollarSign className="text-danger" />
              <p className="m-0 p-0 text-muted"> $850</p>
            </td>
          </tr>
          <tr>
            <td className="h5 pe-0">Discounted online prescriptions</td>
            <td className="text-center" style={{ background: "#EBF5FF" }}>
              <FaCheck className="text-success h3" />
            </td>
            <td className="text-center ">
              <FaXmark className="text-danger h3 fontweight-bold" />
            </td>
            <td className="text-center ">
              <FaXmark className="text-danger h3 fontweight-bold" />
            </td>
          </tr>
          <tr style={{ borderBottom: "hidden" }}>
            <td className="h5 pe-0">24/7 virtual care</td>
            <td
              className="text-center"
              style={{
                background: "#EBF5FF",
                borderRadius: "0 0 20px 20px",
              }}
            >
              <FaCheck className="text-success h3" />
            </td>
            <td className="text-center ">
              <FaXmark className="text-danger h3 fontweight-bold" />
            </td>
            <td className="text-center ">
              <FaXmark className="text-danger h3 fontweight-bold" />
            </td>
          </tr>
        </tbody>
      </Table>
    </section>
  );
};

export default Session2;
