import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isUndefined } from "lodash";
import moment from "moment";
import axios from "../../../util/axiosInst";
import MediaPreview from "../../../components/common/MediaPreview";
import Pagination from "../../../components/common/Pagination";
import HelmetTag from "../../../components/helmet/Helmet";
import Layout from "./layout/Layout";
import Footer from "./Home/Footer";
import Image from "./custom/Image";

const Newsroom = () => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [adminPosts, setAdminPosts] = useState([]);

  const getAdminPost = async () => {
    const { data } = await axios.get(
      `/v1/public/blog-news/?limit=6&sortBy=createdAt:desc&page=${page}`
    );
    setPagination(data);
    setAdminPosts(data);
  };

  const handlePageChange = (event) => {
    setPage(event.selected + 1);
    document.getElementById("root").scrollTo(0, 0);
  };

  const textTrimming = () => {
    if (document.getElementById("post")) {
      if (document.getElementById("post").childNodes.length > 2) {
        if (document.getElementById("learnMore")) {
          document.getElementById("learnMore").style.display = "flex";
        }
        let slice = function (elements, start, end) {
          let sliced = Array.prototype.slice.call(elements, start, end);
          return sliced;
        };

        let elems = document.getElementById("post").childNodes;
        let html = "";
        let slices = slice(elems, 0, 4),
          len = slices.length,
          i = 0;
        for (i; i < len; i++) {
          if (slices[i].firstChild.nodeName === "#text") {
            html += "<p>" + slices[i].firstChild.nodeValue + "</p>";
            document.getElementById("post").innerHTML = html;
          } else if (slices[i].firstChild.localName === "img") {
            html += "<p>" + slices[i].firstChild.outerHTML + "</p>";
            document.getElementById("post").innerHTML = html;
          }
        }
      } else {
        if (document.getElementById("post")) {
          if (
            document.getElementById("post").childNodes &&
            document.getElementById("blogpost")
          ) {
            document.getElementById("post").childNodes.forEach((element) => {
              if (
                element.firstChild &&
                element.firstChild.localName === "img"
              ) {
                element.firstChild.classList.add("w-100");
              }
            });
            if (
              document.getElementById("blogpost").getElementsByTagName("img")
                .length > 0
            ) {
              document
                .getElementById("blogpost")
                .querySelectorAll("img")
                .forEach((element) => {
                  element.classList.add("w-100");
                });
            }
          }
        }
        if (document.getElementById("learnMore")) {
          document.getElementById("learnMore").style.display = "none";
        }
      }
      document
        .getElementById("post")
        .querySelectorAll("img")
        .forEach((element) => {
          element.classList.add("w-100");
        });
    }
  };

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    getAdminPost(1);
    window.fbq("track", "Latest News Page is viewed");
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    textTrimming();
  }, [location?.state?.data]);
  return (
    <Layout>
      <div className="container" key="ddd">
        <HelmetTag
          title="Orthopedic Newsroom | My Orthopedic Problem"
          content="Stay up-to-date on the latest in the world of orthopedics by visiting our newsroom. Need to see a specialist? Let My Orthopedic Problem connect you today!"
          canonical="https://myorthopedicproblem.com/press"
          JSONLD={`
          {
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "Newsroom",
            "url": "http://myorthopedicproblem.com/press",
            "description": "Stay up-to-date on the latest in the world of orthopedics by visiting our newsroom. Need to see a specialist? Let My Orthopedic Problem connect you today!"
          }
          `}
        />
        <section className="sec_seven pt-5 px-md-5 px-3">
          <div className="container-fluid px-0">
            <div className="d-flex justify-content-start align-items-start mb-3">
              <h3 className="heading-font">Newsroom</h3>
            </div>
          </div>
          <div className="col-12 row  overflow-auto px-3 px-md-0 py-2">
            {!isUndefined(adminPosts) && adminPosts?.results?.length > 0 && (
              <>
                {adminPosts?.results.map((item, index) => (
                  <div
                    key={index}
                    className={` ${
                      adminPosts?.results.length > 5
                        ? "justify-content-between"
                        : ""
                    } mx-xl-0   col-md-4 col-12 mb-4`}
                  >
                    <Link to={`/press/${item.slug}`} state={{ data: item }}>
                      <div
                        className={`evaluate_img latests-animation position-relative ${
                          item.video ? "press-card-video " : "press-card"
                        } m-0 min-w-100`}
                      >
                        {item.video ? (
                          <>
                            <MediaPreview
                              isEducation={true}
                              fileName={item.video ? item.video : item.link}
                              height={"236px"}
                              className="m-0 news-room"
                            />
                            <div className=" h-100 w-100 top-0 rounded position-absolute background-transparent">
                              <Image
                                local={true}
                                alt="play.webp"
                                src={require("../../../images/play.webp")}
                                className="position-absolute play-icon start-0 top-0 bottom-0 end-0 d-flex m-0 m-auto width-20px height-20px"
                              />
                            </div>
                          </>
                        ) : (
                          <Image
                            src={item?.banner}
                            alt={`news ${index}`}
                            className="card-img-top w-100"
                          />
                        )}
                        <div className="evaluate_content">
                          <h5 className="fw-800 text-white mb-0">
                            {item.title}
                          </h5>
                          <span className="fw-semibold text-white-65 fs-6">
                            {moment(item.createdAt).format("MMMM DD, YYYY")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            )}
          </div>
        </section>
        <div className="mx-5">
          {adminPosts?.results?.length > 0 && (
            <Pagination
              onPageChange={handlePageChange}
              pageRangeDisplayed={window.innerWidth < 769 ? 2 : 5}
              currentPageNo={pagination.page - 1}
              totalPages={pagination.totalPages}
            />
          )}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default Newsroom;
