import React, { createRef, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown, Modal, Tab, Tabs } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import ReactStars from "react-rating-stars-component";
import { capitalize, isEmpty } from "lodash";
import mixpanel from "mixpanel-browser";
import Swal from "sweetalert2";
import ExerciseTab from "../../../../components/providers/ExcerciseTab/ExerciseTab";
import LoadingScreen from "../../../../components/custom/LoadingScreen";
import ProfileTab from "../../../../components/providers/ProfileTab";
import ContactTab from "../../../../components/providers/ContactTab";
import ClassesTab from "../../../../components/providers/ClassesTab";
import HomeTab from "../../../../components/providers/HomeTab";
import { bookAnAppointmentText } from "../../../../constants";
import HelmetTag from "../../../../components/helmet/Helmet";
import axios from "../../../../util/axiosInst";
import {
  formatPhoneNumber,
  getName,
  getProviderName,
} from "../../../../util/utils";
import Layout from "../layout/Layout";
import Image from "../custom/Image";
export const MySwal = withReactContent(Swal);
const ProviderDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [moreMenu, setMoreMenu] = useState("");

  const [condition, setCondition] = useState({
    name: "",
    usertype: "",
    address: "",
  });
  const ref = createRef();

  const [membershipDetail, setMembershipDetail] = useState({});
  const [providerDetail, setProviderDetail] = useState({});
  const [loader, setLoader] = useState(false);
  const [tabKey, settabKey] = useState("");
  const [showBook, setShowBook] = useState(false);
  const getProvider = async (id) => {
    setProviderDetail({});
    setLoader(true);
    const { data } = await axios.get("/v1/public/provider/" + id + "/detail");
    if (isEmpty(data)) navigate("/404");
    else if (data && data.hasOwnProperty("ratings")) data.rating = data.ratings;
    else if (data && !data.hasOwnProperty("rating")) data.rating = 0;
    if (data.planType !== "premium") {
      settabKey("home");
      setMoreMenu("home");
    } else {
      if (
        data?.userType === "clinic" &&
        data.profile_claimed_status !== undefined &&
        data.profile_claimed_status !== "approved"
      ) {
        settabKey("contact");
      } else {
        if (location?.state?.type === "exercise") {
          settabKey("exercises");
        } else {
          settabKey("home");
        }
      }
    }
    setCondition({
      name:
        data?.userType === "hospital" ||
        data?.userType === "clinic" ||
        data?.userType === "gym" ||
        data?.userType === "rehabilitation_center"
          ? data?.firstName
          : data?.firstName + " " + data?.lastName,
      usertype: data?.userType
        ? data?.userType !== "neuro_surgeon"
          ? "" + data?.userType?.split("_").join(" ") + ""
          : "" + data?.userType?.split("_").join("") + ""
        : "",
      address:
        data.userType === "orthopedic_surgeon" ||
        data.userType === "neuro_surgeon" ||
        data.userType === "primary_care" ||
        data.userType === "nurse_practitioner" ||
        data?.userType === "pain_management_specialist"
          ? data?.clinic[0]?.city
          : data.userType === "wellness_provider" ||
            data.userType === "massage_therapist"
          ? data?.business_info?.city
          : data.userType === "physical_therapist" ||
            data.userType === "chiropractor"
          ? data?.rehab_info?.city
          : data?.city,
    });
    setLoader(false);
    setProviderDetail(data);
  };
  const getMembershipDetails = async () => {
    const { data } = await axios.get(
      "/v1/public/membership/plan/" + providerDetail?.membershipId
    );
    setMembershipDetail(data);
  };

  const handleCloseBook = () => setShowBook(false);
  const handleShowBook = () => setShowBook(true);

  const websiteOpening = (website) => {
    if (!website.includes("https") || !website.includes("http"))
      window.open(`https://${website}`, "_blank").focus();
    else window.open(website, "_blank").focus();
  };

  useEffect(() => {
    sessionStorage.setItem("provider", params.id);
    getProvider(params.id);
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    if (providerDetail?.name) {
      mixpanel.track("User", {
        type: "Page View",
        name: `${getProviderName(providerDetail)} Profile`,
      });
    }

    if (providerDetail?.membershipId) getMembershipDetails();
    // eslint-disable-next-line
  }, [providerDetail]);

  const renderContent = (type) => {
    switch (type) {
      case "home":
        return <HomeTab providerDetail={providerDetail} />;
      case "reviews":
        return <ProfileTab providerDetail={providerDetail} />;
      case "exercise":
        return (
          providerDetail?.userId?.length && (
            <ExerciseTab
              tabKey={tabKey}
              providerId={providerDetail?.userId}
              providerDetail={providerDetail}
            />
          )
        );
      case "contact":
        return <ContactTab providerDetail={providerDetail} />;
      case "classes":
        return <ClassesTab providerDetail={providerDetail} />;
      default:
        return <></>;
    }
  };
  return (
    <Layout>
      <div className="mt-3" ref={ref}>
        <HelmetTag
          title={`${condition.name} | ${capitalize(condition.usertype)} in ${
            condition.address
          } `}
          content={`${condition.name} is an expertly trained ${condition.usertype} dedicated to providing exceptional care to individuals throughout ${condition.address}.`}
          canonical={`https://myorthopedicproblem.com/providers/${params.id}`}
          JSONLD={`{
          "@context": "http://schema.org",
          "@type": "Person",
          "name": "${condition.name}",
          "url": "https://myorthopedicproblem.com/providers/${params.id}"
        }
        `}
        />
        {loader ? (
          <div className="d-flex justify-content-center">
            <LoadingScreen color="primary" />
          </div>
        ) : (
          <>
            <section>
              <div className="container px-sm-5">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="provider-bg">
                      {providerDetail.banner || providerDetail?._id ? (
                        <Image
                          src={providerDetail.banner}
                          alt="Find a Provider Banner"
                          className={`w-100 banner-image ${
                            providerDetail.banner ? "" : "p-5"
                          } rounded shadow-sm bg-muted-25 p-3`}
                        />
                      ) : (
                        <> </>
                      )}
                    </div>
                    <div className="d-flex justify-content-start">
                      <div className="provider-pic w-100">
                        <div className="img-provider ms-md-0 ms-3">
                          {providerDetail?.photo?.includes(
                            "https://dev-mop.s3.us-east-2.amazonaws.com//"
                          ) || !providerDetail?.photo?.length ? (
                            providerDetail?.userId?.length && (
                              <Image
                                src={providerDetail?.photo}
                                type="provider"
                                gender={providerDetail?.gender?.toUpperCase()}
                                alt="Find a Provider"
                              />
                            )
                          ) : (
                            <Image
                              src={providerDetail?.photo}
                              type="provider"
                              gender={providerDetail?.gender?.toUpperCase()}
                              alt="Find a Provider"
                            />
                          )}
                        </div>
                        <div className="profile-provider w-100 mt-2 mb-0">
                          <div className="content ms-md-1 ms-2">
                            <div className="heading fw-bold user-name d-md-flex">
                              {providerDetail?.userType === "hospital" ||
                              providerDetail?.userType === "clinic" ||
                              providerDetail?.userType === "gym" ||
                              providerDetail?.userType ===
                                "rehabilitation_center"
                                ? providerDetail?.firstName
                                : getProviderName(providerDetail)}
                              {providerDetail?.mopRecommended && (
                                <i className="fa fa-check-circle text-primary small ms-lg-2 ms-1 mt-2" />
                              )}
                              <div className="heading fw-bold d-flex user-name p-0 ps-md-2">
                                {(providerDetail.profile_claimed_status ===
                                  "rejected" ||
                                  providerDetail.profile_claimed_status ===
                                    "created") && (
                                  <Link
                                    to={"/provider-claim-your-profile"}
                                    className=" text-decoration-none "
                                    state={{
                                      id: providerDetail._id,
                                      userId: providerDetail.userId,
                                      evaluation: location.state?.evaluation,
                                      condition: location.state?.evaluation,
                                      pathName: "/providers/" + params.id,
                                    }}
                                  >
                                    Claim Your Profile?
                                  </Link>
                                )}
                              </div>
                            </div>
                            <p className="text-muted text-capitalize mb-0">
                              {providerDetail?.userType
                                ? providerDetail?.userType !== "neuro_surgeon"
                                  ? "" +
                                    providerDetail?.userType
                                      ?.split("_")
                                      .join(" ") +
                                    ""
                                  : "" +
                                    providerDetail?.userType
                                      ?.split("_")
                                      .join("") +
                                    ""
                                : ""}
                            </p>
                            <div className="d-flex">
                              {providerDetail &&
                                providerDetail.hasOwnProperty("rating") && (
                                  <ReactStars
                                    count={5}
                                    edit={false}
                                    isHalf={true}
                                    value={providerDetail?.rating || 0}
                                    size={24}
                                    activeColor="#ffd700"
                                  />
                                )}
                              <p className="align-self-center mb-0 text-muted">
                                {providerDetail?.googleRatingsCount
                                  ? `(${providerDetail?.googleRatingsCount})`
                                  : ""}
                              </p>
                            </div>
                            {providerDetail.planType === "premium" && (
                              <div className="d-md-flex d-none align-items-center btn_provider">
                                <button className="btn btn-primary mb-0 me-4 btn-sm fs-5 fw-normal">
                                  Follow
                                </button>
                                <button
                                  className="btn my-3 btn-primary btn-sm  me-4 fs-5 fw-normal"
                                  onClick={() => {
                                    window.fbq(
                                      "track",
                                      "Book Appointment is clicked."
                                    );
                                    if (
                                      providerDetail?.email.includes("@tbd.com")
                                    ) {
                                      handleShowBook();
                                    } else if (
                                      providerDetail?.userType ===
                                      "athletic_trainers"
                                    ) {
                                      MySwal.fire({
                                        title: `Your selected provider is not receiving online appointment request at this time. Please contact your selected provider at ${
                                          providerDetail !== ""
                                            ? providerDetail?.phoneNumber
                                              ? formatPhoneNumber(
                                                  providerDetail?.phoneNumber
                                                )
                                              : "xxx-xxx-xxxx"
                                            : ""
                                        }`,
                                        icon: "info",
                                        showConfirmButton:
                                          providerDetail?.phoneNumber !== ""
                                            ? true
                                            : false,
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: (
                                          <a
                                            className="text-decoration-none text-white"
                                            href={`tel:${providerDetail?.phoneNumber}`}
                                          >
                                            Call
                                          </a>
                                        ),
                                        showCancelButton: true,
                                        cancelButtonText: "Cancel",
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                      });
                                    } else if (
                                      (providerDetail?.userType === "clinic" &&
                                        providerDetail?.profile_claimed_status ===
                                          undefined) ||
                                      providerDetail?.email.includes(
                                        "@clinic.com"
                                      )
                                    )
                                      handleShowBook();
                                    else {
                                      navigate("/referral", {
                                        state: {
                                          evaluation:
                                            location?.state?.evaluation,
                                          condition: location?.state?.condition,
                                          provider: providerDetail,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  Book Appointment
                                </button>
                                {providerDetail.planType === "premium" && (
                                  <div className="d-flex justify-content-between align-items-center text-primary me-4">
                                    {providerDetail?.facebook && (
                                      <i
                                        className="fab fa-facebook fa-2x me-4 cursor-pointer"
                                        onClick={() => {
                                          websiteOpening(
                                            providerDetail?.facebook
                                          );
                                        }}
                                      />
                                    )}
                                    {providerDetail?.instagram && (
                                      <i
                                        className="fab fa-instagram fa-2x me-4 cursor-pointer"
                                        onClick={() => {
                                          websiteOpening(
                                            providerDetail?.instagram
                                          );
                                        }}
                                      />
                                    )}
                                    {providerDetail?.youtube && (
                                      <i
                                        className="fab fa-youtube-square fa-2x cursor-pointer"
                                        onClick={() => {
                                          websiteOpening(
                                            providerDetail?.youtube
                                          );
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        {providerDetail.planType === "premium" && (
                          <div className="d-flex d-md-none justify-content-center">
                            <div className="d-flex justify-content-between align-items-center text-primary me-4">
                              {providerDetail?.facebook && (
                                <i
                                  className="fab fa-facebook fa-2x me-4 cursor-pointer"
                                  onClick={() => {
                                    websiteOpening(providerDetail?.facebook);
                                  }}
                                />
                              )}
                              {providerDetail?.instagram && (
                                <i
                                  className="fab fa-instagram fa-2x me-4 cursor-pointer"
                                  onClick={() => {
                                    websiteOpening(providerDetail?.instagram);
                                  }}
                                />
                              )}
                              {providerDetail?.youtube && (
                                <i
                                  className="fab fa-youtube-square fa-2x cursor-pointer"
                                  onClick={() => {
                                    websiteOpening(providerDetail?.youtube);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {providerDetail.planType === "premium" && (
                          <div className="d-flex d-md-none align-items-center btn_provider justify-content-center">
                            <div className="d-flex">
                              <button className="btn btn-primary mb-0 me-2 btn-sm fs-5 fw-normal">
                                Follow
                              </button>
                            </div>
                            <button
                              className="btn my-3 btn-primary btn-sm  me-2 fs-5 fw-normal"
                              onClick={() => {
                                window.fbq(
                                  "track",
                                  "Book Appointment is clicked."
                                );
                                if (
                                  providerDetail?.email.includes("@tbd.com")
                                ) {
                                  handleShowBook();
                                } else if (
                                  providerDetail?.userType ===
                                  "athletic_trainers"
                                ) {
                                  MySwal.fire({
                                    title: `Your selected provider is not receiving online appointment request at this time. Please contact your selected provider at ${
                                      providerDetail !== ""
                                        ? providerDetail?.phoneNumber
                                          ? formatPhoneNumber(
                                              providerDetail?.phoneNumber
                                            )
                                          : "xxx-xxx-xxxx"
                                        : ""
                                    }`,
                                    icon: "info",
                                    showConfirmButton:
                                      providerDetail?.phoneNumber !== ""
                                        ? true
                                        : false,
                                    showCancelButton: true,
                                    cancelButtonText: "Cancel",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: (
                                      <a
                                        className="text-decoration-none text-white"
                                        href={`tel:${providerDetail?.phoneNumber}`}
                                      >
                                        Call
                                      </a>
                                    ),
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                    } else {
                                    }
                                  });
                                } else if (
                                  (providerDetail?.userType === "clinic" &&
                                    providerDetail?.profile_claimed_status ===
                                      undefined) ||
                                  providerDetail?.email.includes("@clinic.com")
                                ) {
                                  handleShowBook();
                                } else {
                                  navigate("/referral", {
                                    state: {
                                      evaluation: location?.state?.evaluation,
                                      condition: location?.state?.condition,
                                      provider: providerDetail,
                                    },
                                  });
                                }
                              }}
                            >
                              Book Appointment
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {(providerDetail.planType === "premium" &&
              JSON.stringify(membershipDetail) === "{}") ||
            membershipDetail?.askAnExpertSocial ? (
              <>
                {providerDetail.askAnExpert &&
                  providerDetail?.userType !== "primary_care" &&
                  providerDetail?.userType !== "clinic" &&
                  providerDetail?.userType !== "nurse_practitioner" &&
                  providerDetail?.userType !== "neuro_surgeon" &&
                  providerDetail?.userType !== "athletic_trainers" &&
                  providerDetail?.userType !== "orthopedic_surgeon" && (
                    <section className="my-3">
                      <div className="container px-md-5">
                        <div className="row">
                          <div className="col-12">
                            <div className="d-sm-flex bg-white rounded border  justify-content-center p-3">
                              <div className="d-flex">
                                <h5 className="mb-0 align-self-center ">
                                  {` Want to ask ${
                                    providerDetail?.userType === "hospital" ||
                                    providerDetail?.userType === "clinic" ||
                                    providerDetail?.userType === "gym" ||
                                    providerDetail?.userType ===
                                      "rehabilitation_center"
                                      ? providerDetail?.firstName
                                      : `${getName(providerDetail)}`
                                  }  a question? Skip the wait and click the button`}
                                </h5>
                              </div>
                              <button className="btn btn-outline-primary btn-primary text-white fw-semibold mt-3 mt-sm-0 ms-md-2">
                                Ask An Expert
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
              </>
            ) : (
              <></>
            )}

            <div className="container px-4">
              <hr
                className={`mx-md-4 ${
                  providerDetail.planType === "premium" ? "my-0" : "mt-4"
                } `}
              />
            </div>
            <section>
              <div className="container px-md-5">
                <div className="row">
                  <div className="col-12 bg-white pt-2  provider-tabs d-md-block d-none">
                    {JSON.stringify(membershipDetail) === "{}" ||
                    membershipDetail?.blogs ? (
                      <Tabs
                        className="mb-3"
                        variant="pills"
                        fill
                        defaultActiveKey={tabKey}
                        onSelect={(key) => {
                          window.fbq(
                            "track",
                            `${
                              providerDetail.firstName
                            }'s ${key.toUpperCase()} Tab is opened."`
                          );
                          document.getElementById("root").scrollTo(0, 0);
                          settabKey(key);
                        }}
                      >
                        <Tab eventKey="home" title="About">
                          <HomeTab providerDetail={providerDetail} />
                        </Tab>

                        {providerDetail.planType === "premium" && (
                          <Tab eventKey="reviews" title="Reviews">
                            <ProfileTab providerDetail={providerDetail} />
                          </Tab>
                        )}

                        {providerDetail.planType === "premium" && (
                          <Tab eventKey="exercises" title="Exercises">
                            {providerDetail?.userId?.length && (
                              <ExerciseTab
                                tabKey={tabKey}
                                providerId={providerDetail?.userId}
                                providerDetail={providerDetail}
                              />
                            )}
                          </Tab>
                        )}
                        <Tab eventKey="contact" title="Contact & Location">
                          <ContactTab providerDetail={providerDetail} />
                        </Tab>
                        {providerDetail.planType === "premium" &&
                          (providerDetail?.userType === "wellness_provider" ||
                            providerDetail?.userType === "personal_trainer" ||
                            providerDetail?.userType === "gym") && (
                            <Tab eventKey="classes" title="Classes">
                              <ClassesTab providerDetail={providerDetail} />
                            </Tab>
                          )}
                      </Tabs>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-12 bg-white pt-2 provider-tabs d-md-none d-block">
                    {JSON.stringify(membershipDetail) === "{}" ||
                    membershipDetail?.blogs ? (
                      providerDetail.planType === "premium" ? (
                        <div className="container-sticky">
                          <div className="w-100">
                            <Tabs
                              className="mb-3 me-5"
                              variant="pills"
                              fill
                              activeKey={tabKey}
                              onSelect={(key) => {
                                window.fbq(
                                  "track",
                                  `${
                                    providerDetail.firstName
                                  }'s ${key.toUpperCase()} Tab is opened."`
                                );
                                document.getElementById("root").scrollTo(0, 0);
                                settabKey(key);
                                setMoreMenu("");
                              }}
                            >
                              <Tab
                                eventKey="home"
                                title="About"
                                className={`${
                                  tabKey === "home" ? "active" : ""
                                }`}
                              />
                              {providerDetail.planType === "premium" && (
                                <Tab
                                  eventKey="blogs"
                                  title="Posts"
                                  className={`${
                                    tabKey === "blogs" ? "active" : ""
                                  }`}
                                />
                              )}
                              {providerDetail.planType === "premium" && (
                                <Tab
                                  eventKey="reviews"
                                  title="Reviews"
                                  className={`${
                                    tabKey === "reviews" ? "active" : ""
                                  }`}
                                ></Tab>
                              )}
                            </Tabs>
                          </div>
                          <div className="fw-bold  text-muted p-2 more-button ">
                            <Dropdown>
                              <Dropdown.Toggle
                                className="bg-white text-muted fw-bold border-white p-0 profile-more fs--18"
                                id="dropdown-basic"
                              >
                                More
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="px-2">
                                {providerDetail.planType === "premium" && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      settabKey("");
                                      setMoreMenu("exercise");
                                      document
                                        .getElementById("root")
                                        .scrollTo(0, 0);
                                    }}
                                    className="bg-white text-muted border-white"
                                  >
                                    <div
                                      className={`fw-bold text-muted ${
                                        moreMenu === "exercise"
                                          ? "border-5-bottom"
                                          : ""
                                      }`}
                                    >
                                      Exercises
                                    </div>
                                  </Dropdown.Item>
                                )}

                                <Dropdown.Item
                                  onClick={() => {
                                    settabKey("");
                                    setMoreMenu("contact");
                                    document
                                      .getElementById("root")
                                      .scrollTo(0, 0);
                                  }}
                                  className="bg-white text-muted border-white"
                                >
                                  <div
                                    className={`fw-bold text-muted ${
                                      moreMenu === "contact"
                                        ? "border-5-bottom"
                                        : ""
                                    }`}
                                  >
                                    Contact & Location
                                  </div>
                                </Dropdown.Item>

                                {providerDetail.planType === "premium" &&
                                  (providerDetail?.userType ===
                                    "wellness_provider" ||
                                    providerDetail?.userType ===
                                      "personal_trainer" ||
                                    providerDetail?.userType === "gym") && (
                                    <Dropdown.Item
                                      onClick={() => {
                                        settabKey("");
                                        setMoreMenu("classes");
                                        document
                                          .getElementById("root")
                                          .scrollTo(0, 0);
                                      }}
                                      className="bg-white text-muted border-white"
                                    >
                                      <div
                                        className={`fw-bold text-muted ${
                                          moreMenu === "classes"
                                            ? "border-5-bottom"
                                            : ""
                                        }`}
                                      >
                                        Classes
                                      </div>
                                    </Dropdown.Item>
                                  )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex position-relative">
                          <div className="w-100">
                            <Tabs
                              className="mb-3 me-5"
                              variant="pills"
                              fill
                              activeKey={tabKey}
                              onSelect={(key) => {
                                window.fbq(
                                  "track",
                                  `${
                                    providerDetail.firstName
                                  }'s ${key.toUpperCase()} Tab is opened."`
                                );
                                settabKey(key);
                                document.getElementById("root").scrollTo(0, 0);
                                setMoreMenu("");
                              }}
                            >
                              <Tab
                                eventKey="contact"
                                title="Contact & Location"
                              ></Tab>
                              <Tab eventKey="" title=""></Tab>
                              <Tab eventKey="" title=""></Tab>
                            </Tabs>
                          </div>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                    {tabKey !== ""
                      ? renderContent(tabKey)
                      : renderContent(moreMenu)}
                  </div>
                </div>
              </div>
            </section>
            <Modal
              show={showBook}
              onHide={handleCloseBook}
              centered
              className="phoneModal"
            >
              <Modal.Body className="text-center bg-teal rounded border border-2 border-primary">
                <div className="mb-3 py-3 h5">
                  <p className="text-dark mb-0 ">
                    {bookAnAppointmentText}
                    {providerDetail?.phoneNumber
                      ? `${formatPhoneNumber(providerDetail?.phoneNumber)}`
                      : ""}{" "}
                  </p>
                </div>
                <Button className="btn btn-primary mx-2">
                  <a
                    className="text-decoration-none text-white"
                    href={`tel:${providerDetail?.phoneNumber}`}
                    onClick={handleCloseBook}
                  >
                    Call
                  </a>
                </Button>
                <Button className="btn btn-primary" onClick={handleCloseBook}>
                  OK
                </Button>
              </Modal.Body>
            </Modal>
            <Modal
              show={showBook}
              onHide={handleCloseBook}
              className="mobilePhoneModal"
              centered
            >
              <Modal.Body className="text-center bg-teal rounded border border-2 border-primary">
                <div className="mb-3 py-3 h5">
                  <p className="text-dark mb-0 ">
                    {bookAnAppointmentText}
                    {formatPhoneNumber(providerDetail?.phoneNumber)}
                  </p>
                </div>
                <Button className="btn btn-primary mx-2">
                  <a
                    className="text-decoration-none text-white"
                    onClick={handleCloseBook}
                    href={`tel:${providerDetail?.phoneNumber}`}
                  >
                    Call
                  </a>
                </Button>
                <Button className="btn btn-primary" onClick={handleCloseBook}>
                  OK
                </Button>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </Layout>
  );
};
export default ProviderDetails;
