import React from "react";
import { Accordion } from "react-bootstrap";
import Quotation from "../../../../assets/images/Quotation.svg";

const Accordation = ({ FAQ }) => {
  return (
    <Accordion className="mb-4 text-start">
      {FAQ?.map((item, index) => (
        <Accordion.Item className="mt-3" eventKey={index} key={index}>
          <h2 className="accordion-header">
            <Accordion.Button
              className="h2 fw-semibold bg-white fw-700 py-1"
              style={{
                borderBottom: "2px solid #dfdfdf",
                borderRadius: 0,
                fontSize: "22px",
              }}
            >
              <img
                alt="quotation"
                className="mb-3 me-1"
                width={"25px"}
                height={"15px"}
                src={Quotation}
              />
              {item?.Question}
            </Accordion.Button>
          </h2>
          <Accordion.Body style={{ fontSize: "20px" }}>
            {item?.Answer}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default Accordation;
