import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStoreActions, useStoreState } from "easy-peasy";
import mixpanel from "mixpanel-browser";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import axios from "../../utils/axiosInst";
import { ProvidersList, ratingOptions } from "../../../../constants";
import LoadingScreen from "../custom/LoadingScreen";
import Pagination from "../custom/Pagination";
import Layout from "../layout/Layout";
import ProviderCard from "./ProviderCard";
const schema = yup
  .object({
    city: yup.string(),
    address: yup.string(),
    name: yup.string(),
    userType: yup.string(),
    ratings: yup.string(),
    reco: yup.string(),
  })
  .required();

const Providers = () => {
  const getProviders = useStoreActions((actions) => actions.getProviders);
  const { innerWidth } = window;
  const [rating, setRating] = useState("");
  const location = useLocation();
  const [isSearchVisible, setIsSearchVisible] = useState(true);
  const { register, handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const setProviderPagination = useStoreActions(
    (actions) => actions.setProviderPagination
  );
  const totalPages = useStoreState(
    (state) => state.providerPagination.totalPages
  );
  const currentPageNo = useStoreState(
    (state) => state.providerPagination.currentPageNo
  );

  const [city, setcity] = useState([]);
  const [selectedcity, setselectedcity] = useState("");
  const [selectedcity_lat, setselectedcity_lat] = useState("");
  const [selectedcity_lng, setselectedcity_lng] = useState("");

  const selectcity_handler = (i) => {
    setValue("address", city[i].description);
    setselectedcity(city[i].description);
    setselectedcity_lat(city[i].geometry.location.lat);
    setselectedcity_lng(city[i].geometry.location.lng);
    setcity([]);
  };
  const onchangehandler_city = (e) => {
    setcity([]);
    setselectedcity_lat("");
    setselectedcity_lng("");
    setselectedcity(e.target.value);
    if (e.target.value.length > 3) {
      axios
        .get(`/v1/public/search/address?place=${e.target.value} `)
        .then(({ data }) => {
          setcity(data.predictions);
        });
    } else {
      setcity([]);
    }
  };
  const loader = useStoreState((state) => state.loading);
  const [formPreData] = useState({ limit: 10, page: 1 });
  const providers = useStoreState((state) => state.providers);
  const [search, setSearch] = useState(false);
  const setFilterData = useStoreActions((actions) => actions.setFilterData);
  const filterData = useStoreState((state) => state.filterData);
  const handlePageChange = (page) => {
    formPreData.page = page;
    if (filterData) {
      formPreData.name = filterData?.name;
      formPreData.userType = filterData?.type;
      formPreData.ratings = filterData?.ratings;
      formPreData.address = filterData?.address ? filterData?.address : "";
      formPreData.city = filterData?.city ? filterData?.city : "";
      formPreData.mopRecommended = filterData?.mop;
    }
    if (search) formPreData.search = search;
    let data = {
      name: filterData?.name,
      type: filterData?.type,
      ratings: filterData?.ratings,
      address: filterData?.address ? filterData?.address : "",
      city: filterData?.city ? filterData?.city : "",
      mop: filterData?.mop,
      page: page,
      back: filterData.back,
    };
    setFilterData(data);
    getProviders(formPreData);
  };
  useEffect(() => {
    document.getElementById("root").scrollTo(0, 0);
    if (!filterData?.back) {
      try {
        mixpanel.track("User", {
          type: "Page View",
          name: "Providers Page",
        });
      } catch (error) {}
      getProviders({
        page: filterData?.page === 0 ? 1 : filterData?.page,
        userType: filterData?.type,
        name: filterData?.name,
        rating: filterData?.ratings,
        address: filterData?.address ? filterData?.address : "",
        city: filterData?.city ? filterData?.city : "",
        mopRecommended: filterData?.mop,
        lat: selectedcity_lat,
        lng: selectedcity_lng,
      });
      setValue("userType", filterData?.type);
      setValue("name", filterData?.name);
      setValue("ratings", filterData?.ratings);
      setValue("address", filterData?.address ? filterData?.address : "");
      setValue("city", filterData?.city ? filterData?.city : "");
      setValue("mopRecommended", filterData?.mop);
      setValue("page", filterData?.page);
    } else {
      setValue("userType", filterData?.type);
      setValue("name", filterData?.name);
      setValue("ratings", filterData?.ratings);
      setValue("address", filterData?.address ? filterData?.address : "");
      setValue("city", filterData?.city ? filterData?.city : "");
      setValue("mopRecommended", filterData?.mop);
      setValue("page", filterData?.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const showHideFilterContent = () => setIsSearchVisible(!isSearchVisible);

  return (
    <Layout>
      <section className="pb-3 bg-F5F5F7">
        <div className="container">
          <div className="row mb-3">
            <div className="col-sm-12 d-block justify-content-between">
              <h1 className="text-black heading-font mb-1 display-5 ">
                Find a Provider
              </h1>
              <h5 className="text-muted mb-3">
                Connect with a provider to get treated
              </h5>
              <button
                className="btn d-block d-lg-none btn-outline-primary"
                onClick={showHideFilterContent}
              >
                Filter
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              {isSearchVisible && (
                <div className="mb-4 mt-2 sticky-top">
                  <div className="card rounded-4 h-auto">
                    <div className="p-3 pb-0 d-flex justify-content-between">
                      <h5 className="mb-0">Search</h5>
                      <button
                        className="mb-0 align-self-center btn btn-sm btn-link text-primary text-decoration-none fw-bold"
                        onClick={() => {
                          let data = {
                            name: "",
                            type: "",
                            ratings: "",
                            address: "",
                            city: "",
                            mop: "",
                            page: 1,
                            back: filterData.back,
                          };
                          setRating("");
                          setFilterData(data);
                          setselectedcity("");
                          setcity([]);
                          getProviders({ page: 1 });
                        }}
                      >
                        Clear
                      </button>
                    </div>
                    <hr className="mx-3 my-0" />
                    <div className="p-3">
                      <form
                        onSubmit={handleSubmit((formData) => {
                          setSearch(true);
                          formData.page = 1;
                          formData.search = true;
                          formData.ratings = rating;
                          formData.lat = selectedcity_lat;
                          formData.lng = selectedcity_lng;
                          // delete formData.rating;
                          getProviders(formData);
                          let data = {
                            name: formData?.name ? formData?.name : "",
                            type: formData?.userType ? formData?.userType : "",
                            ratings: rating,
                            address: formData?.address ? formData?.address : "",
                            city: formData?.city ? formData?.city : "",
                            mop: formData?.mopRecommended
                              ? formData?.mopRecommended
                              : "",
                            page: 1,
                            back: filterData.back,
                          };
                          setFilterData(data);
                        })}
                      >
                        <div className="mb-3">
                          <input
                            type="text"
                            className={`form-control bg-light-50 text-black ${
                              getValues("name") !== "" ? "text-black" : ""
                            }`}
                            placeholder="Enter Provider Name"
                            {...register("name")}
                          />
                        </div>
                        <div className="mb-3">
                          <select
                            className={`form-select bg-light-50 ${
                              filterData.type ? "text-black" : "text-muted"
                            }`}
                            {...register("userType", {
                              onChange: (e) => {
                                setValue("userType", e.target.value);
                                e.target.className =
                                  e.target.value === ""
                                    ? "form-select text-muted bg-light-50"
                                    : "form-select text-black bg-light-50";
                              },
                            })}
                          >
                            <option value="">Enter Provider Type</option>
                            {ProvidersList?.map((providerType) => (
                              <option
                                value={providerType?.value}
                                key={providerType?.value}
                              >
                                {providerType?.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-3">
                          <input
                            type="text"
                            className={`form-control bg-light-50 text-black`}
                            value={selectedcity}
                            name="selectedcity"
                            placeholder="Enter Address or City"
                            {...register("address")}
                            onChange={(e) => onchangehandler_city(e)}
                          />
                          {city.length > 0 && (
                            <div className="dropdown-menu d-block provider-select-div">
                              <ul className="provider-select-ul">
                                <p
                                  className="float-right cursor-pointer p-0 fw-bold"
                                  onClick={() => setcity([])}
                                >
                                  X
                                </p>
                                {city.map((data, i) => (
                                  <li
                                    key={i}
                                    onClick={() => selectcity_handler(i)}
                                    className="provider-select-li"
                                  >
                                    {data.description}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <select
                            className={`form-select bg-light-50 ${
                              filterData.ratings ? "text-black" : "text-muted"
                            }`}
                            // name="ratings"
                            value={rating}
                            {...register("ratings", {
                              onChange: (e) => {
                                setRating(e.target.value);
                                setValue("ratings", e.target.value);
                                e.target.className =
                                  e.target.value === ""
                                    ? "form-select text-muted bg-light-50"
                                    : "form-select text-black bg-light-50";
                              },
                            })}
                          >
                            <option value="">Rating</option>
                            {ratingOptions.map((ratingOption) => (
                              <option
                                value={ratingOption.value}
                                key={ratingOption?.value}
                              >
                                {ratingOption.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mb-4">
                          <select
                            className={`form-select bg-light-50   ${
                              filterData.mop ? "text-black" : "text-muted"
                            }`}
                            {...register("mopRecommended", {
                              onChange: (e) => {
                                setValue("mopRecommended", e.target.value);
                                e.target.className =
                                  e.target.value === "MOP Recommended provider"
                                    ? "form-select text-muted bg-light-50"
                                    : "form-select text-black bg-light-50";
                              },
                            })}
                          >
                            <option value="">Recommended provider</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                        <button
                          className="btn btn-primary rounded w-100"
                          type="submit"
                        >
                          Search
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-9  mt-4 mt-lg-0">
              <div className="mb-4 mt-2">
                {providers && providers.length > 0 ? (
                  providers.map((provider) => (
                    <ProviderCard
                      key={provider._id}
                      provider={provider}
                      evaluation={location.state?.evaluation}
                      condition={location.state?.condition}
                    />
                  ))
                ) : (
                  <>
                    {loader ? (
                      <LoadingScreen color="primary" />
                    ) : (
                      <h4 className="text-center">No data Found</h4>
                    )}
                  </>
                )}
              </div>
              {providers && providers.length > 0 && (
                <Pagination
                  onPageChange={(event) => {
                    setProviderPagination({
                      totalPages: totalPages,
                      currentPageNo: event.selected + 1,
                    });
                    handlePageChange(event.selected + 1);
                  }}
                  pageRangeDisplayed={innerWidth < 769 ? 2 : 3}
                  totalPages={totalPages}
                  currentPageNo={currentPageNo - 1}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Providers;
