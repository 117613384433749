export const certificationInitValue = {
  label: "",
  value: "",
};
export const educationInitValue = {
  degree: "",
  college: "",
  completionyear: "",
};
export const classesInitValue = {
  taught: "",
  location: "",
  timings: [{ days: [], start_time: "12:00 AM", end_time: "12:00 AM" }],
};
