import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-phone-number-input/style.css";
import "react-international-phone/style.css";
import { userType } from "../../../../constants/index";
import PasswordCheck from "../custom/PasswordCheck";
import TextInput from "../custom/TextInput";
import { useForm } from "react-hook-form";

const CreateAccount = ({
  useFormControl,
  setShow,
  phoneNumber,
  onSubmit,
  setPhoneNumber,
  loading,
}) => {
  const [isVisibile, setIsVisibile] = useState(false);
  const [password, setpassword] = useState("");
  const [typePassword, setTypePassword] = useState(false);
  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["us", "in"].includes(iso2);
  });
  const schema = yup
    .object({
      firstName: yup
        .string()
        .required("It is a required field")
        .matches(
          /^(?=.*[a-zA-Z])[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
          "Please enter the first name correctly"
        ),
      lastName: yup.string().when("userType", {
        is: (userType) =>
          userType === "rehabilitation_center" ||
          userType === "hospital" ||
          userType === "clinic" ||
          userType === "gym",
        then: yup.string(),
        otherwise: yup
          .string()
          .required("First name is required")
          .matches(
            /^(?=.*[a-zA-Z])[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/,
            "Please enter the First Name correctly"
          ),
      }),
    })
    .required();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  watch((data) => setpassword(data.password));

  const onSubmitHandler = () => {
    onSubmit(watch());
  };

  const firstName =
    watch("userType") !== "gym" &&
    watch("userType") !== "hospital" &&
    watch("userType") !== "clinic" &&
    watch("userType") !== "rehabilitation_center";

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="container mt-5 mb-5">
          <div className="row pt-md-2 px-md-5 text-center d-flex">
            <div className="col-sm-3">&nbsp;</div>
            <div className="col-sm-6 center-block">
              <div className="bg-white w-100 p-3 px-md-5 border rounded shadow-lg">
                <h2 className="text-primary py-2">Create your account</h2>
                <div className="mb-3 mt-4">
                  <select
                    className="form-select text-muted"
                    {...register("userType")}
                  >
                    <option value="">Select User Type</option>
                    {userType?.map((item, index) => (
                      <option value={item?.value} key={item?.value}>
                        {item?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="row">
                  <div
                    className={`mb-3 ${firstName ? "col-sm-6" : "col-sm-12"}`}
                  >
                    <TextInput
                      type="text"
                      id="firstName"
                      register={register}
                      errors={errors}
                      placeholder={firstName ? "First name" : "Name"}
                      name="firstName"
                      mandatory={true}
                    />
                  </div>
                  {firstName ? (
                    <div className="mb-3 col-sm-6">
                      <TextInput
                        type="text"
                        errors={errors}
                        id="lastName"
                        register={register}
                        placeholder="Last name"
                        name="lastName"
                        mandatory={true}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mb-3">
                  <PhoneInput
                    inputClassName="form-control"
                    inputStyle={{ color: "#212529", fontSize: "1rem" }}
                    name={"phoneNumber"}
                    defaultCountry="us"
                    countries={countries}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e)}
                  />
                </div>
                <div className="mb-3">
                  <TextInput
                    type="text"
                    errors={errors}
                    id="email"
                    register={register}
                    placeholder="Email"
                    name="email"
                    mandatory={true}
                  />
                </div>
                <div className="input-group mb-3 bg-white">
                  <input
                    type={isVisibile ? "text" : "password"}
                    id="password"
                    className={`form-control bg-transparent ${
                      errors.password && "is-invalid"
                    }`}
                    placeholder="Create password"
                    onClick={() => setTypePassword(true)}
                    {...register("password")}
                  />
                  <span
                    className={`input-group-text border-top-right-radius cursor-pointer ${
                      errors.password && "border-danger"
                    }`}
                    onClick={() => setIsVisibile(!isVisibile)}
                  >
                    {!isVisibile ? (
                      <i className="far fa-eye text-muted" />
                    ) : (
                      <i className="far fa-eye-slash text-muted" />
                    )}
                  </span>
                  <div className="invalid-feedback">
                    {errors.password?.message}
                  </div>
                </div>
                <PasswordCheck
                  typePassword={typePassword}
                  password={password}
                />
                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-3 text-white fw-bold"
                  disabled={!!loading}
                >
                  {loading ? "Loading..." : "Continue"}
                </button>
                <p className="mt-3 text-center">
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="text-decoration-none text-primary"
                  >
                    Log in
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-sm-3">&nbsp;</div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default CreateAccount;
