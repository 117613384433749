import React, { useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import search from "../../../assets/images/mop-search.svg";
import Image from "../custom/Image";

const Summary = () => {
  let navigate = useNavigate();

  const results = useStoreState((state) => state.results);

  const getArticles = (conditions) => {
    const articles = conditions.map((condition) => condition?.contents[0]);
    return [...new Set(articles.filter((article) => article))];
  };

  useEffect(() => {
    window.fbq("track", `Summary result is opened`, { data: results });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="col-sm-5 mt-md-0 mt-3">
        {results?.conditions.map((condition) => (
          <div
            key={condition?.name}
            className="rounded-3 bg-muted-25 px-2 py-3 mb-2 cursor-pointer educational-animation"
            onClick={() => {
              if (condition?.contents?.length > 0) {
                let similarArticles = getArticles(results?.conditions);
                const currentArticle = condition?.contents[0];
                const filteredArticles = similarArticles.filter(
                  (article) => article !== currentArticle
                );
                filteredArticles.unshift(currentArticle);
                similarArticles = filteredArticles;
                if (similarArticles.length > 0) {
                  navigate(
                    `/education-library/${
                      similarArticles[0]
                    }?evaluation_result=${similarArticles
                      .slice(1, similarArticles.length)
                      .join(",")}`,
                    {
                      state: {
                        evaluation: true,
                        condition: condition?.name,
                      },
                    }
                  );
                } else navigate(`/education-library`);
              }
            }}
          >
            <div className="d-lg-flex d-flex d-md-block overflow-hidden">
              <div className="me-2 border-start border-5 border-primary">
                <div className="d-flex ps-2 fw-semibold ms-3">
                  <h2 className="text-black fw-semibold text-nowrap mb-0">
                    {condition.confidence}% Confidence
                  </h2>
                </div>
                <h4
                  className={`mt-3 ms-3 text-primary fw-semibold mb-0 ${
                    condition?.contents?.length > 0 ? "cursor-pointer" : ""
                  }`}
                >
                  {condition.name}
                </h4>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="col-sm-2">
        <div className="d-md-block d-none text-center">
          <hr width="3" size="380" className="horizontal-line d-flex" />
        </div>
        <div className="d-md-none d-block">
          <div className="straight-line w-100 d-flex" />
        </div>
      </div>
      <div className="col-sm-5 mt-md-0 mt-3 mb-2">
        <div
          className="h-100 educational-animation bg-super-light rounded-3 p-3"
          onClick={() => navigate("/signup")}
        >
          <>
            <div className="text-center pt-4">
              <h2 className=" text-black fw-semibold text-nowrap mb-0">
                Find a Provider
              </h2>
            </div>
            <div className="p-2 pt-4 pb-0 mt-2 contactus-img">
              <Image
                local={true}
                src={search}
                className="w-100"
                alt="Find a Provider"
              />
            </div>
          </>
        </div>
      </div>
    </>
  );
};
export default Summary;
