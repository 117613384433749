import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineExpandMore } from "react-icons/md";
import { ReactComponent as TalkToProvider } from "../../../assets/patient-icons/sidebar/2.svg";
import { ReactComponent as Pharmacy } from "../../../assets/patient-icons/sidebar/9.svg";
import { ReactComponent as SymtomChecker } from "../../../assets/patient-icons/sidebar/3.svg";
import { ReactComponent as MedicalSupplies } from "../../../assets/patient-icons/sidebar/5.svg";
import { ReactComponent as EducationLibrary } from "../../../assets/patient-icons/sidebar/4.svg";
import { ReactComponent as Assessment } from "../../../assets/patient-icons/sidebar/8.svg";
import Image from "../custom/Image";

const headerRule = {
  background: "#fff",
  height: "76px",
  left: 0,
  position: "fixed",
  textAlign: "left",
  top: 0,
  width: "100%",
  zIndex: 3,
};

const headerRuleScrolled = {
  borderBottom: "1px solid #e9ecef",
};

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex p-3 sticky-top top-0 bg-white">
      <NavLink to="/" className="me-5">
        <Image
          local={true}
          alt="My Orthopedic Problem"
          className="width-8rem"
        />
      </NavLink>
      <ul className="navbar-nav ms-3">
        <li className="nav-item dropdown">
          <div
            className="nav-link fontweight-bold dropdown-toggle navigation-web"
            role="button"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ fontSize: "18px" }}
          >
            For Individuals
            <MdOutlineExpandMore />
          </div>
          <ul
            className="dropdown-menu mt-2 shadow-lg border-0"
            aria-labelledby="navbarDropdown"
          >
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2"
              onClick={() => navigate("/signup")}
            >
              <TalkToProvider
                style={{ stroke: "#0071FF" }}
                width="20px"
                height="20px"
                className="me-1"
              />
              Talk to a Provider
            </li>
            <li
              className="header-tabmenu cursor-pointer p-2 fontweight-bold"
              onClick={() => navigate("/symptom-checker")}
            >
              <SymtomChecker
                style={{ stroke: "#0071FF" }}
                width="20px"
                height="20px"
                className="me-2"
              />
              Symptom Checker
            </li>
            <li
              className="header-tabmenu cursor-pointer p-2 fontweight-bold"
              onClick={() => navigate("/education-library")}
            >
              <EducationLibrary
                style={{ fill: "#0071FF", stroke: "#0071FF" }}
                width="20px"
                height="20px"
                className="me-2"
              />
              Education Library
            </li>
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2"
              onClick={() => navigate("/signup")}
            >
              <MedicalSupplies
                style={{ fill: "#0071FF", stroke: "#0071FF" }}
                width="20px"
                height="20px"
                className="me-2"
              />
              Medical Supplies
            </li>
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2 text-nowrap"
              onClick={() => navigate("/signup")}
            >
              <Assessment
                style={{
                  stroke: "#0071FF",
                }}
                width="20px"
                height="20px"
                className="me-2"
              />
              Wellness Assessment
            </li>
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2 text-nowrap"
              onClick={() => navigate("/signup")}
            >
              <Pharmacy
                style={{ stroke: "#0071FF", fill: "#0071FF" }}
                width="20px"
                height="20px"
                className="me-2"
              />
              Online Prescriptions
            </li>
          </ul>
        </li>
      </ul>
      <ul className="navbar-nav ms-3">
        <li className="nav-item dropdown">
          <div
            className="nav-link fontweight-bold dropdown-toggle navigation-web"
            role="button"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ fontSize: "18px" }}
          >
            For Providers
            <MdOutlineExpandMore />
          </div>

          <ul
            className="dropdown-menu mt-2 shadow-lg border-0"
            aria-labelledby="navbarDropdown"
          >
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2"
              onClick={() => navigate("/join-our-network")}
            >
              Join our Network
            </li>
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2"
              onClick={() => navigate("/faq")}
            >
              FAQ's
            </li>
          </ul>
        </li>
      </ul>
      <ul className="navbar-nav ms-3">
        <li className="nav-item dropdown">
          <div
            className="nav-link fontweight-bold dropdown-toggle navigation-web"
            role="button"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ fontSize: "18px" }}
          >
            About us
            <MdOutlineExpandMore />
          </div>

          <ul
            className="dropdown-menu mt-2 shadow-lg border-0"
            aria-labelledby="navbarDropdown"
          >
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2"
              onClick={() => navigate("/who-we-are")}
            >
              Who We Are
            </li>
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2"
              onClick={() => navigate("/why-trust-us")}
            >
              Why Trust Us
            </li>
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2"
              onClick={() => navigate("/who-we-work-with")}
            >
              Who We Work With
            </li>
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2"
              onClick={() => navigate("/newsroom")}
            >
              Newsroom
            </li>
            <li
              className="header-tabmenu fontweight-bold cursor-pointer p-2"
              onClick={() => navigate("/contact-us")}
            >
              Contact Us
            </li>
          </ul>
        </li>
      </ul>
      <div className="flex-fill text-end" id="home-btn">
        <button
          className="btn btn-outline-primary px-4 rounded-pill fw-semibold me-3"
          type="button"
          onClick={() => navigate("/login")}
          style={{ padding: "7px 0" }}
        >
          Login
        </button>
        <button
          className="text-white btn btn-primary rounded-pill px-4 fw-semibold"
          type="button"
          onClick={() => navigate("/signup")}
          style={{ padding: "7px 0" }}
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default Header;
