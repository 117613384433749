import React, { useEffect, useRef } from "react";
import { BsDownload } from "react-icons/bs";
import { BsThreeDots } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";

import { useAppState } from "../state";
import { getDateFormat } from "../../../../util/utils";
import MediaPreview from "./MediaPreview";
import Image from "./Image";

const MessageBody = ({ conversation, isFetching }) => {
  const { userID, getSignedUrl, deleteChatMessage } = useAppState();

  const bottomRef = useRef(null);

  const downLoadFile = async (fileName, type) => {
    const params = {
      fileName: fileName,
    };
    await getSignedUrl(params)
      .then(async (data) => {
        if (type === "image") {
          await fetch(data.url)
            .then((response) => response.blob())
            .then((blob) => {
              const url = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download = "image.jpg";
              link.click();
              URL.revokeObjectURL(url);
            })
            .catch((error) => console.error("Error downloading image:", error));
        } else {
          window.open(data.url);
        }
      })
      .catch((error) => console.error(error));
  };

  const MessageSettingsDropDown = ({ convo, left }) => {
    return (
      (left && (
        <Dropdown id="chat-dropdown">
          <Dropdown.Toggle
            variant="white"
            id="dropdown-basic"
            style={{
              float: "right",
              marginTop: "-6px",
              padding: "0 0 0 10px",
              color: left ? "#868e96" : "#ffffff",
            }}
          >
            <BsThreeDots />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                deleteChatMessage(convo._id);
              }}
            >
              Delete Chat
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )) || <></>
    );
  };

  const MessageCard = ({ convo, left }) => {
    return (
      <React.StrictMode>
        <div
          className="d-flex"
          style={{
            minWidth: "300px",
            fontSize: "10px",
            color: left ? "#868e96" : "#ffffff",
          }}
        >
          <div
            className={
              left ? "m-0 pb-1 col-sm-6" : "m-0 pb-1 text-start col-sm-6"
            }
          >
            {convo?.postedByUser?.name}
          </div>
          <div className={"text-end col-sm-6"}>
            <MessageSettingsDropDown convo={convo} left={left} />
            <span>{getDateFormat(convo?.createdAt)}</span>
          </div>
        </div>
        <p
          className="mb-0 text-start wordBreak-break-word"
          style={{ fontSize: "14px" }}
        >
          {convo?.message?.type === "text" ? convo?.message?.message : ""}
          {convo.message?.type !== "text" && (
            <div
              onClick={() =>
                downLoadFile(convo.message.message, convo.message.type)
              }
              className="cursor-pointer"
            >
              <BsDownload />
            </div>
          )}
          {convo.message?.type !== "text" &&
            (convo.message.type === "image" ||
            convo.message.type === "video" ? (
              <div className=" text-start ">
                <MediaPreview
                  fileName={convo.message.message}
                  type={convo.message.type}
                  width="250px"
                  height="250px"
                  className="my-2 rounded w-100"
                />
              </div>
            ) : (
              <div
                className={`border-primary border px-2 py-1 rounded d-flex height-70px ${
                  window.innerWidth > 900 ? "width-280px" : "width-195px"
                }`}
              >
                <Image
                  local={true}
                  src={
                    convo?.message?.title?.includes(".pdf")
                      ? require("../images/PDF.webp")
                      : require("../images/doc.webp")
                  }
                  alt={convo}
                  className={
                    convo?.message?.title?.includes(".pdf") ? "m-1" : ""
                  }
                />
                <div className="mt-md-3 ms-1 fontsize-smaller word-break">
                  {convo?.message?.title}
                </div>
              </div>
            ))}
        </p>
      </React.StrictMode>
    );
  };

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  return (
    <React.Fragment>
      {!isFetching && conversation && conversation.length > 0 && (
        <div
          className="pr-4 overflowX-hidden px-3 pt-2"
          style={{ filter: "blur(0px)" }}
        >
          {conversation?.map((convo, index) => {
            if (convo?.postedByUser?.userId !== userID) {
              return (
                <div className="d-flex mt-2" id={`conversation_${index}`}>
                  <div className="col-11 d-flex justify-content-start">
                    <div
                      className="d-flex justify-content-between rounded"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #e9ecef",
                      }}
                    >
                      <div
                        className="text-start"
                        style={{ padding: "8px 12px" }}
                      >
                        <MessageCard convo={convo} left={true} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="row mt-3" id={`conversation_${index}`}>
                  <div className="col-sm-12 col-12 d-flex justify-content-end pe-3 ">
                    <div
                      className="mb-1 d-flex justify-content-end rounded bg-primary text-white"
                      style={{
                        backgroundColor: "#e9ecef",
                        padding: "8px 12px",
                      }}
                    >
                      <div className="d-flex cards ">
                        <div className="text-end p-1">
                          <MessageCard convo={convo} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}
      {isFetching && conversation.length === 0 && (
        <div
          className="pr-4 overflowX-hidden px-3 pt-2 text-center"
          style={{ filter: "blur(0px)" }}
        >
          Connecting...
        </div>
      )}
      <div ref={bottomRef} />
    </React.Fragment>
  );
};

export default MessageBody;
