import { yupResolver } from '@hookform/resolvers/yup';
import { isUndefined } from 'lodash';
import { SetIntercomSettings } from '../constants/index';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HelmetTag from '../components/helmet/Helmet';
import axios from '../util/axiosInst';
import SocialMediaLogin from '../components/SocialMediaLogin';
import { bootWithProps } from '../util/intercom';
import Layout from '../mockup-v3/landing-page/components/layout/Layout';

const Login = () => {
  const schema = yup
    .object({
      email: yup.string().email('Email must be a valid email').required('Email is a required '),
      password: yup.string().required('Password is a required '),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isVisibile, setIsVisibile] = useState(false);

  const renderRoutes = (data) => {
    if (data.user?.userType === 'personal_trainer') return '/personal-trainer-profile';
    else if (data.user?.userType === 'hospital') return '/hospital-profile';
    else if (data.user?.userType === 'clinic') return '/clinic-profile';
    else if (data.user?.userType === 'rehabilitation_center') return '/rehabilitation-center-profile';
    else if (data.user?.userType === 'gym') return '/gym-profile';
    else if (data.user?.userType === 'athletic_trainers') return '/athletic-trainers-profile';
    else if (data.user?.userType === 'wellness_provider') return '/wellness-provider-profile';
    else if (data.user?.userType === 'physical_therapist') return '/physical-therapist-profile';
    else return '/provider-profile';
  };

  const onSuccessLogin = async (data) => {
    try {
      if (data && (data.user.role === 'provider' || data.user.role === 'patient')) {
        if (data?.user?.membershipId) {
          const res = await axios.get('/v1/public/membership/plan/' + data?.user?.membershipId);
          sessionStorage.setItem('membershipDetails', JSON.stringify(res.data));
        }
        let zipcode = '';
        let slug = '';
        let phoneNumber = '';
        let userType = '';
        if (data.providerDetails?.phoneNumber) {
          phoneNumber = data.providerDetails?.phoneNumber;
        } else if (data?.user?.userType === 'patient' && data?.user?.phoneNumber) {
          phoneNumber = data.user?.phoneNumber;
        }
        if (data.providerDetails?.zipcode) {
          zipcode = data.providerDetails?.zipcode;
        }
        slug = data?.providerDetails?.slug;
        userType = data?.providerDetails?.userType;
        if (
          data?.user?.userType === 'nurse_practitioner' ||
          data?.user?.userType === 'orthopedic_surgeon' ||
          data?.user?.userType === 'neuro_surgeon' ||
          data?.user?.userType === 'primary_care'
        ) {
          if (data.providerDetails?.clinic.length > 0) {
            if (data.providerDetails?.clinic[0].zipcode !== '') {
              zipcode = data.providerDetails?.clinic[0].zipcode;
            }
          }
        }
        if (data?.user?.userType === 'physical_therapist') {
          if (!isUndefined(data.providerDetails?.rehab_info)) {
            if (data.providerDetails?.rehab_info.zipcode !== '') {
              zipcode = data.providerDetails?.rehab_info.zipcode;
            }
            if (data.providerDetails?.rehab_info?.phoneNumber !== '') {
              phoneNumber = data.providerDetails?.rehab_info?.phoneNumber;
            }
          }
        }
        if (data?.user?.userType === 'wellness_provider') {
          if (!isUndefined(data.providerDetails?.business_info)) {
            if (data.providerDetails?.business_info.zipcode !== '') {
              zipcode = data.providerDetails?.business_info.zipcode;
            }
          }
        }

        let user = {
          ...data.user,
          zipcode,
          phoneNumber,
          slug,
          userType,
          planType: data.providerDetails?.planType || null,
          planStatus: data.providerDetails?.planStatus || null,
          isPaid: data.providerDetails?.isPaid || false,
          stripeCustomerId: data.providerDetails?.stripeCustomerId || null,
          stripePaymentStatus: data.providerDetails?.stripePaymentStatus || null,
          stripeSubscriptionId: data.providerDetails?.stripeSubscriptionId || null,
        };
        localStorage.setItem('mop-token', JSON.stringify(data.tokens));
        localStorage.setItem('mop-user', JSON.stringify(user));
        SetIntercomSettings();
        if (data?.claimProfile) {
          localStorage.setItem('claim-status', JSON.stringify(data?.claimProfile));
          localStorage.setItem('claim-id', JSON.stringify(data?.claimId));
        }

        if (data?.claimProfile && data?.claimProfile === true) {
          window.location.href = `${renderRoutes(data)}`;
        } else {
          window.location.href = `${data.user.role === 'provider' ? '/pro-home' : '/pat-home'}`;
        }
      } else {
        toast.error('Only providers and patients are allowed', {
          position: 'top-right',
          autoClose: 12000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (formData) => {
    formData.role = 'provider';
    try {
      window.fbq('track', 'User Logged In.', {
        email: formData.email,
      });
      await axios
        .post('/v1/auth/login', formData)
        .then(async ({ data }) => {
          await onSuccessLogin(data);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: 'top-right',
            autoClose: 12000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'light',
          });
        });
    } catch (e) {
      console.error(e);
    }
  };

  const onSuccess = async ({ provider, data }) => {
    const formData = data;
    formData.role = 'provider';
    try {
      window.fbq('track', 'User Logged In.', {
        email: formData.email,
      });
      const { data } = await axios.post('/v1/auth/google/login', formData);
      await onSuccessLogin(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    document.getElementById('root').scrollTo(0, 0);
    bootWithProps();
  }, []);

  return (
    <Layout>
      <div className="wrapper">
        <HelmetTag
          title="Login | My Orthopedic Problem"
          content="Start your orthopedic journey with My Orthopedic Problem. We connect patients with leading orthopedic specialists. Log in to use our tools."
          canonical="https://myorthopedicproblem.com/login"
          JSONLD={`
        {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "Login",
          "url": "http://myorthopedicproblem.com/login",
          "description":"Start your orthopedic journey with My Orthopedic Problem. We connect patients with leading orthopedic specialists. Log in to use our tools."
        }
        `}
        />
        <section className="pb-3 my-5">
          <div className="container mt-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row justify-content-center py-5">
                <div className="col-sm-6 pt-md-5 d-none d-md-block resetPassword-text">
                  <div className=" heading-font my-5 text-left paddingtop-10rem ">Welcome Back</div>
                </div>
                <div className="col-sm-6 py-md-5 px-md-5 text-center">
                  <div className="ps-md-5 d-flex m-0 m-auto">
                    <div className="bg-white w-100 rounded p-3 px-md-5 border shadow-lg">
                      <h2 className="text-primary py-2 d-none d-md-block">Login</h2>
                      <div className="d-sm-block d-md-none">
                        <h2 className="py-2">Welcome back</h2>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className={`form-control ${errors.email && 'is-invalid'}`}
                          placeholder="Email"
                          {...register('email')}
                        />
                        <div className="invalid-feedback text-start">{errors.email?.message}</div>
                      </div>
                      <div className="input-group mb-3 bg-white">
                        <input
                          type={isVisibile ? 'text' : 'password'}
                          className={`form-control bg-transparent ${errors.password && 'is-invalid'}`}
                          placeholder="Password"
                          {...register('password')}
                        />
                        <span
                          className={`input-group-text border-top-right-radius cursor-pointer ${
                            errors.password && 'border-danger'
                          }`}
                          onClick={() => setIsVisibile(!isVisibile)}
                        >
                          {!isVisibile ? (
                            <i className="far fa-eye text-muted" />
                          ) : (
                            <i className="far fa-eye-slash text-muted" />
                          )}
                        </span>
                        <div className="invalid-feedback text-start">{errors.password?.message}</div>
                      </div>
                      <div className="text-start">
                        <Link to="/forgot-password" className="text-decoration-none">
                          Forgot password?
                        </Link>
                      </div>
                      <button type="submit" className="text-white btn btn-primary w-100 mt-3">
                        Continue
                      </button>
                      <p className="mt-3 text-center">
                        Don’t have an account?{' '}
                        <Link to="/signup" className={'text-decoration-none'}>
                          Sign Up
                        </Link>
                      </p>
                      <div className="mt-4 loginor d-flex w-100 align-items-center">
                        <span>OR</span>
                      </div>
                      <p className="mt-3 text-center">
                        <div className="row">
                          <div className="col-sm-12">
                            <SocialMediaLogin provider={'google'} onSuccess={onSuccess} />
                          </div>
                          <div className="col-sm-12">
                            <SocialMediaLogin provider={'facebook'} onSuccess={onSuccess} />
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Login;
