import React from "react";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="p-5">
      <div className="container rounded  mb-3 bg-white py-3">
        <div className="text-center mt-5 p-4">
          <h1 className="heading-font-404">404</h1>
          <br />
          <h1 className="my-3 text-primary">
            This page is not available, Please go to home.
          </h1>
          <button
            type="button"
            onClick={() => {
              navigate("/");
            }}
            className="w-fit btn btn-primary btn-lg rounded-pill fw-semibold fs-md-6 fs-5  px-5 border-0  py-2 d-flex my-0 mx-auto justify-content-center"
          >
            Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
