import React, { useEffect } from "react";
import SideBar from "./SideBar";
import { useLocation } from "react-router-dom";
import axios from "../utils/axiosInst";
import { PAGE_VARIABLES } from "../constants/constant";

const Layout = ({ children }) => {
  const location = useLocation();
  const getPatientDetails = async () => {
    if (PAGE_VARIABLES?.AUTH?._id) {
      const { data } = await axios.get(
        `/v1/patient/${PAGE_VARIABLES?.AUTH?._id}`
      );
      localStorage.setItem(
        "mop-user",
        JSON.stringify({ ...data, role: "patient", _id: data?.userId })
      );
      PAGE_VARIABLES.AUTH = { ...data, role: "patient", _id: data?.userId };
    }
  };

  useEffect(() => {
    getPatientDetails();
  }, []);

  return (
    <div className="d-flex m-0 bg-F5F5F7 vh-100">
      <SideBar />
      <div
        className={`flex-fill ${
          location?.pathname.includes("pat-messages")
            ? "overflow-hidden"
            : "scroll-div vh-100"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;
