import React from "react";
// import Image from "../../../../components/Image";
import axios from "../utils/axiosInst";
import DefaultUserLogo from "../Components/DefaultUserLogo";

const ConversationCard = ({
  chat,
  getConverserationByRoomId,
  setCurrentUser,
  roomId,
  formatDateTime,
  getChatList,
  setIsFiles,
}) => {
  const markAsRead = async (chatRoomId) => {
    await axios.put(`/v1/chat/${chatRoomId}/mark-read`);
    getChatList();
  };
  return (
    <div className="infiniteScroll">
      {chat?.conversation?.length ? (
        chat?.conversation?.map((map) => (
          <div
            className={`conversationSnapshot ${
              map?.chatRoomId === roomId ? "active" : ""
            }`}
            onClick={() => {
              markAsRead(map?.chatRoomId);
              getConverserationByRoomId(map?.chatRoomId);
              setIsFiles(false);
              setCurrentUser(
                map?.roomType === "group"
                  ? {
                      name: map?.roomName,
                      userId: map?.chatUser?.userId || map?.chatUser?._id,
                      roomType: map?.roomType,
                    }
                  : {
                      name: `${map?.chatUser?.firstName || ""} ${
                        map?.chatUser?.lastName || ""
                      }`.trim(),
                      userId: map?.chatUser?.userId || map?.chatUser?._id,
                      roomType: map?.roomType,
                    }
              );
            }}
          >
            <div className="avatarContainer sm">
              <div className="avatar">
                {/* <Image
                  src={map?.chatUser?.photo}
                  alt="Chat0"
                  className="rounded-circle w-100 "
                  loading="lazy"
                  type="provider"
                  local={true}
                /> */}
                <DefaultUserLogo height="42px" width="42px" />
              </div>
            </div>
            <div className="details">
              <span className="d-flex justify-content-between">
                <h6
                  className="fw-semibold text-truncate"
                  title={
                    map?.roomType !== "group"
                      ? map?.chatUserName
                      : map?.roomName
                  }
                >
                  {map?.roomType !== "group"
                    ? `${map?.chatUser?.firstName || ""} ${
                        map?.chatUser?.lastName || ""
                      }`.trim()
                    : map?.roomName || ""}
                </h6>
                {map?.unreadMessages > 0 ? (
                  <span className="bg-primary count fs-12px text-center pb-2">
                    {map?.unreadMessages}
                  </span>
                ) : (
                  <></>
                )}
              </span>
              <div className="text-capitalize msg ellipses">
                {map?.message?.type !== "text" ? (
                  <>
                    {map?.message && (
                      <i
                        className={`${
                          map?.message.type === "image"
                            ? "fa fa-photo"
                            : map?.message.type === "video"
                            ? "fa fa-video"
                            : "fa fa-file"
                        } me-1`}
                      ></i>
                    )}
                    {map?.message?.type === "application"
                      ? "document"
                      : map?.message?.type}
                  </>
                ) : (
                  map?.message?.message
                )}
              </div>
            </div>
            <div className="status">
              <div className="time">{formatDateTime(map?.createdAt)}</div>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default ConversationCard;
