import React, { useEffect, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Radio from "../../../../components/Radio";
import Slider from "../../../../components/Slider";
import Checkbox from "../../../../components/Checkbox";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import axios from "../../../../util/axiosInst";
import { muscleGroupConstant } from "../../../../util/utils";
import LoadingScreen from "../../../../components/custom/LoadingScreen";
import ProgressLine from "../../../../components/ProgressLine";
import { toast } from "react-toastify";
import Layout from "../layout/Layout";
import Summary from "./Summary";

const MuscleType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { muscleType } = useParams();

  const answers = useStoreState((state) => state.answers);

  const selectedMuscleGroupServerId = useStoreState(
    (state) => state.selectedMuscleGroupServerId
  );
  const currentQuestionNo = useStoreState((state) => state.currentQuestionNo);
  const questions = useStoreState((state) => state.questions);
  const results = useStoreState((state) => state.results);
  const [progress, setprogress] = useState(0);
  const [loading, setLoading] = useState(false);
  const setMuscleGroup = useStoreActions((actions) => actions.setMuscleGroup);

  const nextQuestion = useStoreActions((actions) => actions.nextQuestion);
  const prevQuestion = useStoreActions((actions) => actions.prevQuestion);
  const setResults = useStoreActions((actions) => actions.setResults);
  const setQuestions = useStoreActions((actions) => actions.setQuestions);
  const setEducationPageModal = useStoreActions(
    (actions) => actions.setEducationPageModal
  );
  const submitTest = async (submitValue) => {
    let userData = JSON.parse(localStorage.getItem("mop-user"));
    if (userData) {
      let Data = {
        email: userData?.email,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        zipcode: userData?.zipcode,
      };
      setEducationPageModal(Data);
    }
    setResults([{}]);
    setLoading(true);
    const { data } = await axios.post("/v1/public/evaluation/results", {
      id: selectedMuscleGroupServerId,
      responses: submitValue,
    });
    const response = await axios.post("/v1/evaluation/history", {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      zipcode: userData?.zipcode ? userData?.zipcode.toString() : "0",
      request: {
        id: selectedMuscleGroupServerId,
        responses: submitValue,
      },
      result: data,
    });
    localStorage?.setItem("evaluation-history-id", response?.data?._id);
    setResults(data);
    setLoading(false);
    if (userData) {
      await axios.post(`v1/public/blog-views`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        zipcode: userData.zipcode?.toString().length
          ? Number(userData.zipcode)
          : 0,
        type: "evaluation",
      });
      window.fbq("track", "Education Page Modal Data is closed.", {
        name: userData.firstName + userData.lastName,
        email: userData.email,
      });
      mixpanel.track("User", {
        type: "Geo Data",
        email: userData.email,
        name: `${userData.zipcode}`,
      });

      mixpanel.track("User", {
        type: "count",
        email: userData.email,
        name: userData.firstName + " " + userData.lastName,
      });
    }
  };

  const nextQuestionHandler = (submitValue) => {
    document.getElementById("root").scrollTo(0, 0);
    if (currentQuestionNo === questions.length - 1) {
      setprogress(((currentQuestionNo + 1) / questions.length) * 100);
      submitTest(submitValue);
    } else {
      nextQuestion();
      setprogress(((currentQuestionNo + 1) / questions.length) * 100);
    }
  };

  const removeDuplicates = (arr) => {
    return [...new Set(arr)];
  };

  const capitalizeFirstLetter = (string) => {
    if (string && !Array.isArray(string)) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else if (Array.isArray(string)) {
      const uniqueChars = removeDuplicates(string);
      const joinStr = uniqueChars.join(", ");
      return joinStr.charAt(0).toUpperCase() + joinStr.slice(1);
    }
    return string;
  };

  const getSummaryText = (value) => {
    let clearedValue =
      typeof value === "object"
        ? value.map((v) => capitalizeFirstLetter(v)).join(", ")
        : String(value);

    return capitalizeFirstLetter(clearedValue).split("_").join(" ");
  };

  const capitalizeValue = (value) => {
    let capitalizedValue = capitalizeFirstLetter(value);
    return capitalizedValue.split("_").join(" ");
  };

  const fetchQuestionsForMuscleGroup = async (
    muscleGroupId,
    muscleGroupServerId
  ) => {
    setMuscleGroup({
      selectedMuscleGroup: muscleGroupId,
      selectedMuscleGroupServerId: muscleGroupServerId,
    });
    const { data } = await axios.get(
      `/v1/public/evaluation/type/${muscleGroupServerId}`
    );
    setQuestions(data.prompts);
  };

  const initQuestions = async (muscleType) => {
    const { data } = await axios.get("/v1/public/evaluation/type");
    const muscleGroupsLocal = data.map((muscleGroup) => {
      return {
        name: muscleGroup.name,
        id: muscleGroup.evaluationType,
        serverId: muscleGroup.id,
      };
    });

    const targetedMuscleGroup = muscleGroupsLocal.find(
      (muscleGroup) => muscleGroup.id === muscleType
    );

    fetchQuestionsForMuscleGroup(
      targetedMuscleGroup.id,
      targetedMuscleGroup.serverId
    );
  };

  useEffect(() => {
    if (!muscleGroupConstant?.includes(muscleType)) navigate("/404");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.fbq("track", `Muscle Type getting successfully.`);
    if (location.state === true) {
      document
        .querySelector("#evaluationDisclaimerModal")
        .classList.add("show");
      document.querySelector("#evaluationDisclaimerModal").style.display =
        "block";
      document
        .getElementById("custom-drop")
        .classList.add("modal-backdrop", "fade", "show");
    }
    if (muscleType) initQuestions(muscleType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [muscleType]);

  return (
    <Layout>
      <div className="bg-white">
        <div className="mb-4">
          <section className="sec_three py-4">
            <div className="container bg-white rounded-1">
              {questions.length > 0 &&
                currentQuestionNo > -1 &&
                currentQuestionNo < questions.length && (
                  <>
                    <div className="row">
                      <div
                        className={
                          !results && Object.keys(answers).length > 0
                            ? "col-sm-9"
                            : "col-sm-12"
                        }
                      >
                        <div className="  p-md-4 p-2 rounded">
                          {results ? (
                            <h1 className="text-black  heading-font">
                              Symptom Checker - Results
                            </h1>
                          ) : (
                            <h1 className="text-black  heading-font">
                              Symptom Checker
                            </h1>
                          )}
                          {results && (
                            <h4 className="fw-semibold">
                              Possible sources of your pain. Click each
                              Condition to learn more, or Find a Provider to get
                              treated!
                            </h4>
                          )}
                          {!results && (
                            <div className="d-flex flex-wrap flex-md-nowrap">
                              <p className="mb-md-0 mb-1 me-2">
                                {currentQuestionNo + 1}/{questions.length}
                              </p>
                              <ProgressLine progress={progress} />
                            </div>
                          )}
                          <div className="row pt-4">
                            {loading ? (
                              <>
                                <LoadingScreen color={"primary"} />
                                <h2 className="text-primary text-center pt-2">
                                  Results are loading, Please wait.
                                </h2>
                              </>
                            ) : (
                              results && <Summary />
                            )}
                            {!results &&
                              questions[currentQuestionNo].input ===
                                "radio" && (
                                <Radio
                                  question={
                                    questions[currentQuestionNo].question[0]
                                      .label
                                  }
                                  options={questions[currentQuestionNo].options}
                                  prompt={
                                    questions[currentQuestionNo].question[0]
                                      .prompt
                                  }
                                  nextQuestionHandler={nextQuestionHandler}
                                  data={questions[currentQuestionNo]}
                                />
                              )}

                            {!results &&
                              questions[currentQuestionNo].input ===
                                "slider" && (
                                <Slider
                                  question={
                                    questions[currentQuestionNo].question[0]
                                      .label
                                  }
                                  prompt={
                                    questions[currentQuestionNo].question[0]
                                      .prompt
                                  }
                                  options={location?.state}
                                  data={questions[currentQuestionNo]}
                                />
                              )}

                            {!results &&
                              questions[currentQuestionNo].input ===
                                "checkbox" && (
                                <Checkbox
                                  question={
                                    questions[currentQuestionNo].question[0]
                                      .label
                                  }
                                  options={questions[currentQuestionNo].options}
                                  prompt={
                                    questions[currentQuestionNo].question[0]
                                      .prompt
                                  }
                                  data={questions[currentQuestionNo]}
                                />
                              )}
                          </div>
                          {!results && (
                            <div className="row d-md-none d-block">
                              <div className="col-12">
                                <div className="d-flex justify-content-between">
                                  <button
                                    className="btn border-22  border-white p-0 pe-3 py-1 rounded bg-muted-25 fs-5 "
                                    onClick={() => {
                                      if (currentQuestionNo === 0) {
                                        navigate("/symptom-checker");
                                      } else {
                                        prevQuestion();
                                      }
                                      document
                                        .getElementById("root")
                                        .scrollTo(0, 0);
                                    }}
                                  >
                                    <MdArrowBack className=" mx-2 fs-4" />
                                    Back
                                  </button>
                                  <button
                                    className={`btn border-22 btn-primary p-0 ${
                                      currentQuestionNo === questions.length - 1
                                        ? "px-3"
                                        : "ps-3"
                                    } fs-5 border-none rounded bg-primary `}
                                    onClick={() => {
                                      const answered =
                                        answers[
                                          questions[currentQuestionNo]
                                            .question[0].prompt
                                        ]?.constructor === Array
                                          ? answers[
                                              questions[currentQuestionNo]
                                                .question[0].prompt
                                            ].length !== 0
                                          : answers[
                                              questions[currentQuestionNo]
                                                .question[0].prompt
                                            ] !== undefined;
                                      if (answered) {
                                        nextQuestionHandler();
                                      } else {
                                        alert(
                                          "You must select Atleast one Option"
                                        );
                                      }
                                    }}
                                  >
                                    {currentQuestionNo === questions.length - 1
                                      ? "Submit"
                                      : "Next"}
                                    {currentQuestionNo !==
                                      questions.length - 1 && (
                                      <MdArrowForward className=" mx-2 fs-4" />
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {!results && (
                        <div className="col-sm-3 summary-section my-4">
                          {Object.keys(answers).length > 0 && (
                            <div className="bg-muted-25 mt-md-5 rounded-3 p-3  me-md-3 mb-md-5">
                              <p>
                                <h5 className="fw-semibold text-center mb-2">
                                  Summary
                                </h5>
                              </p>
                              <div className=" mb-3"></div>
                              {Object.keys(answers).map((key) => (
                                <div
                                  className="d-flex justify-content-between  mb-3"
                                  key={key}
                                >
                                  <p className="text-black ">
                                    {capitalizeValue([key])}
                                  </p>
                                  <p className="px-2 fw-semibold text-end text-primary">
                                    {getSummaryText(answers[key])}
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="container p-md-4 p-2 d-md-block d-none">
                        <div className="row px-md-2">
                          <div className="mb-4 col-sm-12">
                            {!results && (
                              <div className="row">
                                <div className="col-12 pe-2">
                                  <div className="d-flex justify-content-between">
                                    <button
                                      className="btn border-22 border-white p-0 pe-3 py-1 rounded bg-muted-25 fs-5 "
                                      onClick={() => {
                                        if (currentQuestionNo === 0) {
                                          navigate("/symptom-checker");
                                        } else {
                                          prevQuestion();
                                        }
                                        document
                                          .getElementById("root")
                                          .scrollTo(0, 0);
                                      }}
                                    >
                                      <MdArrowBack className=" mx-2 fs-4" />
                                      Back
                                    </button>
                                    <button
                                      className={`btn border-22 btn-primary p-0 ${
                                        currentQuestionNo ===
                                        questions.length - 1
                                          ? "px-3"
                                          : "ps-3"
                                      } fs-5 border-none rounded bg-primary `}
                                      onClick={() => {
                                        const answered =
                                          answers[
                                            questions[currentQuestionNo]
                                              .question[0].prompt
                                          ]?.constructor === Array
                                            ? answers[
                                                questions[currentQuestionNo]
                                                  .question[0].prompt
                                              ].length !== 0
                                            : answers[
                                                questions[currentQuestionNo]
                                                  .question[0].prompt
                                              ] !== undefined;
                                        if (answered) nextQuestionHandler();
                                        else
                                          toast.error(
                                            "You must select Atleast one Option"
                                          );
                                      }}
                                    >
                                      {currentQuestionNo ===
                                      questions.length - 1
                                        ? "Submit"
                                        : "Next"}
                                      {currentQuestionNo !==
                                        questions.length - 1 && (
                                        <MdArrowForward className=" mx-2 fs-4" />
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};
export default MuscleType;
