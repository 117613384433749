import { LocalVideoTrack, RemoteVideoTrack } from "twilio-video";

LocalVideoTrack.isSwitchedOff = undefined;
LocalVideoTrack.setPriority = undefined;

export const Callback = (...args) => undefined;

export const ErrorCallback = (error) => undefined;

export const IVideoTrack = LocalVideoTrack || RemoteVideoTrack;

export const RoomType = "group" || "group-small" || "peer-to-peer" || "go";

export const RecordingRule = {
  type: "include" || "exclude",
  all: false,
  kind: "audio" || "video",
  publisher: "",
};

export const RecordingRules = [RecordingRule];
