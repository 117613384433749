import React, { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import { ToastContainer } from "react-toastify";
import { hideIntercomChatWidget } from "../../util/intercom";
import PageNotFound from "./components/not-found/PageNotFound.jsx";

const Home = lazy(() => import("./components/new-home/Home"));
const Notification = lazy(() =>
  import("./components/notification/Notifiation.jsx")
);
const Patient = lazy(() => import("./components/Patients/Patients.jsx"));
const Appointment = lazy(() =>
  import("./components/Appointments/Appoinment.jsx")
);
const Schedule = lazy(() => import("./components/Schedule/Schedule.jsx"));
const ProviderMessage = lazy(() => import("./pages/messages/Messages.jsx"));
const Call = lazy(() => import("./components/Calls/Calls.jsx"));
const Analytics = lazy(() => import("./components/Analytics/Analytics.jsx"));
const Settings = lazy(() => import("./components/Settings/Settings.jsx"));
const Referrals = lazy(() => import("./components/Referrals/Referrals.jsx"));
const ViewReferral = lazy(() => import("./pages/Referrals/ViewReferrals.jsx"));
const CreateReferral = lazy(() =>
  import("./pages/Referrals/CreateReferrals.jsx")
);
const Login = lazy(() => import("../../pages/Login.jsx"));
const SocketAlert = lazy(() => import("./components/common/SocketAlert.jsx"));

// const CreateAccount = lazy(() =>
//   import("./components/CreateAccount/CreateAccount.jsx")
// );
// const SuccessPageNew = lazy(() =>
//   import("./components/CreateAccount/SuccessPage.jsx")
// );
const ProviderApp = () => {
  const RequireAuth = ({ children }) => {
    let isAuthenticated = localStorage.getItem("mop-token");
    return isAuthenticated ? children : <Navigate to="/login" />;
  };
  const UnRequireAuth = ({ children }) => {
    let isAuthenticated = localStorage.getItem("mop-token");
    return !isAuthenticated ? children : <Navigate to="/pro-home" />;
  };

  useEffect(() => {
    hideIntercomChatWidget();
  }, []);

  return (
    <Suspense fallback={<Layout></Layout>}>
      <SocketAlert />
      <ToastContainer limit={1} />
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={
            <UnRequireAuth>
              <Login />
            </UnRequireAuth>
          }
        />
      </Routes>
      <Layout>
        <Routes>
          <Route
            path="/pro-home"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path="/pro-notifications"
            element={
              <RequireAuth>
                <Notification />
              </RequireAuth>
            }
          />
          <Route
            path="/view-referral/:id"
            element={
              <RequireAuth>
                <ViewReferral />
              </RequireAuth>
            }
          />
          <Route
            path="/pro-appointment"
            element={
              <RequireAuth>
                <Appointment />
              </RequireAuth>
            }
          />
          <Route
            path="/pro-patients"
            element={
              <RequireAuth>
                <Patient />
              </RequireAuth>
            }
          />
          <Route
            path="/pro-schedule"
            element={
              <RequireAuth>
                <Schedule />
              </RequireAuth>
            }
          />
          <Route
            path="/pro-provider-messages"
            element={
              <RequireAuth>
                <ProviderMessage />
              </RequireAuth>
            }
          />
          <Route
            path="/pro-calls"
            element={
              <RequireAuth>
                <Call />
              </RequireAuth>
            }
          />
          <Route
            path="/pro-referrals"
            element={
              <RequireAuth>
                <Referrals />
              </RequireAuth>
            }
          />
          <Route
            path="/create-referral"
            element={
              <RequireAuth>
                <CreateReferral />
              </RequireAuth>
            }
          />
          <Route
            path="/pro-analytics"
            element={
              <RequireAuth>
                <Analytics />
              </RequireAuth>
            }
          />
          <Route
            path="/pro-settings"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path="*"
            element={
              <RequireAuth>
                <PageNotFound />
              </RequireAuth>
            }
          />
        </Routes>
      </Layout>
    </Suspense>
  );
};

export default ProviderApp;
